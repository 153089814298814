import React, { useState } from "react";
import PropTypes from "prop-types";
import Index from "../../../Index";
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import {
  changePasswordProfile,
  editProfileUpdate,
} from "../../../../redux/slices/AdminService";
import { useNavigate } from "react-router-dom";
import {
  ChangePasswordSchemaProfile,
  EditProfileSchema,
} from "../../../../validation/Validation";
import { imageUrl } from "../../../../config/dataService";
// for tabs design

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function EditProfile() {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading,setLoading]=useState(false)

  const { adminData } = useSelector((state) => state.admin);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickShowPassword = (field) => {
    switch (field) {
      case "oldPassword":
        setShowOldPassword((show) => !show);
        break;
      case "newPassword":
        setShowNewPassword((show) => !show);
        break;
      case "confirmPassword":
        setShowConfirmPassword((show) => !show);
        break;
      default:
        break;
    }
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  //edit profile call
  const handleEditProfile = (values) => {
    setLoading(true)
    const formdata = new FormData();
    formdata.append("id", adminData._id);
    for (let key in values) {
      formdata.append(key, values[key]);
    }

    dispatch(editProfileUpdate(formdata)).then((res)=>{
      setLoading(false)
    });
  };
  const submitForm = (values, { resetForm }) => {
    try {
      dispatch(changePasswordProfile(values)).then((data) => {
        if (data?.payload === 200) {
          resetForm({
            ...values,
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
          });
        }
      });
    } catch (error) {}
  };
  return (
    <Index.Box className="dashboard-content edit-profile-containt">
      <Index.Typography
        className="admin-page-title"
        component="h2"
        variant="h2"
      >
        Account Settings
      </Index.Typography>

      <Index.Box className="tabs-main-box">
        <Index.Box sx={{ width: "100%" }}>
          <Index.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Index.Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              className="admin-tabs-main"
            >
              <Index.Tab
                label="Edit Profile"
                {...a11yProps(0)}
                className="admin-tab"
              />
              <Index.Tab
                label="Change Password"
                {...a11yProps(1)}
                className="admin-tab"
              />
            </Index.Tabs>
          </Index.Box>
          <Formik
            initialValues={{
              name: adminData?.name,
              email: adminData?.email,
              mobileNumber: adminData?.mobileNumber,
            }}
            onSubmit={handleEditProfile}
            validationSchema={EditProfileSchema}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <TabPanel value={value} index={0} className="admin-tabpanel">
                  <Index.Box className="tabpanel-main">
                    <Index.Box className="page-border">
                      <Index.Typography
                        className="common-para edit-highlight-text"
                        component="p"
                        variant="p"
                      ></Index.Typography>
                      <Index.Typography
                        className="common-para edit-para-text"
                        component="p"
                        variant="p"
                      ></Index.Typography>
                      <Index.Box className="edit-profile-flex">
                        <Index.Box className="file-upload-btn-main">
                          <img
                            src={
                              props.values.image
                                ? URL.createObjectURL(props.values.image)
                                : adminData?.image === "false"
                                ? Index.Png.usericon
                                : `${imageUrl}/${adminData?.image}`
                            }
                            onChange={(e) => e.target.files[0]}
                            className="upload-profile-img"
                            alt="upload img"
                          ></img>
                          <Index.Button
                            variant="contained"
                            component="label"
                            className="file-upload-btn"
                          >
                            <img
                              src={Index.Svg.edit}
                              className="upload-icon-img"
                              alt="upload img"
                            ></img>
                            <input
                              hidden
                              accept="image/*"
                              name="image"
                              type="file"
                              onChange={(e) => {
                                try {
                                  props.setFieldValue(
                                    "image",
                                    e.target.files[0]
                                  );
                                } catch (error) {
                                  console.error(error);
                                  e.target.value = null;
                                }
                              }}
                            />
                          </Index.Button>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="add-user-data-main">
                        <Index.Box sx={{ width: 1 }} className="grid-main">
                          <Index.Box
                            display="grid"
                            className="display-row-edit-profile"
                            gridTemplateColumns="repeat(12, 1fr)"
                            gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                          >
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box add-user-input">
                                <Index.FormHelperText className="form-lable">
                                  Name*
                                </Index.FormHelperText>
                                <Index.Box className="form-group">
                                  <Index.TextField
                                    fullWidth
                                    name="name"
                                    value={props.values.name}
                                    onChange={(event) => {
                                      const inputValue = event.target.value;
                                      if (inputValue.length <= 30) {
                                        // Only update the value if it's not more than 30 characters
                                        props.handleChange(event);
                                      }
                                    }}
                                    maxLength={30}
                                    helperText={props.errors.name}
                                    error={Boolean(props.errors.name)}
                                    id="fullWidth"
                                    className="form-control"
                                  />
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box add-user-input">
                                <Index.FormHelperText className="form-lable">
                                  Email*
                                </Index.FormHelperText>
                                <Index.Box className="form-group">
                                  <Index.TextField
                                    name="email"
                                    value={props.values.email}
                                    onChange={props.handleChange}
                                    helperText={props.errors.email}
                                    error={Boolean(props.errors.email)}
                                    fullWidth
                                    id="fullWidth"
                                    className="form-control"
                                    placeholder=""
                                  />
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box add-user-input">
                                <Index.FormHelperText className="form-lable">
                                  Mobile number*
                                </Index.FormHelperText>
                                <Index.Box className="form-group">
                                  <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    type="number"
                                    className="form-control"
                                    placeholder=""
                                    name="mobileNumber"
                                    value={props.values.mobileNumber}
                                    onChange={(event) => {
                                      const inputValue = event.target.value;
                                      if (inputValue.length <= 10) {
                                        // Only update the value if it's not more than 10 characters
                                        props.handleChange(event);
                                      }
                                    }}
                                    helperText={props.errors.mobileNumber}
                                    error={Boolean(props.errors.mobileNumber)}
                                    maxLength={10}
                                  />
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box className="save-btn-main border-btn-main edit-profile-btn-main">
                            <Index.Button
                              className="save-user-btn border-btn"
                              type="submit"
                              disabled={loading}
                            >
                              <img
                                alt="img"
                                src={Index.Svg.save}
                                className="user-save-icon"
                              ></img>
                              Update
                            </Index.Button>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </TabPanel>
              </form>
            )}
          </Formik>
          <TabPanel value={value} index={1} className="admin-tabpanel">
            <Index.Box className="tabpanel-main">
              <Index.Box className="change-pass-main">
                <Index.Typography
                  className="common-para edit-highlight-text"
                  component="p"
                  variant="p"
                >
                  Password
                </Index.Typography>
                <Index.Typography
                  className="common-para edit-para-text"
                  component="p"
                  variant="p"
                >
                  Enter your current & new password to change your password
                </Index.Typography>
                <Formik
                  validationSchema={ChangePasswordSchemaProfile}
                  initialValues={{
                    oldPassword: "",
                    newPassword: "",
                    confirmPassword: "",
                  }}
                  onSubmit={submitForm}
                >
                  {({
                    values,
                    errors,
                    handleChange,
                    handleSubmit,
                    isValid,
                    dirty,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Index.Box className="input-box change-pass-input-box">
                        <Index.FormHelperText className="form-lable">
                          Old Password*
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            size="small"
                            name="oldPassword"
                            className="form-control input-with-radius password-form-control"
                            type={showOldPassword ? "text" : "password"}
                            // label="Password"
                            variant="outlined"
                            autoComplete="off"
                            inputProps={{
                              className: "input_props",
                            }}
                            InputLabelProps={{ className: "add-formlabel" }}
                            FormHelperTextProps={{
                              className: "input_label_props",
                            }}
                            // onBlur={handleBlur}
                            value={values.oldPassword}
                            onChange={handleChange}
                            helperText={errors.oldPassword}
                            error={Boolean(errors.oldPassword)}
                            sx={{ mb: 3 }}
                            InputProps={{
                              // <-- This is where the toggle button is added.
                              endAdornment: (
                                <Index.InputAdornment position="end">
                                  <Index.IconButton
                                    className="passwrd-eye"
                                    aria-label="toggle password visibility"
                                    onClick={() =>
                                      handleClickShowPassword("oldPassword")
                                    }
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showOldPassword ? (
                                      <Index.VisibilityOff />
                                    ) : (
                                      <Index.Visibility />
                                    )}
                                  </Index.IconButton>
                                </Index.InputAdornment>
                              ),
                            }}
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="input-box change-pass-input-box">
                        <Index.FormHelperText className="form-lable">
                          New Password*
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            size="small"
                            name="newPassword"
                            className="form-control input-with-radius password-form-control"
                            type={showNewPassword ? "text" : "password"}
                            // label="Password"
                            variant="outlined"
                            autoComplete="off"
                            inputProps={{
                              className: "input_props",
                            }}
                            InputLabelProps={{ className: "add-formlabel" }}
                            FormHelperTextProps={{
                              className: "input_label_props",
                            }}
                            // onBlur={handleBlur}
                            value={values.newPassword}
                            onChange={handleChange}
                            helperText={errors.newPassword}
                            error={Boolean(errors.newPassword)}
                            sx={{ mb: 3 }}
                            InputProps={{
                              // <-- This is where the toggle button is added.
                              endAdornment: (
                                <Index.InputAdornment position="end">
                                  <Index.IconButton
                                    className="passwrd-eye"
                                    aria-label="toggle password visibility"
                                    onClick={() =>
                                      handleClickShowPassword("newPassword")
                                    }
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showNewPassword ? (
                                      <Index.VisibilityOff />
                                    ) : (
                                      <Index.Visibility />
                                    )}
                                  </Index.IconButton>
                                </Index.InputAdornment>
                              ),
                            }}
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="input-box change-pass-input-box">
                        <Index.FormHelperText className="form-lable">
                          Confirm Password*
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            size="small"
                            name="confirmPassword"
                            className="form-control input-with-radius password-form-control"
                            type={showConfirmPassword ? "text" : "password"}
                            // label="Password"
                            variant="outlined"
                            autoComplete="off"
                            inputProps={{
                              className: "input_props",
                            }}
                            InputLabelProps={{ className: "add-formlabel" }}
                            FormHelperTextProps={{
                              className: "input_label_props",
                            }}
                            // onBlur={handleBlur}
                            value={values.confirmPassword}
                            onChange={handleChange}
                            helperText={errors.confirmPassword}
                            error={Boolean(errors.confirmPassword)}
                            sx={{ mb: 3 }}
                            InputProps={{
                              // <-- This is where the toggle button is added.
                              endAdornment: (
                                <Index.InputAdornment position="end">
                                  <Index.IconButton
                                    className="passwrd-eye"
                                    aria-label="toggle password visibility"
                                    onClick={() =>
                                      handleClickShowPassword("confirmPassword")
                                    }
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showConfirmPassword ? (
                                      <Index.VisibilityOff />
                                    ) : (
                                      <Index.Visibility />
                                    )}
                                  </Index.IconButton>
                                </Index.InputAdornment>
                              ),
                            }}
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="user-btn-flex change-pass-flex">
                        <Index.Box className="save-btn-main border-btn-main">
                          <Index.Button
                            className="save-user-btn border-btn"
                            type="submit"
                          >
                            <img
                              alt="img"
                              src={Index.Svg.save}
                              className="user-save-icon"
                            ></img>
                            Save
                          </Index.Button>
                        </Index.Box>
                      </Index.Box>
                    </form>
                  )}
                </Formik>
              </Index.Box>
            </Index.Box>
          </TabPanel>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}
