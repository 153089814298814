import React, { useState } from "react";
import { Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
// import Index from "../../Index";
import { ResetPasswordSchema } from "../../../validation/Validation";
import { resetPassword } from "../../../redux/slices/AdminService";
import Index from "../../../component/Index";
// for custom design of checkbox

export default function ResetPassword() {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();
  const ChangePassId = state?.state?.ResetId;
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const ChangePassord = (val) => {
    setLoading(true);
    const formdata = new URLSearchParams();
    formdata.append("adminId", ChangePassId);
    formdata.append("newPassword", val.newPassword);
    formdata.append("confirmPassword", val.confirmPassword);

    dispatch(resetPassword(formdata)).then((val) => {
      setLoading(false);
      if (val?.payload?.status === 200) {
        navigate("/");
      }
    });
  };

  return (
    <Index.LoadingOverlay
      spinner
      text="Loading your content..."
      active={loading}
    >
      <div>
        <Index.Box className="admin-login-main-flex">
          <Index.Box className="admin-login-left-main">
            <Index.Box className="login-left-bg">
              {/* <Index.Box className="auth-logo-box">
                <img
                  alt="logo_img"
                  src={Index.Png.headerlogo}
                  className="auth-logo"
                />
              </Index.Box> */}
            </Index.Box>
          </Index.Box>
          <Index.Box className="admin-login-right-main">
            <Index.Box className="admin-login-box">
              <Index.Box className="admin-login-main">
                <Index.Box className="admin-login-inner">
                  <Index.Typography
                    component="h2"
                    variant="h2"
                    className="admin-wel-text"
                  >
                    Reset Password!
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="admin-sign-para common-para"
                  >
                    Please enter your credentials to Reset Password !
                  </Index.Typography>
                  <Formik
                    validationSchema={ResetPasswordSchema}
                    initialValues={{
                      password: "",
                      confirmpassword: "",
                    }}
                    onSubmit={ChangePassord}
                  >
                    {(props) => (
                      <form onSubmit={props.handleSubmit}>
                        <Index.Box className="input-box">
                          <Index.FormHelperText className="form-lable">
                            Password
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.OutlinedInput
                              className="form-control-eye form-control"
                              autocomplete="off"
                              id="outlined-adornment-password"
                              name="newPassword"
                              onChange={props.handleChange}
                              value={props.values.newPassword}
                              error={Boolean(props.errors.newPassword)}
                              type={showPassword ? "text" : "password"}
                              endAdornment={
                                <Index.InputAdornment position="end">
                                  <Index.IconButton
                                    className="toggle-eye-btn"
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <Index.VisibilityOff />
                                    ) : (
                                      <Index.Visibility />
                                    )}
                                  </Index.IconButton>
                                </Index.InputAdornment>
                              }
                            />
                            {props?.errors?.newPassword && (
                              <Index.FormHelperText error>
                                {props?.errors?.newPassword}
                              </Index.FormHelperText>
                            )}
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="input-box">
                          <Index.FormHelperText className="form-lable">
                            Confirm Password
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.OutlinedInput
                              className="form-control-eye form-control"
                              autocomplete="off"
                              id="outlined-adornment-password"
                              type={showPassword ? "text" : "password"}
                              name="confirmPassword"
                              onChange={props.handleChange}
                              value={props.values.confirmPassword}
                              error={Boolean(props.errors.confirmPassword)}
                              endAdornment={
                                <Index.InputAdornment position="end">
                                  <Index.IconButton
                                    className="toggle-eye-btn"
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <Index.VisibilityOff />
                                    ) : (
                                      <Index.Visibility />
                                    )}
                                  </Index.IconButton>
                                </Index.InputAdornment>
                              }
                            />
                            {props?.errors?.confirmPassword && (
                              <Index.FormHelperText error>
                                {props?.errors?.confirmPassword}
                              </Index.FormHelperText>
                            )}
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="btn-main-primary login-btn-main">
                          <Index.Button
                            className="btn-primary login-btn"
                            type="submit"
                          >
                            Submit
                          </Index.Button>
                        </Index.Box>
                        <Index.Typography
                          component="p"
                          variant="p"
                          align="center"
                          className="admin-sign-para common-para margin-top"
                          onClick={() => {
                            navigate("/");
                          }}
                        >
                          Go to Login page ?
                        </Index.Typography>
                      </form>
                    )}
                  </Formik>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </div>
    </Index.LoadingOverlay>
  );
}
