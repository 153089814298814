import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import {
  editGeneralSetting,
  getAllGeneralSetting,
} from "../../../../redux/slices/AdminService";
import { GeneralSettingSchema } from "../../../../validation/Validation";
import PageIndex from "../../../PageIndex";
import "./GeneralSetting.css";

import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export default function GeneralSetting() {
  const { generalSettingData, generalSettingList } = PageIndex.useSelector(
    (state) => state.admin
  );
  // for open dropdown handleChangedropdown
  const dispatch = PageIndex.useDispatch();
  const navigate = PageIndex.useNavigate();
  const [loading, setLoading] = useState(true);

  const updateGeneralSetting = (val) => {
    setLoading(true);
    dispatch(editGeneralSetting({ ...val })).then((val) => {
      if (val?.payload?.status) {
        navigate("/dashboard/general-setting");
        dispatch(getAllGeneralSetting());
      }
      setTimeout(() => {
        setLoading(false);
      }, 500);
    });
  };

  useEffect(() => {
    setLoading(true); // Set loading state to true before fetching data
    setTimeout(() => {
      // Simulating a delay of 1 seconds
      dispatch(getAllGeneralSetting())
        .then((val) => {
          if (val?.payload?.status === 200) {
            dispatch(getAllGeneralSetting());
          }
          setLoading(false); // Set loading state to false after data is fetched
        })
        .catch((error) => {
          setLoading(false); // Set loading state to false if there's an error
        });
    }, 500);
  }, []);

  return (
    <PageIndex.LoadingOverlay
      spinner
      className="Generalsetting-form"
      text="Loading your content..."
      active={loading}
    >
      {!loading && (
        <Index.Box className="dashboard-content add-genres-containt">
          <Index.Typography
            className="admin-page-title"
            component="h2"
            variant="h2"
          >
            General Setting
          </Index.Typography>
          <Index.Box className="page-border">
            <PageIndex.Formik
              initialValues={{
                image: generalSettingList[0] ? generalSettingList[0].image : "",
                welcome_Video: generalSettingList[0]
                  ? generalSettingList[0].welcome_Video
                  : "",
                minimum_Payment: generalSettingList[0]
                  ? generalSettingList[0].minimum_Payment
                  : "",
                maximum_Payment: generalSettingList[0]
                  ? generalSettingList[0].maximum_Payment
                  : "",
                mobile_number: generalSettingList[0]
                  ? generalSettingList[0].mobile_number
                  : "",
              }}
              onSubmit={updateGeneralSetting}
              validationSchema={GeneralSettingSchema}
            >
              {({
                values,
                errors,
                handleChange,
                handleBlur,
                touched,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Index.Box className="add-user-data-main">
                    <Index.Box sx={{ width: 1 }} className="grid-main">
                      <Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box add-user-input ">
                            <Index.FormHelperText className="form-lable">
                              Image Link
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                fullWidth
                                onBlur={handleBlur}
                                id="fullWidth"
                                className="form-control"
                                placeholder=""
                                name="image"
                                value={values.image}
                                onChange={handleChange}
                                error={Boolean(errors.image)}
                              />
                              {errors?.image && touched?.image ? (
                                <Index.Typography className="error-msg">
                                  {errors?.image}
                                </Index.Typography>
                              ) : null}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box add-user-input">
                            <Index.FormHelperText className="form-lable">
                              Video Link
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                fullWidth
                                onBlur={handleBlur}
                                id="fullWidth"
                                className="form-control"
                                placeholder=""
                                name="welcome_Video"
                                value={values.welcome_Video}
                                onChange={handleChange}
                                error={Boolean(errors.welcome_Video)}
                              />
                              {errors?.welcome_Video &&
                              touched?.welcome_Video ? (
                                <Index.Typography className="error-msg">
                                  {errors?.welcome_Video}
                                </Index.Typography>
                              ) : null}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box add-user-input">
                            <Index.FormHelperText className="form-lable">
                              Mobile No*
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                fullWidth
                                onBlur={handleBlur}
                                id="fullWidth"
                                className="form-control"
                                placeholder=""
                                name="mobile_number"
                                value={values.mobile_number}
                                onChange={(event) => {
                                  const inputValue = event.target.value;
                                  if (inputValue.length <= 10) {
                                    // Only update the value if it's not more than 10 characters
                                    handleChange(event);
                                  }
                                }}
                                maxLength={10}
                                error={Boolean(errors.mobile_number)}
                              />
                              {errors?.mobile_number &&
                              touched?.mobile_number ? (
                                <Index.Typography className="error-msg">
                                  {errors?.mobile_number}
                                </Index.Typography>
                              ) : null}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box add-user-input">
                            <Index.FormHelperText className="form-lable">
                              Minimum Franchise Amount*
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                fullWidth
                                onBlur={handleBlur}
                                className="MyData"
                                type="number"
                                id="outlined-start-adornment"
                                placeholder=""
                                name="minimum_Payment"
                                value={values.minimum_Payment}
                                onChange={(event) => {
                                  const inputValue = event.target.value;
                                  if (inputValue.length <= 10) {
                                    // Only update the value if it's not more than 10 characters
                                    handleChange(event);
                                  }
                                }}
                                maxLength={10}
                                error={Boolean(errors.minimum_Payment)}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment
                                      position="start"
                                      style={{ color: "white" }}
                                    >
                                      ₹
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              {errors?.minimum_Payment &&
                              touched?.minimum_Payment ? (
                                <Index.Typography className="error-msg">
                                  {errors?.minimum_Payment}
                                </Index.Typography>
                              ) : null}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box add-user-input">
                            <Index.FormHelperText className="form-lable">
                              Maximum Franchise Amount*
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <TextField
                                className="MyData"
                                fullWidth
                                onBlur={handleBlur}
                                type="number"
                                placeholder=""
                                name="maximum_Payment"
                                value={values.maximum_Payment}
                                onChange={(event) => {
                                  const inputValue = event.target.value;
                                  if (inputValue.length <= 10) {
                                    // Only update the value if it's not more than 10 characters
                                    handleChange(event);
                                  }
                                }}
                                id="outlined-start-adornment"
                                // sx={{ m: 1, width: "25ch" }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment
                                      position="start"
                                      style={{ color: "white" }}
                                    >
                                      ₹
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              {/* <Index.TextField
                                fullWidth
                                onBlur={handleBlur}
                                id="fullWidth"
                                className="form-control"
                                type="number"
                                placeholder=""
                                name="maximum_Payment"
                                value={values.maximum_Payment}
                                onChange={(event) => {
                                  const inputValue = event.target.value;
                                  if (inputValue.length <= 10) {
                                    // Only update the value if it's not more than 10 characters
                                    handleChange(event);
                                  }
                                }}
                                startAdornment={
                                  <Index.InputAdornment position="start">
                                    $
                                  </Index.InputAdornment>
                                }
                                maxLength={10}
                                error={Boolean(errors.maximum_Payment)}
                              /> */}
                              {errors?.maximum_Payment &&
                              touched?.maximum_Payment ? (
                                <Index.Typography className="error-msg">
                                  {errors?.maximum_Payment}
                                </Index.Typography>
                              ) : null}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="user-btn-flex">
                        <Index.Box className="save-btn-main border-btn-main">
                          <Index.Button
                            className="save-user-btn border-btn"
                            type="submit"
                          >
                            <img
                              alt="save_img"
                              src={Index.Svg.save}
                              className="user-save-icon"
                            ></img>
                            Update
                          </Index.Button>
                        </Index.Box>
                        <Index.Box className="cancel-btn-main border-btn-main">
                          <Index.Button
                            className="cancel-user-btn border-btn"
                            onClick={() => navigate("/dashboard")}
                          >
                            <img
                              alt="save_img"
                              src={Index.Svg.save}
                              className="user-save-icon"
                            ></img>
                            Cancel
                          </Index.Button>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </form>
              )}
            </PageIndex.Formik>
          </Index.Box>
        </Index.Box>
      )}
    </PageIndex.LoadingOverlay>
  );
}
