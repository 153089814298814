import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { editProperty } from "../../../../redux/slices/AdminSlice";
import {
  getAllPropertyList,
  getFilteredPropertyList,
} from "../../../../redux/slices/AdminService";
import PageIndex from "../../../PageIndex";
import { imageUrl } from "../../../../config/dataService";
import { useFormik } from "formik";

export default function UserPropertyList() {
  let navigate = PageIndex.useNavigate();
  const dispatch = PageIndex.useDispatch();
  const { propertyList } = PageIndex.useSelector((state) => state.admin);
  const { adminData } = PageIndex.useSelector((store) => store.admin);
  const [leaseProperty, setLeaseProperty] = useState([]);
  const [search, setSearch] = useState("");
  const [pageData, setPageData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [openExportModal, setOpenExportModal] = useState(false);
  const initialValues = {
    start_date: "",
    end_date: "",
  };

  useEffect(() => {
    setLoading(true); // Set loading state to true before fetching data
    setTimeout(() => {
      // Simulating a delay of 1 seconds
      dispatch(getAllPropertyList())
        .then((val) => {
          if (val?.payload?.status === 200) {
            dispatch(getAllPropertyList());
          }
          setLoading(false); // Set loading state to false after data is fetched
        })
        .catch((error) => {
          setLoading(false); // Set loading state to false if there's an error
        });
    }, 1000);
  }, []);

  useEffect(() => {
    const filteredLeaseProperty = propertyList?.userProperty?.filter(
      (ele) => ele?.options == "List Your Property To Lease"
    );
    setLeaseProperty(filteredLeaseProperty);
  }, [propertyList?.userProperty]);

  useEffect(() => {
    const filtered = leaseProperty
      ?.filter((item) => {
        return !search
          ? item
          : item?.property_name
              ?.toLowerCase()
              .includes(search?.toLowerCase()) ||
              item?.property_type
                ?.toLowerCase()
                .includes(search?.toLowerCase()) ||
              item?.project_status
                ?.toLowerCase()
                ?.includes(search?.toLowerCase()) ||
              item?.propertyCode
                .toString()
                .toLowerCase()
                .includes(search.toString().toLowerCase());
      })
      ?.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateB - dateA;
      });
    if (filtered) {
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  }, [leaseProperty, search]);
  const exportData = (data, fileName, type) => {
    // Create a link and download the file
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  // export
  const generateExcel = async (filteredData) => {
    const headers = [
      "Property Name",
      "Property Code",
      "Property Type",
      "Property Status",
      "Option",
      "Created Date",
    ];
    const rows = filteredData?.map((item) => ({
      property_name: item?.property_name ? item?.property_name : "-",
      propertyCode: item?.propertyCode ? item?.propertyCode : "-",
      property_type: item?.property_type ? item?.property_type : "-",
      project_status: item?.project_status ? item?.project_status : "-",
      options: item?.options ? item?.options : "-",
      // item?.options === "List Your Property To Build Franchise"
      //   ? "Option One"
      //   : item?.options === "Select From Upcoming Location"
      //   ? "Option Two"
      //   : item.options === "List Your Property To Lease"
      //   ? "Option Four"
      //   : "-----",
      createdAt: item?.createdAt
        ? Index.moment(item?.createdAt).utc().format("DD/MM/YYYY hh:mm A")
        : "-",
    }));

    const workbook = Index.XLSX.utils.book_new();
    const worksheet = Index.XLSX.utils.json_to_sheet(rows);

    Index.XLSX.utils.book_append_sheet(workbook, worksheet, "Property List");

    // customize header names
    Index.XLSX.utils.sheet_add_aoa(worksheet, [headers]);

    return new Promise((resolve, reject) => {
      try {
        Index.XLSX.writeFile(workbook, `Property_List.xlsx`, {
          compression: true,
        });
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };
  const handleGenerateExcel = async () => {
    try {
      await generateExcel(filteredData);
    } catch (error) {
      console.error("Error generating Excel:", error);
    }
  };

  // const formik = useFormik({
  //   initialValues,
  //   validationSchema: "",
  //   onSubmit: (values) => {
  //     handleCloseModal();
  //     dispatch(
  //       getFilteredPropertyList({
  //         startDate: values?.start_date,
  //         endDate: values?.end_date,
  //       })
  //     ).then((res) => {
  //       const headers = [
  //         "Property Name",
  //         "Property Code",
  //         "Property Type",
  //         "Property Status",
  //         "Option",
  //         "Created Date",
  //       ];

  //       let modifiedData = [];
  //       const filteredLeaseProperty = res?.payload?.data?.userProperty?.filter(
  //         (ele) => ele?.options === "List Your Property To Lease"
  //       );

  //       // Filter based on start and end date
  //       const filteredData = filteredLeaseProperty?.filter((item) => {
  //         if (!values.start_date && !values.end_date) {
  //           return true;
  //         }
  //         const createdDate = new Date(item.createdAt);
  //         const startDate = new Date(values.start_date);
  //         const endDate = new Date(values.end_date);

  //         return createdDate >= startDate && createdDate <= endDate;
  //       });

  //       console.log(filteredData,202)

  //       filteredData
  //         ?.filter((item) => {
  //           return !search
  //             ? item
  //             : item?.property_name
  //                 ?.toLowerCase()
  //                 .includes(search?.toLowerCase()) ||
  //                 item?.property_type
  //                   ?.toLowerCase()
  //                   .includes(search?.toLowerCase()) ||
  //                 item?.project_status
  //                   ?.toLowerCase()
  //                   .includes(search?.toLowerCase());
  //         })
  //         ?.sort((a, b) => {
  //           const dateA = new Date(a.createdAt);
  //           const dateB = new Date(b.createdAt);
  //           return dateB - dateA;
  //         })
  //         .map((item) => {
  //           let objData = [
  //             item?.property_name ? item?.property_name : "-",
  //             item?.propertyCode ? item?.propertyCode : "-",
  //             item?.property_type ? item?.property_type : "-",
  //             item?.project_status ? item?.project_status : "-",
  //             item?.options === "List Your Property To Build Franchise"
  //               ? "Option One"
  //               : item?.options === "Select From Upcoming Location"
  //               ? "Option Two"
  //               : item.options === "List Your Property To Lease"
  //               ? "Option Four"
  //               : "-----",
  //             item?.createdAt
  //               ? Index.moment(item?.createdAt).utc().format("DD/MM/YYYY hh:mm A")
  //               : "-",
  //           ];
  //           modifiedData.push(objData.join(","));
  //         });

  //       exportData(
  //         [[headers, ...modifiedData].join("\n")],
  //         `${Index.moment().format("DD-MM-YYYY hh:mm:ss A")}_Property_list.csv`,
  //         "text/csv"
  //       );
  //     });
  //   },
  // });

  const handleOpenModal = () => {
    setOpenExportModal(true);
  };
  const handleCloseModal = () => {
    setOpenExportModal(false);
  };

  // filter date
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const handleFilter = () => {
    setSearch("");
    if (fromDate && toDate) {
      const filtered = filteredData?.filter((item) => {
        const itemDate = Index.moment(item?.createdAt);
        if (fromDate && toDate) {
          const startDate = Index.moment(fromDate);
          const endDate = Index.moment(toDate);
          return (
            itemDate.isSameOrAfter(startDate, "day") &&
            itemDate.isSameOrBefore(endDate, "day")
          );
        }
        return true;
      });

      setFilteredData(filtered);
    }
  };
  useEffect(() => {
    if (fromDate && toDate) {
      handleFilter();
    }
  }, [fromDate, toDate]);
  const clearFilters = () => {
    setFromDate(null);
    setToDate(null);
    setSearch("");
    setFilteredData(
      leaseProperty?.slice().sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateB - dateA;
      }) || []
    );
  };

  if (
    (adminData &&
      adminData?.role?.permission?.includes("Requested Property_view")) ||
    (adminData && adminData?.isAdmin === true)
  ) {
    return (
      <>
        <PageIndex.LoadingOverlay
          spinner
          text="Loading your content..."
          active={loading}
        >
          <Index.Box className="dashboard-content">
           
            <Index.Box className="user-list-flex">
              <Index.Box className="admin-page-title-main">
                <Index.Typography
                  className="admin-page-title"
                  component="h2"
                  variant="h2"
                >
                  Lease Property List
                </Index.Typography>
              </Index.Box>
              <Index.Box className="userlist-btn-flex">
                <Index.Box className="user-search-main">
                  <Index.Box className="user-search-box">
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="Search Property"
                        value={search}
                        onChange={(e) => {
                          const inputValue = e.target.value
                            .replace(/^\s+/, "")
                            .replace(/\s\s+/g, " ");
                          setSearch(inputValue);
                        }}
                      />
                      <span className="search-icon-box">
                        <img
                          alt="search_icon"
                          src={Index.Svg.search}
                          className="search-icon"
                        />
                      </span>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="flex-all user-list-inner-flex">
                  <Index.Box className="adduser-btn-main btn-main-primary">
                    <Index.Button
                      className="adduser-btn btn-primary"
                      // onClick={handleOpenModal}
                      onClick={() => {
                        handleGenerateExcel();
                      }}
                    >
                      <img
                        alt="save_img"
                        src={Index.Svg.save}
                        className="user-save-icon"
                      ></img>
                      Export
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="flex-all user-list-inner-flex"></Index.Box>
              </Index.Box>
            </Index.Box>

             {/* filter date */}
             <Index.Box className="userlist-btn-flex-filter">
              <Index.Box className="input-box modal-input-box">
                <Index.FormHelperText className="form-lable bold-label-common">
                  From Date
                </Index.FormHelperText>
                <Index.Box className="form-group date-picker">
                  <Index.LocalizationProvider dateAdapter={Index.AdapterDayjs}>
                    <Index.Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        position: "relative",
                      }}
                    >
                      <Index.DatePicker
                        fullWidth
                        id="fromDate"
                        name="fromDate"
                        // className="form-control"
                        format="DD/MM/YYYY"
                        placeholder="Add from date"
                        value={Index.dayjs(
                          Index.moment(fromDate).format("YYYY-MM-DD")
                        )}
                        slotProps={{
                          textField: {
                            readOnly: true,
                            error: false,
                          },
                        }}
                        maxDate={Index.dayjs(Index.moment().format("YYYY-MM-DD"))}
                        onChange={(date) => {
                          setFromDate(date?.$d);
                          if (!toDate || date >= toDate) {
                            setToDate(null);
                          }
                        }}
                        renderInput={(params) => (
                          <Index.TextField {...params} sx={{ width: "100%" }} />
                        )}
                      />
                    </Index.Box>
                  </Index.LocalizationProvider>
                </Index.Box>
              </Index.Box>
              <Index.Box className="input-box modal-input-box  mt-remover-datepicker">
                <Index.FormHelperText className="form-lable bold-label-common">
                  To Date
                </Index.FormHelperText>
                <Index.Box className="form-group date-picker">
                  <Index.LocalizationProvider dateAdapter={Index.AdapterDayjs}>
                    <Index.DatePicker
                      fullWidth
                      // className="form-control"
                      id="toDate"
                      name="toDate"
                      format="DD/MM/YYYY"
                      placeholder="Add to date"
                      value={Index.dayjs(Index.moment(toDate).format("YYYY-MM-DD"))}
                      onChange={(date) => {
                        setToDate(date?.$d);
                        handleFilter();
                      }}
                      minDate={Index.dayjs(
                        Index.moment(fromDate).format("YYYY-MM-DD")
                      )}
                      maxDate={Index.dayjs(Index.moment().format("YYYY-MM-DD"))}
                      disabled={!fromDate}
                      renderInput={(params) => (
                        <Index.TextField {...params} sx={{ width: "100%" }} />
                      )}
                      slotProps={{
                        textField: {
                          readOnly: true,
                          error: false,
                        },
                      }}
                    />
                  </Index.LocalizationProvider>
                </Index.Box>
              </Index.Box>
              <Index.Box className="adduser-btn-main btn-main-primary">
                <Index.Button
                  className="adduser-btn btn-primary"
                  variant="contained"
                  disableRipple
                  onClick={() => clearFilters()}
                >
                  Clear
                </Index.Button>
              </Index.Box>
            </Index.Box>
            <Index.Box className="admin-dashboard-list-row">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row-userlist"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dash-box">
                      <Index.Box className="propertylist-table-main page-table-main">
                        <Index.TableContainer
                          component={Index.Paper}
                          className="table-container"
                        >
                          <Index.Table
                            aria-label="sticky table"
                            className="table"
                          >
                            <Index.TableHead className="table-head sticky">
                              <Index.TableRow className="table-row">
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  No.
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Image
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Property Code
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Name
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Type
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Property Status
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Status
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Option
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Created Date
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Action
                                </Index.TableCell>
                              </Index.TableRow>
                            </Index.TableHead>
                            <Index.TableBody className="table-body">
                              {pageData?.length ? (
                                pageData?.map((row, ind) => (
                                  <Index.TableRow
                                    key={row._id}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row?.sNo ? row?.sNo : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row.images.length === 0 ? (
                                        <img
                                          className="property_img"
                                          src={Index.Png.staticLogoImage}
                                          alt="img"
                                        />
                                      ) : (
                                        row.images
                                          ?.filter(
                                            (image) =>
                                              image.includes(".jpg") ||
                                              image.includes(".png") ||
                                              image.includes(".jpeg")
                                          )
                                          .map((image, index) => {
                                            if (index === 0) {
                                              return (
                                                <img
                                                  key={image}
                                                  className="property_img"
                                                  src={`${imageUrl}/${image}`}
                                                  alt="img"
                                                />
                                              );
                                            }
                                            return null; // Render null for other images in the array
                                          })
                                      )}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row?.propertyCode
                                        ? row?.propertyCode
                                        : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row?.property_type === "Land"
                                        ? "Land"
                                        : row?.property_name}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row?.property_type
                                        ? row?.property_type
                                        : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      <div
                                      // className={
                                      //   row?.isActive
                                      //     ? "table-td-active"
                                      //     : "table-td-Inactive"
                                      // }
                                      >
                                        {row?.property_type === "Land"
                                          ? "----"
                                          : row?.project_status}
                                      </div>
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                      align="center"
                                    >
                                      {/* {row?.options ===
                                    "List Your Property To Build Franchise"
                                      ? "Option One"
                                      : row?.options ===
                                        "Select From Upcoming Location"
                                      ? "Option Two"
                                      : row.options ===
                                        "List Your Property To Lease"
                                      ? "Option Four"
                                      : "-----"} */}
                                      {row?.isVerified
                                        ? "Verified"
                                        : "Un Verified"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                      align="center"
                                    >
                                      {/* {row?.options ===
                                    "List Your Property To Build Franchise"
                                      ? "Option One"
                                      : row?.options ===
                                        "Select From Upcoming Location"
                                      ? "Option Two"
                                      : row.options ===
                                        "List Your Property To Lease"
                                      ? "Option Four"
                                      : "-----"} */}
                                      {row?.options}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                      align="center"
                                    >
                                      {row?.createdAt
                                        ? Index.moment(row?.createdAt).format(
                                            "DD/MM/YYYY"
                                          )
                                        : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                      align="center"
                                    >
                                      <Index.Box className="userdata-btn-flex">
                                        {(adminData &&
                                          adminData?.role?.permission?.includes(
                                            "Requested Property_edit"
                                          )) ||
                                        (adminData &&
                                          adminData?.isAdmin === true) ? (
                                          <>
                                            <Index.IconButton
                                              color="primary"
                                              aria-label="upload picture"
                                              component="label"
                                              onClick={() => {
                                                dispatch(editProperty(row));
                                                navigate(
                                                  "/dashboard/update-user-property",
                                                  {
                                                    state: row._id,
                                                  }
                                                );
                                              }}
                                            >
                                              <Index.EditIcon />
                                            </Index.IconButton>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                        {(adminData &&
                                          adminData?.role?.permission?.includes(
                                            "Requested Property_view"
                                          )) ||
                                        (adminData &&
                                          adminData?.isAdmin === true) ? (
                                          <>
                                            <Index.IconButton
                                              color="primary"
                                              aria-label="upload picture"
                                              component="label"
                                              className="rolelist-btn"
                                              onClick={() => {
                                                dispatch(editProperty(row));
                                                navigate(
                                                  "/dashboard/view-user-property",
                                                  {
                                                    state: row,
                                                  }
                                                );
                                              }}
                                            >
                                              <Index.RemoveRedEyeIcon />
                                            </Index.IconButton>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </Index.Box>
                                    </Index.TableCell>
                                  </Index.TableRow>
                                ))
                              ) : (
                                <Index.TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td no-record-text"
                                    align="center"
                                    colSpan={10}
                                  >
                                    No records found
                                  </Index.TableCell>
                                </Index.TableRow>
                              )}
                            </Index.TableBody>
                          </Index.Table>
                        </Index.TableContainer>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="pagination-main">
              <PageIndex.Pagination
                fetchData={filteredData}
                setPageData={setPageData}
                pageData={pageData}
                search={search}
              />
            </Index.Box>
          </Index.Box>
        </PageIndex.LoadingOverlay>
        {/* <Index.Box className="custom-dialog">
          <Index.Dialog
            open={openExportModal}
            onClose={handleCloseModal}
            maxWidth="sm"
            fullWidth
            className="commect-modal custom-dialog"
          >
            <Index.Box className="page-border">
              <Index.Box className="add-user-data-main">
                <Index.Box sx={{ width: 1 }} className="grid-main">
                  <form onSubmit={formik.handleSubmit}>
                    <Index.Box className="admin-dashboard-list-row">
                      <Index.Box sx={{ width: 1 }} className="grid-main">
                        <Index.Box
                          display="grid"
                          className="display-row"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                        >
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box add-user-input">
                              <Index.FormHelperText className="form-lable">
                                Start Date
                              </Index.FormHelperText>
                              <Index.Box className="form-group">
                                <Index.TextField
                                  fullWidth
                                  type="date"
                                  onBlur={formik.handleBlur}
                                  id="start_date"
                                  className="form-control"
                                  placeholder=""
                                  name="start_date"
                                  value={formik.values.start_date}
                                  onChange={formik.handleChange}
                                  error={
                                    formik.touched.start_date &&
                                    Boolean(formik.errors.start_date)
                                  }
                                />
                                {formik.touched.start_date &&
                                  formik.errors.start_date && (
                                    <Index.Typography className="error-msg">
                                      {formik.errors.start_date}
                                    </Index.Typography>
                                  )}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box add-user-input">
                              <Index.FormHelperText className="form-lable">
                                End Date
                              </Index.FormHelperText>
                              <Index.Box className="form-group">
                                <Index.TextField
                                  fullWidth
                                  type="date"
                                  onBlur={formik.handleBlur}
                                  id="end_date"
                                  className="form-control"
                                  placeholder=""
                                  name="end_date"
                                  inputProps={{ min: formik.values.start_date }}
                                  value={formik.values.end_date}
                                  onChange={formik.handleChange}
                                  error={
                                    formik.touched.end_date &&
                                    Boolean(formik.errors.end_date)
                                  }
                                />
                                {formik.touched.end_date &&
                                  formik.errors.end_date && (
                                    <Index.Typography className="error-msg">
                                      {formik.errors.end_date}
                                    </Index.Typography>
                                  )}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>

                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 12",
                              md: "span 12",
                              lg: "span 12",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="user-btn-flex comman-btn-main">
                              <Index.Box className="user-btn-flex">
                                <Index.Box className="save-btn-main border-btn-main">
                                  <Index.Button
                                    className="save-user-btn border-btn"
                                    type="submit"
                                  >
                                    <img
                                      alt="save_img"
                                      src={Index.Svg.save}
                                      className="user-save-icon"
                                    ></img>
                                    Export
                                  </Index.Button>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </form>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Dialog>
        </Index.Box> */}
      </>
    );
  } else {
    navigate("/dashboard");
  }
}
