import React, { useEffect, useState } from "react";
import { Form, Field } from "formik";
import Index from "../../../Index";
import PageIndex, { stateList } from "../../../PageIndex";
import { editRebrandingPropertyData } from "../../../../redux/slices/AdminService";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import { updateRebrandingPropertySchema } from "../../../../validation/Validation";
import { imageUrl } from "../../../../config/dataService";
import { toast } from "react-toastify";
const UpdateRebrandingProperty = () => {
  const dispatch = PageIndex.useDispatch();
  const navigate = PageIndex.useNavigate();
  const params = PageIndex.useLocation();
  const [imageVideoPreview, setImageVideoPreview] = useState([]);
  const [imageVideoData, setImageVideoData] = useState([]);
  const [removeImageVideoData, setRemoveImageVideoData] = useState([]);
  const [documentData, setDocumentData] = useState([]);
  const [documentPreview, setDocumentPreview] = useState([]);
  const [loading, setLoading] = useState(true);
  const id = params?.state;
  const { updateRebrandingProperty, adminData } = PageIndex.useSelector(
    (state) => state.admin
  );

  const initialValues = {
    property_name: id ? updateRebrandingProperty.property_name : "",
    cinema_name: id ? updateRebrandingProperty.cinema_name : "",
    area_of_property: id ? updateRebrandingProperty.area_of_property : "",
    city: id ? updateRebrandingProperty?.property_location?.city : "",
    pincode: id
      ? id && updateRebrandingProperty?.property_location?.pincode == null
        ? ""
        : updateRebrandingProperty?.property_location?.pincode
      : "",
    state: id ? updateRebrandingProperty?.property_location?.state : "",
    no_of_sheets: id ? updateRebrandingProperty.no_of_sheets : "",
    no_of_screens: id ? updateRebrandingProperty.no_of_screens : "",
    existing_business: id ? updateRebrandingProperty.existing_business : "",
  };

  const documentHandle = (e) => {
    const files = [...e.target.files];
    if (!files || files.length === 0) {
      return;
    }
    const arr = Array.from(files);

    arr.forEach((data) => {
      let checkDocument = data.type.match(
        /pdf|doc|docx|application\/msword|application\/vnd.openxmlformats-officedocument.wordprocessingml.document/i
      );

      if (!checkDocument) {
        toast.error("Please upload PDF or Word documents");
        return;
      } else {
        setDocumentData((e) => (Array.isArray(e) ? [...e, data] : [data]));
        setDocumentPreview((e) =>
          Array.isArray(e)
            ? [
                ...e,
                {
                  type: "document",
                  pre: URL.createObjectURL(data),
                },
              ]
            : [
                {
                  type: "document",
                  pre: URL.createObjectURL(data),
                },
              ]
        );
      }
    });
  };

  const removeDocument = (index, pre, backend = false) => {
    setDocumentData((e) => {
      let arr = [...e];
      return arr.filter((_, i) => i !== index);
    });
    setDocumentPreview((e) => {
      let arr = [...e];
      return arr.filter((_, i) => i !== index);
    });
    // if (!backend) {
    //   let file = pre;
    //   console.log("file--",file);
    //   setRemoveImageVideoData((e) => [...e, file]);
    // }
    if (!pre.pre) {
      let file = pre;

      setRemoveImageVideoData((e) => [...e, file]);
    }
  };

  const handleSubmit = (values) => {
    const { state, city, pincode } = values;
    const property_location = {
      city: city,
      pincode: pincode,
      state: state,
    };

    setLoading(true);
    const formData = new FormData();

    imageVideoData.forEach((file, index) => {
      formData.append(`images`, file);
    });
    documentData.forEach((file, index) => {
      formData.append(`documents`, file);
    });
    formData.append("removeImageVideoData", removeImageVideoData);
    // formData.append("documents", brochureData);
    formData.append("property_name", values.property_name);
    formData.append("existing_business", values.existing_business);
    formData.append("no_of_screens", values.no_of_screens);
    formData.append("no_of_sheets", values.no_of_sheets);
    formData.append("cinema_name", values.cinema_name);
    formData.append("area_of_property", values.area_of_property);
    formData.append("property_location[city]", property_location.city);
    formData.append("property_location[pincode]", property_location.pincode);
    formData.append("property_location[state]", property_location.state);

    if (id) {
      formData.append("id", id);
    }
    dispatch(editRebrandingPropertyData(formData)).then((val) => {
      setLoading(false);
      if (val?.payload?.status) {
        navigate("/dashboard/rebranding-list");
      }
    });
  };

  const imageVideoHandle = (e) => {
    const files = [...e.target.files];
    if (!files || files.length === 0) {
      return;
    }
    const arr = Array.from(files);

    arr.forEach((data) => {
      let check = data.type.match(/image/i);

      let checkVideo = data.type.match(/video/i);

      if (!check && !checkVideo) {
        alert("Please upload Images/Videos");
        return;
      } else {
        setImageVideoData((e) => (Array.isArray(e) ? [...e, data] : [data]));
        setImageVideoPreview((e) =>
          Array.isArray(e)
            ? [
                ...e,
                {
                  type: check
                    ? check[0]
                    : false || checkVideo
                    ? checkVideo[0]
                    : false,
                  pre: URL.createObjectURL(data),
                },
              ]
            : [
                {
                  type: check
                    ? check[0]
                    : false || checkVideo
                    ? checkVideo[0]
                    : false,
                  pre: URL.createObjectURL(data),
                },
              ]
        );
      }
    });
  };

  const removeImageVideo = (index, pre, backend = false) => {
    setImageVideoData((e) => {
      let arr = [...e];
      return arr.filter((_, i) => i !== index);
    });
    setImageVideoPreview((e) => {
      let arr = [...e];
      return arr.filter((_, i) => i !== index);
    });
    if (backend) {
      let file = pre?.split("/");

      setRemoveImageVideoData((e) => [...e, file[file?.length - 1]]);
    }
  };

  useEffect(() => {
    if (id) {
      setImageVideoPreview((data) => {
        return updateRebrandingProperty?.images?.map((e) => {
          let check = e.match(/jpg|jpeg|JPEG|png/i);
          let checkVideo = e.match(/mp4|avi|webm|m4v|mpg/i);
          return {
            type: check ? "image" : false || checkVideo ? "video" : false,
            pre: `${imageUrl}/${e}`,
            backend: true,
          };
        });
      });
    }
    if (updateRebrandingProperty?.documents) {
      setDocumentPreview(updateRebrandingProperty?.documents);
    }
  }, []);

  // const brochureHandle = (event, setFieldValue) => {
  //   const file = event.currentTarget.files[0];
  //   setBrochureData(file);
  // };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  if (
    (adminData && adminData?.role?.permission?.includes("Rebranding_edit")) ||
    (adminData && adminData?.isAdmin === true)
  ) {
    return (
      <PageIndex.LoadingOverlay
        spinner
        text="Loading your content..."
        active={loading}
      >
        {!loading && (
          <Index.Box className="dashboard-content add-property-containt">
            <Index.Typography
              className="admin-page-title add-title"
              component="h2"
              variant="h2"
            >
              {id ? "Update" : "Add "} Requested Property
            </Index.Typography>
            <Index.Box className="page-border">
              <PageIndex.Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={updateRebrandingPropertySchema}
              >
                {({
                  values,
                  errors,
                  handleChange,
                  handleBlur,
                  touched,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <Form>
                    <Index.Box className="add-user-data-main">
                      <Index.Box sx={{ width: 1 }} className="grid-main">
                        {/* <Index.Box
                        display="grid"
                        className="display-row-add-user"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                      >
                         <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box add-user-input">
                            <Index.FormHelperText className="form-lable">
                              Latitude*
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Field
                                name="latitude"
                                as={Index.TextField}
                                // label="Carpet Area"
                                variant="outlined"
                                fullWidth
                                id="fullWidth"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={Boolean(errors.latitude)}
                              />
                              {errors?.latitude && touched?.latitude ? (
                                <Index.Typography className="error-msg">
                                  {errors?.latitude}
                                </Index.Typography>
                              ) : null}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box add-user-input">
                            <Index.FormHelperText className="form-lable">
                              Longitude*
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Field
                                name="longitude"
                                as={Index.TextField}
                                // label="Carpet Area"
                                variant="outlined"
                                fullWidth
                                id="fullWidth"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={Boolean(errors.longitude)}
                              />
                              {errors?.longitude && touched?.longitude ? (
                                <Index.Typography className="error-msg">
                                  {errors?.longitude}
                                </Index.Typography>
                              ) : null}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box> 
                      </Index.Box> */}

                        <Index.Box
                          display="grid"
                          className="display-row-add-user"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                        >
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box add-user-input">
                              <Index.FormHelperText className="form-lable">
                                Property Name*
                              </Index.FormHelperText>
                              <Index.Box className="form-group">
                                <Field
                                  name="property_name"
                                  as={Index.TextField}
                                  variant="outlined"
                                  fullWidth
                                  id="fullWidth"
                                  className="form-control"
                                  onChange={(event) => {
                                    const inputValue = event.target.value;
                                    if (inputValue.length <= 30) {
                                      handleChange(event);
                                    }
                                  }}
                                  maxLength={30}
                                  onBlur={handleBlur}
                                  error={Boolean(errors.property_name)}
                                />
                                {errors?.property_name &&
                                touched?.property_name ? (
                                  <Index.Typography className="error-msg">
                                    {errors?.property_name}
                                  </Index.Typography>
                                ) : null}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box add-user-input">
                              <Index.FormHelperText className="form-lable">
                                Cinema Name*
                              </Index.FormHelperText>
                              <Index.Box className="form-group">
                                <Field
                                  name="cinema_name"
                                  as={Index.TextField}
                                  variant="outlined"
                                  fullWidth
                                  id="fullWidth"
                                  className="form-control"
                                  onChange={(event) => {
                                    const inputValue = event.target.value;
                                    if (inputValue.length <= 30) {
                                      handleChange(event);
                                    }
                                  }}
                                  maxLength={30}
                                  onBlur={handleBlur}
                                  error={Boolean(errors.cinema_name)}
                                />
                                {errors?.cinema_name && touched?.cinema_name ? (
                                  <Index.Typography className="error-msg">
                                    {errors?.cinema_name}
                                  </Index.Typography>
                                ) : null}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box add-user-input">
                              <Index.FormHelperText className="form-lable">
                                Carpet Area(Sq Mtrs)*
                              </Index.FormHelperText>
                              <Index.Box className="form-group">
                                <Field
                                  name="area_of_property"
                                  as={Index.TextField}
                                  variant="outlined"
                                  fullWidth
                                  id="fullWidth"
                                  className="form-control"
                                  onChange={(event) => {
                                    let inputValue = event.target.value
                                      .replace(/^\s+/, "")
                                      .replace(/[^0-9]/g, "");
                                    inputValue = inputValue.replace(/^0+/, "");
                                    if (inputValue.length <= 10) {
                                      setFieldValue(
                                        "area_of_property",
                                        inputValue
                                      );
                                    }
                                  }}
                                  onBlur={handleBlur}
                                  error={Boolean(errors.area_of_property)}
                                />
                                {errors?.area_of_property &&
                                touched?.area_of_property ? (
                                  <Index.Typography className="error-msg">
                                    {errors?.area_of_property}
                                  </Index.Typography>
                                ) : null}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box add-user-input">
                              <Index.FormHelperText className="form-lable">
                                Number Of Seats*
                              </Index.FormHelperText>
                              <Index.Box className="form-group">
                                <Field
                                  name="no_of_sheets"
                                  as={Index.TextField}
                                  variant="outlined"
                                  fullWidth
                                  id="fullWidth"
                                  className="form-control"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={Boolean(errors.no_of_sheets)}
                                />
                                {errors?.no_of_sheets &&
                                touched?.no_of_sheets ? (
                                  <Index.Typography className="error-msg">
                                    {errors?.no_of_sheets}
                                  </Index.Typography>
                                ) : null}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box add-user-input">
                              <Index.FormHelperText className="form-lable">
                                Number Of Screens*
                              </Index.FormHelperText>
                              <Index.Box className="form-group">
                                <Field
                                  name="no_of_screens"
                                  as={Index.TextField}
                                  variant="outlined"
                                  fullWidth
                                  id="fullWidth"
                                  className="form-control"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={Boolean(errors.no_of_screens)}
                                />
                                {errors?.no_of_screens &&
                                touched?.no_of_screens ? (
                                  <Index.Typography className="error-msg">
                                    {errors?.no_of_screens}
                                  </Index.Typography>
                                ) : null}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>

                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box add-user-input">
                              <Index.FormHelperText className="form-lable">
                                State*
                              </Index.FormHelperText>
                              <Index.Box className="form-group">
                                <Field
                                  name="state"
                                  as={Index.Select}
                                  variant="outlined"
                                  fullWidth
                                  id="fullWidth"
                                  className="dropdown-property"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  MenuProps={{
                                    PaperProps: {
                                      style: {
                                        maxHeight: 200, // Set the maximum height you want
                                      },
                                    },
                                  }}
                                >
                                  <Index.MenuItem value="" disabled>
                                    Select State
                                  </Index.MenuItem>
                                  {stateList.map((state) => (
                                    <Index.MenuItem key={state} value={state}>
                                      {state}
                                    </Index.MenuItem>
                                  ))}
                                </Field>
                                {errors?.state && touched?.state ? (
                                  <Index.Typography className="error-msg">
                                    {errors?.state}
                                  </Index.Typography>
                                ) : null}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>

                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box add-user-input">
                              <Index.FormHelperText className="form-lable">
                                City*
                              </Index.FormHelperText>
                              <Index.Box className="form-group">
                                <Field
                                  name="city"
                                  as={Index.TextField}
                                  variant="outlined"
                                  fullWidth
                                  id="fullWidth"
                                  className="form-control"
                                  onChange={(event) => {
                                    const inputValue = event.target.value;
                                    if (inputValue.length <= 30) {
                                      handleChange(inputValue);
                                    }
                                  }}
                                  maxLength={30}
                                  onBlur={handleBlur}
                                  value={values.city}
                                  error={Boolean(errors.city)}
                                />
                                {errors?.city && touched?.city ? (
                                  <Index.Typography className="error-msg">
                                    {errors?.city}
                                  </Index.Typography>
                                ) : null}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>

                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box add-user-input">
                              <Index.FormHelperText className="form-lable">
                                Pin Code*
                              </Index.FormHelperText>
                              <Index.Box className="form-group">
                                <Field
                                  name="pincode"
                                  as={Index.TextField}
                                  variant="outlined"
                                  fullWidth
                                  id="fullWidth"
                                  className="form-control"
                                  onChange={(event) => {
                                    const inputValue = event.target.value;
                                    if (inputValue.length <= 6) {
                                      handleChange(event);
                                    }
                                  }}
                                  maxLength={6}
                                  onBlur={handleBlur}
                                  value={values.pincode}
                                  error={Boolean(errors.pincode)}
                                />
                                {errors?.pincode && touched?.pincode ? (
                                  <Index.Typography className="error-msg">
                                    {errors?.pincode}
                                  </Index.Typography>
                                ) : null}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>

                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 12",
                              md: "span 12",
                              lg: "span 12",
                            }}
                            className="grid-column"
                          >
                            <Index.FormControl>
                              <Index.FormLabel
                                id="demo-row-radio-buttons-group-label"
                                sx={{ color: "white", fontSize: "13px" }}
                              >
                                Existing Business Ongoing
                              </Index.FormLabel>
                              <Index.RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="existing_business"
                                value={values?.existing_business}
                                onChange={handleChange}
                              >
                                <Index.FormControlLabel
                                  sx={{ color: "white" }}
                                  value="ONGOING"
                                  control={<Index.Radio />}
                                  label="Ongoing"
                                />
                                <Index.FormControlLabel
                                  sx={{ color: "white" }}
                                  value="CLOSED"
                                  control={<Index.Radio />}
                                  label="Closed"
                                />
                              </Index.RadioGroup>
                            </Index.FormControl>
                          </Index.Box>

                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 12",
                              md: "span 12",
                              lg: "span 12",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box add-user-input add-game-row">
                              <Index.FormHelperText className="form-lable add-game-lables">
                                Property Brochure
                              </Index.FormHelperText>

                              <Index.Box className="grid-row">
                                <Index.Box
                                  sx={{ width: 1 }}
                                  className="grid-main"
                                >
                                  <Index.Box
                                    display="grid"
                                    className="display-row-add-user"
                                    gridTemplateColumns="repeat(12, 1fr)"
                                    gap={{ xs: 2, sm: 3, md: 3, lg: 3 }}
                                  >
                                    {documentPreview?.map((val, i) => (
                                      <Index.Box
                                        gridColumn={{
                                          xs: "span 12",
                                          sm: "span 3",
                                          md: "span 3",
                                          lg: "span 3",
                                        }}
                                        key={i}
                                      >
                                        <Index.Box className="document-close-set">
                                          <CloseTwoToneIcon
                                            className="document-close"
                                            onClick={() =>
                                              removeDocument(
                                                i,
                                                val,
                                                val.backend
                                              )
                                            }
                                          />
                                        </Index.Box>
                                        <Index.Box>
                                          {val?.pre ? (
                                            <a
                                              style={{
                                                color: "goldenrod",
                                                textDecoration: "none",
                                              }}
                                              href={val.pre}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              Document {i + 1}
                                            </a>
                                          ) : (
                                            <a
                                              style={{
                                                color: "goldenrod",
                                                textDecoration: "none",
                                              }}
                                              href={`${imageUrl}/${val}`}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              Document {i + 1}
                                            </a>
                                          )}
                                        </Index.Box>
                                      </Index.Box>
                                    ))}

                                    <Index.Box
                                      gridColumn={{
                                        xs: "span 12",
                                        sm: "span 3",
                                        md: "span 3",
                                        lg: "span 3",
                                      }}
                                    >
                                      <Index.Box className="images-upload-btn">
                                        <Index.Button
                                          variant="contained"
                                          component="label"
                                          className="save-user-btn border-btn document-upload-btn"
                                        >
                                          <img
                                            alt="img"
                                            src={Index.Svg.upload}
                                            className="upload-img"
                                          />
                                          <input
                                            hidden
                                            multiple
                                            accept=".pdf, .doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                            name="document"
                                            type="file"
                                            onClick={(e) => {
                                              e.target.value = null;
                                            }}
                                            onChange={(e) => {
                                              documentHandle(e);
                                            }}
                                          />
                                        </Index.Button>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 12",
                              md: "span 12",
                              lg: "span 12",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box add-user-input add-game-row">
                              <Index.FormHelperText
                                className="form-lable add-game-lables"
                                sx={{ marginBottom: "15px" }}
                              >
                                Property Images/Videos
                              </Index.FormHelperText>

                              <Index.Box className="grid-row">
                                <Index.Box
                                  sx={{ width: 1 }}
                                  className="grid-main"
                                >
                                  <Index.Box
                                    display="grid"
                                    className="display-row-add-user"
                                    gridTemplateColumns="repeat(12, 1fr)"
                                    gap={{ xs: 2, sm: 3, md: 3, lg: 3 }}
                                  >
                                    {imageVideoPreview?.map((val, i) => {
                                      return val.type === "video" ? (
                                        <>
                                          <Index.Box
                                            gridColumn={{
                                              xs: "span 12",
                                              sm: "span 3",
                                              md: "span 3",
                                              lg: "span 3",
                                            }}
                                          >
                                            <Index.Box className="document-close-set">
                                              <CloseTwoToneIcon
                                                className="document-close"
                                                onClick={() =>
                                                  removeImageVideo(
                                                    i,
                                                    val.pre,
                                                    val.backend
                                                  )
                                                }
                                              />
                                            </Index.Box>
                                            <Index.Box>
                                              <video
                                                className="upload-img-slider"
                                                controls
                                              >
                                                <source
                                                  src={val.pre}
                                                  type="video/mp4"
                                                />
                                              </video>
                                            </Index.Box>
                                          </Index.Box>
                                        </>
                                      ) : (
                                        <>
                                          <Index.Box
                                            gridColumn={{
                                              xs: "span 12",
                                              sm: "span 3",
                                              md: "span 3",
                                              lg: "span 3",
                                            }}
                                          >
                                            <Index.Box className="document-close-set">
                                              <CloseTwoToneIcon
                                                className="document-close"
                                                onClick={() =>
                                                  removeImageVideo(
                                                    i,
                                                    val.pre,
                                                    val.backend
                                                  )
                                                }
                                              />
                                              <img
                                                alt="propoerty_img"
                                                className="upload-img-slider"
                                                src={val.pre}
                                              />
                                            </Index.Box>
                                          </Index.Box>
                                        </>
                                      );
                                    })}
                                    <Index.Box
                                      gridColumn={{
                                        xs: "span 12",
                                        sm: "span 3",
                                        md: "span 3",
                                        lg: "span 3",
                                      }}
                                    >
                                      <Index.Box className="images-upload-btn">
                                        <Index.Button
                                          variant="contained"
                                          component="label"
                                          className="save-user-btn border-btn image-upload-btn"
                                        >
                                          <img
                                            alt="img"
                                            src={Index.Svg.upload}
                                            className="upload-img"
                                          />
                                          <input
                                            hidden
                                            multiple
                                            accept="image/*, video/*"
                                            name="image"
                                            type="file"
                                            onClick={(e) => {
                                              e.target.value = null;
                                            }}
                                            onChange={(e) => {
                                              imageVideoHandle(e);
                                            }}
                                          />
                                        </Index.Button>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="user-btn-flex">
                        <Index.Box className="save-btn-main border-btn-main">
                          <Index.Button
                            className="save-user-btn border-btn"
                            type="submit"
                          >
                            <img
                              alt="save_img"
                              src={Index.Svg.save}
                              className="user-save-icon"
                            ></img>
                            {id ? "Update" : " Add"} Property
                          </Index.Button>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Form>
                )}
              </PageIndex.Formik>
            </Index.Box>
          </Index.Box>
        )}
      </PageIndex.LoadingOverlay>
    );
  } else {
    navigate("/dashboard");
  }
};

export default UpdateRebrandingProperty;
