import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { editFranchiseUser } from "../../../../redux/slices/AdminService";
import { updateFranchiseUserSchema } from "../../../../validation/Validation";
import PageIndex from "../../../PageIndex";

export default function UpdateFranchiseUser() {
  const [loading, setLoading] = useState(true);
  const dispatch = PageIndex.useDispatch();
  const params = PageIndex.useLocation();
  const navigate = PageIndex.useNavigate();
  const id = params?.state;
  const { editFranchise, adminData } = PageIndex.useSelector(
    (state) => state.admin
  );
  const updateFranchiseUser = (val) => {
    setLoading(true);
    dispatch(
      editFranchiseUser({
        ...val,
        [id ? "id" : ""]: id,
      })
    ).then((val) => {
      if (val?.payload?.status) {
        navigate("/dashboard/franchise-user-list");
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  if (
    (adminData &&
      adminData?.role?.permission?.includes("Franchise User_edit")) ||
    (adminData && adminData?.isAdmin === true)
  ) {
    return (
      <PageIndex.LoadingOverlay
        spinner
        text="Loading your content..."
        active={loading}
      >
        {!loading && (
          <Index.Box className="dashboard-content add-franchise-containt">
            <Index.Typography
              className="admin-page-title add-title"
              component="h2"
              variant="h2"
            >
              {id ? "Update" : "Add"} Franchise User
            </Index.Typography>
            <Index.Box className="page-border">
              <Index.Typography
                className="common-para add-basic-text"
                component="p"
                variant="p"
              >
                {/* Basic Information */}
              </Index.Typography>
              <Index.Typography
                className="common-para add-section-text"
                component="p"
                variant="p"
              >
                {/* Section to config basic product information */}
              </Index.Typography>

              <PageIndex.Formik
                initialValues={{
                  name: id ? editFranchise?.name : "",
                  email: id ? editFranchise?.email : "",
                  city: id ? editFranchise?.city : "",
                  pincode: id ? editFranchise?.pincode : "",
                  mobile: id ? editFranchise?.mobile : "",
                }}
                onSubmit={updateFranchiseUser}
                validationSchema={updateFranchiseUserSchema}
              >
                {({
                  values,
                  errors,
                  handleChange,
                  handleBlur,
                  touched,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Index.Box className="add-user-data-main">
                      <Index.Box sx={{ width: 1 }} className="grid-main">
                        <Index.Box
                          display="grid"
                          className="display-row-add-user"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                        >
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box add-user-input">
                              <Index.FormHelperText className="form-lable">
                                Name*
                              </Index.FormHelperText>
                              <Index.Box className="form-group">
                                <Index.TextField
                                  fullWidth
                                  id="fullWidth"
                                  className="form-control"
                                  placeholder=""
                                  name="name"
                                  onBlur={handleBlur}
                                  value={values.name}
                                  onChange={(event) => {
                                    const inputValue = event.target.value;
                                    if (inputValue.length <= 30) {
                                      // Only update the value if it's not more than 30 characters
                                      handleChange(event);
                                    }
                                  }}
                                  maxLength={30}
                                  error={Boolean(errors.name)}
                                />
                                {errors?.name && touched?.name ? (
                                  <Index.Typography className="error-msg">
                                    {errors?.name}
                                  </Index.Typography>
                                ) : null}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box add-user-input">
                              <Index.FormHelperText className="form-lable">
                                Email
                              </Index.FormHelperText>
                              <Index.Box className="form-group">
                                <Index.TextField
                                  fullWidth
                                  onBlur={handleBlur}
                                  id="fullWidth"
                                  className="form-control"
                                  placeholder=""
                                  name="email"
                                  value={values.email}
                                  onChange={handleChange}
                                  error={Boolean(errors.email)}
                                />
                                {errors?.email && touched?.email ? (
                                  <Index.Typography className="error-msg">
                                    {errors?.email}
                                  </Index.Typography>
                                ) : null}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box add-user-input">
                              <Index.FormHelperText className="form-lable">
                                Mobile Number
                              </Index.FormHelperText>
                              <Index.Box className="form-group">
                                <Index.TextField
                                  fullWidth
                                  type="tel"
                                  onBlur={handleBlur}
                                  id="fullWidth"
                                  className="form-control"
                                  placeholder=""
                                  name="mobile"
                                  value={values.mobile}
                                  onChange={(event) => {
                                    let inputValue = event.target.value
                                      .replace(/^\s+/, "")
                                      .replace(/[^0-9]/g, "");
                                    if (inputValue.length <= 10) {
                                      setFieldValue("mobile", inputValue);
                                    }
                                  }}
                                  maxLength={10}
                                  error={Boolean(errors.mobile)}
                                />
                                {errors?.mobile && touched?.mobile ? (
                                  <Index.Typography className="error-msg">
                                    {errors?.mobile}
                                  </Index.Typography>
                                ) : null}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box add-user-input">
                              <Index.FormHelperText className="form-lable">
                                City*
                              </Index.FormHelperText>
                              <Index.Box className="form-group">
                                <Index.TextField
                                  fullWidth
                                  onBlur={handleBlur}
                                  id="fullWidth"
                                  className="form-control"
                                  placeholder=""
                                  name="city"
                                  value={values.city}
                                  onChange={(event) => {
                                    const inputValue = event.target.value;
                                    if (inputValue.length <= 30) {
                                      // Only update the value if it's not more than 30 characters
                                      handleChange(event);
                                    }
                                  }}
                                  maxLength={30}
                                  error={Boolean(errors.city)}
                                />
                                {errors?.city && touched?.city ? (
                                  <Index.Typography className="error-msg">
                                    {errors?.city}
                                  </Index.Typography>
                                ) : null}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box add-user-input">
                              <Index.FormHelperText className="form-lable">
                                Pin Code*
                              </Index.FormHelperText>
                              <Index.Box className="form-group">
                                <Index.TextField
                                  fullWidth
                                  type="tel"
                                  onBlur={handleBlur}
                                  id="fullWidth"
                                  className="form-control"
                                  placeholder=""
                                  name="pincode"
                                  value={values.pincode}
                                  onChange={(event) => {
                                    let inputValue = event.target.value
                                      .replace(/^\s+/, "")
                                      .replace(/[^0-9]/g, "")
                                      ?.replace(/^0+/, "");
                                    if (inputValue.length <= 6) {
                                      setFieldValue("pincode", inputValue);
                                    }
                                  }}
                                  maxLength={6}
                                  error={Boolean(errors.pincode)}
                                />
                                {errors?.pincode && touched?.pincode ? (
                                  <Index.Typography className="error-msg">
                                    {errors?.pincode}
                                  </Index.Typography>
                                ) : null}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="user-btn-flex">
                          <Index.Box className="save-btn-main border-btn-main">
                            <Index.Button
                              className="save-user-btn border-btn"
                              type="submit"
                              disabled={loading}
                            >
                              <img
                                alt="save_img"
                                src={Index.Svg.save}
                                className="user-save-icon"
                              ></img>
                              Update
                            </Index.Button>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </form>
                )}
              </PageIndex.Formik>
            </Index.Box>
          </Index.Box>
        )}
      </PageIndex.LoadingOverlay>
    );
  } else {
    navigate("/dashboard");
  }
}
