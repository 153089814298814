import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { editRole } from "../../../../redux/slices/AdminSlice";
import {
  deleteRoleData,
  getAllRoleData,
  statusRoleData,
} from "../../../../redux/slices/AdminService";
import PageIndex from "../../../PageIndex";

export default function RoleList() {
  let navigate = PageIndex.useNavigate();
  const dispatch = PageIndex.useDispatch();
  const [search, setSearch] = useState("");
  const [pageData, setPageData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const { roleList } = PageIndex.useSelector((state) => state.admin);
  const { adminData } = PageIndex.useSelector((store) => store.admin);

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [id, setId] = useState("");
  // handle delete
  const handleDeleteOpen = (id) => {
    setId(id);
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setId("");
  };
  const handleOpen = () => {
    navigate("/dashboard/add-role");
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      // Simulating a delay of 1 seconds
      dispatch(getAllRoleData())
        .then((val) => {
          if (val?.payload?.status === 200) {
            dispatch(getAllRoleData());
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }, 1000);
  }, []);
  useEffect(() => {
    const filtered = roleList
      ?.filter((item) => {
        return !search
          ? item
          : item?.roleName?.toLowerCase().includes(search?.toLowerCase());
      })
      ?.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateB - dateA;
      });
    if (filtered) {
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  }, [roleList, search]);

  const deleteRole = () => {
    // setLoading(true);
    dispatch(deleteRoleData(id))
      .then((val) => {
        if (val?.payload?.status === 200) {
          handleDeleteClose();
          dispatch(getAllRoleData());
          // setLoading(false);
        } else {
          // setLoading(false);
        }
      })
      .catch((error) => {
        // setLoading(false);
      });
  };

  const statusRole = (id, status) => {
    setLoading(true);
    dispatch(statusRoleData({ isActive: status, id })).then((val) => {
      setLoading(false);
      dispatch(getAllRoleData());
    });
  };

  if (
    (adminData && adminData?.role?.permission?.includes("Role_view")) ||
    (adminData && adminData?.isAdmin === true)
  ) {
    return (
      <>
        {loading ? (
          <PageIndex.LoadingOverlay
            spinner
            text="Loading your content..."
            active={loading}
          />
        ) : (
          <Index.Box className="dashboard-content">
            <Index.Box className="user-list-flex">
              <Index.Box className="admin-page-title-main">
                <Index.Typography
                  className="admin-page-title"
                  component="h2"
                  variant="h2"
                >
                  Role List
                </Index.Typography>
              </Index.Box>
              <Index.Box className="userlist-btn-flex">
                <Index.Box className="user-search-main">
                  <Index.Box className="user-search-box">
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="Search role"
                        value={search}
                        onChange={(e) => {
                          const inputValue = e.target.value
                            .replace(/^\s+/, "")
                            .replace(/\s\s+/g, " ");
                          setSearch(inputValue);
                        }}
                      />
                      <span className="search-icon-box">
                        <img
                          alt="search_icon"
                          src={Index.Svg.search}
                          className="search-icon"
                        />
                      </span>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="flex-all user-list-inner-flex">
                  <Index.Box className="adduser-btn-main btn-main-primary">
                    {(adminData &&
                      adminData?.role?.permission?.includes("Role_add")) ||
                    (adminData && adminData?.isAdmin === true) ? (
                      <>
                        <Index.Button
                          className="adduser-btn btn-primary"
                          onClick={handleOpen}
                        >
                          <img
                            src={Index.Svg.plus}
                            className="plus-icon"
                            alt="plus icon"
                          />
                          Add Role
                        </Index.Button>
                      </>
                    ) : (
                      ""
                    )}
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="admin-dashboard-list-row">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row-userlist"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dash-box">
                      <Index.Box className="rolelist-table-main page-table-main">
                        <Index.TableContainer
                          component={Index.Paper}
                          className="table-container"
                        >
                          <Index.Table
                            aria-label="sticky table"
                            className="table"
                          >
                            <Index.TableHead className="table-head sticky">
                              <Index.TableRow className="table-row">
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  No.
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Role
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Created Date
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="left"
                                >
                                  Status
                                </Index.TableCell>
                                {(adminData &&
                                  adminData?.role?.permission?.includes(
                                    "Role_delete"
                                  )) ||
                                adminData?.role?.permission?.includes(
                                  "Role_edit"
                                ) ||
                                (adminData && adminData?.isAdmin === true) ? (
                                  <Index.TableCell
                                    component="th"
                                    variant="th"
                                    className="table-th"
                                    align="center"
                                  >
                                    Action
                                  </Index.TableCell>
                                ) : (
                                  ""
                                )}
                              </Index.TableRow>
                            </Index.TableHead>
                            <Index.TableBody className="table-body">
                              {pageData?.length ? (
                                pageData?.map((row, ind) => (
                                  <Index.TableRow
                                    key={row._id}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                    >
                                      {row.sNo ? row.sNo : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                    >
                                      {row?.roleName ? row?.roleName : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                      align="center"
                                    >
                                      {row?.createdAt
                                        ? new Date(
                                            row?.createdAt
                                          )?.toLocaleDateString("en-GB")
                                        : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                      align="center"
                                    >
                                      <div
                                        className={
                                          row?.isActive
                                            ? "table-td-active"
                                            : "table-td-Inactive"
                                        }
                                      >
                                        {row?.isActive ? "Active" : "Deactive"}{" "}
                                      </div>
                                    </Index.TableCell>

                                    {(adminData &&
                                      adminData?.role?.permission?.includes(
                                        "Role_delete"
                                      )) ||
                                    adminData?.role?.permission?.includes(
                                      "Role_edit"
                                    ) ||
                                    (adminData &&
                                      adminData?.isAdmin === true) ? (
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                        align="center"
                                      >
                                        <Index.Box className="userdata-btn-flex">
                                          {(adminData &&
                                            adminData?.role?.permission?.includes(
                                              "Role_edit"
                                            )) ||
                                          (adminData &&
                                            adminData?.isAdmin === true) ? (
                                            <>
                                              <IconButton
                                                color="primary"
                                                aria-label="upload picture"
                                                className="rolelist-btn"
                                                component="label"
                                                onClick={() => {
                                                  dispatch(editRole(row));
                                                  navigate(
                                                    "/dashboard/add-role",
                                                    {
                                                      state: row,
                                                    }
                                                  );
                                                }}
                                              >
                                                <Index.EditIcon />
                                              </IconButton>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                          {(adminData &&
                                            adminData?.role?.permission?.includes(
                                              "Role_delete"
                                            )) ||
                                          (adminData &&
                                            adminData?.isAdmin === true) ? (
                                            <>
                                              <IconButton
                                                color="primary"
                                                aria-label="upload picture"
                                                component="label"
                                                className="rolelist-btn"
                                                // onClick={() => {
                                                //   deleteRole(row?._id);
                                                // }}
                                                onClick={() =>
                                                  handleDeleteOpen(row?._id)
                                                }
                                              >
                                                <Index.DeleteIcon />
                                              </IconButton>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                          {(adminData &&
                                            adminData?.role?.permission?.includes(
                                              "Role_edit"
                                            )) ||
                                          (adminData &&
                                            adminData?.isAdmin === true) ? (
                                            <>
                                              {row?.isActive ? (
                                                <IconButton
                                                  color="primary"
                                                  aria-label="upload picture"
                                                  component="label"
                                                  className="rolelist-btn"
                                                  onClick={() => {
                                                    statusRole(
                                                      row?._id,
                                                      !row?.isActive
                                                    );
                                                  }}
                                                >
                                                  <Index.ThumbUpAltIcon />
                                                </IconButton>
                                              ) : (
                                                <IconButton
                                                  color="primary"
                                                  aria-label="upload picture"
                                                  component="label"
                                                  className="rolelist-btn"
                                                  onClick={() => {
                                                    statusRole(
                                                      row?._id,
                                                      !row?.isActive
                                                    );
                                                  }}
                                                  disabled={loading}
                                                >
                                                  <Index.ThumbDownAltIcon />
                                                </IconButton>
                                              )}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </Index.Box>
                                      </Index.TableCell>
                                    ) : (
                                      ""
                                    )}
                                  </Index.TableRow>
                                ))
                              ) : (
                                <Index.TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td no-record-text"
                                    align="center"
                                    colSpan={5}
                                  >
                                    No records found
                                  </Index.TableCell>
                                </Index.TableRow>
                              )}
                            </Index.TableBody>
                          </Index.Table>
                        </Index.TableContainer>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="pagination-main">
              <PageIndex.Pagination
                fetchData={filteredData}
                setPageData={setPageData}
                pageData={pageData}
                search={search}
              />
            </Index.Box>
            {/* delete modal */}
            <PageIndex.DeleteModal
              deleteOpen={deleteOpen}
              handleDeleteClose={handleDeleteClose}
              handleDeleteRecord={deleteRole}
            />
          </Index.Box>
        )}
      </>
    );
  } else {
    navigate("/dashboard");
  }
}
