import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Index from "../../../Index";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import PageIndex from "../../../PageIndex";

import { imageUrl } from "../../../../config/dataService";
import "./FranchiseApplication.css";
// For Show Transation Table
import {
  getAllUserTransactionData,
  handleAddRejectDocComment,
  handleDocumentStatus,
  getAllFranchiseApplication,
  statusFranchiseApplication,
  statusVerifyProertyStatus,
} from "../../../../redux/slices/AdminService";
import FileModal from "./DocumentModal";
import {
  allUserTransactionLog,
  editfranchiseApplication,
} from "../../../../redux/slices/AdminSlice";
import CommonModal from "../../../../component/common/ConnplexModel";
const options = ["Approved", "Rejected"];
const allStatuses = [
  "Submitted",
  "Under Verification",
  "Documents Under Verification",
  "Documents Rejected",
  "Documents Approved",
  "Franchise Rejected",
  "Franchise Approved",
];

const ITEM_HEIGHT = 48;

const ViewFranchiseApplication = () => {
  let navigate = PageIndex.useNavigate();
  const { getfranchiseApplicationData, franchiseApplicationList, adminData } =
    PageIndex.useSelector((state) => state.admin);
  const [loading, setLoading] = useState(false);

  const [selectedValue, setSelectedValue] = useState(null);
  const [descriptionValue, setDescriptionValue] = useState("");
  const [franchiseDescriptionValue, setFranchiseDescriptionValue] =
    useState("");
  const [documentId, setDocumentId] = useState();
  const [documentList, setDocumentList] = useState([]);
  const [statusOfFranchiseDisable, setStatusOfFranchiseDisable] = useState({});
  const [commentValidationMessage, setCommentValidationMessage] = useState("");
  // const [manageFranchiseStatus, setManageFranchiseStatus] = useState("");
  const [franchiseRejectData, setFranchiseRejectData] = useState("");

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const [statusOfFranchise, setStatusOfFranchise] = useState("");
  const [imageSizes, setImageSizes] = useState({});
  // For show Transaction
  const { userTransactionList } = PageIndex.useSelector((state) => state.admin);
  // const { propertyList } = PageIndex.useSelector((state) => state.admin);
  const dispatch = PageIndex.useDispatch();
  const [search, setSearch] = useState("");
  const [pageData, setPageData] = useState([]);
  const [rejectReason, setRejectReason] = useState(false);


  const handleOpenModal = (fileUrl) => {
    setSelectedFile(fileUrl.toString());
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedFile(null);
  };

  let initialValues = {
    status_of_franchise: getfranchiseApplicationData?.status_of_franchise,
    isVerified: getfranchiseApplicationData?.property_id?.isVerified,
  };
  useEffect(() => {
    setStatusOfFranchise(getfranchiseApplicationData?.status_of_franchise);
  }, [getfranchiseApplicationData]);

  const handleVerifyStatusChange = (value, setFieldValue) => {
    setFieldValue("isVerified", value);
    updatePropertyVerifyStatus(value);
  };

  const updatePropertyVerifyStatus = async (value) => {
   await dispatch(
      statusVerifyProertyStatus({
        isVerified: value,
        property_id: getfranchiseApplicationData?.property_id?._id,
      })
    ).then((val) => {
       dispatch(getAllFranchiseApplication()).then((data) => {
        if (data?.payload?.status) {
          let listDataStatus = data?.payload?.data?.filter((ele) => {
            return ele?._id === getfranchiseApplicationData?._id;
          });

          setStatusOfFranchiseDisable(
            listDataStatus[0]?.property_id?.isVerified
          );
        }
      });
    });
  };

  const handleStatusChange = (selectedValue, setFieldValue) => {
    if (selectedValue === "Franchise Rejected") {
      setOpenFranchiseRejectModal(true);
      setFranchiseRejectData(selectedValue);
      if (rejectReason) {
        setStatusOfFranchise(selectedValue);
        setRejectReason(false)
      }
    } else {
      setFieldValue("status_of_franchise", selectedValue);
      handleStatusSubmit(selectedValue);
      setStatusOfFranchise(selectedValue);
    }
  };

  const isOptionDisabled = (currentOption, selectedValue) => {
    if (selectedValue === "") {
      // When no value is selected, no option should be disabled
      return false;
    } else if (selectedValue === "Franchise Rejected") {
      return true;
    }

    const selectedIndex = allStatuses.indexOf(selectedValue);
    const currentIndex = allStatuses.indexOf(currentOption);

    // Disable the options that come after the selected option
    return currentIndex < selectedIndex;
  };

  // const addCommentForFranchiseRejected = (statusOfFranchise, Status) => {
  //   console.log(statusOfFranchise, "statusOfFranchise");
  //   console.log(Status, "Status");
  //   setManageFranchiseStatus(Status);
  //   if (Status === "Franchise Rejected") {
  //     setOpenFranchiseRejectModal(true);
  //   }
  // };

  const handleStatusSubmit = (value) => {
    dispatch(
      statusFranchiseApplication({
        status_of_franchise: value,
        id: getfranchiseApplicationData?._id,
      })
    ).then((val) => {
      if (val?.payload?.status === 200) {
        dispatch(getAllFranchiseApplication());
        dispatch(
          editfranchiseApplication(val?.payload?.data?.updateDataOfFranchise)
        );
      }
    });
  };

  const handleImageLoad = (index, width, height) => {
    setImageSizes((prevSizes) => ({
      ...prevSizes,
      [index]: { width, height },
    }));
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openFranchiseRejectModal, setOpenFranchiseRejectModal] =
    useState(false);
  const [openCommentModal, setOpenCommentModal] = useState(false);
  const [fullComment, setFullComment] = useState([]);

  const open = Boolean(anchorEl);
  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setDocumentId(id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenCommentModal = (comment, time) => {
    setFullComment({ comment: comment, time: time });
    // setOpenModal(true);
    setOpenCommentModal(true);
  };

  const handleOptionSelect = (option) => {
    if (option === "Rejected") {
      setSelectedValue(option);

      setOpenModal(true); // Open the modal when "Rejected" option is selected
      handleClose(); // Close the menu regardless of the selected option
    } else {
      dispatch(
        handleDocumentStatus({
          document_id: documentId,
          isApproved: option,
          franchise_id: getfranchiseApplicationData?._id,
          userId: getfranchiseApplicationData?.user_id?._id,
        })
      ).then((data) => {
        if (data?.payload?.status === 200) {
          filterApplicationList();
          dispatch(
            editfranchiseApplication(data?.payload?.data?.updateDataOfFranchise)
          );
        }
      });
      handleClose();
    }
  };

  const handleRejectedComment = () => {
    const rejectCommentWords = descriptionValue.split(/\s+/);
    if (rejectCommentWords?.length >= 3) {
      dispatch(
        handleAddRejectDocComment({
          document_id: documentId,
          isApproved: selectedValue,
          reject_message: descriptionValue,
          franchise_id: getfranchiseApplicationData?._id,
          userId: getfranchiseApplicationData?.user_id?._id,
        })
      ).then((data) => {
        if (data?.payload?.status === 200) {
          filterApplicationList();
          dispatch(
            editfranchiseApplication(data?.payload?.data?.updateDataOfFranchise)
          );
          setDescriptionValue("");
        }
      });
      closeHandleModal();
    } else {
      setCommentValidationMessage(
        "Please Enter At Least Three Words (upload proper document)"
      );
    }
  };
  const handleFranchiseRejectedComment = () => {
    const rejectCommentWords = franchiseDescriptionValue.split(/\s+/);
    if (rejectCommentWords?.length >= 3) {
      dispatch(
        statusFranchiseApplication({
          status_of_franchise: franchiseRejectData,
          id: getfranchiseApplicationData?._id,
          franchise_reject_message: franchiseDescriptionValue,
        })
      ).then((val) => {
        if (val?.payload?.status === 200) {
          filterApplicationList();
          dispatch(
            editfranchiseApplication(val?.payload?.data?.updateDataOfFranchise)
          );
          setRejectReason(true);
        }
      });
      closeHandleFranchiseModal();
    } else {
      setCommentValidationMessage(
        "Please Enter At Least Three Words (upload proper document)"
      );
    }
  };
  const filterApplicationList = () => {
    dispatch(getAllFranchiseApplication()).then((data) => {
      if (data?.payload?.status) {
        let listData = data?.payload?.data?.filter((ele) => {
          return ele?._id === getfranchiseApplicationData?._id;
        });
        setDocumentList(listData[0]);
      }
    });
    getAllFranchiseDocument();
  };

  const closeHandleModal = () => {
    setOpenModal(false);
  };
  const closeHandleFranchiseModal = () => {
    setOpenFranchiseRejectModal(false);
  };
  const closeHandleRejectCommentModal = () => {
    setOpenCommentModal(false);
  };

  //Get list
  const getAllFranchiseDocument = () => {
    const data = franchiseApplicationList?.filter((ele, i) => {
      return ele?._id === getfranchiseApplicationData?._id;
    });
    if (data) {
      setDocumentList(data[0]);
    } else {
      setDocumentList([]);
    }
  };

  useEffect(() => {
    let data;
    if (getfranchiseApplicationData?.property_id?._id) {
      data = {
        user_id: getfranchiseApplicationData?.user_id?._id,
        property_id: getfranchiseApplicationData?.property_id?._id,
      };
    } else {
      data = {
        user_id: getfranchiseApplicationData?.user_id?._id,
        rebranding_id: getfranchiseApplicationData?.rebranding_id?._id,
      };
    }

    dispatch(getAllUserTransactionData(data));
    getAllFranchiseDocument();
  }, []);
  const city = getfranchiseApplicationData?.property_id
    ? getfranchiseApplicationData?.property_id?.project_address?.city
    : getfranchiseApplicationData?.rebranding_id?.property_location?.city;
  const state = getfranchiseApplicationData?.property_id
    ? getfranchiseApplicationData?.property_id?.project_address?.state
    : getfranchiseApplicationData?.rebranding_id?.property_location?.state;
  const pincode = getfranchiseApplicationData?.property_id
    ? getfranchiseApplicationData?.property_id?.project_address?.pincode
    : getfranchiseApplicationData?.rebranding_id?.property_location?.pincode;

  let address = "";

  if (city) {
    address += city;
  }

  if (state) {
    address += address ? `, ${state}` : state;
  }

  if (pincode) {
    address += address ? `, ${pincode}` : pincode;
  }
  if (
    (adminData &&
      adminData?.role?.permission?.includes("Franchise Application_view")) ||
    (adminData && adminData?.isAdmin === true)
  ) {
    return (
      <>
        <PageIndex.LoadingOverlay
          spinner
          text="Loading your content..."
          active={loading}
        >
          <Index.Box>
            <Index.Box className="admin-page-title-main-viewcomponent">
              <Index.Typography
                className="admin-page-title-viewcomponent"
                // component="h4"
                // variant="h4"
              >
                View Franchise Details
              </Index.Typography>
            </Index.Box>
            <Index.Box className="view-card">
              <Index.Box
                display="grid"
                className="display-row-add-user"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 6",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="view-swiper-main">
                    <Swiper
                      spaceBetween={20}
                      pagination={{
                        clickable: true,
                      }}
                      breakpoints={{
                        280: {
                          slidesPerView: 1,
                        },
                        375: {
                          slidesPerView: 1,
                        },
                        640: {
                          slidesPerView: 1,
                        },
                        768: {
                          slidesPerView: 1,
                        },
                        1024: {
                          slidesPerView: 1,
                        },
                        1366: {
                          slidesPerView: 1,
                        },
                        1500: {
                          slidesPerView: 1,
                        },
                      }}
                      modules={[Pagination, Navigation]}
                      className="new-game-swiper mySwiper"
                    >
                      {!getfranchiseApplicationData?.property_id ? (
                        getfranchiseApplicationData?.rebranding_id?.images?.map(
                          (element, index) => {
                            const fileExtension = element
                              .split(".")
                              .pop()
                              .toLowerCase(); // Get the file extension

                            const isVideo =
                              fileExtension === "mp4" ||
                              fileExtension === "avi"; // Check if the element is a video file
                            const mediaUrl = `${imageUrl}/${element}`; // Set the correct URL based on the file type

                            const imageSize = imageSizes[index];
                            const imageStyle = imageSize
                              ? {
                                  // maxWidth: "100%",
                                  // maxHeight: "100%",
                                }
                              : {};

                            return (
                              <SwiperSlide
                                className="new-game-swiper-slide"
                                key={index}
                              >
                                <Index.Box className="new-game-flex-li">
                                  <Index.Box className="new-game-card">
                                    {isVideo ? (
                                      <video className="new-game-img" controls>
                                        <source
                                          src={mediaUrl}
                                          type="video/mp4"
                                        />
                                        Your browser does not support the video
                                        tag.
                                      </video>
                                    ) : (
                                      <img
                                        alt="img"
                                        src={mediaUrl}
                                        className="new-game-img"
                                        onLoad={(e) =>
                                          handleImageLoad(
                                            index,
                                            e.target.naturalWidth,
                                            e.target.naturalHeight
                                          )
                                        }
                                        style={imageStyle}
                                      />
                                    )}
                                  </Index.Box>
                                </Index.Box>
                              </SwiperSlide>
                            );
                          }
                        )
                      ) : getfranchiseApplicationData?.property_id?.images
                          ?.length === 0 ? (
                        <SwiperSlide className="new-game-swiper-slide">
                          <Index.Box className="new-game-flex-li">
                            <Index.Box className="game-card"></Index.Box>
                            <Index.Box className="new-game-card">
                              <img
                                src={Index.Png.staticLogoImage}
                                className="static-use-image"
                                alt="logo"
                              />
                            </Index.Box>
                          </Index.Box>
                        </SwiperSlide>
                      ) : (
                        getfranchiseApplicationData?.property_id?.images?.map(
                          (element, index) => {
                            const fileExtension = element
                              .split(".")
                              .pop()
                              .toLowerCase(); // Get the file extension

                            const isVideo =
                              fileExtension === "mp4" ||
                              fileExtension === "avi"; // Check if the element is a video file
                            const mediaUrl = `${imageUrl}/${element}`; // Set the correct URL based on the file type

                            const imageSize = imageSizes[index];
                            const imageStyle = imageSize
                              ? {
                                  // maxWidth: "100%",
                                  // maxHeight: "100%",
                                }
                              : {};

                            return (
                              <SwiperSlide
                                className="new-game-swiper-slide"
                                key={index}
                              >
                                <Index.Box className="new-game-flex-li">
                                  <Index.Box className="new-game-card">
                                    {isVideo ? (
                                      <video className="new-game-img" controls>
                                        <source
                                          src={mediaUrl}
                                          type="video/mp4"
                                        />
                                        Your browser does not support the video
                                        tag.
                                      </video>
                                    ) : (
                                      <img
                                        alt="img"
                                        src={mediaUrl}
                                        className="new-game-img"
                                        onLoad={(e) =>
                                          handleImageLoad(
                                            index,
                                            e.target.naturalWidth,
                                            e.target.naturalHeight
                                          )
                                        }
                                        style={imageStyle}
                                      />
                                    )}
                                  </Index.Box>
                                </Index.Box>
                              </SwiperSlide>
                            );
                          }
                        )
                      )}
                    </Swiper>
                  </Index.Box>
                </Index.Box>

                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 6",
                  }}
                  className="grid-column"
                >
                  <PageIndex.Formik initialValues={initialValues}>
                    {({ values, setFieldValue }) => (
                      <PageIndex.Form>
                        <Index.Box
                          display="grid"
                          className="display-row-add-user"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                        >
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box add-user-input dropdown-select">
                              <Index.FormHelperText className="form-lable">
                                Status Of Franchise
                              </Index.FormHelperText>
                              <Index.Box className="form-group">
                                <PageIndex.Field
                                  name="status_of_franchise"
                                  as={Index.Select}
                                  disabled={
                                    getfranchiseApplicationData?.property_id
                                      ?.isVerified === false ||
                                    statusOfFranchiseDisable === false
                                      ? true
                                      : false
                                  }
                                  value={statusOfFranchise}
                                  variant="outlined"
                                  fullWidth
                                  id="fullWidth"
                                  className="dropdown-franchise-status form-control"
                                  onChange={(e) =>
                                    handleStatusChange(
                                      e.target.value,
                                      setFieldValue
                                    )
                                  }
                                >
                                  <Index.MenuItem value="" disabled>
                                    Select Status
                                  </Index.MenuItem>
                                  {allStatuses?.map((status) => (
                                    <Index.MenuItem
                                      key={status}
                                      value={status}
                                      disabled={isOptionDisabled(
                                        status,
                                        statusOfFranchise
                                      )}
                                      // onClick={() =>
                                      //   addCommentForFranchiseRejected(
                                      //     statusOfFranchise,
                                      //     status
                                      //   )
                                      // }
                                    >
                                      {status ? status : "-"}
                                    </Index.MenuItem>
                                  ))}
                                </PageIndex.Field>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>

                          {getfranchiseApplicationData?.property_id && (
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box add-user-input dropdown-select">
                                <Index.FormHelperText className="form-lable">
                                  Property Verification Status
                                </Index.FormHelperText>
                                <Index.Box className="form-group">
                                  <PageIndex.Field
                                    name="isVerified"
                                    as={Index.Select}
                                    variant="outlined"
                                    fullWidth
                                    id="fullWidth"
                                    className={
                                      getfranchiseApplicationData?.property_id
                                        ?.isVerified === true
                                        ? "dropdown-franchise-status form-control"
                                        : "dropdown-franchise-status form-control"
                                    }
                                    onChange={(e) =>
                                      handleVerifyStatusChange(
                                        e.target.value,
                                        setFieldValue
                                      )
                                    }
                                  >
                                    <Index.MenuItem value="" disabled>
                                      Select Status
                                    </Index.MenuItem>
                                    <Index.MenuItem
                                      value="true"
                                      disabled={
                                        getfranchiseApplicationData?.property_id
                                          ?.isVerified === true
                                      }
                                    >
                                      Verified
                                    </Index.MenuItem>
                                    <Index.MenuItem
                                      value="false"
                                      disabled={
                                        getfranchiseApplicationData?.property_id
                                          ?.isVerified === false
                                      }
                                    >
                                      UnVerified
                                    </Index.MenuItem>
                                  </PageIndex.Field>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          )}
                        </Index.Box>
                        <Index.Typography
                          sx={{ color: "red", fontSize: "12px" }}
                        >
                          {getfranchiseApplicationData?.franchise_reject_message
                            ? getfranchiseApplicationData
                                ?.franchise_reject_message[0]
                            : ""}
                        </Index.Typography>
                      </PageIndex.Form>
                    )}
                  </PageIndex.Formik>

                  <Index.Box className="view-franchises-details-box view-propert-box">
                    <Index.Typography
                      variant="h4"
                      className="proerty-details-titles"
                    >
                      {getfranchiseApplicationData?.property_id
                        ?.property_type === "Land"
                        ? getfranchiseApplicationData?.property_id
                            ?.property_type
                        : getfranchiseApplicationData?.rebranding_id
                        ? getfranchiseApplicationData?.rebranding_id
                            ?.property_name
                        : getfranchiseApplicationData?.property_id
                            ?.property_name}
                    </Index.Typography>
                    {getfranchiseApplicationData?.property_id?.property_type !==
                      "Land" && (
                      <Index.Box className="property-flex">
                        <Index.Typography className="property-lables">
                          <img
                            src={Index.Svg.propertylocation}
                            alt="sidebar icon"
                            className="franchises-icons"
                          />
                          {address ? address : ""}
                        </Index.Typography>
                      </Index.Box>
                    )}
                    <Index.Box className="property-detail-box view-user-property-box">
                      {getfranchiseApplicationData?.user_id && (
                        <Index.Box className="property-flex">
                          <Index.Typography className="property-lables">
                            <img
                              src={Index.Svg.propertyuser}
                              alt="sidebar icon"
                              className="franchises-icons"
                            />
                            User Name
                          </Index.Typography>
                          <Index.Typography className="property-details-data-text">
                            {` ${
                              getfranchiseApplicationData?.user_id?.name
                                ? getfranchiseApplicationData?.user_id?.name
                                : "-"
                            }`}
                          </Index.Typography>
                        </Index.Box>
                      )}
                      {getfranchiseApplicationData?.user_id && (
                        <Index.Box className="property-flex">
                          <Index.Typography className="property-lables">
                            <img
                              src={Index.Svg.propertyemail}
                              alt="sidebar icon"
                              className="franchises-icons"
                            />
                            User Email
                          </Index.Typography>
                          <Index.Typography className="property-details-data-text">
                            {` ${
                              getfranchiseApplicationData?.user_id?.email
                                ? getfranchiseApplicationData?.user_id?.email
                                : "-"
                            }`}
                          </Index.Typography>
                        </Index.Box>
                      )}
                      {getfranchiseApplicationData?.user_id && (
                        <Index.Box className="property-flex">
                          <Index.Typography className="property-lables">
                            <img
                              src={Index.Svg.propertycall}
                              alt="sidebar icon"
                              className="franchises-icons"
                            />
                            User Mobile
                          </Index.Typography>
                          <Index.Typography className="property-details-data-text">
                            {` ${
                              getfranchiseApplicationData?.user_id?.mobile
                                ? getfranchiseApplicationData?.user_id?.mobile
                                : "-"
                            }`}
                          </Index.Typography>
                        </Index.Box>
                      )}
                      {getfranchiseApplicationData?.user_id?.docType && (
                        <Index.Box className="property-flex">
                          <Index.Typography className="property-lables">
                            <img
                              src={Index.Png.panCard}
                              alt="sidebar icon"
                              className="franchises-icons"
                            />
                            User{" "}
                            {getfranchiseApplicationData?.user_id?.docType ===
                            "Pan Card Number"
                              ? "Pan Card"
                              : "GST"}{" "}
                            No.
                          </Index.Typography>
                          <Index.Typography className="property-details-data-text">
                            {`  ${
                              getfranchiseApplicationData?.user_id?.docNumber
                                ? getfranchiseApplicationData?.user_id
                                    ?.docNumber
                                : "---"
                            }`}
                          </Index.Typography>
                        </Index.Box>
                      )}

                      {getfranchiseApplicationData?.property_id && (
                        <>
                          <Index.Box className="property-flex">
                            <Index.Typography className="property-lables">
                              <img
                                src={Index.Svg.propertytype}
                                alt="sidebar icon"
                                className="franchises-icons"
                              />
                              Property Code
                            </Index.Typography>
                            <Index.Typography className="property-details-data-text">
                              {` ${
                                getfranchiseApplicationData?.property_id
                                  ?.propertyCode
                                  ? getfranchiseApplicationData?.property_id
                                      ?.propertyCode
                                  : "-"
                              }`}
                            </Index.Typography>
                          </Index.Box>
                        </>
                      )}
                      {getfranchiseApplicationData?.property_id && (
                        <>
                          <Index.Box className="property-flex">
                            <Index.Typography className="property-lables">
                              <img
                                src={Index.Svg.propertytype}
                                alt="sidebar icon"
                                className="franchises-icons"
                              />
                              Property Type
                            </Index.Typography>
                            <Index.Typography className="property-details-data-text">
                              {` ${
                                getfranchiseApplicationData?.property_id
                                  ?.property_type
                                  ? getfranchiseApplicationData?.property_id
                                      ?.property_type
                                  : "-"
                              }`}
                            </Index.Typography>
                          </Index.Box>
                        </>
                      )}
                      {getfranchiseApplicationData?.property_id &&
                        getfranchiseApplicationData?.property_id
                          ?.property_type !== "Land" && (
                          <>
                            <Index.Box className="property-flex">
                              <Index.Typography className="property-lables">
                                <img
                                  src={Index.Svg.propertyarea}
                                  alt="sidebar icon"
                                  className="franchises-icons"
                                />
                                Floor Height
                              </Index.Typography>
                              <Index.Typography className="property-details-data-text">
                                {` ${
                                  getfranchiseApplicationData?.property_id
                                    ?.floor_height
                                    ? getfranchiseApplicationData?.property_id
                                        ?.floor_height
                                    : "0"
                                } Ft`}
                              </Index.Typography>
                            </Index.Box>
                          </>
                        )}
                      {getfranchiseApplicationData?.property_id &&
                        getfranchiseApplicationData?.property_id
                          ?.property_type !== "Land" && (
                          <>
                            <Index.Box className="property-flex">
                              <Index.Typography className="property-lables">
                                <img
                                  src={Index.Svg.propertyarea}
                                  alt="sidebar icon"
                                  className="franchises-icons"
                                />
                                Floor Number
                              </Index.Typography>
                              <Index.Typography className="property-details-data-text">
                                {` ${
                                  getfranchiseApplicationData?.property_id
                                    ?.floor_number
                                    ? getfranchiseApplicationData?.property_id
                                        ?.floor_number
                                    : "-"
                                }`}
                              </Index.Typography>
                            </Index.Box>
                          </>
                        )}
                      {getfranchiseApplicationData?.property_id &&
                        getfranchiseApplicationData?.property_id
                          ?.property_type !== "Land" && (
                          <>
                            <Index.Box className="property-flex">
                              <Index.Typography className="property-lables">
                                <img
                                  src={Index.Png.propertyStatus}
                                  alt="sidebar icon"
                                  className="franchises-icons"
                                />
                                Property Status
                              </Index.Typography>
                              <Index.Typography className="property-details-data-text">
                                {` ${
                                  getfranchiseApplicationData?.property_id
                                    ?.project_status
                                    ? getfranchiseApplicationData?.property_id
                                        ?.project_status
                                    : "-"
                                }`}
                              </Index.Typography>
                            </Index.Box>
                          </>
                        )}
                      {getfranchiseApplicationData?.property_id &&
                        getfranchiseApplicationData?.property_id
                          ?.property_type === "Land" && (
                          <>
                            <Index.Box className="property-flex">
                              <Index.Typography className="property-lables">
                                <img
                                  src={Index.Png.landType}
                                  alt="sidebar icon"
                                  className="franchises-icons"
                                />
                                Land Type
                              </Index.Typography>
                              <Index.Typography className="property-details-data-text">
                                {` ${
                                  getfranchiseApplicationData?.property_id
                                    ?.land_type
                                    ? getfranchiseApplicationData?.property_id
                                        ?.land_type
                                    : "-"
                                }`}
                              </Index.Typography>
                            </Index.Box>
                          </>
                        )}
                      {getfranchiseApplicationData?.property_id &&
                        getfranchiseApplicationData?.property_id
                          ?.property_type === "Land" && (
                          <>
                            <Index.Box className="property-flex">
                              <Index.Typography className="property-lables">
                                <img
                                  src={Index.Svg.propertyarea}
                                  alt="sidebar icon"
                                  className="franchises-icons"
                                />
                                Size Of Land
                              </Index.Typography>
                              <Index.Typography className="property-details-data-text">
                                {` ${
                                  getfranchiseApplicationData?.property_id
                                    ?.size_of_the_land
                                    ? getfranchiseApplicationData?.property_id
                                        ?.size_of_the_land
                                    : "-"
                                }`}
                              </Index.Typography>
                            </Index.Box>
                          </>
                        )}
                      {getfranchiseApplicationData?.property_id &&
                        getfranchiseApplicationData?.property_id
                          ?.property_type === "Land" && (
                          <>
                            <Index.Box className="property-flex">
                              <Index.Typography className="property-lables">
                                <img
                                  src={Index.Png.landConnectivity}
                                  alt="sidebar icon"
                                  className="franchises-icons"
                                />
                                Land Connectivity
                              </Index.Typography>
                              <Index.Typography className="property-details-data-text">
                                {` ${
                                  getfranchiseApplicationData?.property_id
                                    ?.land_connectivity
                                    ? getfranchiseApplicationData?.property_id
                                        ?.land_connectivity
                                    : "-"
                                }`}
                              </Index.Typography>
                            </Index.Box>
                          </>
                        )}
                      {!getfranchiseApplicationData?.property_id && (
                        <>
                          <Index.Box className="property-flex">
                            <Index.Typography className="property-lables">
                              <img
                                src={Index.Png.theatreName}
                                alt="sidebar icon"
                                className="franchises-icons"
                              />
                              Cinema Name
                            </Index.Typography>
                            <Index.Typography className="property-details-data-text">
                              {` ${
                                getfranchiseApplicationData?.rebranding_id
                                  ?.cinema_name
                                  ? getfranchiseApplicationData?.rebranding_id
                                      ?.cinema_name
                                  : "-"
                              }`}
                            </Index.Typography>
                          </Index.Box>
                        </>
                      )}
                      {!getfranchiseApplicationData?.property_id && (
                        <>
                          <Index.Box className="property-flex">
                            <Index.Typography className="property-lables">
                              <img
                                src={Index.Png.noOfScreens}
                                alt="sidebar icon"
                                className="franchises-icons"
                              />
                              Number Of Screens
                            </Index.Typography>
                            <Index.Typography className="property-details-data-text">
                              {` ${
                                getfranchiseApplicationData?.rebranding_id
                                  ?.no_of_screens
                                  ? getfranchiseApplicationData?.rebranding_id
                                      ?.no_of_screens
                                  : "-"
                              }`}
                            </Index.Typography>
                          </Index.Box>
                        </>
                      )}
                      {!getfranchiseApplicationData?.property_id && (
                        <>
                          <Index.Box className="property-flex">
                            <Index.Typography className="property-lables">
                              <img
                                src={Index.Svg.propertyarea}
                                alt="sidebar icon"
                                className="franchises-icons"
                              />
                              Existing Business
                            </Index.Typography>
                            <Index.Typography className="property-details-data-text">
                              {` ${
                                getfranchiseApplicationData?.rebranding_id
                                  ?.existing_business
                                  ? getfranchiseApplicationData?.rebranding_id
                                      ?.existing_business
                                  : "-"
                              }`}
                            </Index.Typography>
                          </Index.Box>
                        </>
                      )}
                      {!getfranchiseApplicationData?.property_id && (
                        <>
                          <Index.Box className="property-flex">
                            <Index.Typography className="property-lables">
                              <img
                                src={Index.Svg.propertyarea}
                                alt="sidebar icon"
                                className="franchises-icons"
                              />
                              Area Of Property
                            </Index.Typography>
                            <Index.Typography className="property-details-data-text">
                              {` ${
                                getfranchiseApplicationData?.rebranding_id
                                  ?.area_of_property
                                  ? getfranchiseApplicationData?.rebranding_id
                                      ?.area_of_property
                                  : "0"
                              } Sq Mtrs`}
                            </Index.Typography>
                          </Index.Box>
                        </>
                      )}

                      <Index.Box className="property-flex">
                        <Index.Typography className="property-lables">
                          <img
                            src={Index.Png.investAmount}
                            alt="sidebar icon"
                            className="franchises-icons"
                          />
                          Invest Amount
                        </Index.Typography>
                        <Index.Typography className="property-details-data-text">
                          {getfranchiseApplicationData?.invest_amount
                            ? `	₹ ${getfranchiseApplicationData?.invest_amount?.toLocaleString(
                                "en-IN"
                              )}`
                            : "-"}
                        </Index.Typography>
                      </Index.Box>

                      <Index.Box className="property-flex">
                        <Index.Typography className="property-lables">
                          <img
                            src={Index.Png.investAmount}
                            alt="sidebar icon"
                            className="franchises-icons"
                          />
                          Total Amount
                        </Index.Typography>
                        <Index.Typography className="property-details-data-text">
                          {/* {`₹ ${getfranchiseApplicationData?.tatal_amount?.toLocaleString(
                          "en-IN"
                        )}`} */}
                          {getfranchiseApplicationData?.tatal_amount
                            ? `	₹ ${getfranchiseApplicationData?.tatal_amount?.toLocaleString(
                                "en-IN"
                              )}`
                            : "-"}
                        </Index.Typography>
                      </Index.Box>

                      <Index.Box className="property-flex">
                        <Index.Typography className="property-lables">
                          <img
                            src={Index.Svg.propertyarea}
                            alt="sidebar icon"
                            className="franchises-icons"
                          />
                          Option
                        </Index.Typography>
                        <Index.Typography className="property-details-data-text">
                          {` ${
                            getfranchiseApplicationData?.options
                              ? getfranchiseApplicationData?.options
                              : "-"
                          }`}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box className="view-card">
              <Index.Box
                display="grid"
                className="display-row-add-user"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column divider-column"
                >
                  <Index.Box className="franchise-devider-main">
                    <Index.Divider className="franchise-divider">
                      <Index.Chip
                        label="Applicant Documents"
                        className="list-divider"
                      />
                    </Index.Divider>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="dashboard-content">
                    <Index.Box className="admin-dashboard-list-row">
                      <Index.Box sx={{ width: 1 }} className="grid-main">
                        <Index.Box
                          display="grid"
                          className="display-row-userlist"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                        >
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 12",
                              md: "span 12",
                              lg: "span 12",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="viewfranchise-document-table-main page-table-main">
                              <Index.TableContainer
                                component={Index.Paper}
                                className="table-container"
                              >
                                <Index.Table
                                  sx={{ minWidth: 650 }}
                                  aria-label="sticky table"
                                  className="table"
                                >
                                  <Index.TableHead className="table-head sticky">
                                    <Index.TableRow className="table-row">
                                      <Index.TableCell
                                        component="th"
                                        variant="th"
                                        className="table-th"
                                        align="center"
                                      >
                                        No.
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="th"
                                        variant="th"
                                        className="table-th"
                                        align="center"
                                      >
                                        Document Name
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="th"
                                        variant="th"
                                        className="table-th"
                                        align="center"
                                      >
                                        DateTime
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="th"
                                        variant="th"
                                        className="table-th"
                                        align="center"
                                      >
                                        Comment
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="th"
                                        variant="th"
                                        className="table-th"
                                        align="center"
                                      >
                                        Document Status
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="th"
                                        variant="th"
                                        className="table-th"
                                        align="center"
                                      >
                                        Action
                                      </Index.TableCell>
                                    </Index.TableRow>
                                  </Index.TableHead>
                                  <Index.TableBody className="table-body">
                                    {documentList?.document_id?.length > 0 ? (
                                      documentList.document_id?.map(
                                        (row, ind) => (
                                          <Index.TableRow
                                            key={row._id}
                                            sx={{
                                              "&:last-child td, &:last-child th":
                                                {
                                                  border: 0,
                                                },
                                            }}
                                          >
                                            <Index.TableCell
                                              component="td"
                                              variant="td"
                                              scope="row"
                                              className="table-td"
                                              align="center"
                                            >
                                              {ind + 1}
                                            </Index.TableCell>

                                            <Index.TableCell
                                              component="td"
                                              variant="td"
                                              scope="row"
                                              className="table-td"
                                              align="center"
                                            >
                                              {row.document_name
                                                ? row.document_name
                                                : "-"}
                                            </Index.TableCell>

                                            <Index.TableCell
                                              component="td"
                                              variant="td"
                                              scope="row"
                                              className="table-td"
                                              align="center"
                                            >
                                              {row.createdAt
                                                ? new Date(
                                                    row.createdAt
                                                  ).toLocaleDateString(
                                                    "en-GB"
                                                  ) +
                                                  " " +
                                                  new Date(
                                                    row.createdAt
                                                  ).toLocaleTimeString(
                                                    "en-US",
                                                    {
                                                      hour: "2-digit",
                                                      minute: "2-digit",
                                                      hour12: true,
                                                    }
                                                  )
                                                : "-"}
                                            </Index.TableCell>
                                            <Index.TableCell
                                              component="td"
                                              variant="td"
                                              scope="row"
                                              className="table-td"
                                              align="center"
                                            >
                                              {/* {row?.reject_message !== "false" ? (
                                              <>
                                                {row?.reject_message &&
                                                  row?.reject_message[0]
                                                    ?.split(" ")
                                                    ?.slice(0, 3)
                                                    ?.join(" ")}
                                                {row?.reject_message &&
                                                  row?.reject_message[0]?.split(
                                                    " "
                                                  ).length >= 3 && (
                                                    <button
                                                      className="reject-message-btn"
                                                      onClick={() =>
                                                        handleOpenCommentModal(
                                                          row?.reject_message,
                                                          row?.rejectTime
                                                        )
                                                      }
                                                    >
                                                      ...
                                                    </button>
                                                  )}
                                              </>
                                            ) : (
                                              "----"
                                            )} */}
                                              {row?.reject_message &&
                                              row.reject_message.length > 0 ? (
                                                <>
                                                  {row?.reject_message &&
                                                    row?.reject_message[0]
                                                      ?.split(" ")
                                                      ?.slice(0, 3)
                                                      ?.join(" ")}
                                                  {row?.reject_message &&
                                                    row?.reject_message[0]?.split(
                                                      " "
                                                    ).length >= 3 && (
                                                      <button
                                                        className="reject-message-btn"
                                                        onClick={() =>
                                                          handleOpenCommentModal(
                                                            row?.reject_message,
                                                            row?.rejectTime
                                                          )
                                                        }
                                                      >
                                                        ...
                                                      </button>
                                                    )}
                                                </>
                                              ) : (
                                                "----"
                                              )}
                                            </Index.TableCell>

                                            <Index.TableCell
                                              component="td"
                                              variant="td"
                                              scope="row"
                                              className="table-td"
                                              align="center"
                                            >
                                              <div
                                                className={
                                                  row?.isApproved === "Approved"
                                                    ? "table-td-active-transaction"
                                                    : row?.isApproved ===
                                                      "Rejected"
                                                    ? "table-td-Inactive-transaction"
                                                    : row?.isApproved ===
                                                      "Pending"
                                                    ? "table-td-Pending-transaction"
                                                    : ""
                                                }
                                              >
                                                {row?.isApproved
                                                  ? row?.isApproved
                                                  : "-"}
                                              </div>
                                            </Index.TableCell>

                                            <Index.TableCell
                                              component="td"
                                              variant="td"
                                              className="table-td"
                                              align="center"
                                            >
                                              <Index.Box className="userdata-btn-flex document-show-action">
                                                <Index.Box
                                                  key={ind}
                                                  className="document-show-icon"
                                                  onClick={() =>
                                                    handleOpenModal(row?.image)
                                                  }
                                                >
                                                  <Index.Tooltip
                                                    title="View Document"
                                                    placement="top"
                                                    arrow
                                                  >
                                                    <Index.ListItemText
                                                      button
                                                      primary={
                                                        <Index.RemoveRedEyeIcon />
                                                      }
                                                    />
                                                  </Index.Tooltip>
                                                </Index.Box>
                                                <FileModal
                                                  open={modalOpen}
                                                  onClose={handleCloseModal}
                                                  fileUrl={selectedFile}
                                                />
                                                <Index.IconButton
                                                  aria-label="more"
                                                  id="long-button"
                                                  aria-controls={
                                                    open
                                                      ? "long-menu"
                                                      : undefined
                                                  }
                                                  aria-expanded={
                                                    open ? "true" : undefined
                                                  }
                                                  aria-haspopup="true"
                                                  onClick={(e) =>
                                                    handleClick(e, row._id)
                                                  }
                                                  disabled={
                                                    getfranchiseApplicationData
                                                      ?.property_id
                                                      ?.isVerified === false ||
                                                    statusOfFranchiseDisable ===
                                                      false
                                                      ? true
                                                      : false
                                                  }
                                                >
                                                  <Index.MoreVertIcon className="dot-button" />
                                                </Index.IconButton>
                                                <Index.Menu
                                                  id="long-menu"
                                                  MenuListProps={{
                                                    "aria-labelledby":
                                                      "long-button",
                                                  }}
                                                  anchorEl={anchorEl}
                                                  open={open}
                                                  onClose={handleClose}
                                                  PaperProps={{
                                                    style: {
                                                      maxHeight:
                                                        ITEM_HEIGHT * 4.5,
                                                      width: "20ch",
                                                    },
                                                  }}
                                                >
                                                  {options?.map((option) => (
                                                    <Index.MenuItem
                                                      key={option}
                                                      selected={selectedValue}
                                                      onClick={() =>
                                                        handleOptionSelect(
                                                          option
                                                        )
                                                      }
                                                    >
                                                      {option}
                                                    </Index.MenuItem>
                                                  ))}
                                                </Index.Menu>
                                              </Index.Box>
                                            </Index.TableCell>
                                          </Index.TableRow>
                                        )
                                      )
                                    ) : (
                                      <Index.TableRow
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          scope="row"
                                          className="table-td no-record-text"
                                          align="center"
                                          colSpan={6}
                                        >
                                          No records found
                                        </Index.TableCell>
                                      </Index.TableRow>
                                    )}
                                  </Index.TableBody>
                                </Index.Table>
                              </Index.TableContainer>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  {openModal && (
                    <Index.Box className="custom-dialog">
                      <Index.Dialog
                        open={openModal}
                        onClose={closeHandleModal}
                        maxWidth="sm"
                        fullWidth
                        className="commect-modal custom-dialog"
                      >
                        <Index.DialogTitle className="comment-title">
                          Reason For Reject Document
                        </Index.DialogTitle>
                        <Index.DialogContent>
                          <Index.TextField
                            id="description"
                            label="Comment"
                            className="comment-description"
                            multiline
                            minRows={3}
                            value={descriptionValue}
                            onChange={(e) => {
                              const inputValue = e.target.value
                                .replace(/^\s+/, "")
                                .replace(/\s\s+/g, " ");
                              setDescriptionValue(inputValue);
                            }}
                            fullWidth
                          />
                          <Index.Typography className="reject-comment-validation">
                            {commentValidationMessage}
                          </Index.Typography>
                        </Index.DialogContent>
                        <Index.Button
                          type="submit"
                          variant="contained"
                          className="comment-button"
                          onClick={handleRejectedComment}
                        >
                          Add Comment
                        </Index.Button>
                      </Index.Dialog>
                    </Index.Box>
                  )}
                  {openFranchiseRejectModal && (
                    <Index.Box className="custom-dialog">
                      <Index.Dialog
                        open={openFranchiseRejectModal}
                        onClose={closeHandleFranchiseModal}
                        maxWidth="sm"
                        fullWidth
                        className="commect-modal custom-dialog"
                      >
                        <Index.DialogTitle className="comment-title">
                          Reason For Reject Franchise
                        </Index.DialogTitle>
                        <Index.DialogContent>
                          <Index.TextField
                            id="description"
                            label="Comment"
                            className="comment-description"
                            multiline
                            minRows={3}
                            // value={description}
                            onChange={(e) =>
                              setFranchiseDescriptionValue(e.target.value)
                            }
                            fullWidth
                          />
                          <Index.Typography className="reject-comment-validation">
                            {commentValidationMessage}
                          </Index.Typography>
                        </Index.DialogContent>
                        <Index.Button
                          type="submit"
                          variant="contained"
                          className="comment-button"
                          onClick={handleFranchiseRejectedComment}
                        >
                          Add Comment
                        </Index.Button>
                      </Index.Dialog>
                    </Index.Box>
                  )}
                  {openCommentModal && (
                    <Index.Box className="custom-dialog">
                      <Index.Dialog
                        open={openCommentModal}
                        onClose={closeHandleRejectCommentModal}
                        maxWidth="sm"
                        fullWidth
                        className="commect-modal custom-dialog"
                      >
                        <Index.DialogTitle className="comment-title">
                          Reason For Reject Document
                        </Index.DialogTitle>

                        <Index.DialogContent className="comment-time-modal">
                          <Index.List>
                            {fullComment?.comment?.map((commentData) => (
                              <Index.ListItem>
                                <Index.ListItemText primary={commentData} />
                              </Index.ListItem>
                            ))}
                          </Index.List>
                          <Index.List>
                            {fullComment?.time?.map((commentData) => (
                              <Index.ListItem>
                                <Index.ListItemText
                                  primary={
                                    new Date(commentData).toLocaleDateString(
                                      "en-GB"
                                    ) +
                                    " " +
                                    new Date(commentData).toLocaleTimeString(
                                      "en-US",
                                      {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        hour12: true,
                                      }
                                    )
                                  }
                                />
                              </Index.ListItem>
                            ))}
                          </Index.List>
                        </Index.DialogContent>
                      </Index.Dialog>
                    </Index.Box>
                  )}
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box className="view-card">
              <Index.Box
                display="grid"
                className="display-row-add-user"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column divider-column"
                >
                  <Index.Box className="franchise-devider-main">
                    <Index.Divider className="franchise-divider">
                      <Index.Chip
                        label="Transaction History"
                        className="list-divider"
                      />
                    </Index.Divider>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column divider-column"
                >
                  <Index.Box className="dashboard-content">
                    <Index.Box className="admin-dashboard-list-row">
                      <Index.Box sx={{ width: 1 }} className="grid-main">
                        <Index.Box
                          display="grid"
                          className="display-row-userlist"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                        >
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 12",
                              md: "span 12",
                              lg: "span 12",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="userlist-table-main page-table-main">
                              <Index.TableContainer
                                component={Index.Paper}
                                className="table-container"
                              >
                                <Index.Table
                                  sx={{ minWidth: 650 }}
                                  aria-label="sticky table"
                                  className="table"
                                >
                                  <Index.TableHead className="table-head sticky">
                                    <Index.TableRow className="table-row">
                                      <Index.TableCell
                                        component="th"
                                        variant="th"
                                        className="table-th"
                                        align="center"
                                      >
                                        No.
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="th"
                                        variant="th"
                                        className="table-th"
                                        align="center"
                                      >
                                        Transaction Id
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="th"
                                        variant="th"
                                        className="table-th"
                                        align="center"
                                      >
                                        User Name
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="th"
                                        variant="th"
                                        className="table-th"
                                        align="center"
                                      >
                                        Property Name
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="th"
                                        variant="th"
                                        className="table-th"
                                        align="center"
                                      >
                                        Total Amount
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="th"
                                        variant="th"
                                        className="table-th"
                                        align="center"
                                      >
                                        Amount Type
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="th"
                                        variant="th"
                                        className="table-th"
                                        align="center"
                                      >
                                        Transaction Date
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="th"
                                        variant="th"
                                        className="table-th"
                                        align="center"
                                      >
                                        Status
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="th"
                                        variant="th"
                                        className="table-th"
                                        align="center"
                                      >
                                        Action
                                      </Index.TableCell>
                                    </Index.TableRow>
                                  </Index.TableHead>

                                  <Index.TableBody className="table-body">
                                    {pageData?.length ? (
                                      <Index.TableRow
                                        key={pageData[0]._id}
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          scope="row"
                                          className="table-td"
                                          align="center"
                                        >
                                          {pageData[0].sNo}
                                        </Index.TableCell>

                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          scope="row"
                                          className="table-td"
                                          align="center"
                                        >
                                          {pageData[0]?.transaction_id
                                            ? pageData[0]?.transaction_id
                                            : "-"}
                                        </Index.TableCell>
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          scope="row"
                                          className="table-td"
                                          align="center"
                                        >
                                          {pageData[0]?.user_id?.name
                                            ? pageData[0]?.user_id?.name
                                            : "-"}
                                        </Index.TableCell>
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          scope="row"
                                          className="table-td"
                                          align="center"
                                        >
                                          {pageData[0].property_id
                                            ?.property_name
                                            ? pageData[0].property_id
                                                ?.property_name
                                            : pageData[0].rebranding_id
                                                ?.property_name
                                            ? pageData[0].rebranding_id
                                                ?.property_name
                                            : !pageData[0].property_id
                                                ?.property_name
                                            ? "----"
                                            : "---"}
                                        </Index.TableCell>
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          scope="row"
                                          className="table-td"
                                          align="center"
                                        >
                                          {pageData[0]?.property_id
                                            ?.franchise_amount
                                            ? `₹ ${pageData[0]?.property_id?.franchise_amount}`
                                            : "----"}
                                        </Index.TableCell>
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          scope="row"
                                          className="table-td"
                                          align="center"
                                        >
                                          {pageData[0]?.amount_type
                                            ? pageData[0]?.amount_type
                                            : "-"}
                                        </Index.TableCell>
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          scope="row"
                                          className="table-td"
                                          align="center"
                                        >
                                          {pageData[0].createdAt
                                            ? new Date(
                                                pageData[0].createdAt
                                              ).toLocaleDateString("en-GB") +
                                              " " +
                                              new Date(
                                                pageData[0].createdAt
                                              ).toLocaleTimeString("en-US", {
                                                hour: "2-digit",
                                                minute: "2-digit",
                                                hour12: true,
                                              })
                                            : "-"}
                                        </Index.TableCell>
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          className="table-td"
                                          align="center"
                                        >
                                          <div
                                            className={
                                              pageData[0]?.razorPayments
                                                ?.status === "captured"
                                                ? "table-td-active-transaction"
                                                : pageData[0]?.razorPayments
                                                    ?.status === "failed"
                                                ? "table-td-Inactive-transaction"
                                                : "table-td-Pending-transaction"
                                              // : "table-td-Inactive-transaction"
                                            }
                                          >
                                            {pageData[0]?.razorPayments
                                              ?.status === "captured"
                                              ? "Success"
                                              : pageData[0]?.razorPayments
                                                  ?.status === "failed"
                                              ? "Failed"
                                              : "---"}
                                          </div>
                                        </Index.TableCell>
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          className="table-td"
                                          align="center"
                                        >
                                          <Index.Box className="userdata-btn-flex">
                                            <Index.IconButton
                                              color="primary"
                                              aria-label="upload picture"
                                              component="label"
                                              onClick={() => {
                                                dispatch(
                                                  allUserTransactionLog(
                                                    pageData
                                                  )
                                                );
                                                navigate(
                                                  "/dashboard/view-transaction-log",
                                                  {
                                                    state: pageData,
                                                  }
                                                );
                                              }}
                                            >
                                              <Index.RemoveRedEyeIcon />
                                            </Index.IconButton>
                                          </Index.Box>
                                        </Index.TableCell>
                                      </Index.TableRow>
                                    ) : (
                                      // ))
                                      <Index.TableRow
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          scope="row"
                                          className="table-td no-record-text"
                                          align="center"
                                          colSpan={9}
                                        >
                                          No records found
                                        </Index.TableCell>
                                      </Index.TableRow>
                                    )}
                                  </Index.TableBody>
                                </Index.Table>
                              </Index.TableContainer>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="pagination-main">
                      <PageIndex.Pagination
                        fetchData={userTransactionList}
                        setPageData={setPageData}
                        pageData={pageData}
                        search={search}
                        filterBy={["property_name", "property_type", "name"]}
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </PageIndex.LoadingOverlay>
      </>
    );
  } else {
    navigate("/dashboard");
  }
};

export default ViewFranchiseApplication;
