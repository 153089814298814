import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Index from "../../../Index";

import { useDispatch, useSelector } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import {
  createTermsConditionCms,
  getTermsConditionCms,
} from "../../../../redux/slices/AdminService";
import { termsConditionSchema } from "../../../../validation/Validation";
import PageIndex from "../../../PageIndex";

const TermsCondition = () => {
  const navigate = PageIndex.useNavigate();
  const dispatch = useDispatch();
  const { termscondition, adminData } = useSelector((state) => state.admin);
  const [loading, setLoading] = useState(true);
  const termsId = termscondition?._id;
  const handleUpdateTerms = async (values) => {
    setLoading(true);
    const formdata = new URLSearchParams();
    formdata.append("title", values.title);
    formdata.append("description", values.description);
    dispatch(createTermsConditionCms(values)).then((val) => {
      if (val?.payload?.status === 201) {
        navigate("/dashboard/terms-condition");
        dispatch(getTermsConditionCms());
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    });
  };
  useEffect(() => {
    dispatch(getTermsConditionCms()).then(() => {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    });
  }, []);
  if (
    (adminData &&
      adminData?.role?.permission?.includes("Terms & Condition_view")) ||
    (adminData && adminData?.isAdmin === true)
  ) {
    return (
      <LoadingOverlay spinner text="Loading your content..." active={loading}>
        {!loading && (
          <PageIndex.Formik
            initialValues={{
              title: termscondition?.termsAndCondition?.title,
              description: termscondition?.termsAndCondition?.description,
            }}
            validationSchema={termsConditionSchema}
            onSubmit={handleUpdateTerms}
          >
            {({
              values,
              errors,
              handleChange,
              handleSubmit,
              touched,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Index.Box className="dashboard-content add-user-containt cms-page-containt">
                  <Index.Typography
                    className="admin-page-title cms-page-title"
                    component="h2"
                    variant="h4"
                  >
                    Terms & Condition
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="admin-dash-box cms-page-containt">
                  <Index.Box className="grid-row cms-page-row">
                    <Index.Box sx={{ width: 1 }} className="grid-main">
                      <Index.Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                      >
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box">
                            <Index.FormHelperText className="form-label cms-discription-lables">
                              Title*
                            </Index.FormHelperText>
                            <Index.Box className="form-group cms-input-box">
                              <Index.TextField
                                fullWidth
                                id="title"
                                className="form-control"
                                placeholder="Enter title"
                                name="title"
                                onChange={(event) => {
                                  const inputValue = event.target.value.replace(
                                    /^[^a-zA-Z]+/g,
                                    ""
                                  );
                                  if (inputValue.length <= 40) {
                                    // Only update the value if it's not more than 40 characters
                                    setFieldValue("title", inputValue);
                                  }
                                }}
                                value={values.title}
                                helperText={errors.title}
                                error={Boolean(errors.title)}
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 12",
                            md: "span 12",
                            lg: "span 12",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box">
                            <Index.FormHelperText className="form-label cms-discription-lables">
                              Terms_Condition*
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <CKEditor
                                editor={ClassicEditor}
                                data={values.description}
                                name="description"
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  setFieldValue("description", data);
                                }}
                              />
                            </Index.Box>
                          </Index.Box>
                          {errors?.description && touched?.description ? (
                            <Index.Typography className="error-msg">
                              {errors?.description}
                            </Index.Typography>
                          ) : null}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  {(adminData &&
                    adminData?.role?.permission?.includes(
                      "Terms & Condition_edit"
                    )) ||
                  (adminData && adminData?.isAdmin === true) ? (
                    <>
                      <Index.Box className="save-btn-main border-btn-main cms-btn-main">
                        <Index.Button
                          className="save-user-btn border-btn"
                          type="submit"
                        >
                          <img
                            alt="save"
                            src={Index.Svg.save}
                            className="user-save-icon"
                          ></img>
                          Update
                        </Index.Button>
                      </Index.Box>
                    </>
                  ) : (
                    ""
                  )}
                </Index.Box>
              </form>
            )}
          </PageIndex.Formik>
        )}
      </LoadingOverlay>
    );
  } else {
    navigate("/dashboard");
  }
};

export default TermsCondition;
