import React, { useEffect, useState } from "react";
import { logout, showNotificationData } from "../../../redux/slices/AdminSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Index from "../../Index";
import { imageUrl } from "../../../config/dataService";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Popover from "@mui/material/Popover";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import {
  getAllNotificationData,
  readNotification,
} from "../../../redux/slices/AdminService";

const notifications = [
  {
    icon: "",
    content: "4 new messages",
    time: "3 mins",
  },
  {
    icon: <i className="fas fa-users mr-2"></i>,
    content: "8 friend requests",
    time: "12 hours",
  },
  {
    icon: <i className="fas fa-file mr-2"></i>,
    content: "3 new reports",
    time: "2 days",
  },
];

export default function Header(props) {
  // for header profile menu
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { adminData, token, notificationList } = useSelector(
    (state) => state.admin
  );
  const unreadNotifications = notificationList?.filter(
    (item) => item?.isRead === false
  );

  console.log(unreadNotifications?.length, "unreadNotifications");
  const location = useLocation();

  const [profileMenu, setProfileMenu] = useState(null);

  const [notificationMenu, setNotificationMenu] = useState(null);

  // useEffect = (()=>{
  //  dispatch(getAllNotificationData())
  // },[])

  const handleClickNotification = (event) => {
    setNotificationMenu(event.currentTarget);
  };

  const handleCloseNotification = () => {
    setNotificationMenu(null);
  };
  const openNot = Boolean(notificationMenu);

  const open = Boolean(profileMenu);
  const handleClick = (event) => {
    setProfileMenu(event.currentTarget);
  };
  const handleClose = () => {
    setProfileMenu(null);
  };
  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  const handleRead = (id) => {
    dispatch(readNotification(id)).then((res) => {
      console.log(res, "res");
      dispatch(getAllNotificationData());
    });
  };

  useEffect(() => {
    dispatch(getAllNotificationData());
  }, [props?.open]);

  return (
    <>
      <Index.Box className={`admin-header-main ${props.open ? "active" : ""}`}>
        <Index.Box className="admin-header-left">
          <Index.Box
            className="admin-header-logo-main"
            onClick={() => navigate("/dashboard")}
          >
            <img
              src={Index.Png.headerlogo}
              className="admin-sidebar-logo"
              alt="logo"
            />
          </Index.Box>
          <Index.Box className="admin-header-right">
            <Index.Box
              className={`admin-header-icon-box ${
                location.pathname === "/dashboard/notification-list"
                  ? "active"
                  : ""
              }`}
              // onClick={() => navigate("/dashboard/general-setting")}
            >
              <div>
                <IconButton color="inherit" onClick={handleClickNotification}>
                  <Badge
                    badgeContent={unreadNotifications?.length}
                    color="warning"
                    className="badge-content"
                  >
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
                <Popover
                  open={openNot}
                  anchorEl={notificationMenu}
                  onClose={handleCloseNotification}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <List className="notification-list">
                    <Typography
                      variant="subtitle1"
                      className="notification-list-subtitle"
                    >
                      Notifications
                    </Typography>
                    <Divider />
                    {notificationList
                      ?.filter((notification) => notification?.isRead === false)
                      ?.slice(0, 3)
                      ?.map((notification, index) => (
                        <ListItem key={index} button component="a" href="#">
                          {/* <ListItemIcon>{notification.icon}</ListItemIcon> */}
                          <ListItemIcon>
                            {
                              // <img
                              //   src={Index.Svg.notificationAvtarImage}
                              //   className="notification-list-img"
                              //   alt="search grey img"
                              // />
                              <NotificationsIcon />
                            }
                          </ListItemIcon>

                          <ListItemText
                            primary={
                              <Index.Box className="notification-list-text">
                                <Index.Typography
                                  onClick={() => {
                                    if (notification?.isRead === false) {
                                      handleRead(notification?._id);
                                    }
                                  }}
                                  className={`notification-label ${
                                    notification?.isRead === false
                                      ? "read-notification"
                                      : ""
                                  }`}
                                >
                                  {/* {notification.details} */}
                                  {notification?.details ? notification?.details : "-"}{" "}
                          {notification?.activityType &&
                          notification.activityType !== "Created"
                            ? notification.activityType
                            : ""}
                                </Index.Typography>
                                <Index.Typography>
                                  {/* {notification.time} */}
                                </Index.Typography>
                              </Index.Box>
                            }
                          />
                        </ListItem>
                      ))}
                    {notificationList?.filter(
                      (notification) => notification?.isRead === false
                    ).length === 0 && (
                      <ListItem>
                        <ListItemText primary="No new notifications" sx={{textAlign:"center"}} />
                      </ListItem>
                    )}
                    <Divider />
                    <ListItem
                      button
                      component="a"
                      className="notification-list-footer"
                    >
                      <ListItemText
                        onClick={() => {
                          // dispatch(showNotificationData(notificationList));
                          dispatch(getAllNotificationData());
                          navigate("/dashboard/notification-list");
                          handleCloseNotification();
                        }}
                        primary="See All Notifications"
                      />
                    </ListItem>
                  </List>
                </Popover>
              </div>
            </Index.Box>
            <Index.Box
              className={`admin-header-icon-box ${
                location.pathname === "/dashboard/general-setting"
                  ? "active"
                  : ""
              }`}
              onClick={() => navigate("/dashboard/general-setting")}
            >
              {/* <img src={Index.SettingsIcon} className="admin-header-icon" alt='dashboard logo'></img> */}

              <Index.SettingsIcon />
            </Index.Box>
            <Index.Box className="admin-header-drop-main">
              <Index.Button
                className="drop-header-btn"
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <Index.Box className="flex-drop-main">
                  <img
                    src={
                      adminData?.image === "false"
                        ? Index.Png.usericon
                        : `${imageUrl}/${adminData?.image}`
                    }
                    className="admin-header-profile-icon"
                    alt="dashboard bell icon"
                  ></img>
                </Index.Box>
              </Index.Button>
            </Index.Box>
            <Index.Menu
              className="drop-header-menu"
              id="basic-menu"
              anchorEl={profileMenu}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <Index.MenuItem
                onClick={handleClose}
                className="drop-header-menuitem"
              >
                <Index.Link
                  to="/dashboard/edit-profile"
                  className="text-decoration-none drop-header-menuitemlink "
                >
                  My profile
                </Index.Link>
              </Index.MenuItem>
              <Index.MenuItem
                onClick={handleLogout}
                className="drop-header-menuitem"
              >
                <Index.Box className="text-decoration-none drop-header-menuitemlink pink-color">
                  Sign Out
                </Index.Box>
              </Index.MenuItem>
            </Index.Menu>

            <Index.Box className="bergur-btn-main">
              <Index.Button
                className="admin-bergur-button"
                onClick={() => {
                  props.setOpen(!props.open);
                  document.body.classList[!props.open ? "add" : "remove"](
                    "body-overflow"
                  );
                }}
              >
                <img
                  src={Index.Svg.bergurmenu}
                  className="bergurmenu-icon"
                  alt="dashboard logo"
                ></img>
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
