import React, { useState } from 'react'

import { Outlet } from 'react-router-dom';
import Index from '../../Index';



export default function Dashboard() {

  const [open,setOpen] =useState(false);

  return (
    <div>
      <Index.Box className="page-body">
        <Index.Header setOpen={setOpen} open={open}/>
        <Index.Box className="dashboard-main">
          <Index.Box className={`dashboard-left-main ${open?"active":""}`}>
            <Index.Sidebar open={open} setOpen={setOpen}/>
          </Index.Box>
          <Index.Box className="dashboard-right-main">
            <Index.Box className="dashboard-containt-main page-scrollbar">
              <Outlet />
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div >
  )
}
