import axios from "axios";

// const API_ENDPOINT = "http://localhost:3025/api/";  //Local
// const API_ENDPOINT = "http://35.177.56.74:3025/api/";  //Local

// const API_ENDPOINT = "https://connplex-backend-franchise.appworkdemo.com/api/"; // UAT
// export const imageUrl = "https://connplex-backend-franchise.appworkdemo.com/uploads"; // UAT


const API_ENDPOINT = "https://backendfranchise.theconnplex.com/api/"; //Live
export const imageUrl = "https://backendfranchise.theconnplex.com/uploads";  //Live

const dataService = axios.create({
  baseURL: API_ENDPOINT,
});

dataService.interceptors.request.use(
  (config) => {
    config.headers.auth = localStorage.getItem("token");
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default dataService;
