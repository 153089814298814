import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Index from "../../../Index";
import { useDispatch, useSelector } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import {
  createPrivacyPolicyCms,
  getPrivacyPolicyCms,
} from "../../../../redux/slices/AdminService";
import { privacyPolicySchema } from "../../../../validation/Validation";

const PrivayPolicy = () => {
  const { privacypolicy } = useSelector((state) => state.admin);

  const privacyId = privacypolicy?._id;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const genratePrivacyPolicy = (val) => {
    const formdata = new URLSearchParams();
    formdata.append("title", val.title);
    formdata.append("description", val.description);
    dispatch(createPrivacyPolicyCms(formdata));
  };

  useEffect(() => {
    dispatch(getPrivacyPolicyCms()).then(() => {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    });
  }, []);

  return (
    <>
      <LoadingOverlay spinner text="Loading your content..." active={loading}>
        {!loading && (
          <Index.Formik
            initialValues={{
              title: privacypolicy?.privacyPolicy?.title,
              description: privacypolicy?.privacyPolicy?.description,
            }}
            validationSchema={privacyPolicySchema}
            onSubmit={genratePrivacyPolicy}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <Index.Box className="admin-dash-box">
                  <Index.Box className="dashboard-content add-user-containt cms-page-containt">
                    <Index.Typography
                      className="admin-page-title"
                      component="h2"
                      variant="h4"
                    >
                      Privacy Policy
                    </Index.Typography>
                    <Index.Box className="grid-row cms-page-row">
                      <Index.Box sx={{ width: 1 }} className="grid-main">
                        <Index.Box
                          display="grid"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                        >
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 5",
                              md: "span 4",
                              lg: "span 3",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box">
                              <Index.FormHelperText className="form-label cms-discription-lables">
                                Title
                              </Index.FormHelperText>
                              <Index.Box className="form-group cms-input-box">
                                <Index.TextField
                                  fullWidth
                                  id="title"
                                  className="form-control"
                                  placeholder="Enter title"
                                  name="title"
                                  onChange={props.handleChange}
                                  value={props.values.title}
                                  helperText={props.errors.title}
                                  error={Boolean(props.errors.title)}
                                />
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 12",
                              md: "span 12",
                              lg: "span 12",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box">
                              <Index.FormHelperText className="form-label cms-discription-lables">
                                Description
                              </Index.FormHelperText>
                              <Index.Box className="form-group">
                                <CKEditor
                                  editor={ClassicEditor}
                                  data={props.values.description}
                                  name="description"
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    props.setFieldValue("description", data);
                                  }}
                                />
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="save-btn-main border-btn-main">
                      <Index.Button
                        className="save-user-btn border-btn"
                        type="submit"
                      >
                        <img
                          alt="save"
                          src={Index.Svg.save}
                          className="user-save-icon"
                        ></img>
                        {privacyId ? "Update" : "Add"}
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </form>
            )}
          </Index.Formik>
        )}
      </LoadingOverlay>
    </>
  );
};

export default PrivayPolicy;
