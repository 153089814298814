import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import Index from "../../../Index";
import { addEditState } from "../../../../redux/slices/AdminService";
import { stateSchema } from "../../../../validation/Validation";
import PageIndex from "../../../PageIndex";

export default function AddState() {
  const [loading, setLoading] = useState(true);
  const dispatch = PageIndex.useDispatch();
  const params = PageIndex.useLocation();
  const navigate = PageIndex.useNavigate();
  const id = params?.state;
  const { editState } = PageIndex.useSelector((state) => state.admin);


  const initialValues = {
    stateName: id ? editState?.stateName : "",
    GSTNumber: id ? editState?.GSTNumber : "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: stateSchema,
    onSubmit: (values) => {
      setLoading(true);
      dispatch(addEditState({ ...values, [id ? "id" : ""]: id })).then(
        (val) => {
          setLoading(false);
          if (val?.payload?.status) {
            navigate("/dashboard/state-list");
          }
        }
      );
    },
  });

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <PageIndex.LoadingOverlay
      spinner
      text="Loading your content..."
      active={loading}
    >
      {!loading && (
        <Index.Box className="dashboard-content add-genres-containt">
          <Index.Typography
            className="admin-page-title add-coupen-title"
            component="h2"
            variant="h2"
          >
            {id ? "Update" : "Add"} State
          </Index.Typography>
          <Index.Box className="page-border">
            <form onSubmit={formik.handleSubmit}>
              <Index.Box className="add-user-data-main">
                <Index.Box sx={{ width: 1 }} className="grid-main">
                  <Index.Box
                    display="grid"
                    className="display-row-add-user"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable">
                          State*
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="stateName"
                            className="form-control"
                            placeholder=""
                            name="stateName"
                            onBlur={formik.handleBlur}
                            value={formik.values.stateName}
                            onChange={(event) => {
                              const inputValue = event.target.value;
                              if (inputValue.length <= 25) {
                                // Only update the value if it's not more than 30 characters
                                formik?.handleChange(event);
                              }
                            }}
                            maxLength={25}
                            error={
                              formik.touched.stateName &&
                              Boolean(formik.errors.stateName)
                            }
                          />
                          {formik.touched.stateName && formik.errors.stateName && (
                            <Index.Typography className="error-msg">
                              {formik.errors.stateName}
                            </Index.Typography>
                          )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable">
                          GST Number
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="GSTNumber"
                            className="form-control"
                            placeholder=""
                            name="GSTNumber"
                            onBlur={formik.handleBlur}
                            value={formik.values.GSTNumber}
                            onChange={(event) => {
                              const inputValue = event.target.value;
                              if (inputValue.length <= 30) {
                                // Only update the value if it's not more than 30 characters
                                formik?.handleChange(event);
                              }
                            }}
                            maxLength={30}
                            error={
                              formik.touched.GSTNumber &&
                              Boolean(formik.errors.GSTNumber)
                            }
                          />
                          {formik.touched.GSTNumber && formik.errors.GSTNumber && (
                            <Index.Typography className="error-msg">
                              {formik.errors.GSTNumber}
                            </Index.Typography>
                          )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    
                  </Index.Box>
                  <Index.Box className="user-btn-flex">
                    <Index.Box className="save-btn-main border-btn-main">
                      <Index.Button
                        className="save-user-btn border-btn"
                        type="submit"
                      >
                        <img
                          alt="save_img"
                          src={Index.Svg.save}
                          className="user-save-icon"
                        ></img>
                        {id ? "Update" : "Add"}
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </form>
          </Index.Box>
        </Index.Box>
      )}
    </PageIndex.LoadingOverlay>
  );
}
