import React from "react";
import Index from "../../../Index";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import PageIndex from "../../../PageIndex";

const ViewStaffHiring = () => {
  const navigate = PageIndex.useNavigate();
  const { staffHiringdata, adminData } = PageIndex.useSelector(
    (state) => state.admin
  );

  const city = staffHiringdata?.user_id?.city;
  const pincode = staffHiringdata?.user_id?.pincode;

  let address = "";

  if (city) {
    address += city;
  }
  if (pincode) {
    address += address ? `, ${pincode}` : pincode;
  }
  if (
    (adminData && adminData?.role?.permission?.includes("Staff_view")) ||
    (adminData && adminData?.isAdmin === true)
  ) {
    return (
      <Index.Box>
        <Index.Box className="admin-page-title-main-viewcomponent">
          <Index.Typography className="admin-page-title-viewcomponent">
            View Staff Hiring Details
          </Index.Typography>
        </Index.Box>
        <Index.Box
          display="grid"
          className="display-row-add-user"
          gridTemplateColumns="repeat(12, 1fr)"
          gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
        >
          <Index.Box
            gridColumn={{
              xs: "span 12",
              sm: "span 6",
              md: "span 12",
              lg: "span 12",
            }}
            className="grid-column"
          >
            <Index.Box className="view-franchises-details-box view-franchise-box">
              <Index.Typography
                variant="h4"
                className="proerty-details-titles view-frenchises-user-title"
              >
                {staffHiringdata?.designation}
              </Index.Typography>
              {address && (
                <Index.Box className="property-flex">
                  <Index.Typography className="property-details-data-text">
                    <img
                      src={Index.Svg.propertylocation}
                      alt="sidebar icon"
                      className="franchises-icons"
                    />
                    {address ? address : ""}
                  </Index.Typography>
                </Index.Box>
              )}

              <Index.Box className="property-detail-box view-user-property-box">
                <Index.Box className="property-flex">
                  <Index.Typography className="property-lables">
                    {" "}
                    <img
                      src={Index.Svg.propertyuser}
                      alt="sidebar icon"
                      className="franchises-icons"
                    />
                    Created By
                  </Index.Typography>
                  <Index.Typography className="property-details-data-text">
                    {staffHiringdata?.user_id?.name
                      ? `  ${staffHiringdata?.user_id?.name}`
                      : "---"}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="property-flex">
                  <Index.Typography className="property-lables">
                    <img
                      src={Index.Svg.propertyemail}
                      alt="sidebar icon"
                      className="franchises-icons"
                    />
                    User Email
                  </Index.Typography>
                  <Index.Typography className="property-details-data-text">
                    {staffHiringdata?.user_id?.email
                      ? `  ${staffHiringdata?.user_id?.email}`
                      : "---"}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="property-flex">
                  <Index.Typography className="property-lables">
                    {" "}
                    <img
                      src={Index.Svg.propertycall}
                      alt="sidebar icon"
                      className="franchises-icons"
                    />
                    User mobile
                  </Index.Typography>
                  <Index.Typography className="property-details-data-text">
                    {staffHiringdata?.user_id?.mobile
                      ? `  ${staffHiringdata?.user_id?.mobile}`
                      : "---"}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="property-flex">
                  <Index.Typography className="property-lables">
                    {" "}
                    <img
                      src={Index.Png.jobVacancy}
                      alt="sidebar icon"
                      className="franchises-icons"
                    />
                    Number Of Vacancy
                  </Index.Typography>
                  <Index.Typography className="property-details-data-text">
                    {`  ${
                      staffHiringdata?.numberOfVacancy
                        ? staffHiringdata?.numberOfVacancy
                        : "---"
                    }`}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="property-flex">
                  <Index.Typography className="property-lables">
                    <img
                      src={Index.Svg.propertyarea}
                      alt="sidebar icon"
                      className="franchises-icons"
                    />
                    Total Experience
                  </Index.Typography>
                  <Index.Typography className="property-details-data-text">
                    {`  ${
                      staffHiringdata?.totalExperience
                        ? staffHiringdata?.totalExperience
                        : "---"
                    }`}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="property-flex">
                  <Index.Typography className="property-lables">
                    <img
                      src={Index.Png.salary}
                      alt="sidebar icon"
                      className="franchises-icons"
                    />
                    Salary
                  </Index.Typography>
                  <Index.Typography className="property-details-data-text">
                    {`  ${
                      staffHiringdata?.salary ? staffHiringdata?.salary : "---"
                    }`}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="property-flex">
                  <Index.Typography className="property-lables">
                    <img
                      src={Index.Png.jobTime}
                      alt="sidebar icon"
                      className="franchises-icons"
                    />
                    Job Timing
                  </Index.Typography>
                  <Index.Typography className="property-details-data-text">
                    {`  ${
                      staffHiringdata?.jobTiming
                        ? staffHiringdata?.jobTiming
                        : "---"
                    }`}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="property-flex">
                  <Index.Typography className="property-lables">
                    <img
                      src={Index.Png.validTime}
                      alt="sidebar icon"
                      className="franchises-icons"
                    />
                    Valid Till
                  </Index.Typography>
                  <Index.Typography className="property-details-data-text">
                    {staffHiringdata.endDate
                      ? `${new Date(staffHiringdata.endDate).toLocaleDateString(
                          "en-GB"
                        )}`
                      : "---"}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="property-flex">
                  <Index.Typography className="property-lables">
                    <img
                      src={Index.Svg.propertystatus}
                      alt="sidebar icon"
                      className="franchises-icons"
                    />
                    Job Description
                  </Index.Typography>
                  <Index.Typography className="property-details-data-text">
                    {`  ${
                      staffHiringdata?.description
                        ? staffHiringdata?.description
                        : "---"
                    }`}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    );
  } else {
    navigate("/dashboard");
  }
};

export default ViewStaffHiring;
