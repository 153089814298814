import React, { useEffect, useState } from "react";
import { Form, Field } from "formik";
import Index from "../../../Index";
import PageIndex, { stateList } from "../../../PageIndex";
import {
  addPropertyData,
  editPropertyData,
} from "../../../../redux/slices/AdminService";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import { propertySchema } from "../../../../validation/Validation";
import { imageUrl } from "../../../../config/dataService";
import { toast } from "react-toastify";
import moment from "moment";
const UpdateUserProperty = () => {
  const dispatch = PageIndex.useDispatch();
  const navigate = PageIndex.useNavigate();
  const params = PageIndex.useLocation();
  const { editProperty, adminData } = PageIndex.useSelector(
    (state) => state.admin
  );
  const [imageVideoPreview, setImageVideoPreview] = useState([]);
  const [imageVideoData, setImageVideoData] = useState([]);
  const [removeImageVideoData, setRemoveImageVideoData] = useState([]);
  const [brochureData, setBrochureData] = useState([]);

  const [loading, setLoading] = useState(true);
  const id =
    params?.state?.row == undefined ? params?.state : params?.state?.row;
  const dateData = editProperty?.completion_date;
  // function convertDateFormat(dateData) {
  //   // Split the input date string into day, month, and year parts
  //   const [day, month, year] = dateData?.split("-");
  //   // Construct the new format "yyyy-MM-dd"
  //   const newFormat = `${year}-${month}-${day}`;
  //   return newFormat;
  // }
  function convertDateFormat(dateData) {
    // Check if the dateData is in the "dd-mm-yyyy" format
    const isDdMmYyyyFormat = /^\d{2}-\d{2}-\d{4}$/.test(dateData);

    if (isDdMmYyyyFormat) {
      // Split the input date string into day, month, and year parts
      const [day, month, year] = dateData.split("-");
      // Construct the new format "yyyy-MM-dd"
      const newFormat = `${year}-${month}-${day}`;
      return newFormat;
    } else {
      // Assume the input is already in "yyyy-MM-dd" format, so return it as is
      return dateData;
    }
  }

  // const completion_date = "14-03-2024";
  const formattedDate = convertDateFormat(
    editProperty?.completion_date ? dateData : ""
  );
  const initialValues = {
    property_type: id ? editProperty.property_type : "Commercial",
    property_name: id ? editProperty.property_name : "",
    carpet_area: id ? editProperty.carpet_area : "",
    floor_number: id
      ? id && editProperty?.floor_number == null
        ? ""
        : editProperty.floor_number
      : "",
    // floor_number: id ? editProperty.floor_number : "",
    floor_height: id
      ? id && editProperty?.floor_height == null
        ? ""
        : editProperty.floor_height
      : "",
    // floor_height: id ? editProperty.floor_height : "",
    city: id ? editProperty?.project_address?.city : "",
    pincode: id
      ? id && editProperty?.project_address?.pincode == null
        ? ""
        : editProperty?.project_address?.pincode
      : "",
    // pincode: id ? editProperty?.project_address?.pincode : "",
    state: id ? editProperty?.project_address?.state : "",
    project_status: id ? editProperty.project_status : "Completed",
    land_type: id ? editProperty.land_type : "",
    size_of_the_land: id ? editProperty.size_of_the_land : "",
    land_connectivity: id ? editProperty.land_connectivity : "",
    franchise_amount: id ? editProperty.franchise_amount : "",
    admin_mobile_no: id
      ? id && editProperty.admin_mobile_no == null
        ? ""
        : editProperty.admin_mobile_no
      : "",
    // admin_mobile_no: id ? editProperty.admin_mobile_no : "",
    latitude: id ? editProperty.latitude : "",
    longitude: id ? editProperty.longitude : "",
    completion_date: id ? formattedDate : "",
    // completion_date: id ? editProperty?.completion_date : "",
  };

  const handleSubmit = (values) => {
    const { state, city, pincode } = values;
    const project_address = {
      city: city,
      pincode: pincode,
      state: state,
    };

    setLoading(true);
    const formData = new FormData();

    imageVideoData.forEach((file, index) => {
      formData.append(`images`, file);
    });

    if (values?.property_type === "Land") {
      formData.append("removeImageVideoData", removeImageVideoData);
      formData.append("latitude", values.latitude);
      formData.append("longitude", values.longitude);
      formData.append("property_type", values.property_type);
      formData.append("land_type", values.land_type);
      formData.append("size_of_the_land", values.size_of_the_land);
      formData.append("land_connectivity", values.land_connectivity);
      formData.append("franchise_amount", values.franchise_amount);
    } else {
      formData.append("removeImageVideoData", removeImageVideoData);
      formData.append("brochure", brochureData);
      formData.append("completion_date", values.completion_date);
      formData.append("latitude", values.latitude);
      formData.append("longitude", values.longitude);
      formData.append("property_type", values.property_type);
      formData.append("property_name", values.property_name);
      formData.append("carpet_area", values.carpet_area);
      formData.append("floor_number", values.floor_number);
      formData.append("floor_height", values.floor_height);
      formData.append("project_address[city]", project_address.city);
      formData.append("project_address[pincode]", project_address.pincode);
      formData.append("project_address[state]", project_address.state);
      formData.append("project_status", values.project_status);
      formData.append("franchise_amount", values.franchise_amount);
      formData.append("admin_mobile_no", values.admin_mobile_no);
    }
    if (id) {
      formData.append("id", id);
    }
    !id
      ? dispatch(addPropertyData(formData)).then((val) => {
          if (val?.payload?.status) {
            navigate("/dashboard/user-property-list");
            setLoading(false);
          }
        })
      : dispatch(editPropertyData(formData)).then((val) => {
          if (val?.payload?.status == 201 || val?.payload?.status == 200) {
            if (params?.state?.location == "user-own-property-list") {
              navigate("/dashboard/user-own-property-list");
            } else {
              navigate("/dashboard/user-property-list");
            }
            setLoading(false);
          }
        });
  };

  const imageVideoHandle = (e) => {
    const files = [...e.target.files];
    if (!files || files.length === 0) {
      return;
    }
    const arr = Array.from(files);

    arr.forEach((data) => {
      let check = data.type.match(/image/i);

      let checkVideo = data.type.match(/video/i);

      if (!check && !checkVideo) {
        alert("Please upload Images/Videos");
        return;
      } else {
        setImageVideoData((e) => (Array.isArray(e) ? [...e, data] : [data]));
        setImageVideoPreview((e) =>
          Array.isArray(e)
            ? [
                ...e,
                {
                  type: check
                    ? check[0]
                    : false || checkVideo
                    ? checkVideo[0]
                    : false,
                  pre: URL.createObjectURL(data),
                },
              ]
            : [
                {
                  type: check
                    ? check[0]
                    : false || checkVideo
                    ? checkVideo[0]
                    : false,
                  pre: URL.createObjectURL(data),
                },
              ]
        );
      }
    });
  };

  const removeImageVideo = (index, pre, backend = false) => {
    setImageVideoData((e) => {
      let arr = [...e];
      return arr.filter((_, i) => i !== index);
    });
    setImageVideoPreview((e) => {
      let arr = [...e];
      return arr.filter((_, i) => i !== index);
    });
    if (backend) {
      let file = pre?.split("/");
      setRemoveImageVideoData((e) => [...e, file[file?.length - 1]]);
    }
  };

  const brochureHandle = (event, setFieldValue) => {
    const file = event.currentTarget.files[0];
    setBrochureData(file);
  };

  useEffect(() => {
    if (id) {
      setImageVideoPreview((data) => {
        return editProperty?.images?.map((e) => {
          let check = e.match(/jpg|jpeg|JPEG|png/i);
          let checkVideo = e.match(/mp4|avi|webm|m4v|mpg/i);
          return {
            type: check ? "image" : false || checkVideo ? "video" : false,
            pre: `${imageUrl}/${e}`,
            backend: true,
          };
        });
      });
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  if (
    (adminData &&
      adminData?.role?.permission?.includes("Requested Property_edit")) ||
    (adminData && adminData?.isAdmin === true)
  ) {
    return (
      <PageIndex.LoadingOverlay
        spinner
        text="Loading your content..."
        active={loading}
      >
        {!loading && (
          <Index.Box className="dashboard-content add-property-containt">
            <Index.Typography
              className="admin-page-title add-title"
              component="h2"
              variant="h2"
            >
              {id ? "Update" : "Add "} Requested Property
            </Index.Typography>
            <Index.Box className="page-border">
              <PageIndex.Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={propertySchema}
              >
                {({
                  values,
                  errors,
                  handleChange,
                  handleBlur,
                  touched,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <Form>
                    <Index.Box className="add-user-data-main">
                      {console.log(errors, "errors")}

                      <Index.Box sx={{ width: 1 }} className="grid-main">
                        <Index.Box
                          display="grid"
                          className="display-row-add-user"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                        >
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 12",
                              md: "span 12",
                              lg: "span 12",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box add-user-input">
                              <Index.FormHelperText className="form-lable">
                                Property Type*
                              </Index.FormHelperText>
                              <Index.Box className="form-group">
                                <Field
                                  name="property_type"
                                  as={Index.Select}
                                  variant="outlined"
                                  fullWidth
                                  id="fullWidth"
                                  className="dropdown-property"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={Boolean(errors.property_type)}
                                >
                                  <Index.MenuItem value="" disabled>
                                    Select Property Type
                                  </Index.MenuItem>
                                  <Index.MenuItem value="Commercial">
                                    Commercial
                                  </Index.MenuItem>
                                  <Index.MenuItem value="Mixed">
                                    Mixed
                                  </Index.MenuItem>
                                  <Index.MenuItem value="Mall">
                                    Mall
                                  </Index.MenuItem>
                                  <Index.MenuItem value="Resort">
                                    Resort
                                  </Index.MenuItem>
                                  {/* <Index.MenuItem value="Residential">
                                  Residential
                                </Index.MenuItem> */}
                                  <Index.MenuItem value="Land">
                                    Land
                                  </Index.MenuItem>
                                </Field>
                                {errors?.property_type &&
                                touched?.property_type ? (
                                  <Index.Typography className="error-msg">
                                    {errors?.property_type}
                                  </Index.Typography>
                                ) : null}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box add-user-input">
                              <Index.FormHelperText className="form-lable">
                                Latitude*
                              </Index.FormHelperText>
                              <Index.Box className="form-group">
                                <Field
                                  name="latitude"
                                  as={Index.TextField}
                                  variant="outlined"
                                  fullWidth
                                  id="fullWidth"
                                  className="form-control"
                                  onChange={(event) => {
                                    const { value } = event.target;
                                    let processedValue = value.startsWith(" ")
                                      ? value.slice(1)
                                      : value;
                                    processedValue = processedValue.replace(
                                      /[^0-9.]/g,
                                      ""
                                    );
                                    processedValue = processedValue.replace(
                                      /^0+(?!\.)/,
                                      ""
                                    );
                                    handleChange({
                                      target: {
                                        name: "latitude",
                                        value: processedValue,
                                      },
                                    });
                                  }}
                                  onBlur={handleBlur}
                                  error={Boolean(errors.latitude)}
                                />
                                {errors?.latitude && touched?.latitude ? (
                                  <Index.Typography className="error-msg">
                                    {errors?.latitude}
                                  </Index.Typography>
                                ) : null}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box add-user-input">
                              <Index.FormHelperText className="form-lable">
                                Longitude*
                              </Index.FormHelperText>
                              <Index.Box className="form-group">
                                <Field
                                  name="longitude"
                                  as={Index.TextField}
                                  variant="outlined"
                                  fullWidth
                                  id="fullWidth"
                                  className="form-control"
                                  onChange={(event) => {
                                    const { value } = event.target;
                                    let processedValue = value.startsWith(" ")
                                      ? value.slice(1)
                                      : value;
                                    processedValue = processedValue.replace(
                                      /[^0-9.]/g,
                                      ""
                                    );
                                    processedValue = processedValue.replace(
                                      /^0+(?!\.)/,
                                      ""
                                    );
                                    handleChange({
                                      target: {
                                        name: "longitude",
                                        value: processedValue,
                                      },
                                    });
                                  }}
                                  onBlur={handleBlur}
                                  error={Boolean(errors.longitude)}
                                />
                                {errors?.longitude && touched?.longitude ? (
                                  <Index.Typography className="error-msg">
                                    {errors?.longitude}
                                  </Index.Typography>
                                ) : null}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                        {values.property_type !== "Land" && (
                          <Index.Box
                            display="grid"
                            className="display-row-add-user"
                            gridTemplateColumns="repeat(12, 1fr)"
                            gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                          >
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box add-user-input">
                                <Index.FormHelperText className="form-lable">
                                  Property Name*
                                </Index.FormHelperText>
                                <Index.Box className="form-group">
                                  <Field
                                    name="property_name"
                                    as={Index.TextField}
                                    // label="Property Name"
                                    variant="outlined"
                                    fullWidth
                                    id="fullWidth"
                                    className="form-control"
                                    onChange={(event) => {
                                      const inputValue = event.target.value;
                                      if (inputValue.length <= 30) {
                                        handleChange(event);
                                      }
                                    }}
                                    maxLength={30}
                                    onBlur={handleBlur}
                                    error={Boolean(errors.property_name)}
                                  />
                                  {errors?.property_name &&
                                  touched?.property_name ? (
                                    <Index.Typography className="error-msg">
                                      {errors?.property_name}
                                    </Index.Typography>
                                  ) : null}
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box add-user-input">
                                <Index.FormHelperText className="form-lable">
                                  Carpet Area(Sq Mtrs)*
                                </Index.FormHelperText>
                                <Index.Box className="form-group">
                                  <Field
                                    name="carpet_area"
                                    as={Index.TextField}
                                    // label="Carpet Area"
                                    variant="outlined"
                                    fullWidth
                                    id="fullWidth"
                                    className="form-control"
                                    onChange={(event) => {
                                      let inputValue = event.target.value
                                        .replace(/^\s+/, "")
                                        .replace(/[^0-9]/g, "");
                                      inputValue = inputValue.replace(
                                        /^0+/,
                                        ""
                                      );
                                      if (inputValue.length <= 10) {
                                        setFieldValue(
                                          "carpet_area",
                                          inputValue
                                        );
                                      }
                                    }}
                                    onBlur={handleBlur}
                                    error={Boolean(errors.carpet_area)}
                                  />
                                  {errors?.carpet_area &&
                                  touched?.carpet_area ? (
                                    <Index.Typography className="error-msg">
                                      {errors?.carpet_area}
                                    </Index.Typography>
                                  ) : null}
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box add-user-input">
                                <Index.FormHelperText className="form-lable">
                                  Floor Number*
                                </Index.FormHelperText>
                                <Index.Box className="form-group">
                                  <Field
                                    name="floor_number"
                                    as={Index.TextField}
                                    variant="outlined"
                                    fullWidth
                                    id="fullWidth"
                                    className="form-control"
                                    onChange={(event) => {
                                      const inputValue = event.target.value
                                        ?.replace(/[^0-9]/g, "")
                                        ?.replace(/^0+/, "");
                                      if (inputValue.length <= 3) {
                                        setFieldValue(
                                          "floor_number",
                                          inputValue
                                        );
                                      }
                                    }}
                                    onBlur={handleBlur}
                                    error={Boolean(errors.floor_number)}
                                  />
                                  {errors?.floor_number &&
                                  touched?.floor_number ? (
                                    <Index.Typography className="error-msg">
                                      {errors?.floor_number}
                                    </Index.Typography>
                                  ) : null}
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box add-user-input">
                                <Index.FormHelperText className="form-lable">
                                  Floor Height(ft)*
                                </Index.FormHelperText>
                                <Index.Box className="form-group">
                                  <Field
                                    name="floor_height"
                                    as={Index.TextField}
                                    variant="outlined"
                                    fullWidth
                                    id="fullWidth"
                                    className="form-control"
                                    onChange={(event) => {
                                      const inputValue = event.target.value
                                        ?.replace(/[^0-9]/g, "")
                                        ?.replace(/^0+/, "");
                                      if (inputValue.length <= 3) {
                                        setFieldValue(
                                          "floor_height",
                                          inputValue
                                        );
                                      }
                                    }}
                                    onBlur={handleBlur}
                                    error={Boolean(errors.floor_height)}
                                  />
                                  {errors?.floor_height &&
                                  touched?.floor_height ? (
                                    <Index.Typography className="error-msg">
                                      {errors?.floor_height}
                                    </Index.Typography>
                                  ) : null}
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box add-user-input">
                                <Index.FormHelperText className="form-lable">
                                  State*
                                </Index.FormHelperText>
                                <Index.Box className="form-group">
                                  <Field
                                    name="state"
                                    as={Index.Select}
                                    variant="outlined"
                                    fullWidth
                                    id="fullWidth"
                                    className="dropdown-property"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    MenuProps={{
                                      PaperProps: {
                                        style: {
                                          maxHeight: 200, // Set the maximum height you want
                                        },
                                      },
                                    }}
                                  >
                                    <Index.MenuItem value="" disabled>
                                      Select State
                                    </Index.MenuItem>
                                    {stateList.map((state) => (
                                      <Index.MenuItem key={state} value={state}>
                                        {state}
                                      </Index.MenuItem>
                                    ))}
                                  </Field>
                                  {errors?.state && touched?.state ? (
                                    <Index.Typography className="error-msg">
                                      {errors?.state}
                                    </Index.Typography>
                                  ) : null}
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box add-user-input">
                                <Index.FormHelperText className="form-lable">
                                  City*
                                </Index.FormHelperText>
                                <Index.Box className="form-group">
                                  <Field
                                    name="city"
                                    as={Index.TextField}
                                    variant="outlined"
                                    fullWidth
                                    id="fullWidth"
                                    className="form-control"
                                    onChange={(event) => {
                                      const inputValue = event.target.value;
                                      if (inputValue.length <= 30) {
                                        handleChange(event);
                                      }
                                    }}
                                    maxLength={30}
                                    onBlur={handleBlur}
                                    value={values.city}
                                    error={Boolean(errors.city)}
                                  />
                                  {errors?.city && touched?.city ? (
                                    <Index.Typography className="error-msg">
                                      {errors?.city}
                                    </Index.Typography>
                                  ) : null}
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box add-user-input">
                                <Index.FormHelperText className="form-lable">
                                  Pin Code*
                                </Index.FormHelperText>
                                <Index.Box className="form-group">
                                  <Field
                                    name="pincode"
                                    as={Index.TextField}
                                    variant="outlined"
                                    fullWidth
                                    id="fullWidth"
                                    className="form-control"
                                    onChange={(event) => {
                                      const inputValue = event.target.value
                                        ?.replace(/[^0-9]/g, "")
                                        ?.replace(/^0+/, "")
                                        .slice(0, 6);
                                      setFieldValue("pincode", inputValue);
                                    }}
                                    maxLength={6}
                                    onBlur={handleBlur}
                                    value={values.pincode}
                                    error={Boolean(errors.pincode)}
                                  />
                                  {errors?.pincode && touched?.pincode ? (
                                    <Index.Typography className="error-msg">
                                      {errors?.pincode}
                                    </Index.Typography>
                                  ) : null}
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box add-user-input">
                                <Index.FormHelperText className="form-lable">
                                  Project Status*
                                </Index.FormHelperText>
                                <Index.Box className="form-group">
                                  <Field
                                    name="project_status"
                                    as={Index.Select}
                                    value={values?.project_status}
                                    variant="outlined"
                                    fullWidth
                                    id="fullWidth"
                                    className="dropdown-property"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={Boolean(errors.project_status)}
                                  >
                                    <Index.MenuItem value="" disabled>
                                      Select Project Status
                                    </Index.MenuItem>
                                    <Index.MenuItem value="Completed">
                                      Completed
                                    </Index.MenuItem>
                                    <Index.MenuItem value="Under Construction">
                                      Under Construction
                                    </Index.MenuItem>
                                    <Index.MenuItem value="Construction Not started">
                                      Construction Not Started
                                    </Index.MenuItem>
                                  </Field>
                                  {errors?.project_status &&
                                  touched?.project_status ? (
                                    <Index.Typography className="error-msg">
                                      {errors?.project_status}
                                    </Index.Typography>
                                  ) : null}
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            {values.project_status !== "Completed" && (
                              <Index.Box
                                gridColumn={{
                                  xs: "span 12",
                                  sm: "span 6",
                                  md: "span 6",
                                  lg: "span 6",
                                }}
                                className="grid-column"
                              >
                                <Index.Box className="input-box add-user-input">
                                  <Index.FormHelperText className="form-lable">
                                    Project Completion Date
                                  </Index.FormHelperText>
                                  <Index.Box className="form-group">
                                    <Field
                                      type="date"
                                      name="completion_date"
                                      as={Index.TextField}
                                      variant="outlined"
                                      fullWidth
                                      id="fullWidth"
                                      className="form-control"
                                      onChange={handleChange}
                                      // onChange={(e) => {
                                      //   // handleChange(moment(e.target.value,"YYYY-MM-DD").format('DD-MM-YYYY'));
                                      //   setFieldValue('completion_date',moment(e.target.value,"YYYY-MM-DD").format('DD-MM-YYYY'));
                                      // }}
                                      onBlur={handleBlur}
                                      value={values.completion_date}
                                      // value={moment(values.completion_date,"YYYY-MM-DD").format('YYYY-MM-DD')}
                                      // value={moment(values.completion_date,"DD-MM-YYYY").format('DD/MM/YYYY')}
                                      // value={moment(values?.completion_date).utc().format("DD/MM/YYYY hh:mm A")}

                                      error={Boolean(errors.completion_date)}
                                    />
                                    {errors?.completion_date &&
                                    touched?.completion_date ? (
                                      <Index.Typography className="error-msg">
                                        {errors?.completion_date}
                                      </Index.Typography>
                                    ) : null}
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            )}
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box add-user-input">
                                <Index.FormHelperText className="form-lable">
                                  Franchise Amount*
                                </Index.FormHelperText>
                                <Index.Box className="form-group">
                                  <Field
                                    name="franchise_amount"
                                    as={Index.TextField}
                                    variant="outlined"
                                    fullWidth
                                    id="fullWidth"
                                    className="form-control"
                                    onChange={(event) => {
                                      const inputValue = event.target.value
                                        ?.replace(/[^0-9]/g, "")
                                        ?.replace(/^0+/, "");
                                      setFieldValue(
                                        "franchise_amount",
                                        inputValue
                                      );
                                    }}
                                    onBlur={handleBlur}
                                    error={Boolean(errors.franchise_amount)}
                                  />
                                  {errors?.franchise_amount &&
                                  touched?.franchise_amount ? (
                                    <Index.Typography className="error-msg">
                                      {errors?.franchise_amount}
                                    </Index.Typography>
                                  ) : null}
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box add-user-input">
                                <Index.FormHelperText className="form-lable">
                                  Mobile No*
                                </Index.FormHelperText>
                                <Index.Box className="form-group">
                                  <Field
                                    name="admin_mobile_no"
                                    as={Index.TextField}
                                    variant="outlined"
                                    fullWidth
                                    id="fullWidth"
                                    className="form-control"
                                    onChange={(event) => {
                                      const inputValue = event.target.value
                                        ?.replace(/[^0-9]/g, "")
                                        ?.replace(/^0+/, "")
                                        .slice(0, 10);
                                      setFieldValue(
                                        "admin_mobile_no",
                                        inputValue
                                      );
                                    }}
                                    maxLength={10}
                                    onBlur={handleBlur}
                                    error={Boolean(errors.admin_mobile_no)}
                                  />
                                  {errors?.admin_mobile_no &&
                                  touched?.admin_mobile_no ? (
                                    <Index.Typography className="error-msg">
                                      {errors?.admin_mobile_no}
                                    </Index.Typography>
                                  ) : null}
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box add-user-input">
                                <Index.FormHelperText className="form-lable">
                                  Brochure
                                </Index.FormHelperText>
                                <Index.Box className="form-group">
                                  <Field
                                    name="brochure"
                                    type="file"
                                    accept=".pdf, .doc, .docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                    as={Index.TextField}
                                    variant="outlined"
                                    fullWidth
                                    id="fullWidth"
                                    className="form-control"
                                    onChange={(event) => {
                                      const selectedFile =
                                        event.target.files[0];
                                      const allowedFileTypes = [
                                        "application/pdf",
                                        "application/msword",
                                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                      ];

                                      if (
                                        selectedFile &&
                                        allowedFileTypes.includes(
                                          selectedFile.type
                                        )
                                      ) {
                                        // Handle the selected file here
                                        brochureHandle(event, setFieldValue);
                                      } else {
                                        // Display an error message or clear the input
                                        event.target.value = null;
                                        toast.error(
                                          "Please select a valid DOC, DOCX, or PDF file."
                                        );
                                      }
                                    }}
                                    onBlur={handleBlur}
                                    value={values.brochure}
                                  />
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 12",
                                lg: "span 12",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box add-user-input add-game-row">
                                <Index.FormHelperText className="form-lable add-game-lables">
                                  Property Images/Videos
                                </Index.FormHelperText>

                                <Index.Box className="grid-row">
                                  <Index.Box
                                    sx={{ width: 1 }}
                                    className="grid-main"
                                  >
                                    <Index.Box
                                      display="grid"
                                      className="display-row-add-user"
                                      gridTemplateColumns="repeat(12, 1fr)"
                                      gap={{ xs: 2, sm: 3, md: 3, lg: 3 }}
                                    >
                                      {imageVideoPreview?.map((val, i) => {
                                        return val.type === "video" ? (
                                          <>
                                            <Index.Box
                                              gridColumn={{
                                                xs: "span 12",
                                                sm: "span 3",
                                                md: "span 3",
                                                lg: "span 3",
                                              }}
                                            >
                                              <Index.Box className="document-close-set">
                                                <CloseTwoToneIcon
                                                  className="document-close"
                                                  onClick={() =>
                                                    removeImageVideo(
                                                      i,
                                                      val.pre,
                                                      val.backend
                                                    )
                                                  }
                                                />
                                              </Index.Box>
                                              <Index.Box>
                                                <video
                                                  className="upload-img-slider"
                                                  controls
                                                >
                                                  <source
                                                    src={val.pre}
                                                    type="video/mp4"
                                                  />
                                                </video>
                                              </Index.Box>
                                            </Index.Box>
                                          </>
                                        ) : (
                                          <>
                                            <Index.Box
                                              gridColumn={{
                                                xs: "span 12",
                                                sm: "span 3",
                                                md: "span 3",
                                                lg: "span 3",
                                              }}
                                            >
                                              <Index.Box className="document-close-set">
                                                <CloseTwoToneIcon
                                                  className="document-close"
                                                  onClick={() =>
                                                    removeImageVideo(
                                                      i,
                                                      val.pre,
                                                      val.backend
                                                    )
                                                  }
                                                />
                                                <img
                                                  alt="propoerty_img"
                                                  className="upload-img-slider"
                                                  src={val.pre}
                                                />
                                              </Index.Box>
                                            </Index.Box>
                                          </>
                                        );
                                      })}
                                      <Index.Box
                                        gridColumn={{
                                          xs: "span 12",
                                          sm: "span 3",
                                          md: "span 3",
                                          lg: "span 3",
                                        }}
                                      >
                                        <Index.Box className="images-upload-btn">
                                          <Index.Button
                                            variant="contained"
                                            component="label"
                                            className="save-user-btn border-btn image-upload-btn"
                                          >
                                            <img
                                              alt="img"
                                              src={Index.Svg.upload}
                                              className="upload-img"
                                            />
                                            <input
                                              hidden
                                              multiple
                                              accept="image/*, video/*"
                                              name="image"
                                              type="file"
                                              onClick={(e) => {
                                                e.target.value = null;
                                              }}
                                              onChange={(e) => {
                                                imageVideoHandle(e);
                                              }}
                                            />
                                          </Index.Button>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        )}

                        {values?.property_type === "Land" && (
                          <Index.Box
                            display="grid"
                            className="display-row-add-user"
                            gridTemplateColumns="repeat(12, 1fr)"
                            gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                          >
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box add-user-input">
                                <Index.FormHelperText className="form-lable">
                                  Land Type*
                                </Index.FormHelperText>
                                <Index.Box className="form-group">
                                  <Field
                                    name="land_type"
                                    as={Index.Select}
                                    variant="outlined"
                                    fullWidth
                                    id="fullWidth"
                                    className="dropdown-property"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={Boolean(errors.land_type)}
                                  >
                                    <Index.MenuItem value="" disabled>
                                      Select Land Type
                                    </Index.MenuItem>
                                    <Index.MenuItem value="Commercial">
                                      Commercial
                                    </Index.MenuItem>
                                    <Index.MenuItem value="Agricultural">
                                      Agricultural
                                    </Index.MenuItem>
                                  </Field>
                                  {errors?.land_type && touched?.land_type ? (
                                    <Index.Typography className="error-msg">
                                      {errors?.land_type}
                                    </Index.Typography>
                                  ) : null}
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box add-user-input">
                                <Index.FormHelperText className="form-lable">
                                  Size of the Land*
                                </Index.FormHelperText>
                                <Index.Box className="form-group">
                                  <Field
                                    name="size_of_the_land"
                                    as={Index.TextField}
                                    variant="outlined"
                                    fullWidth
                                    id="fullWidth"
                                    className="form-control"
                                    onChange={(event) => {
                                      let inputValue = event.target.value
                                        .replace(/^\s+/, "")
                                        .replace(/[^0-9]/g, "")
                                        ?.replace(/^0+/, "");
                                      if (inputValue.length <= 8) {
                                        setFieldValue(
                                          "size_of_the_land",
                                          inputValue
                                        );
                                      }
                                    }}
                                    onBlur={handleBlur}
                                    error={Boolean(errors.size_of_the_land)}
                                  />
                                  {errors?.size_of_the_land &&
                                  touched?.size_of_the_land ? (
                                    <Index.Typography className="error-msg">
                                      {errors?.size_of_the_land}
                                    </Index.Typography>
                                  ) : null}
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box add-user-input">
                                <Index.FormHelperText className="form-lable">
                                  Land Connectivity*
                                </Index.FormHelperText>
                                <Index.Box className="form-group">
                                  <Field
                                    name="land_connectivity"
                                    as={Index.Select}
                                    variant="outlined"
                                    className="dropdown-property"
                                    fullWidth
                                    id="fullWidth"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={Boolean(errors.land_connectivity)}
                                  >
                                    <Index.MenuItem value="" disabled>
                                      Select Land Connectivity
                                    </Index.MenuItem>
                                    <Index.MenuItem value="Main Road">
                                      Main Road
                                    </Index.MenuItem>
                                    <Index.MenuItem value="Side Road">
                                      Side Road
                                    </Index.MenuItem>
                                    <Index.MenuItem value="Sub Road">
                                      Sub Road
                                    </Index.MenuItem>
                                  </Field>
                                  {errors?.land_connectivity &&
                                  touched?.land_connectivity ? (
                                    <Index.Typography className="error-msg">
                                      {errors?.land_connectivity}
                                    </Index.Typography>
                                  ) : null}
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box add-user-input">
                                <Index.FormHelperText className="form-lable">
                                  Franchise Amount*
                                </Index.FormHelperText>
                                <Index.Box className="form-group">
                                  <Field
                                    name="franchise_amount"
                                    as={Index.TextField}
                                    variant="outlined"
                                    fullWidth
                                    id="fullWidth"
                                    className="form-control"
                                    onChange={(event) => {
                                      let inputValue = event.target.value
                                        .replace(/^\s+/, "")
                                        .replace(/[^0-9]/g, "")
                                        ?.replace(/^0+/, "");
                                      if (inputValue.length <= 8) {
                                        setFieldValue(
                                          "franchise_amount",
                                          inputValue
                                        );
                                      }
                                    }}
                                    onBlur={handleBlur}
                                    error={Boolean(errors.franchise_amount)}
                                  />
                                  {errors?.franchise_amount &&
                                  touched?.franchise_amount ? (
                                    <Index.Typography className="error-msg">
                                      {errors?.franchise_amount}
                                    </Index.Typography>
                                  ) : null}
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 12",
                                md: "span 12",
                                lg: "span 12",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box add-user-input add-game-row">
                                <Index.FormHelperText className="form-lable add-game-lables">
                                  Land Images/Videos
                                </Index.FormHelperText>

                                <Index.Box className="grid-row">
                                  <Index.Box
                                    sx={{ width: 1 }}
                                    className="grid-main"
                                  >
                                    <Index.Box
                                      display="grid"
                                      className="display-row-add-user"
                                      gridTemplateColumns="repeat(12, 1fr)"
                                      gap={{ xs: 2, sm: 3, md: 3, lg: 3 }}
                                    >
                                      {imageVideoPreview?.map((val, i) => {
                                        return val.type === "video" ? (
                                          <>
                                            <Index.Box
                                              gridColumn={{
                                                xs: "span 12",
                                                sm: "span 2",
                                                md: "span 2",
                                                lg: "span 2",
                                              }}
                                            >
                                              <Index.Box className="document-close-set">
                                                <CloseTwoToneIcon
                                                  className="document-close"
                                                  onClick={() =>
                                                    removeImageVideo(
                                                      i,
                                                      val.pre,
                                                      val.backend
                                                    )
                                                  }
                                                />
                                              </Index.Box>
                                              <Index.Box>
                                                <video
                                                  className="upload-img-slider"
                                                  controls
                                                >
                                                  <source
                                                    src={val.pre}
                                                    type="video/mp4"
                                                  />
                                                </video>
                                              </Index.Box>
                                            </Index.Box>
                                          </>
                                        ) : (
                                          <>
                                            <Index.Box
                                              gridColumn={{
                                                xs: "span 12",
                                                sm: "span 2",
                                                md: "span 2",
                                                lg: "span 2",
                                              }}
                                            >
                                              <Index.Box className="document-close-set">
                                                <CloseTwoToneIcon
                                                  className="document-close"
                                                  onClick={() =>
                                                    removeImageVideo(
                                                      i,
                                                      val.pre,
                                                      val.backend
                                                    )
                                                  }
                                                />
                                                <img
                                                  alt="propoerty_img"
                                                  className="upload-img-slider"
                                                  src={val.pre}
                                                />
                                              </Index.Box>
                                            </Index.Box>
                                          </>
                                        );
                                      })}
                                      <Index.Box
                                        gridColumn={{
                                          xs: "span 12",
                                          sm: "span 3",
                                          md: "span 3",
                                          lg: "span 2",
                                        }}
                                      >
                                        <Index.Box className="images-upload-btn">
                                          <Index.Button
                                            variant="contained"
                                            component="label"
                                            className="save-user-btn border-btn image-upload-btn"
                                          >
                                            <img
                                              alt="img"
                                              src={Index.Svg.upload}
                                              className="upload-img"
                                            />
                                            <input
                                              hidden
                                              multiple
                                              name="image"
                                              accept="image/*, video/*"
                                              type="file"
                                              onClick={(e) => {
                                                e.target.value = null;
                                              }}
                                              onChange={(e) => {
                                                imageVideoHandle(e);
                                              }}
                                            />
                                          </Index.Button>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        )}
                      </Index.Box>
                      <Index.Box className="user-btn-flex">
                        <Index.Box className="save-btn-main border-btn-main">
                          <Index.Button
                            className="save-user-btn border-btn"
                            type="submit"
                            disabled={loading}
                          >
                            <img
                              alt="save_img"
                              src={Index.Svg.save}
                              className="user-save-icon"
                            ></img>
                            {id ? "Update" : " Add"} Property
                          </Index.Button>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Form>
                )}
              </PageIndex.Formik>
            </Index.Box>
          </Index.Box>
        )}
      </PageIndex.LoadingOverlay>
    );
  } else {
    navigate("/dashboard");
  }
};

export default UpdateUserProperty;
