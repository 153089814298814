import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import {
  EditRebrandingProperty,
  editRebrandingProperty,
} from "../../../../redux/slices/AdminSlice";
import {
  deleteRebrandingPropertyData,
  getAllRebrandingPropertyList,
} from "../../../../redux/slices/AdminService";

import PageIndex from "../../../PageIndex";
import { imageUrl } from "../../../../config/dataService";

export default function RebrandingPropertyList() {
  let navigate = PageIndex.useNavigate();
  const dispatch = PageIndex.useDispatch();
  const { rebrandingPropertyList } = PageIndex.useSelector(
    (state) => state.admin
  );
  const { adminData } = PageIndex.useSelector((store) => store.admin);
  const [search, setSearch] = useState("");
  const [pageData, setPageData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  // handle delete
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [id, setId] = useState("");
  const handleDeleteOpen = (id) => {
    setId(id);
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setId("");
  };

  useEffect(() => {
    setLoading(true); // Set loading state to true before fetching data
    setTimeout(() => {
      // Simulating a delay of 1 seconds
      dispatch(getAllRebrandingPropertyList())
        .then((val) => {
          if (val?.payload?.status === 200) {
            dispatch(getAllRebrandingPropertyList());
          }
          setLoading(false); // Set loading state to false after data is fetched
        })
        .catch((error) => {
          setLoading(false); // Set loading state to false if there's an error
        });
    }, 1000);
  }, []);

  useEffect(() => {
    const filtered = rebrandingPropertyList
      ?.filter((item) => {
        return !search
          ? item
          : item?.cinema_name?.toLowerCase().includes(search?.toLowerCase()) ||
              item?.property_name
                .toLowerCase()
                .includes(search?.toLowerCase()) ||
              item?.property_location?.city
                ?.toLowerCase()
                .includes(search?.toLowerCase()) ||
              item?.project_status
                ?.toLowerCase()
                .includes(search?.toLowerCase()) ||
              item?.existing_business
                ?.toLowerCase()
                .includes(search?.toLowerCase()) ||
              item?.no_of_screens?.toString().includes(search?.toString());
      })
      ?.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateB - dateA;
      });
    if (filtered) {
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  }, [rebrandingPropertyList, search]);

  const deleteProperty = () => {
    setLoading(true);
    dispatch(deleteRebrandingPropertyData(id))
      .then((val) => {
        if (val?.payload?.status === 200) {
          // dispatch(getAllPropertyList());
          dispatch(getAllRebrandingPropertyList());
          handleDeleteClose();
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  if (
    (adminData && adminData?.role?.permission?.includes("Rebranding_view")) ||
    (adminData && adminData?.isAdmin === true)
  ) {
    return (
      <>
        <PageIndex.LoadingOverlay
          spinner
          text="Loading your content..."
          active={loading}
        >
          <Index.Box className="dashboard-content">
            <Index.Box className="user-list-flex">
              <Index.Box className="admin-page-title-main">
                <Index.Typography
                  className="admin-page-title"
                  component="h2"
                  variant="h2"
                >
                  Covert Your Existing Cinema List
                </Index.Typography>
              </Index.Box>
              <Index.Box className="userlist-btn-flex">
                <Index.Box className="user-search-main">
                  <Index.Box className="user-search-box">
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="Search Property"
                        value={search}
                        onChange={(e) => {
                          const inputValue = e.target.value
                            .replace(/^\s+/, "")
                            .replace(/\s\s+/g, " ");
                          setSearch(inputValue);
                        }}
                      />
                      <span className="search-icon-box">
                        <img
                          alt="search_icon"
                          src={Index.Svg.search}
                          className="search-icon"
                        />
                      </span>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="admin-dashboard-list-row">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row-userlist"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dash-box">
                      <Index.Box className="rebrandinglist-table-main page-table-main">
                        <Index.TableContainer
                          component={Index.Paper}
                          className="table-container"
                        >
                          <Index.Table
                            sx={{ minWidth: 650 }}
                            aria-label="sticky table"
                            className="table"
                          >
                            <Index.TableHead className="table-head sticky">
                              <Index.TableRow className="table-row">
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  No.
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Image
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Cinema Name
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Total screens
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Property Name
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Property Location
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Existing Business
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Status
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Action
                                </Index.TableCell>
                              </Index.TableRow>
                            </Index.TableHead>
                            <Index.TableBody className="table-body">
                              {pageData?.length ? (
                                pageData?.map((row, ind) => (
                                  <Index.TableRow
                                    key={row._id}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                    >
                                      {row.sNo ? row.sNo : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                    >
                                      {row.images.length === 0 ? (
                                        <img
                                          className="property_img"
                                          src={Index.Png.staticLogoImage}
                                          alt="img"
                                        />
                                      ) : (
                                        row.images
                                          ?.filter(
                                            (image) =>
                                              image.includes(".jpg") ||
                                              image.includes(".png") ||
                                              image.includes(".jpeg")
                                          )
                                          .map((image, index) => {
                                            if (index === 0) {
                                              return (
                                                <img
                                                  key={image}
                                                  className="property_img"
                                                  src={`${imageUrl}/${image}`}
                                                  alt="img"
                                                />
                                              );
                                            }
                                            return null; // Render null for other images in the array
                                          })
                                      )}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                    >
                                      {row?.property_type === "Land"
                                        ? "Land"
                                        : row?.cinema_name}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                    >
                                      {row?.no_of_screens
                                        ? row?.no_of_screens
                                        : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                    >
                                      {row?.property_name
                                        ? row?.property_name
                                        : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                    >
                                      {row?.property_location?.city
                                        ? row?.property_location?.city
                                        : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                    >
                                      {row?.existing_business
                                        ? row?.existing_business
                                        : "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                    >
                                      <div
                                        className={
                                          row?.rebranding_status
                                            ? "table-td-active"
                                            : "table-td-Inactive"
                                        }
                                      >
                                        {row?.rebranding_status
                                          ? row?.rebranding_status
                                          : "-"}
                                      </div>
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                      align="center"
                                    >
                                      <Index.Box className="userdata-btn-flex">
                                        {(adminData &&
                                          adminData?.role?.permission?.includes(
                                            "Requested Property_edit"
                                          )) ||
                                        (adminData &&
                                          adminData?.isAdmin === true) ? (
                                          <>
                                            <Index.IconButton
                                              color="primary"
                                              aria-label="upload picture"
                                              component="label"
                                              onClick={() => {
                                                dispatch(
                                                  EditRebrandingProperty(row)
                                                );
                                                navigate(
                                                  "/dashboard/update-rebranding-property",
                                                  {
                                                    state: row._id,
                                                  }
                                                );
                                              }}
                                            >
                                              <Index.EditIcon />
                                            </Index.IconButton>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                        {(adminData &&
                                          adminData?.role?.permission?.includes(
                                            "Rebranding_view"
                                          )) ||
                                        (adminData &&
                                          adminData?.isAdmin === true) ? (
                                          <>
                                            <Index.IconButton
                                              color="primary"
                                              aria-label="upload picture"
                                              component="label"
                                              onClick={() => {
                                                dispatch(
                                                  editRebrandingProperty(row)
                                                );
                                                navigate(
                                                  "/dashboard/view-rebranding",
                                                  {
                                                    state: row,
                                                  }
                                                );
                                              }}
                                            >
                                              <Index.RemoveRedEyeIcon />
                                            </Index.IconButton>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                        {(adminData &&
                                          adminData?.role?.permission?.includes(
                                            "Rebranding_delete"
                                          )) ||
                                        (adminData &&
                                          adminData?.isAdmin === true) ? (
                                          <>
                                            <Index.IconButton
                                              color="primary"
                                              aria-label="upload picture"
                                              component="label"
                                              onClick={() => {
                                                handleDeleteOpen(row?._id);
                                              }}
                                            >
                                              <Index.DeleteIcon />
                                            </Index.IconButton>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </Index.Box>
                                    </Index.TableCell>
                                  </Index.TableRow>
                                ))
                              ) : (
                                <Index.TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td no-record-text"
                                    align="center"
                                    colSpan={8}
                                  >
                                    No records found
                                  </Index.TableCell>
                                </Index.TableRow>
                              )}
                            </Index.TableBody>
                          </Index.Table>
                        </Index.TableContainer>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="pagination-main">
              <PageIndex.Pagination
                fetchData={filteredData}
                setPageData={setPageData}
                pageData={pageData}
                search={search}
              />
            </Index.Box>
            {/* delete modal */}
            <PageIndex.DeleteModal
              deleteOpen={deleteOpen}
              handleDeleteClose={handleDeleteClose}
              handleDeleteRecord={deleteProperty}
            />
          </Index.Box>
        </PageIndex.LoadingOverlay>
      </>
    );
  } else {
    navigate("/dashboard");
  }
}
