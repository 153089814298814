import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { getAllTransactionData } from "../../../../redux/slices/AdminService";
import "./TransactionStyle.css";
export default function TransactionHistoryList() {
  const dispatch = PageIndex.useDispatch();
  const navigate = PageIndex.useNavigate();
  const { transactionList, adminData } = PageIndex.useSelector(
    (state) => state.admin
  );
  const [search, setSearch] = useState("");
  const [pageData, setPageData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    setLoading(true); // Set loading state to true before fetching data
    setTimeout(() => {
      // Simulating a delay of 1 seconds
      dispatch(getAllTransactionData())
        .then((val) => {
          if (val?.payload?.status === 200) {
            dispatch(getAllTransactionData());
          }
          setLoading(false); // Set loading state to false after data is fetched
        })
        .catch((error) => {
          setLoading(false); // Set loading state to false if there's an error
        });
    }, 1000);
  }, []);

  useEffect(() => {
    const filtered = transactionList
      ?.filter((item) => {
        return !search
          ? item
          : item?.user_id?.name
              ?.toLowerCase()
              .includes(search?.toLowerCase()) ||
              item?.property_id?.property_name
                ?.toLowerCase()
                .includes(search?.toLowerCase()) ||
              item?.amount_type
                ?.toLowerCase()
                .includes(search?.toLowerCase()) ||
              item?.transaction_id.includes(search) ||
              item?.pay_amount?.toString()?.includes(search?.toString());
      })
      ?.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateB - dateA;
      });
    if (filtered) {
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  }, [transactionList, search]);
  if (
    (adminData && adminData?.role?.permission?.includes("Transaction_view")) ||
    (adminData && adminData?.isAdmin === true)
  ) {
    return (
      <>
        <PageIndex.LoadingOverlay
          spinner
          text="Loading your content..."
          active={loading}
        >
          <Index.Box className="dashboard-content">
            <Index.Box className="user-list-flex">
              <Index.Box className="admin-page-title-main">
                <Index.Typography
                  className="admin-page-title"
                  component="h2"
                  variant="h2"
                >
                  Transaction List
                </Index.Typography>
              </Index.Box>
              <Index.Box className="userlist-btn-flex">
                <Index.Box className="user-search-main">
                  <Index.Box className="user-search-box">
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="Search Transaction"
                        value={search}
                        onChange={(e) => {
                          const inputValue = e.target.value
                            .replace(/^\s+/, "")
                            .replace(/\s\s+/g, " ");
                          setSearch(inputValue);
                        }}
                      />
                      <span className="search-icon-box">
                        <img
                          alt="search_icon"
                          src={Index.Svg.search}
                          className="search-icon"
                        />
                      </span>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="admin-dashboard-list-row">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row-userlist"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dash-box">
                      <Index.Box className="transactionlist-table-main page-table-main">
                        <Index.TableContainer
                          component={Index.Paper}
                          className="table-container"
                        >
                          <Index.Table
                            sx={{ minWidth: 650 }}
                            aria-label="sticky table"
                            className="table"
                          >
                            <Index.TableHead className="table-head sticky">
                              <Index.TableRow className="table-row">
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  No.
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Transaction Id
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  User Name
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Property Name
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Transaction Date
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Amount Type
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Amount
                                </Index.TableCell>

                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Status
                                </Index.TableCell>
                              </Index.TableRow>
                            </Index.TableHead>
                            <Index.TableBody className="table-body">
                              {pageData?.length ? (
                                pageData?.map((row, ind) => (
                                  <Index.TableRow
                                    key={row._id}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                    >
                                      {row.sNo ? row.sNo : "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                    >
                                      {row?.transaction_id
                                        ? row?.transaction_id
                                        : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                    >
                                      {row?.user_id === null
                                        ? "----"
                                        : row?.user_id?.name}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                    >
                                      {row?.property_id?.property_type ===
                                      "Land"
                                        ? "Land"
                                        : !row.property_id
                                        ? row?.rebranding_id?.property_name
                                        : row.property_id?.property_name}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                    >
                                      {new Date(
                                        row.createdAt
                                      ).toLocaleDateString("en-GB") +
                                        " " +
                                        new Date(
                                          row.createdAt
                                        ).toLocaleTimeString("en-US", {
                                          hour: "2-digit",
                                          minute: "2-digit",
                                          hour12: true,
                                        })}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                    >
                                      {row?.amount_type
                                        ? row?.amount_type
                                        : "----"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                    >
                                      {`₹ ${row?.pay_amount?.toLocaleString(
                                        "en-IN"
                                      )}`}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                      align="center"
                                    >
                                      <div
                                        className={
                                          row?.razorPayments?.status ===
                                          "captured"
                                            ? "table-td-active-transaction"
                                            : row?.razorPayments?.status ===
                                              "failed"
                                            ? "table-td-Inactive-transaction"
                                            : "table-td-Pending-transaction"
                                          // : "table-td-Inactive-transaction"
                                        }
                                      >
                                        {row?.razorPayments?.status ===
                                        "captured"
                                          ? "Success"
                                          : row?.razorPayments?.status ===
                                            "failed"
                                          ? "Failed"
                                          : "---"}
                                      </div>
                                    </Index.TableCell>
                                  </Index.TableRow>
                                ))
                              ) : (
                                <Index.TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td no-record-text"
                                    align="center"
                                    colSpan={8}
                                  >
                                    No records found
                                  </Index.TableCell>
                                </Index.TableRow>
                              )}
                            </Index.TableBody>
                          </Index.Table>
                        </Index.TableContainer>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="pagination-main">
              <PageIndex.Pagination
                fetchData={filteredData}
                setPageData={setPageData}
                pageData={pageData}
                search={search}
              />
            </Index.Box>
          </Index.Box>
        </PageIndex.LoadingOverlay>
      </>
    );
  } else {
    navigate("/dashboard");
  }
}
