import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  AdminForgotPassword,
  resetPassword,
  createPrivacyPolicyCms,
  createTermsConditionCms,
  editProfileUpdate,
  getAdminRole,
  getsubadmin,
  loginAdmin,
  subAdminCreate,
  verfiyOtp,
  changePasswordProfile,
  getTermsConditionCms,
  getPrivacyPolicyCms,
  addEditGenre,
  getAllGenreData,
  deleteGenreData,
  getGameList,

  ///////////////////
  addEmployeeRoleData,
  updateEmployeeRoleData,
  addEditRole,
  getAllRoleData,
  deleteRoleData,
  editGeneralSetting,
  getAllGeneralSetting,
  editFranchiseUser,
  getAllFranchiseUsers,
  getFilteredFranchiseUsers,
  getAllPropertyList,
  getAllEmployeeRoleList,
  getAllDashboardData,
  addPropertyData,
  editPropertyData,
  deletePropertyData,
  addEditCoupon,
  getAllCoupon,
  getAllState,
  deleteCoupon,
  getAllFranchiseApplication,
  getAllTransactionData,
  getAllUserTransactionData,
  getAllRebrandingPropertyList,
  getAllTransactionDashboardData,
  getAllFranchiseUserDashboardData,
  getAllStaffHiringList,
  getAllNotificationData,
  createTrainingSession,
  getTrainingSession,
  couponUserList,
  getOrientationSession,
  createOrientationSession,
  getAllOPerationalModelSession,
  createOperationalModalSession,
  createOrientationModalSession,
  getAllOrientationModelSession,
  getAllTrainingModelSession,
  createTrainingModalSession,
  getSelfLogoutSubAdmin,
  getPaymentTermsConditionCms,
  addEditState,
  getRolePermission
} from "./AdminService";


export const AdminSlice = createSlice({
  name: "counter",
  initialState: {
    token: "",
    loading: false,
    isAuthenticated: false,
    adminData: {},
    UserList: [],
    privacypolicy: {},
    termscondition: {},
    editUser: {},
    genreData: {},
    editGame: {},
    genreList: [],
    gameList: [],

    roleData: {},
    roleList: [],
    editRole: {},
    generalSettingData: {},
    generalSettingList: {},
    franchiseUserData: {},
    franchiseUserList: [],
    editFranchise: {},
    editTrainingData: {},
    staffHiringdata: {},
    propertyData: {},
    employeeRoleData: {},
    employeeRoleList: [],
    operationalModelList: [],
    operationalModelData: {},
    orientationModelList: [],
    orientationModelData: {},
    trainingModelList: [],
    trainingModelData: {},
    editemployeeRoleData: {},
    staffHiringList: [],
    propertyList: [],
    dashboardData: [],
    dashboardFranchiseUserData: [],
    dashboardTransactionData: [],
    editProperty: {},
    updateRebrandingProperty: {},
    editEmployeeRoledata: {},
    editOperationalModelData: {},
    editOrientationModelData: {},
    editTrainingModelData: {},
    couponData: {},
    couponList: [],
    stateList: [],
    editCoupon: {},
    editState: {},
    franchiseApplicationList: [],
    getfranchiseApplicationData: {},
    transactionList: [],
    notificationList: [],
    editNotification: {},
    editTransactionData: {},
    userTransactionList: [],
    rebrandingPropertyList: [],
    getRebrandingPropertyData: {},
    viewAllUserTransactionData: {},
    viewAllUserRazorPayTransactionData: {},
    trainingSession: {},
    orientationSessionData: {},
    orientationSession: [],
    editOrientationSessionData: {},
    operationalModalSession: {},
    couponByUser: [],
    getLogutSubAdmin: {},
    paymentTermsCondition: {},
    // permissions: [],
  },
  reducers: {
    logout: (state) => {
      state.isAuthenticated = false;
      state.adminData = {};
      state.token = "";
    },
    editUser: (state, action) => {
      state.editUser = action.payload;
    },
    editGenre: (state, action) => {
      state.editGenre = action.payload;
    },
    editGame: (state, action) => {
      state.editGame = action.payload;
    },
    editRole: (state, action) => {
      state.editRole = action.payload;
    },
    editFranchise: (state, action) => {
      state.editFranchise = action.payload;
    },
    editTraining: (state, action) => {
      state.editTrainingData = action.payload;
    },
    editProperty: (state, action) => {
      state.editProperty = action.payload;
    },
    EditRebrandingProperty: (state, action) => {
      state.updateRebrandingProperty = action.payload;
    },
    editEmployeeRole: (state, action) => {
      state.editEmployeeRoledata = action.payload;
    },
    editOperationalModelSession: (state, action) => {
      state.editOperationalModelData = action.payload;
    },
    editOrientationModelSession: (state, action) => {
      state.editOrientationModelData = action.payload;
    },
    editTrainingModelSession: (state, action) => {
      state.editTrainingModelData = action.payload;
    },
    editOrientationSession: (state, action) => {
      state.editOrientationSessionData = action.payload;
    },
    editRebrandingProperty: (state, action) => {
      state.getRebrandingPropertyData = action.payload;
    },
    editTransactionData: (state, action) => {
      state.editTransactionData = action.payload;
    },
    editfranchiseApplication: (state, action) => {
      state.getfranchiseApplicationData = action.payload;
    },
    editCoupon: (state, action) => {
      state.editCoupon = action.payload;
    },
    editState: (state, action) => {
      state.editState = action.payload;
    },
    showNotificationData: (state, action) => {
      state.editNotification = action.payload;
    },
    singleStaffHiringData: (state, action) => {
      state.staffHiringdata = action.payload;
    },
    allUserTransactionLog: (state, action) => {
      state.viewAllUserTransactionData = action.payload;
    },
    allUserRazorPayTransaction: (state, action) => {
      state.viewAllUserRazorPayTransactionData = action.payload;
    },
    // getAdminPermission: (state, action) => {
    //   state.permissions = action.payload;
    // },

    updateRolePermission: (state, action) => {
      state.adminData = {
        ...state.adminData,
        role: {
          ...state?.adminData?.role,
          permission: action.payload,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginAdmin.fulfilled, (state, action) => {
      state.isAuthenticated = true;
      state.adminData = action?.payload?.data;
      state.token = action?.payload?.data?.token;
    });
    builder.addCase(AdminForgotPassword.fulfilled, (state, action) => {});

    builder.addCase(verfiyOtp.fulfilled, (state, action) => {});
    builder.addCase(resetPassword.fulfilled, (state, action) => {});

    builder.addCase(editProfileUpdate.fulfilled, (state, action) => {
      state.adminData = action.payload.data;
    });

    builder.addCase(changePasswordProfile.fulfilled, (state, action) => {
      // state.adminData = action?.payload?.data;
    });

    builder.addCase(getsubadmin.fulfilled, (state, action) => {
      state.UserList = action.payload.data;
    });

    builder.addCase(subAdminCreate.fulfilled, (state, action) => {
      // state.UserList = action.payload.data;
    });

    builder.addCase(getAdminRole.fulfilled, (state, action) => {
      console.log(action.payload.data,247)
      state.getRolePermission = action.payload.data;
    });

    builder.addCase(createPrivacyPolicyCms.fulfilled, (state, action) => {
      state.privacypolicy = action.payload.data;
    });

    builder.addCase(createTermsConditionCms.fulfilled, (state, action) => {
      state.termscondition = action.payload.data;
    });

    builder.addCase(getTermsConditionCms.fulfilled, (state, action) => {
      state.termscondition = action?.payload?.data;
    });
    builder.addCase(getPrivacyPolicyCms.fulfilled, (state, action) => {
      state.privacypolicy = action?.payload?.data;
    });
    builder.addCase(getGameList.fulfilled, (state, action) => {
      state.gameList = action?.payload?.data;
    });
    ///////////////////////////////////////////
    builder.addCase(addEditGenre.fulfilled, (state, action) => {
      state.genreData = action?.payload?.data;
    });

    builder.addCase(getAllGenreData.fulfilled, (state, action) => {
      state.genreList = action?.payload?.data;
    });
    builder.addCase(deleteGenreData.fulfilled, (state, action) => {
      state.genreData = action?.payload?.data;
    });

    ////////////////////////////////////
    // New Slices

    builder.addCase(addEditRole.fulfilled, (state, action) => {
      state.roleData = action?.payload?.data;
    });

    builder.addCase(getAllRoleData.fulfilled, (state, action) => {
      state.roleList = action?.payload?.data;
    });

    builder.addCase(deleteRoleData.fulfilled, (state, action) => {
      state.roleData = action?.payload?.data;
    });

    ////General Setting

    builder.addCase(editGeneralSetting.fulfilled, (state, action) => {
      state.generalSettingData = action?.payload?.data;
    });

    builder.addCase(getAllGeneralSetting.fulfilled, (state, action) => {
      state.generalSettingList = action?.payload?.data;
    });

    /// Franchise User

    builder.addCase(editFranchiseUser.fulfilled, (state, action) => {
      state.franchiseUserData = action?.payload?.data;
    });

    builder.addCase(getAllFranchiseUsers.fulfilled, (state, action) => {
      state.franchiseUserList = action?.payload?.data;
    });

    builder.addCase(getFilteredFranchiseUsers.fulfilled, (state, action) => {});

    /// Property List

    builder.addCase(addPropertyData.fulfilled, (state, action) => {
      state.propertyData = action?.payload?.data;
    });
    builder.addCase(editPropertyData.fulfilled, (state, action) => {
      state.propertyData = action?.payload?.data;
    });

    builder.addCase(getAllPropertyList.fulfilled, (state, action) => {
      const current_id = state.editProperty?._id;
      let updatedPropertyDetail = {};
      if (action?.payload?.data?.userProperty?.length) {
        updatedPropertyDetail = action?.payload?.data?.userProperty?.find(
          (property) => property._id === current_id
        );
      }
      state.editProperty = updatedPropertyDetail;
      state.propertyList = action?.payload?.data;
    });

    builder.addCase(getAllDashboardData.fulfilled, (state, action) => {
      state.dashboardData = action?.payload?.data;
    });
    builder.addCase(
      getAllTransactionDashboardData.fulfilled,
      (state, action) => {
        state.dashboardTransactionData = action?.payload?.data;
      }
    );
    builder.addCase(
      getAllFranchiseUserDashboardData.fulfilled,
      (state, action) => {
        state.dashboardFranchiseUserData = action?.payload?.data;
      }
    );

    builder.addCase(deletePropertyData.fulfilled, (state, action) => {
      state.propertyData = action?.payload?.data;
    });
    /////Coupon Slice
    builder.addCase(addEditCoupon.fulfilled, (state, action) => {
      state.couponData = action?.payload?.data;
    });
    builder.addCase(addEditState.fulfilled, (state, action) => {
      state.stateData = action?.payload?.data;
    });
    builder.addCase(getAllCoupon.fulfilled, (state, action) => {
      state.couponList = action?.payload?.data;
    });
    builder.addCase(deleteCoupon.fulfilled, (state, action) => {
      state.couponData = action?.payload?.data;
    });

    //State
    builder.addCase(getAllState.fulfilled, (state, action) => {
      state.stateList = action?.payload?.data;
    });

    //// Franchise Application
    builder.addCase(getAllFranchiseApplication.fulfilled, (state, action) => {
      const current_id = state.getfranchiseApplicationData?._id;
      let updatedPropertyDetail = {};
      if (action?.payload?.data?.length) {
        updatedPropertyDetail = action?.payload?.data?.find(
          (property) => property?._id === current_id
        );
        state.getfranchiseApplicationData = updatedPropertyDetail;
      }

      state.franchiseApplicationList = action?.payload?.data;
    });
    //// Transaction List
    builder.addCase(getAllTransactionData.fulfilled, (state, action) => {
      state.transactionList = action?.payload?.data;
    });
    //// User Propert Transaction List
    builder.addCase(getAllUserTransactionData.fulfilled, (state, action) => {
      state.userTransactionList = action?.payload?.data;
    });

    //// Rebranding Property List

    builder.addCase(getAllRebrandingPropertyList.fulfilled, (state, action) => {
      state.rebrandingPropertyList = action?.payload?.data;
    });

    /// Employee Role add/update/List
    builder.addCase(addEmployeeRoleData.fulfilled, (state, action) => {
      state.employeeRoleData = action?.payload?.data;
    });
    builder.addCase(updateEmployeeRoleData.fulfilled, (state, action) => {
      state.employeeRoleData = action?.payload?.data;
    });

    builder.addCase(getAllEmployeeRoleList.fulfilled, (state, action) => {
      state.employeeRoleList = action?.payload?.data;
    });

    /// Staff Hiring List

    builder.addCase(getAllStaffHiringList.fulfilled, (state, action) => {
      state.staffHiringList = action?.payload?.data;
    });

    //// Notification List
    builder.addCase(getAllNotificationData.fulfilled, (state, action) => {
      state.notificationList = action?.payload?.data;
    });

    /// Training Session ///

    builder.addCase(createTrainingSession.fulfilled, (state, action) => {
      state.trainingSession = action.payload?.data;
    });
    builder.addCase(getTrainingSession.fulfilled, (state, action) => {
      state.trainingSession = action?.payload?.data;
    });
    /// Orientation Session ///

    builder.addCase(createOrientationSession.fulfilled, (state, action) => {
      state.orientationSessionData = action?.payload?.data;
    });
    builder.addCase(getOrientationSession.fulfilled, (state, action) => {
      state.orientationSession = action?.payload?.data;
    });
    /// OPerational Modal Session ///

    // builder.addCase(createOperationModalSession.fulfilled, (state, action) => {
    //   state.operationalModalSession = action.payload.data;
    // });
    // builder.addCase(getOperationModalSession.fulfilled, (state, action) => {
    //   state.operationalModalSession = action?.payload?.data;
    // });

    /// OPerational Modal Session
    builder.addCase(
      createOperationalModalSession.fulfilled,
      (state, action) => {
        state.operationalModelData = action?.payload?.data;
      }
    );

    builder.addCase(
      getAllOPerationalModelSession.fulfilled,
      (state, action) => {
        state.operationalModelList = action?.payload?.data;
      }
    );
    /// Orientation Modal Session
    builder.addCase(
      createOrientationModalSession.fulfilled,
      (state, action) => {
        state.orientationModelData = action?.payload?.data;
      }
    );

    builder.addCase(
      getAllOrientationModelSession.fulfilled,
      (state, action) => {
        state.orientationModelList = action?.payload?.data;
      }
    );
    /// Training Modal Session
    builder.addCase(createTrainingModalSession.fulfilled, (state, action) => {
      state.trainingModelData = action?.payload?.data;
    });

    builder.addCase(getAllTrainingModelSession.fulfilled, (state, action) => {
      state.trainingModelList = action?.payload?.data;
    });

    //// Coupon User List /////

    builder.addCase(couponUserList.fulfilled, (state, action) => {
      state.couponByUser = action?.payload?.data;
    });

    ///// For Self Logout Sub - Admin ////
    builder.addCase(getSelfLogoutSubAdmin.fulfilled, (state, action) => {
      state.getLogutSubAdmin = action?.payload?.data;
    });

    /////////// PAYMENT TERMS CONDITION ////////////
    builder.addCase(getPaymentTermsConditionCms.fulfilled, (state, action) => {
      state.paymentTermsCondition = action?.payload?.data;
    });
  },
});
export const {
  getAdminPermission,
  logout,
  editUser,
  editGenre,
  editGame,
  editRole,
  editFranchise,
  editTraining,
  singleStaffHiringData,
  editProperty,
  editEmployeeRole,
  editOperationalModelSession,
  editOrientationModelSession,
  editTrainingModelSession,
  editOrientationSession,
  editfranchiseApplication,
  editCoupon,
  editState,
  editTransactionData,
  editRebrandingProperty,
  showNotificationData,
  allUserTransactionLog,
  allUserRazorPayTransaction,
  EditRebrandingProperty,
  updateRolePermission
} = AdminSlice.actions;
export default AdminSlice.reducer;
