import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import {
  getAdminRole,
  getAllNotificationData,
  getRolePermission,
  getSelfLogoutSubAdmin,
} from "../redux/slices/AdminService";
import { logout, updateRolePermission } from "../redux/slices/AdminSlice";

const PrivateRoutes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((store) => store.admin);
  const { adminData } = useSelector((store) => store.admin);
  const { pathname } = useLocation();
  const getLogoutSubAdmin = () => {
    dispatch(getSelfLogoutSubAdmin()).then((res) => {
      if (res?.payload?.status === 200) {
        if (
          res?.payload?.data?.isActive === false ||
          res?.payload?.data?.isDeleted === true ||
          res?.payload?.data?.length === 0
        ) {
          dispatch(logout());
          navigate("/");
        }
      }
    });
  };

  // const getPermissionData = () => {
  //   dispatch(getAdminRole()).then((res) => {
  //     console.log(res, 35);
  //     if (res?.payload?.status == 200) {
  //       let data = res?.payload?.data?.map((res) => {
  //         return res?.roleName==adminData?.roleName
  //       });
  //       console.log(data, 36);
  //       dispatch(updateRolePermission(data));
  //     }
  //   });
  // };
  console.log(adminData?.role?._id, 45);
  const getPermissionData = () => {
    dispatch(getRolePermission(adminData?.role?._id)).then((res) => {
      console.log(res, 35);
      if (res?.payload?.status == 200) {
        let data = res?.payload?.data?.permission;
        dispatch(updateRolePermission(data));
      }
    });
  };

  // const getPermissionData = () => {
  //   dispatch(getRoleAdmin()).then((res) => {
  //     console.log(res, 35);
  //     if (res?.payload?.status == 200) {
  //       let data = res?.payload?.data?.permissions;

  //       dispatch(updateRolePermission(data));
  //     }
  //   });
  // };
  console.log(adminData,66)

  useEffect(() => {
    if (adminData?.name !== "admin") {
      getPermissionData();
    }
  }, [pathname]);

  useEffect(() => {
    if (token) {
      getLogoutSubAdmin();
    }
  }, [pathname]);
  useEffect(() => {
    if (token) {
      dispatch(getAllNotificationData());
    }
  }, [pathname]);
  return token ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoutes;
