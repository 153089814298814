import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import Index from "../../../Index";
import { addEditCoupon } from "../../../../redux/slices/AdminService";
import { couponSchema } from "../../../../validation/Validation";
import PageIndex from "../../../PageIndex";

export default function AddCoupon() {
  const dispatch = PageIndex.useDispatch();
  const navigate = PageIndex.useNavigate();
  const params = PageIndex.useLocation();
  const [loading, setLoading] = useState(true);
  const { editCoupon,adminData } = PageIndex.useSelector((state) => state.admin);
  const id = params?.state;
  // const formatTime = (timeString) => {
  //   console.log("timeString", timeString);
  //   const [hours, minutes] = timeString.split(":");
  //   return `${hours}:${minutes}`;
  // };
  const formatTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const hours = dateTime.getUTCHours();
    const minutes = dateTime.getUTCMinutes();
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  };

  const initialValues = {
    title: id ? editCoupon?.title : "",
    discount_amount: id ? editCoupon?.discount_amount : "",
    start_date: id
      ? new Date(editCoupon.start_date).toISOString().split("T")[0]
      : "",
    end_date: id
      ? new Date(editCoupon.end_date).toISOString().split("T")[0]
      : "",
    // ... other fields ...
    // start_time: id ? formatTime(editCoupon.start_time) : "",
    // end_time: id ? formatTime(editCoupon.end_time) : "",
    start_time: id ? editCoupon.start_time : "",
    end_time: id ? editCoupon.end_time : "",

    description: id ? editCoupon?.description : "",
    discount_type: id ? editCoupon?.discount_type : "flat",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: couponSchema,
    onSubmit: (values) => {
      setLoading(true);
      dispatch(addEditCoupon({ ...values, [id ? "id" : ""]: id })).then(
        (val) => {
          setLoading(false);
          if (val?.payload?.status) {
            navigate("/dashboard/coupon-list");
          }
        }
      );
    },
  });

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  if (
    (adminData && adminData?.role?.permission?.includes("Coupon_add")) ||
    adminData?.role?.permission?.includes("Coupon_edit") ||
    (adminData && adminData?.isAdmin === true)
  ) {
    return (
      <PageIndex.LoadingOverlay
        spinner
        text="Loading your content..."
        active={loading}
      >
        {!loading && (
          <Index.Box className="dashboard-content add-genres-containt">
            <Index.Typography
              className="admin-page-title add-coupen-title"
              component="h2"
              variant="h2"
            >
              {id ? "Update" : "Add"} Coupon
            </Index.Typography>
            <Index.Box className="page-border">
              <form onSubmit={formik.handleSubmit}>
                <Index.Box className="add-user-data-main">
                  <Index.Box sx={{ width: 1 }} className="grid-main">
                    <Index.Box
                      display="grid"
                      className="display-row-add-user"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                    >
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Title*
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id="title"
                              className="form-control"
                              placeholder=""
                              name="title"
                              onBlur={formik.handleBlur}
                              value={formik.values.title}
                              onChange={(event) => {
                                const inputValue = event.target.value;
                                if (inputValue.length <= 30) {
                                  // Only update the value if it's not more than 30 characters
                                  formik?.handleChange(event);
                                }
                              }}
                              maxLength={30}
                              error={
                                formik.touched.title &&
                                Boolean(formik.errors.title)
                              }
                            />
                            {formik.touched.title && formik.errors.title && (
                              <Index.Typography className="error-msg">
                                {formik.errors.title}
                              </Index.Typography>
                            )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Discount Type*
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              select
                              id="discountType"
                              className="dropdown-property"
                              name="discount_type"
                              value={formik.values.discount_type}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={
                                formik.touched.discount_type &&
                                Boolean(formik.errors.discount_type)
                              }
                            >
                              <Index.MenuItem value="flat">Flat</Index.MenuItem>
                              <Index.MenuItem value="percentage">
                                Percentage
                              </Index.MenuItem>
                            </Index.TextField>
                            {formik.touched.discount_type &&
                              formik.errors.discount_type && (
                                <Index.Typography className="error-msg">
                                  {formik.errors.discount_type}
                                </Index.Typography>
                              )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Discount Amount*
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              type="tel"
                              onBlur={formik.handleBlur}
                              id="discount_amount"
                              className="form-control"
                              placeholder=""
                              name="discount_amount"
                              value={formik.values.discount_amount}
                              onChange={(event) => {
                                const inputValue = event.target.value;
                                const isPercentage =
                                  formik.values.discount_type === "percentage";

                                if (isPercentage) {
                                  const decimalRegex = /^\d*$/;
                                  const percentageRegex =
                                    /^(100|[1-9][0-9]?)?$/;
                                  if (
                                    decimalRegex.test(inputValue) &&
                                    percentageRegex.test(inputValue)
                                  ) {
                                    formik.handleChange(event);
                                  }
                                } else {
                                  let inputValue = event.target.value
                                    .replace(/^\s+/, "")
                                    .replace(/[^0-9.]/g, "")
                                    .replace(/^0+/, "");
                                  if (inputValue.length <= 6) {
                                    formik.setFieldValue(
                                      "discount_amount",
                                      inputValue
                                    );
                                  }
                                }
                              }}
                              maxLength={6}
                              error={
                                formik.touched.discount_amount &&
                                Boolean(formik.errors.discount_amount)
                              }
                            />
                            {formik.touched.discount_amount &&
                              formik.errors.discount_amount && (
                                <Index.Typography className="error-msg">
                                  {formik.errors.discount_amount}
                                </Index.Typography>
                              )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Start Date*
                          </Index.FormHelperText>
                          <Index.Box className="form-group ">
                            <Index.TextField
                              fullWidth
                              type="date"
                              onBlur={formik.handleBlur}
                              id="start_date"
                              className="form-control"
                              placeholder=""
                              name="start_date"
                              value={formik.values.start_date}
                              inputProps={{
                                min: new Date().toISOString().split("T")[0],
                              }}
                              onChange={formik.handleChange}
                              error={
                                formik.touched.start_date &&
                                Boolean(formik.errors.start_date)
                              }
                            />
                            {formik.touched.start_date &&
                              formik.errors.start_date && (
                                <Index.Typography className="error-msg">
                                  {formik.errors.start_date}
                                </Index.Typography>
                              )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            End Date*
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              type="date"
                              onBlur={formik.handleBlur}
                              id="end_date"
                              className="form-control"
                              placeholder=""
                              name="end_date"
                              value={formik.values.end_date}
                              onChange={formik.handleChange}
                              error={
                                formik.touched.end_date &&
                                Boolean(formik.errors.end_date)
                              }
                            />
                            {formik.touched.end_date &&
                              formik.errors.end_date && (
                                <Index.Typography className="error-msg">
                                  {formik.errors.end_date}
                                </Index.Typography>
                              )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Start Time*
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              type="time"
                              onBlur={formik.handleBlur}
                              id="start_time"
                              className="form-control"
                              placeholder=""
                              name="start_time"
                              value={formik.values.start_time}
                              onChange={formik.handleChange}
                              error={
                                formik.touched.start_time &&
                                Boolean(formik.errors.start_time)
                              }
                            />
                            {formik.touched.start_time &&
                              formik.errors.start_time && (
                                <Index.Typography className="error-msg">
                                  {formik.errors.start_time}
                                </Index.Typography>
                              )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            End Time*
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              type="time"
                              onBlur={formik.handleBlur}
                              id="end_time"
                              className="form-control"
                              placeholder=""
                              name="end_time"
                              value={formik.values.end_time}
                              onChange={formik.handleChange}
                              error={
                                formik.touched.end_time &&
                                Boolean(formik.errors.end_time)
                              }
                            />
                            {formik.touched.end_time &&
                              formik.errors.end_time && (
                                <Index.Typography className="error-msg">
                                  {formik.errors.end_time}
                                </Index.Typography>
                              )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Terms_Condition*
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextareaAutosize
                              minRows={4} // You can adjust the number of rows as needed
                              onBlur={formik.handleBlur}
                              id="description"
                              className="form-control textarea-coupon text-area-box"
                              placeholder=""
                              name="description"
                              value={formik.values.description}
                              onChange={formik.handleChange}
                              error={
                                formik.touched.description &&
                                Boolean(formik.errors.description)
                              }
                            />
                            {formik.touched.description &&
                              formik.errors.description && (
                                <Index.Typography className="error-msg">
                                  {formik.errors.description}
                                </Index.Typography>
                              )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="user-btn-flex">
                      <Index.Box className="save-btn-main border-btn-main">
                        <Index.Button
                          className="save-user-btn border-btn"
                          type="submit"
                        >
                          <img
                            alt="save_img"
                            src={Index.Svg.save}
                            className="user-save-icon"
                          ></img>
                          {id ? "Update" : "Add"}
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </form>
            </Index.Box>
          </Index.Box>
        )}
      </PageIndex.LoadingOverlay>
    );
  } else {
    navigate("/dashboard");
  }
}
