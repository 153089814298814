import { useEffect, useState } from "react";
import { Formik } from "formik";
// import { adminLoginSchema } from "../../validation/schema";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginAdmin } from "../../../redux/slices/AdminService";
import { toast } from "react-toastify";
import { adminLoginSchema } from "../../../validation/Validation";
import Index from "../../../component/Index";
import { getToken } from "firebase/messaging";
import { messaging } from "../../../firebase";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showToken, setShowToken] = useState("");
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.admin);

  const handleClickShowPassword = (e) => {
    e.preventDefault();
    setShowPassword((show) => !show);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // async function requestPermission() {
  //   const permission = await Notification.requestPermission();
  //   if (permission === "granted") {
  //     // Generate Token
  //     const token = await getToken(messaging, {
  //       vapidKey:
  //         "BDYBKecAn6gj0b88G4ynkG9zbDCH6vxf7XFmW6JHzRiHJJVLwnN-95fs8pt41J2m96W_kcd1WAJqtutIzrvWAsQ",
  //     });
  //     console.log("Token Gen", token);
  //     setShowToken(token);
  //     // Send this token  to server ( db)
  //   } else if (permission === "denied") {
  //     alert("You denied for the notification");
  //   }
  // }

  // useEffect(() => {
  //   // Req user for notification permission
  //   requestPermission();
  // }, []);

  const submitForm = (values) => {
    setLoading(true);
    const loginData = {
      email: values?.email,
      password: values?.password,
      // firebaseToken: showToken,
    };
    dispatch(loginAdmin(loginData)).then((val) => {
      setLoading(false);
      if (val?.payload?.status === 200) {
        if (val?.payload?.data?.token) {
          localStorage.setItem("token", val?.payload?.data?.token);
        }

        navigate("/dashboard");
      }
    });
  };
  return (
    <Index.LoadingOverlay
      spinner
      text="Loading your content..."
      active={loading}
    >
      <div>
        <Index.Box className="admin-login-main-flex">
          <Index.Box className="admin-login-left-main">
            <Index.Box className="login-left-bg">
              {/* <Index.Box className="auth-logo-box">
                <img
                  alt="logo_img"
                  src={Index.Png.headerlogo}
                  className="auth-logo"
                />
              </Index.Box> */}
            </Index.Box>
          </Index.Box>
          <Index.Box className="admin-login-right-main">
            <Index.Box className="admin-login-box">
              <Index.Box className="admin-login-main">
                <Formik
                  validationSchema={adminLoginSchema}
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  onSubmit={submitForm}
                >
                  {({
                    values,
                    errors,
                    handleChange,
                    handleSubmit,
                    handleBlur,
                    isValid,
                    touched,
                    dirty,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Index.Box className="admin-login-inner">
                        <Index.Typography
                          component="h2"
                          variant="h2"
                          className="admin-wel-text"
                        >
                          Login!
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="admin-sign-para common-para"
                        >
                          Please enter your credentials to sign in!
                        </Index.Typography>
                        <Index.Box className="input-box">
                          <Index.FormHelperText className="form-lable">
                            Email
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              onBlur={handleBlur}
                              value={values.email}
                              onChange={handleChange}
                              // helperText={errors.email}
                              error={Boolean(errors.email)}
                              fullWidth
                              name="email"
                              id="fullWidth"
                              className="form-control"
                              type="text"
                              autocomplete="off"
                            />
                            {errors?.email && touched?.email ? (
                              <Index.Typography className="error-msg">
                                {errors?.email}
                              </Index.Typography>
                            ) : null}
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="input-box">
                          <Index.FormHelperText className="form-lable">
                            Password
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              size="small"
                              name="password"
                              className="form-control input-with-radius password-form-control"
                              type={showPassword ? "text" : "password"}
                              // label="Password"
                              variant="outlined"
                              autoComplete="off"
                              inputProps={{
                                className: "input_props",
                              }}
                              InputLabelProps={{ className: "add-formlabel" }}
                              FormHelperTextProps={{
                                className: "input_label_props",
                              }}
                              onBlur={handleBlur}
                              value={values.password}
                              onChange={handleChange}
                              // helperText={errors.password}
                              error={Boolean(errors.password)}
                              InputProps={{
                                // <-- This is where the toggle button is added.
                                endAdornment: (
                                  <Index.InputAdornment position="end">
                                    <Index.IconButton
                                      className="passwrd-eye"
                                      aria-label="toggle password visibility"
                                      onClick={(e) =>
                                        handleClickShowPassword(e)
                                      }
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {showPassword ? (
                                        <Index.Visibility />
                                      ) : (
                                        <Index.VisibilityOff />
                                      )}
                                    </Index.IconButton>
                                  </Index.InputAdornment>
                                ),
                              }}
                            />
                            {errors?.password && touched?.password ? (
                              <Index.Typography
                                className="error-msg"
                                // style={{ marginTop: "-21px" }}
                              >
                                {errors?.password}
                              </Index.Typography>
                            ) : null}
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="flex-all forgot-row">
                          <Index.Box className="checkbox-main">
                            {/* <BpCheckbox /> */}
                            <Index.Typography className="checkbox-lable">
                              {/* Remember Me */}
                            </Index.Typography>
                          </Index.Box>
                          <Index.Link
                            to="/forgot-password"
                            className="text-decoration-none"
                          >
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="admin-forgot-para common-para"
                            >
                              Forgot Password?
                            </Index.Typography>
                          </Index.Link>
                        </Index.Box>
                        <Index.Box className="btn-main-primary login-btn-main">
                          <Index.Button
                            type="submit"
                            className="btn-primary login-btn"
                          >
                            Sign In
                          </Index.Button>
                        </Index.Box>
                      </Index.Box>
                    </form>
                  )}
                </Formik>
              </Index.Box>
            </Index.Box>
            <Index.Box className="login-footer-main-data">
              <Index.Typography
                component="p"
                variant="p"
                className=" common-para login-tagline"
              >
                India Ka Apna Smart Theatre
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className=" common-para login-tagline-footer "
              >
                Powered By VCS Industeries Ltd
              </Index.Typography>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </div>
    </Index.LoadingOverlay>
  );
}
