// import { useEffect } from "react";
// import "./App.css";
// import Routers from "./routes/Routes";
// import style from "./assets/style/admin.css";
// import responsive from "./assets/style/responsive.css";
// import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { messaging } from "./firebase";
// import { getToken } from "firebase/messaging";

// // import { messaging } from "./firebase";

// function App() {
//   async function requestPermission() {
//     console.log("Notification",Notification);
//     const permission = await Notification.requestPermission();
//     console.log("permission",permission);
//     if (permission === "granted") {
//       const token = await getToken(messaging, {
//         vapidKey:
//           "BDYBKecAn6gj0b88G4ynkG9zbDCH6vxf7XFmW6JHzRiHJJVLwnN-95fs8pt41J2m96W_kcd1WAJqtutIzrvWAsQ",
//       });
//       console.log("Token Gen>>>", token);
//     } else if (permission === "denied") {
//       // alert("You denied for the notification");
//     }
//   }

//   useEffect(() => {
//     requestPermission();
//   }, []);

//   return (
//     <>
//       <Routers />
//       <ToastContainer />
//       {/* <Wallet></Wallet> */}
//     </>
//   );
// }

// export default App;

import "./App.css";
import Routers from "./routes/Routes";
import style from "./assets/style/admin.css";
import responsive from "./assets/style/responsive.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { useEffect } from "react";
// import { messaging } from "./firebase";
// import { getToken } from "firebase/messaging";
function App() {
  // async function requestPermission() {
  //   const permission = await Notification.requestPermission();
  //   if (permission === "granted") {
  //     // Generate Token
  //     const token = await getToken(messaging, {
  //       vapidKey:
  //         "BDYBKecAn6gj0b88G4ynkG9zbDCH6vxf7XFmW6JHzRiHJJVLwnN-95fs8pt41J2m96W_kcd1WAJqtutIzrvWAsQ",
  //     });
  //     console.log("Token Gen", token);
  //     // Send this token  to server ( db)
  //   } else if (permission === "denied") {
  //     alert("You denied for the notification");
  //   }
  // }

  // useEffect(() => {
  //   // Req user for notification permission
  //   requestPermission();
  // }, []);
  return (
    <div className="App">
      <Routers />
      <ToastContainer />
    </div>
  );
}

export default App;
