// import logo from './images/svg/logo.svg';
import dashboard from "./images/svg/dashboard.svg";
import userlist from "./images/svg/user-list.svg";
import adduser from "./images/svg/add-user.svg";
import editprofile from "./images/svg/edit-profile.svg";
import changepassword from "./images/svg/change-password.svg";
import addusermodal from "./images/svg/add-user-modal.svg";
import settings from "./images/svg/setting.svg";
import notification from "./images/svg/notification.svg";
import search from "./images/svg/search.svg";
import save from "./images/svg/save.svg";
import close from "./images/svg/close.svg";
import filter from "./images/svg/filter.svg";
import plus from "./images/svg/plus.svg";
import blueedit from "./images/svg/blue-edit.svg";
import yelloweye from "./images/svg/yellow-eye.svg";
import trash from "./images/svg/trash.svg";
import exporticon from "./images/svg/export.svg";
import modalclose from "./images/svg/modal-close.svg";
import logout from "./images/svg/logout.svg";
import bergurmenu from "./images/svg/bergur-menu.svg";
import edit from "./images/svg/edit.svg";
import upload from "./images/svg/upload.svg";
import genres from "./images/svg/genre.svg";
import games from "./images/svg/games.svg";
import cms from "./images/svg/cms.svg";
import couponImg from "./images/svg/discout-coupon.svg";
import voucher from "./images/svg/coupon-svgrepo-com.svg";
import property from "./images/svg/residential-svgrepo-com.svg";
import franchiseUserList from "./images/svg/users-svgrepo-com.svg";
import franchiseApplication from "./images/svg/franchiseApplication.svg";
import transactionIcon from "./images/svg/Transactionimage.svg";
import addressimage from "./images/svg/addressimg.svg";
import mobileimage from "./images/svg/mobileimg.svg";
import propertytype from "./images/svg/property-type.svg";
import propertylocation from "./images/svg/property-location.svg";
import propertystatus from "./images/svg/property-status.svg";
import propertynumber from "./images/svg/property-number.svg";
import propertyfloor from "./images/svg/property-floor.svg";
import propertyarea from "./images/svg/property-area.svg";
import userDashIcon from "./images/svg/users-dashboard-icon.svg";
import franchiseUserDashIcon from "./images/svg/franchise-user.svg";
import totalPropertyDashIcon from "./images/svg/total-property.svg";
import requestedPropertyDashIcon from "./images/svg/rent-house-svgrepo-com.svg";
import propertyuser from "./images/svg/property-user.svg";
import propertyemail from "./images/svg/porperty-email.svg";
import propertycall from "./images/svg/property-call.svg";
import notificationAvtarImage from "./images/svg/avatar-svgrepo-com.svg"
import staffImage from "./images/svg/spraying-svgrepo-com (1).svg"



const Svg = {
  staffImage,
  notificationAvtarImage,
  requestedPropertyDashIcon,
  totalPropertyDashIcon,
  franchiseUserDashIcon,
  userDashIcon,
  mobileimage,
  addressimage,
  transactionIcon,
  franchiseApplication,
  franchiseUserList,
  property,
  voucher,
  couponImg,
  dashboard,
  userlist,
  // logo,
  editprofile,
  adduser,
  changepassword,
  addusermodal,
  settings,
  notification,
  search,
  save,
  exporticon,
  filter,
  trash,
  yelloweye,
  plus,
  close,
  blueedit,
  modalclose,
  bergurmenu,
  logout,
  edit,
  upload,
  genres,
  games,
  cms,
  propertytype,
  propertylocation,
  propertystatus,
  propertynumber,
  propertyfloor,
  propertyarea,
  propertyuser,
  propertyemail,
  propertycall,
};

export default Svg;
