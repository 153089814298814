import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { editCoupon } from "../../../../redux/slices/AdminSlice";
import {
  deleteCoupon,
  getAllCoupon,
  statusCouponData,
} from "../../../../redux/slices/AdminService";
import PageIndex from "../../../PageIndex";

export default function CouponList() {
  let navigate = PageIndex.useNavigate();
  const dispatch = PageIndex.useDispatch();
  const { couponList } = PageIndex.useSelector((state) => state.admin);
  const { adminData } = PageIndex.useSelector((store) => store.admin);
 
  const [search, setSearch] = useState("");
  const [pageData, setPageData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  // handle delete
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [id, setId] = useState("");
  const handleDeleteOpen = (id) => {
    setId(id);
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setId("");
  };
  const handleOpen = () => {
    navigate("/dashboard/add-coupon");
  };

  useEffect(() => {
    setLoading(true); // Set loading state to true before fetching data
    setTimeout(() => {
      // Simulating a delay of 1 seconds
      dispatch(getAllCoupon())
        .then((val) => {
          if (val?.payload?.status === 200) {
            dispatch(getAllCoupon());
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }, 1000);
  }, []);

  useEffect(() => {
    const filtered = couponList
      ?.filter((item) => {
        return !search
          ? item
          : item?.discount_type
              ?.toLowerCase()
              .includes(search?.toLowerCase()) ||
              item?.title?.toLowerCase()?.includes(search?.toLowerCase()) ||
              item?.start_date?.toString()?.includes(search?.toString()) ||
              item?.discount_amount?.toString()?.includes(search?.toString());
      })
      ?.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateB - dateA;
      });
    if (filtered) {
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  }, [couponList, search]);

  const deleteCouponData = () => {
    setLoading(true);
    dispatch(deleteCoupon(id))
      .then((val) => {
        if (val?.payload?.status === 200) {
          dispatch(getAllCoupon());
          handleDeleteClose();
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const statusCoupon = (id, status) => {
    setLoading(true)
    dispatch(statusCouponData({ isActive: status, id })).then((val) => {
      dispatch(getAllCoupon());
      setLoading(false)
    });
  };
  if (
    (adminData && adminData?.role?.permission?.includes("Coupon_view")) ||
    (adminData && adminData?.isAdmin === true)
  ) {
  return (
    <>
      {loading ? (
        <PageIndex.LoadingOverlay
          spinner
          text="Loading your content..."
          active={loading}
        />
      ) : (
        <Index.Box className="dashboard-content">
          <Index.Box className="user-list-flex">
            <Index.Box className="admin-page-title-main">
              <Index.Typography
                className="admin-page-title"
                component="h2"
                variant="h2"
              >
                Coupon List
              </Index.Typography>
            </Index.Box>
            <Index.Box className="userlist-btn-flex">
              <Index.Box className="user-search-main">
                <Index.Box className="user-search-box">
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      placeholder="Search coupon"
                      value={search}
                      onChange={(e) => {
                        const inputValue = e.target.value
                          .replace(/^\s+/, "")
                          .replace(/\s\s+/g, " ");
                        setSearch(inputValue);
                      }}
                    />
                    <span className="search-icon-box">
                      <img
                        alt="search_icon"
                        src={Index.Svg.search}
                        className="search-icon"
                      />
                    </span>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="flex-all user-list-inner-flex">
                <Index.Box className="adduser-btn-main btn-main-primary">
                  {(adminData &&
                    adminData?.role?.permission?.includes("Coupon_add")) ||
                  (adminData && adminData?.isAdmin === true) ? (
                    <>
                      <Index.Button
                        className="adduser-btn btn-primary"
                        onClick={handleOpen}
                      >
                        <img
                          src={Index.Svg.plus}
                          className="plus-icon"
                          alt="plus icon"
                        />
                        Add Coupon
                      </Index.Button>
                    </>
                  ) : (
                    ""
                  )}
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="admin-dashboard-list-row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row-userlist"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dash-box">
                    <Index.Box className="couponlist-table-main page-table-main">
                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                      >
                        <Index.Table
                          sx={{ minWidth: 650 }}
                          aria-label="sticky table"
                          className="table"
                        >
                          <Index.TableHead className="table-head sticky">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                No.
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Title
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Discount
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Start Date
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                End Date
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Status
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Action
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {pageData?.length ? (
                              pageData?.map((row, ind) => (
                                <Index.TableRow
                                  key={row._id}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td"
                                    align="center"
                                  >
                                    {row.sNo?row.sNo:'-'}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td"
                                    align="center"
                                  >
                                    {row?.title?row?.title:'-'}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td"
                                    align="center"
                                  >
                                    {row?.discount_type === "flat"
                                      ? `₹ ${row?.discount_amount}`
                                      : `${row?.discount_amount}%`}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                    align="center"
                                  >
                                    {new Date(
                                      row?.start_date
                                    )?.toLocaleDateString("en-GB")}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                    align="center"
                                  >
                                    {new Date(
                                      row?.end_date
                                    )?.toLocaleDateString("en-GB")}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                    align="center"
                                  >
                                    <div
                                      className={
                                        row?.isActive
                                          ? "table-td-active"
                                          : "table-td-Inactive"
                                      }
                                    >
                                      {row?.isActive ? "Active" : "Deactive"}{" "}
                                    </div>
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                    align="center"
                                  >
                                    <Index.Box className="userdata-btn-flex">
                                      {(adminData &&
                                        adminData?.role?.permission?.includes(
                                          "Coupon_edit"
                                        )) ||
                                      (adminData &&
                                        adminData?.isAdmin === true) ? (
                                        <>
                                          <IconButton
                                            color="primary"
                                            aria-label="upload picture"
                                            component="label"
                                            onClick={() => {
                                              dispatch(editCoupon(row));
                                              navigate(
                                                "/dashboard/add-coupon",
                                                {
                                                  state: row._id,
                                                }
                                              );
                                            }}
                                          >
                                            <Index.EditIcon />
                                          </IconButton>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {(adminData &&
                                        adminData?.role?.permission?.includes(
                                          "Coupon_edit"
                                        )) ||
                                      (adminData &&
                                        adminData?.isAdmin === true) ? (
                                        <>
                                          {row?.isActive ? (
                                            <IconButton
                                              color="primary"
                                              aria-label="upload picture"
                                              component="label"
                                              onClick={() => {
                                                statusCoupon(
                                                  row?._id,
                                                  !row?.isActive
                                                );
                                              }}
                                            >
                                              <Index.ThumbUpAltIcon />
                                            </IconButton>
                                          ) : (
                                            <IconButton
                                              color="primary"
                                              aria-label="upload picture"
                                              component="label"
                                              onClick={() => {
                                                statusCoupon(
                                                  row?._id,
                                                  !row?.isActive
                                                );
                                              }}
                                              disabled={loading}
                                            >
                                              <Index.ThumbDownAltIcon />
                                            </IconButton>
                                          )}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {(adminData &&
                                        adminData?.role?.permission?.includes(
                                          "Coupon_view"
                                        )) ||
                                      (adminData &&
                                        adminData?.isAdmin === true) ? (
                                        <>
                                          <Index.IconButton
                                            color="primary"
                                            aria-label="upload picture"
                                            component="label"
                                            onClick={() => {
                                              dispatch(editCoupon(row));
                                              navigate(
                                                "/dashboard/view-coupon",
                                                {
                                                  state: row,
                                                }
                                              );
                                            }}
                                          >
                                            <Index.RemoveRedEyeIcon />
                                          </Index.IconButton>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {(adminData &&
                                        adminData?.role?.permission?.includes(
                                          "Coupon_delete"
                                        )) ||
                                      (adminData &&
                                        adminData?.isAdmin === true) ? (
                                        <>
                                          <IconButton
                                            color="primary"
                                            aria-label="upload picture"
                                            component="label"
                                            onClick={() => {
                                              handleDeleteOpen(row?._id);
                                            }}
                                          >
                                            <Index.DeleteIcon />
                                          </IconButton>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </Index.Box>
                                  </Index.TableCell>
                                </Index.TableRow>
                              ))
                            ) : (
                              <Index.TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td no-record-text"
                                  align="center"
                                  colSpan={7}
                                >
                                  No records found
                                </Index.TableCell>
                              </Index.TableRow>
                            )}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="pagination-main">
            <PageIndex.Pagination
              fetchData={filteredData}
              setPageData={setPageData}
              pageData={pageData}
              search={search}
            />
          </Index.Box>
          {/* delete modal */}
          <PageIndex.DeleteModal
            deleteOpen={deleteOpen}
            handleDeleteClose={handleDeleteClose}
            handleDeleteRecord={deleteCouponData}
          />
        </Index.Box>
      )}
    </>
  );
} else {
  navigate("/dashboard");
}
}
