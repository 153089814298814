import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { createTrainingModalSession } from "../../../../redux/slices/AdminService";
import { trainingSessionSchema } from "../../../../validation/Validation";
const TrainingSession = () => {
  const dispatch = PageIndex.useDispatch();
  const navigate = PageIndex.useNavigate();
  const params = PageIndex.useLocation();
  const [loading, setLoading] = useState(true);
  const id = params?.state;
  const { editTrainingModelData, adminData } = PageIndex.useSelector(
    (state) => state.admin
  );

  // const { roleList } = PageIndex.useSelector((state) => state.admin);

  const initialValues = {
    title: id ? editTrainingModelData.title : "",
    videoLink: id ? editTrainingModelData.videoLink : "",
    description: id ? editTrainingModelData.description : "",
  };

  const handleSubmit = (values) => {
    if (id) {
      values.id = id;
    }
    !id
      ? dispatch(createTrainingModalSession(values)).then((val) => {
          setLoading(false);
          if (val?.payload?.status) {
            navigate("/dashboard/training-session-list");
          }
        })
      : dispatch(createTrainingModalSession(values)).then((val) => {
          setLoading(false);
          if (val?.payload?.status) {
            navigate("/dashboard/training-session-list");
          }
        });
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  });

  if (
    (adminData &&
      adminData?.role?.permission?.includes("Training Session_add")) ||
    adminData?.role?.permission?.includes("Training Session_edit") ||
    (adminData && adminData?.isAdmin === true)
  ) {
    return (
      <PageIndex.LoadingOverlay
        spinner
        text="Loading your content..."
        active={loading}
      >
        {!loading && (
          <Index.Box className="dashboard-content add-property-containt">
            {/* <Index.Typography
            className="admin-page-title add-title"
            component="h2"
            variant="h2"
          >
            {id ? "Update" : "Add "} Admin User
          </Index.Typography> */}
            <Index.Box className="page-border">
              <PageIndex.Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={trainingSessionSchema}
              >
                {({
                  values,
                  errors,
                  handleChange,
                  handleBlur,
                  touched,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Index.Box className="dashboard-content add-user-containt cms-page-containt">
                      <Index.Typography
                        className="admin-page-title cms-page-title"
                        component="h2"
                        variant="h4"
                      >
                        Training Model Session
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="admin-dash-box cms-page-containt">
                      <Index.Box className="grid-row cms-page-row">
                        <Index.Box sx={{ width: 1 }} className="grid-main">
                          <Index.Box
                            display="grid"
                            gridTemplateColumns="repeat(12, 1fr)"
                            gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                          >
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 5",
                                md: "span 4",
                                lg: "span 6",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box">
                                <Index.FormHelperText className="form-label cms-discription-lables">
                                  Title*
                                </Index.FormHelperText>
                                <Index.Box className="form-group cms-input-box">
                                  <Index.TextField
                                    fullWidth
                                    id="title"
                                    className="form-control"
                                    placeholder="Enter Title"
                                    name="title"
                                    onChange={(event) => {
                                      const inputValue =
                                        event.target.value.replace(
                                          /^[^a-zA-Z]+/g,
                                          ""
                                        );
                                      if (inputValue.length <= 40) {
                                        // Only update the value if it's not more than 40 characters
                                        setFieldValue("title", inputValue);
                                      }
                                    }}
                                    maxLength={40}
                                    value={values.title}
                                    helperText={errors.title}
                                    error={Boolean(errors.title)}
                                  />
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 5",
                                md: "span 4",
                                lg: "span 6",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box">
                                <Index.FormHelperText className="form-label cms-discription-lables">
                                  Training Video Link
                                </Index.FormHelperText>
                                <Index.Box className="form-group cms-input-box">
                                  <Index.TextField
                                    fullWidth
                                    id="videoLink"
                                    className="form-control"
                                    placeholder="Enter Video Link"
                                    name="videoLink"
                                    onChange={handleChange}
                                    value={values.videoLink}
                                    helperText={errors.videoLink}
                                    error={Boolean(errors.videoLink)}
                                  />
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 12",
                                md: "span 12",
                                lg: "span 12",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box">
                                <Index.FormHelperText className="form-label cms-discription-lables">
                                  Description*
                                </Index.FormHelperText>
                                <Index.Box className="form-group">
                                  <CKEditor
                                    editor={ClassicEditor}
                                    data={values.description}
                                    name="description"
                                    onChange={(event, editor) => {
                                      const data = editor.getData();
                                      setFieldValue("description", data);
                                    }}
                                  />
                                </Index.Box>
                              </Index.Box>
                              {errors?.description && touched?.description ? (
                                <Index.Typography className="error-msg">
                                  {errors?.description}
                                </Index.Typography>
                              ) : null}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box className="save-btn-main border-btn-main cms-btn-main">
                        <Index.Button
                          className="save-user-btn border-btn"
                          type="submit"
                        >
                          <img
                            alt="save"
                            src={Index.Svg.save}
                            className="user-save-icon"
                          ></img>
                          {id ? "Update" : "Add"}
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </form>
                )}
              </PageIndex.Formik>
            </Index.Box>
          </Index.Box>
        )}
      </PageIndex.LoadingOverlay>
    );
  } else {
    navigate("/dashboard");
  }
};

export default TrainingSession;
