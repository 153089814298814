import React, { useState } from "react";
import Index from "../../Index";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
// import { logout } from "../../../redux/slices/adminService";
export default function Sidebar(props) {
  // const navigate = useNavigate();
  // open sidebar usign handalclick
  // const dispatch = useDispatch();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const { adminData } = useSelector((store) => store.admin);

  console.log(adminData, 15);
  const handleClickAccount = () => {
    setOpen(!open);
  };

  return (
    <>
      <Index.Box
        className={`admin-sidebar-main scrollbar ${props.open ? "active" : ""}`}
      >
        <Index.Box className="admin-sidebar-inner-main">
          <Index.Box className="admin-sidebar-logo-main">
            <Index.Box
              className="admin-header-logo-main"
              // onClick={() => navigate("/dashboard")}
            >
              <img
                src={Index.Png.headerlogo}
                className="admin-sidebar-logo"
                alt="logo"
              />
            </Index.Box>
            <Index.Button
              onClick={() => {
                props.setOpen(!props.open);
                document.body.classList[!props.open ? "add" : "remove"](
                  "body-overflow"
                );
              }}
            >
              <img src={Index.Svg.close} className="close-icon" alt="logo" />
            </Index.Button>
          </Index.Box>
          <Index.Box className="admin-sidebar-list-main">
            <Index.List className="admin-sidebar-list">
              <Index.ListItem className="admin-sidebar-listitem with-shadow">
                <Index.Link
                  to="/dashboard"
                  className={`admin-sidebar-link ${
                    location.pathname === "/dashboard" ? "active" : ""
                  }`}
                >
                  <img
                    src={Index.Svg.dashboard}
                    alt="sidebar icon"
                    className="admin-sidebar-icons"
                  />
                  Dashboard
                </Index.Link>
              </Index.ListItem>
              <Index.ListItem className="admin-sidebar-listitem">
                {(adminData &&
                  adminData?.role?.permission?.includes("Role_view")) ||
                (adminData && adminData?.isAdmin === true) ? (
                  <>
                    <Index.Link
                      to="/dashboard/role-list"
                      className={`admin-sidebar-link ${
                        location.pathname === "/dashboard/add-role" ||
                        location.pathname === "/dashboard/role-list"
                          ? "active"
                          : ""
                      }`}
                    >
                      <img
                        src={Index.Png.roleListLogo}
                        // src={Index.Svg.userlist}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      />
                      Role List
                    </Index.Link>
                  </>
                ) : (
                  ""
                )}
              </Index.ListItem>
              {(adminData &&
                adminData?.role?.permission?.includes("Admin User_view")) ||
              (adminData && adminData?.isAdmin === true) ? (
                <>
                  <Index.ListItem className="admin-sidebar-listitem">
                    <Index.Link
                      to="/dashboard/employee-role-list"
                      className={`admin-sidebar-link ${
                        location.pathname === "/dashboard/add-employee-role" ||
                        location.pathname === "/dashboard/employee-role-list"
                          ? "active"
                          : ""
                      }`}
                    >
                      <img
                        // src={Index.Svg.userlist}
                        src={Index.Png?.adminUserLogo}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      />
                      Admin User
                    </Index.Link>
                  </Index.ListItem>
                </>
              ) : (
                ""
              )}
              {(adminData &&
                adminData?.role?.permission?.includes("Franchise User_view")) ||
              (adminData && adminData?.isAdmin === true) ? (
                <>
                  <Index.ListItem className="admin-sidebar-listitem">
                    <Index.Link
                      to="/dashboard/franchise-user-list"
                      className={`admin-sidebar-link ${
                        location.pathname ===
                          "/dashboard/franchise-user-list" ||
                        location.pathname ===
                          "/dashboard/view-franchise-user" ||
                        location.pathname === "/dashboard/update-franchise-user"
                          ? "active"
                          : ""
                      }`}
                    >
                      <img
                        src={Index.Png.franchiseUserLogo}
                        // src={Index.Svg.franchiseUserList}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      />
                      Franchise User List
                    </Index.Link>
                  </Index.ListItem>
                </>
              ) : (
                ""
              )}
              {(adminData &&
                adminData?.role?.permission?.includes("Property_view")) ||
              (adminData && adminData?.isAdmin === true) ? (
                <>
                  <Index.ListItem className="admin-sidebar-listitem">
                    <Index.Link
                      to="/dashboard/property-list"
                      className={`admin-sidebar-link ${
                        location.pathname === "/dashboard/property-list" ||
                        location.pathname === "/dashboard/add-property" ||
                        location.pathname === "/dashboard/view-property"
                          ? "active"
                          : ""
                      }`}
                    >
                      <img
                        src={Index.Svg.property}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      />
                      Property List
                    </Index.Link>
                  </Index.ListItem>
                </>
              ) : (
                ""
              )}
              {(adminData &&
                adminData?.role?.permission?.includes(
                  "Requested Property_view"
                )) ||
              (adminData && adminData?.isAdmin === true) ? (
                <>
                  <Index.ListItem className="admin-sidebar-listitem">
                    <Index.Link
                      to="/dashboard/user-property-list"
                      className={`admin-sidebar-link ${
                        location.pathname === "/dashboard/user-property-list" ||
                        location.pathname ===
                          "/dashboard/view-user-property-lease" ||
                        location.pathname ===
                          "/dashboard/update-user-property-lease"
                          ? "active"
                          : ""
                      }`}
                    >
                      <img
                        src={Index.Png.requestedPropertyList}
                        // src={Index.Svg.property}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      />
                      Lease Property
                    </Index.Link>
                  </Index.ListItem>
                </>
              ) : (
                ""
              )}

              {(adminData &&
                adminData?.role?.permission?.includes(
                  "Requested Property_view"
                )) ||
              (adminData && adminData?.isAdmin === true) ? (
                <>
                  <Index.ListItem className="admin-sidebar-listitem">
                    <Index.Link
                      to="/dashboard/user-own-property-list"
                      className={`admin-sidebar-link ${
                        location.pathname ===
                          "/dashboard/user-own-property-list" ||
                        location.pathname ===
                          "/dashboard/view-user-property-own" ||
                        location.pathname ===
                          "/dashboard/update-user-property-own"
                          ? "active"
                          : ""
                      }`}
                    >
                      <img
                        src={Index.Png.requestedPropertyList}
                        // src={Index.Svg.property}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      />
                      User Own Property
                    </Index.Link>
                  </Index.ListItem>
                </>
              ) : (
                ""
              )}

              {(adminData &&
                adminData?.role?.permission?.includes("Rebranding_view")) ||
              (adminData && adminData?.isAdmin === true) ? (
                <>
                  <Index.ListItem className="admin-sidebar-listitem">
                    <Index.Link
                      to="/dashboard/rebranding-list"
                      className={`admin-sidebar-link ${
                        location.pathname === "/dashboard/rebranding-list" ||
                        location.pathname ===
                          "/dashboard/update-rebranding-property" ||
                        location.pathname === "/dashboard/view-rebranding"
                          ? "active"
                          : ""
                      }`}
                    >
                      <img
                        src={Index.Png.rebrandingPropertyList}
                        // src={Index.Svg.property}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      />
                      Covert Your Existing Cinema
                    </Index.Link>
                  </Index.ListItem>
                </>
              ) : (
                ""
              )}
              {console.log(adminData?.role?.permission, 266)}
              {(adminData &&
                adminData?.role?.permission?.includes(
                  "Franchise Application_view"
                )) ||
              (adminData && adminData?.isAdmin === true) ? (
                <>
                  <Index.ListItem className="admin-sidebar-listitem">
                    <Index.Link
                      to="/dashboard/franchise-application"
                      className={`admin-sidebar-link ${
                        location.pathname ===
                          "/dashboard/franchise-application" ||
                        location.pathname ===
                          "/dashboard/view-transaction-log" ||
                        location.pathname ===
                          "/dashboard/view-razorpay-transaction" ||
                        location.pathname ===
                          "/dashboard/view-franchise-application"
                          ? "active"
                          : ""
                      }`}
                    >
                      <img
                        src={Index.Svg.franchiseApplication}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      />
                      Franchise Application
                    </Index.Link>
                  </Index.ListItem>
                </>
              ) : (
                ""
              )}
              {(adminData &&
                adminData?.role?.permission?.includes("Transaction_view")) ||
              (adminData && adminData?.isAdmin === true) ? (
                <>
                  <Index.ListItem className="admin-sidebar-listitem">
                    <Index.Link
                      to="/dashboard/transaction-list"
                      className={`admin-sidebar-link ${
                        location.pathname === "/dashboard/transaction-list"
                          ? "active"
                          : ""
                      }`}
                    >
                      <img
                        src={Index.Svg.transactionIcon}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      />
                      Transaction history
                    </Index.Link>
                  </Index.ListItem>
                </>
              ) : (
                ""
              )}
              {(adminData &&
                adminData?.role?.permission?.includes("Coupon_view")) ||
              (adminData && adminData?.isAdmin === true) ? (
                <>
                  <Index.ListItem className="admin-sidebar-listitem">
                    <Index.Link
                      to="/dashboard/coupon-list"
                      className={`admin-sidebar-link ${
                        location.pathname === "/dashboard/coupon-list" ||
                        location.pathname === "/dashboard/add-coupon" ||
                        location.pathname === "/dashboard/view-coupon"
                          ? "active"
                          : ""
                      }`}
                    >
                      <img
                        src={Index.Svg.voucher}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      />
                      Coupon List
                    </Index.Link>
                  </Index.ListItem>
                </>
              ) : (
                ""
              )}

              {/* {(adminData) ||
              (adminData && adminData?.isAdmin === true) ? (
                <>
                  <Index.ListItem className="admin-sidebar-listitem">
                    <Index.Link
                      to="/dashboard/state-list"
                      className={`admin-sidebar-link ${
                        location.pathname === "/dashboard/state-list" ||
                        location.pathname === "/dashboard/add-state"
                          ? "active"
                          : ""
                      }`}
                    >
                      <img
                        src={Index.Svg.voucher}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      />
                      State Management
                    </Index.Link>
                  </Index.ListItem>
                </>
              ) : (
                ""
              )} */}

              {(adminData &&
                adminData?.role?.permission?.includes("Staff_view")) ||
              (adminData && adminData?.isAdmin === true) ? (
                <>
                  <Index.ListItem className="admin-sidebar-listitem">
                    <Index.Link
                      to="/dashboard/staff-hiring-list"
                      className={`admin-sidebar-link ${
                        location.pathname === "/dashboard/staff-hiring-list" ||
                        location.pathname === "/dashboard/view-staff-hiring"
                          ? "active"
                          : ""
                      }`}
                    >
                      <img
                        src={Index.Png.staffHiringLogo}
                        // src={Index.Svg.staffImage}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      />
                      Staff
                    </Index.Link>
                  </Index.ListItem>
                </>
              ) : (
                ""
              )}

              {(adminData &&
                adminData?.role?.permission?.includes(
                  "Terms & Condition_view"
                )) ||
              adminData?.role?.permission?.includes("Training Session_view") ||
              adminData?.role?.permission?.includes(
                "Orientation Session_view"
              )||adminData?.role?.permission?.includes(
                "Operational Session_view"
              ) ||
              (adminData && adminData?.isAdmin === true) ? (
                <>
                  <Index.ListItem className="admin-sidebar-listitem admin-submenu-listitem-main">
                    <Index.Box className="admin-sidebar-link">
                      <Index.Box
                        className="admin-sidebar-parent"
                        onClick={handleClickAccount}
                      >
                        <img
                          src={Index.Svg.cms}
                          alt="sidebar icon"
                          className="admin-sidebar-icons"
                        />
                        CMS
                        {open ? (
                          <Index.ExpandLess className="expandless-icon" />
                        ) : (
                          <Index.ExpandMore className="expandmore-icon" />
                        )}
                      </Index.Box>

                      <Index.Box className="submenu-main">
                        <Index.Collapse
                          in={open}
                          // timeout="auto"
                          // unmountOnExit
                          className="submenu-collapse"
                        >
                          <Index.List
                            component="div"
                            disablePadding
                            className="admin-sidebar-submenulist"
                          >
                            {(adminData &&
                              adminData?.role?.permission?.includes(
                                "Terms & Condition_view"
                              )) ||
                            (adminData && adminData?.isAdmin === true) ? (
                              <>
                                <Index.ListItem className="admin-sidebar-listitem">
                                  <Index.Link
                                    to="/dashboard/terms-condition"
                                    className={`admin-sidebar-link ${
                                      location.pathname ===
                                      "/dashboard/terms-condition"
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    Terms and conditions
                                  </Index.Link>
                                </Index.ListItem>
                              </>
                            ) : (
                              ""
                            )}

                            {(adminData &&
                              adminData?.role?.permission?.includes(
                                "Training Session_view"
                              )) ||
                            (adminData && adminData?.isAdmin === true) ? (
                              <>
                                <Index.ListItem className="admin-sidebar-listitem">
                                  <Index.Link
                                    to="/dashboard/training-session-list"
                                    className={`admin-sidebar-link ${
                                      location.pathname ===
                                      "/dashboard/training-session-list"
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    Training Session
                                  </Index.Link>
                                </Index.ListItem>
                              </>
                            ) : (
                              ""
                            )}

                            {(adminData &&
                              adminData?.role?.permission?.includes(
                                "Orientation Session_view"
                              )) ||
                            (adminData && adminData?.isAdmin === true) ? (
                              <>
                                <Index.ListItem className="admin-sidebar-listitem">
                                  <Index.Link
                                    to="/dashboard/orientation-session-list"
                                    className={`admin-sidebar-link ${
                                      location.pathname ===
                                      "/dashboard/orientation-session-list"
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    Orientation Session
                                  </Index.Link>
                                </Index.ListItem>
                              </>
                            ) : (
                              ""
                            )}

                            {(adminData &&
                              adminData?.role?.permission?.includes(
                                "Operational Session_view"
                              )) ||
                            (adminData && adminData?.isAdmin === true) ? (
                              <>
                                <Index.ListItem className="admin-sidebar-listitem">
                                  <Index.Link
                                    to="/dashboard/oprational-session-list"
                                    className={`admin-sidebar-link ${
                                      location.pathname ===
                                      "/dashboard/oprational-session-list"
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    Operational Model Session
                                  </Index.Link>
                                </Index.ListItem>
                              </>
                            ) : (
                              ""
                            )}
                            {(adminData &&
                              adminData?.role?.permission?.includes(
                                "Operational Session_view"
                              )) ||
                            (adminData && adminData?.isAdmin === true) ? (
                              <>
                                <Index.ListItem className="admin-sidebar-listitem">
                                  <Index.Link
                                    to="/dashboard/payment-terms"
                                    className={`admin-sidebar-link ${
                                      location.pathname ===
                                      "/dashboard/payment-terms"
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    Payment Terms
                                  </Index.Link>
                                </Index.ListItem>
                              </>
                            ) : (
                              ""
                            )}
                          </Index.List>
                        </Index.Collapse>
                      </Index.Box>
                    </Index.Box>
                  </Index.ListItem>
                </>
              ) : (
                ""
              )}
            </Index.List>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
