import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Index from "../../../Index";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import PageIndex from "../../../PageIndex";
import { imageUrl } from "../../../../config/dataService";
import {
  statusUserVerifyProertyStatus,
  getAllPropertyList,
} from "../../../../redux/slices/AdminService";
import "./UserPropertyList.css";

import { Form, Field } from "formik";

const ViewUserProperty = () => {
  const navigate = PageIndex.useNavigate();
  const dispatch = PageIndex.useDispatch();
  const { editProperty,adminData } = PageIndex.useSelector((state) => state.admin);
  const [imageSizes, setImageSizes] = useState({});
  const initialValues = {
    isVerified: editProperty?.isVerified,
  };
  const handleImageLoad = (index, width, height) => {
    setImageSizes((prevSizes) => ({
      ...prevSizes,
      [index]: { width, height },
    }));
  };
  const handleVerifyStatusChange = (value, setFieldValue) => {
    setFieldValue("isVerified", value);
    updatePropertyVerifyStatus(value);
  };

  const updatePropertyVerifyStatus = (value) => {
    dispatch(
      statusUserVerifyProertyStatus({
        isVerified: value,
        property_id: editProperty?._id,
      })
    ).then((val) => {
      dispatch(getAllPropertyList());
    });
  };

  const city = editProperty?.project_address?.city?editProperty?.project_address?.city:"-";
  const state = editProperty?.project_address?.state?editProperty?.project_address?.state:"-";
  const pincode = editProperty?.project_address?.pincode?editProperty?.project_address?.pincode:'-';
  let address = "";

  if (city) {
    address += city;
  }

  if (state) {
    address += address ? `, ${state}` : state;
  }

  if (pincode) {
    address += address ? `, ${pincode}` : pincode;
  }
  if (
    (adminData && adminData?.role?.permission?.includes("Requested Property_view")) ||
    (adminData && adminData?.isAdmin === true)
  ) {
  return (
    <Index.Box>
      <Index.Box className="admin-page-title-main-viewcomponent">
        <Index.Typography
          className="admin-page-title-viewcomponent"
        >
          View Requested Property Details
        </Index.Typography>
      </Index.Box>
      <Index.Box className="view-card">
        <Index.Box sx={{ width: 1 }} className="grid-main">
          <Index.Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gap={{ xs: 2, sm: 2, md: 2, lg: 3 }}
          >
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="view-swiper-main request_property_slider">
                <Swiper
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    280: {
                      slidesPerView: 1,
                    },
                    375: {
                      slidesPerView: 1,
                    },
                    640: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 1,
                    },
                    1024: {
                      slidesPerView: 1,
                    },
                    1366: {
                      slidesPerView: 1,
                    },
                    1500: {
                      slidesPerView: 1,
                    },
                  }}
                  modules={[Pagination, Navigation]}
                  className="new-game-swiper mySwiper"
                >
                  {editProperty?.images.length === 0 ? (
                    <SwiperSlide className="new-game-swiper-slide">
                      <Index.Box className="new-game-flex-li">
                        <Index.Box className="game-card"></Index.Box>
                        <Index.Box className="new-game-card">
                          <img
                            src={Index.Png.staticLogoImage}
                            className="static-use-image"
                            alt="logo"
                          />
                        </Index.Box>
                      </Index.Box>
                    </SwiperSlide>
                  ) : (
                    editProperty?.images?.map((element, index) => {
                      const isVideo =
                        element.endsWith(".mp4") || element.endsWith(".avi"); // Check if the element is a video file
                      const mediaUrl = isVideo
                        ? `${imageUrl}/${element}`
                        : `${imageUrl}/${element}`; // Set the correct URL based on the file type

                      const imageSize = imageSizes[index];
                      const imageStyle = imageSize
                        ? {
                            // maxWidth: "100%",
                            // maxHeight: "100%",
                          }
                        : {};

                      return (
                        <SwiperSlide
                          className="new-game-swiper-slide"
                          key={index}
                        >
                          <Index.Box className="new-game-flex-li">
                            <Index.Box className="game-card"></Index.Box>
                            <Index.Box className="new-game-card">
                              {isVideo ? (
                                <video className="new-game-img" controls>
                                  <source src={mediaUrl} type="video/mp4" />
                                </video>
                              ) : (
                                <img
                                  alt="img"
                                  src={mediaUrl}
                                  className="new-game-img"
                                  onLoad={(e) =>
                                    handleImageLoad(
                                      index,
                                      e.target.naturalWidth,
                                      e.target.naturalHeight
                                    )
                                  }
                                  style={imageStyle}
                                />
                              )}
                              <Index.Box className="new-game-content">
                                <Index.Typography className="new-game-title">
                                  {element?.title}
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </SwiperSlide>
                      );
                    })
                  )}
                </Swiper>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="view-franchises-details-box view-propert-box">
                <PageIndex.Formik initialValues={initialValues}>
                  {({ values, setFieldValue }) => (
                    <Form>
                      <Index.Box className="input-box property-dropdown dropdown-select">
                        <Index.FormHelperText className="form-lable">
                          User Property Verification Status
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Field
                            name="isVerified"
                            as={Index.Select}
                            disabled={
                              (editProperty?.options ===
                                "List Your Property To Lease" &&
                                editProperty?.franchise_amount) ||
                              editProperty?.options ===
                                "List Your Property To Build Franchise"
                                ? false
                                : true
                            }
                            variant="outlined"
                            fullWidth
                            id="fullWidth"
                            className={
                              editProperty?.isVerified === true
                                ? "dropdown-franchise-status form-control"
                                : "dropdown-franchise-status form-control"
                            }
                            onChange={(e) =>
                              handleVerifyStatusChange(
                                e.target.value,
                                setFieldValue
                              )
                            }
                          >
                            <Index.MenuItem value="" disabled>
                              Select Status
                            </Index.MenuItem>
                            <Index.MenuItem
                              value="true"
                              disabled={editProperty?.isVerified === true}
                            >
                              Verified
                            </Index.MenuItem>
                            <Index.MenuItem
                              value="false"
                              disabled={editProperty?.isVerified === false}
                            >
                              UnVerified
                            </Index.MenuItem>
                          </Field>
                        </Index.Box>
                      </Index.Box>
                    </Form>
                  )}
                </PageIndex.Formik>
                <Index.Typography
                  variant="h4"
                  className="proerty-details-titles"
                >
                  {editProperty?.property_name
                    ? editProperty?.property_name
                    : ""}
                </Index.Typography>
                {editProperty?.project_address && address && (
                  <Index.Typography className="property-address-img">
                    <img
                      src={Index.Svg.propertylocation}
                      alt="sidebar icon"
                      className="franchises-icons"
                    />
                    {address}
                    {/* {`${editProperty?.project_address?.city},${editProperty?.project_address?.state},${editProperty?.project_address?.pincode}`} */}
                  </Index.Typography>
                )}
                <Index.Box className="property-detail-box view-user-property-box">
                  <Index.Box className="property-flex">
                    <Index.Typography className="property-lables">
                      <img
                        src={Index.Svg.propertyuser}
                        alt="sidebar icon"
                        className="franchises-icons"
                      />{" "}
                      Franchise User Name
                    </Index.Typography>
                    <Index.Typography className="property-details-data-text">
                      {`  ${
                        editProperty?.created_by?.name
                          ? editProperty?.created_by?.name
                          : "---"
                      }`}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="property-flex">
                    <Index.Typography className="property-lables">
                      <img
                        src={Index.Svg.propertyemail}
                        alt="sidebar icon"
                        className="franchises-icons"
                      />{" "}
                      Franchise User Email
                    </Index.Typography>
                    <Index.Typography className="property-details-data-text">
                      {`  ${
                        editProperty?.created_by?.email
                          ? editProperty?.created_by?.email
                          : "---"
                      }`}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="property-flex">
                    <Index.Typography className="property-lables">
                      <img
                        src={Index.Svg.propertycall}
                        alt="sidebar icon"
                        className="franchises-icons"
                      />{" "}
                      Franchise User Mobile
                    </Index.Typography>

                    <Index.Typography className="property-details-data-text">
                      {`  ${
                        editProperty?.created_by?.mobile
                          ? editProperty?.created_by?.mobile
                          : "---"
                      }`}
                    </Index.Typography>
                  </Index.Box>
                  {editProperty?.user_id?.docType && (
                    <Index.Box className="property-flex">
                      <Index.Typography className="property-lables">
                        <img
                          src={Index.Png.panCard}
                          alt="sidebar icon"
                          className="franchises-icons"
                        />
                        User{" "}
                        {editProperty?.user_id?.docType === "Pan Card Number"
                          ? "Pan Card"
                          : "GST"}{" "}
                        No.
                      </Index.Typography>
                      <Index.Typography className="property-details-data-text">
                        {`  ${
                          editProperty?.user_id?.docNumber
                            ? editProperty?.user_id?.docNumber
                            : "---"
                        }`}
                      </Index.Typography>
                    </Index.Box>
                  )}
                  {editProperty?.franchise_amount && (
                    <Index.Box className="property-flex">
                      <Index.Typography className="property-lables">
                        <img
                          src={Index.Png.investAmount}
                          alt="sidebar icon"
                          className="franchises-icons"
                        />
                        Franchise Amount
                      </Index.Typography>
                      <Index.Typography className="property-details-data-text">
                        {`₹ ${editProperty?.franchise_amount?.toLocaleString(
                          "en-IN"
                        )}`}
                      </Index.Typography>
                    </Index.Box>
                  )}
                  <Index.Box className="property-flex">
                    <Index.Typography className="property-lables">
                      <img
                        src={Index.Svg.propertytype}
                        alt="sidebar icon"
                        className="franchises-icons"
                      />{" "}
                      Property Type
                    </Index.Typography>
                    <Index.Typography className="property-details-data-text">
                      {` ${editProperty?.property_type?editProperty?.property_type:'-'}`}
                    </Index.Typography>
                  </Index.Box>

                  {editProperty?.carpet_area && (
                    <Index.Box className="property-flex">
                      <Index.Typography className="property-lables">
                        <img
                          src={Index.Svg.propertyarea}
                          alt="sidebar icon"
                          className="franchises-icons"
                        />{" "}
                        Total Carpet Area
                      </Index.Typography>
                      <Index.Typography className="property-details-data-text">
                        {`  ${editProperty?.carpet_area?editProperty?.carpet_area:"0"} SqFt`}
                      </Index.Typography>
                    </Index.Box>
                  )}
                  {editProperty?.land_type && (
                    <Index.Box className="property-flex">
                      <Index.Typography className="property-lables">
                        <img
                          src={Index.Svg.propertytype}
                          alt="sidebar icon"
                          className="franchises-icons"
                        />{" "}
                        Land Type
                      </Index.Typography>
                      <Index.Typography className="property-details-data-text">
                        {`  ${editProperty?.land_type?editProperty?.land_type:'0'} Ft`}
                      </Index.Typography>
                    </Index.Box>
                  )}
                  {editProperty?.land_connectivity && (
                    <Index.Box className="property-flex">
                      <Index.Typography className="property-lables">
                        <img
                          src={Index.Png.landConnectivity}
                          alt="sidebar icon"
                          className="franchises-icons"
                        />{" "}
                        Land Connectivity
                      </Index.Typography>
                      <Index.Typography className="property-details-data-text">
                        {`  ${editProperty?.land_connectivity?editProperty?.land_connectivity:"-"}`}
                      </Index.Typography>
                    </Index.Box>
                  )}
                  {editProperty?.size_of_the_land && (
                    <Index.Box className="property-flex">
                      <Index.Typography className="property-lables">
                        <img
                          src={Index.Svg.propertyfloor}
                          alt="sidebar icon"
                          className="franchises-icons"
                        />{" "}
                        Size Of The Land
                      </Index.Typography>
                      <Index.Typography className="property-details-data-text">
                        {`  ${editProperty?.size_of_the_land?editProperty?.size_of_the_land:'-'}`}
                      </Index.Typography>
                    </Index.Box>
                  )}
                  {editProperty?.floor_height && (
                    <Index.Box className="property-flex">
                      <Index.Typography className="property-lables">
                        <img
                          src={Index.Svg.propertyfloor}
                          alt="sidebar icon"
                          className="franchises-icons"
                        />{" "}
                        Floor Height
                      </Index.Typography>
                      <Index.Typography className="property-details-data-text">
                        {`  ${editProperty?.floor_height?editProperty?.floor_height:'0'} Ft`}
                      </Index.Typography>
                    </Index.Box>
                  )}
                  {editProperty?.floor_number && (
                    <Index.Box className="property-flex">
                      <Index.Typography className="property-lables">
                        <img
                          src={Index.Svg.propertynumber}
                          alt="sidebar icon"
                          className="franchises-icons"
                        />{" "}
                        Floor Number
                      </Index.Typography>
                      <Index.Typography className="property-details-data-text">
                        {`  ${editProperty?.floor_number?editProperty?.floor_number:'-'}`}
                      </Index.Typography>
                    </Index.Box>
                  )}
                  {editProperty?.project_status && (
                    <Index.Box className="property-flex">
                      <Index.Typography className="property-lables">
                        <img
                          src={Index.Svg.propertystatus}
                          alt="sidebar icon"
                          className="franchises-icons"
                        />{" "}
                        Property Status
                      </Index.Typography>
                      <Index.Typography className="property-details-data-text">
                        {`  ${editProperty?.project_status?editProperty?.project_status:"-"}`}
                      </Index.Typography>
                    </Index.Box>
                  )}
                  <Index.Box className="property-flex">
                    <Index.Typography className="property-lables">
                      <img
                        src={Index.Svg.propertyarea}
                        alt="sidebar icon"
                        className="franchises-icons"
                      />{" "}
                      Options
                    </Index.Typography>
                    <Index.Typography className="property-details-data-text">
                      {`  ${editProperty?.options?editProperty?.options:'-'}`}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
} else {
  navigate("/dashboard");
}
};

export default ViewUserProperty;
