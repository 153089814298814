import React from "react";
// import PageIndex from "../../../PageIndex";

const ViewTransaction = () => {
  // const { editTransactionData } = PageIndex.useSelector((state) => state.admin);
  return (
    <div>
      <h1 style={{ color: "white" }}>View Transaction</h1>
    </div>
  );
};

export default ViewTransaction;
