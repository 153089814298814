const api = {
  ADMIN_LOGIN: "admin/login",
  FORGOT_PASSWORD: "admin/forgot-password",
  Verif_Otp: "admin/verify-otp",
  Change_Password: "admin/reset-password",
  Edit_Profile: "admin/update-admin",
  Get_Sub_Admin: "admin/get-sub-admin",
  Sub_Admin_Create: "admin/sub-admin-create",
  Get_Subadmin_Role: "admin/get-role",
  Create_Cms_Privacy_Policy: "admin/create-cms-privacy-policy",
  Create_Cms_Terms_Condition: "admin/create-cms-terms-and-condition",

  Edit_Profile_Password: "admin/change-password",
  Get_Privay_Policy_Cms: "admin/get-privacy-policy",
  Get_Terms_Condition: "admin/get-term-and-condition",
  Add_Edit_Genre: "admin/add-edit-genre",
  get_Genre_Data: "admin/get-genre",
  delete_Genre_Data: "admin/delete-genre",
  status_Genre_Data: "admin/active-de-active-genre",
  get_Game_Data: "admin/get-game",
  Add_Edit_Game: "admin/add-edit-game",
  delete_Game_Data: "admin/delete-game",
  status_Game_Data: "admin/active-de-active-game",

  // New Apis
  Admin_Login: "admin/login",
  Add_Role: "admin/create-edit-role",
  Get_Role: "admin/get-role",
  Delete_Role: "admin/delete-role",
  Delete_Property: "admin/delete-property",
  Active_InActive_Role: "admin/active-de-active-role",
  General_Setting: "admin/add-edit-generalSetting",
  Get_General_Setting: "admin/get-general-setting",
  Get_AllFranchis_user: "admin/get-franchise-user",
  Active_InActive_FranchiseUser: "admin/active-deactive",
  Update_FranchiseUser: "user/update-user",
  Add_Update_Coupon: "admin/add-voucher",
  Get_Coupon: "get-voucher",
  Get_State: "admin/get-state",
  Delete_Coupon: "admin/delete-voucher",
  Active_InActive_Coupon: "admin/active-deactive-voucher",
  Add_Property: "admin/add-property",
  Update_Property: "admin/update-property",
  Edit_Property: "admin/update-property",
  Edit_Rebranding_Property: "common/update-rebranding-property",
  Get_AllProperty_list: "admin/get-property",
  Get_AdminUserAllProperty_list: "admin/get-all-property",
  Get_Dashboard_data: "admin/dashboard",
  Get_Dashboard_Transaction_data: "admin/get-last-transaction-history",
  Get_Dashboard_Franchise_User_data: "admin/get-last-franchise-user",
  Get_AllFranchiseApplication_List: "admin/get-franchise-list",
  Get_Single_FranchiseApplication_List: "admin/get-singel-franchise-user",
  
  Get_AllTransaction_List: "admin/get-transaction-history",
  Get_All_UserTransaction_List: "admin/get-user-admin-transaction",
  Franchise_Application_status: "admin/status-update",
  Franchise_Application_Property_Verify_status: "admin/update-verify-status",
  Document_Status: "admin/status-document",
  Get_AllRebrandingProperty_list: "admin/get-rebranding",
  Rebranding_Status: "admin/rebranding-status",
  Add_Employee_Role: "admin/create-admin-sub",
  Update_Employee_Role: "admin/update-sub-admin",
  Get_All_Employee_Role_List: "admin/get-sub-admin",
  Active_InActive_Employee_Role: "admin/active-de-active-sub-admin",
  Delete_Employee_Role_Data: "admin/delete-sub-admin",
  Get_AllStaff_Hiring: "/admin/get-all-job-details",
  Get_AllNotification_List: "/admin/get-activity-data",
  Get_Training_Session: "/common/get-training",
  Create_Cms_Training_Session: "/common/add-edit-training",
  Create_Cms_Orientation_Session: "/common/add-Edit-orientation",
  Get_Orientation_Session: "/common/get-orientation",
  Delete_Oreintation_Data: "/common/delete-orientation",

  Get_All_Operational_List: "/common/get-operational-model",
  Add_OPerational_Model: "/common/add-Edit-operational-model",
  Active_InActive_OPerational_Model_Role:
    "/common/operational-model-active-deActive",
  Delete_OPerational_Model_Data: "/common/delete-operational-model",
  //////
  Get_All_Orientation_List: "/common/get-orientation",
  Add_Orientation_Model: "/common/add-Edit-orientation",
  Active_InActive_Orientation_Model_Role: "/common/operational-active-deActive",
  Delete_Orientation_Model_Data: "/common/delete-orientation",
  //////
  Get_All_Training_List: "/common/get-training",
  Add_Training_Model: "/common/add-edit-training",
  Active_InActive_Training_Model_Role: "/common/training-active-deActive",
  Delete_Training_Model_Data: "/common/delete-training",
  Coupon_User_List: "/admin/coupon-used-by-user",

  Get_Logout_Sub_Admin: "/admin/get-singel-admin",
  Get_Payment_Terms_Condition: "common/get-payment-terms",
  Create_Payment_Terms_Condition: "admin/create-payment-terms",
  Add_Update_State: "admin/add-edit-state",

  Delete_Rebranding_Property: "admin/delete-rebranding",
  Delete_Notification: "admin/delete-activity",
  Read_Notification: "admin/read-activity",
  Get_Role_permission:'admin/get-role-by-id'
};
export { api };
