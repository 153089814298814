import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Index from "../../../Index";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import PageIndex from "../../../PageIndex";
import { imageUrl } from "../../../../config/dataService";
import "./Property.css";

const ViewProperty = () => {
  const navigate = PageIndex.useNavigate();
  const { editProperty,adminData } = PageIndex.useSelector((state) => state.admin);
  const [imageSizes, setImageSizes] = useState({});

  const handleImageLoad = (index, width, height) => {
    setImageSizes((prevSizes) => ({
      ...prevSizes,
      [index]: { width, height },
    }));
  };

  const city = editProperty?.project_address?.city;
  const state = editProperty?.project_address?.state;
  const pincode = editProperty?.project_address?.pincode;
                      
  let address = "";

  if (city) {
    address += city;
  }

  if (state) {
    address += address ? `, ${state}` : state;
  }

  if (pincode) {
    address += address ? `, ${pincode}` : pincode;
  }
  if (
    (adminData && adminData?.role?.permission?.includes("Property_view")) ||
    (adminData && adminData?.isAdmin === true)
  ) {
  return (
    <>
      <Index.Box>
        <Index.Box className="admin-page-title-main-viewcomponent">
          <Index.Typography
            className="admin-page-title-viewcomponent"
            // component="h4"
            // variant="h4"
          >
            View Property Details
          </Index.Typography>
        </Index.Box>
        <Index.Box className="view-card">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 2, lg: 3 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column"
              >
                <Index.Box className="view-swiper-main">
                  <Swiper
                    spaceBetween={20}
                    pagination={{
                      clickable: true,
                    }}
                    breakpoints={{
                      280: {
                        slidesPerView: 1,
                      },
                      375: {
                        slidesPerView: 1,
                      },
                      640: {
                        slidesPerView: 1,
                      },
                      768: {
                        slidesPerView: 1,
                      },
                      1024: {
                        slidesPerView: 1,
                      },
                      1366: {
                        slidesPerView: 1,
                      },
                      1500: {
                        slidesPerView: 1,
                      },
                    }}
                    modules={[Pagination, Navigation]}
                    className="new-game-swiper mySwiper"
                  >
                    {editProperty?.images.length === 0 ? (
                      <SwiperSlide className="new-game-swiper-slide">
                        <Index.Box className="new-game-flex-li">
                          <Index.Box className="game-card"></Index.Box>
                          <Index.Box className="new-game-card">
                            <img
                              src={Index.Png.staticLogoImage}
                              className="static-use-image"
                              alt="logo"
                            />
                          </Index.Box>
                        </Index.Box>
                      </SwiperSlide>
                    ) : (
                      editProperty?.images?.map((element, index) => {
                        const isVideo =
                          element.endsWith(".mp4") || element.endsWith(".avi"); // Check if the element is a video file
                        const mediaUrl = isVideo
                          ? `${imageUrl}/${element}`
                          : `${imageUrl}/${element}`; // Set the correct URL based on the file type

                        const imageSize = imageSizes[index];
                        const imageStyle = imageSize
                          ? {
                              // maxWidth: "100%",
                              // maxHeight: "100%",
                            }
                          : {};

                        return (
                          <SwiperSlide
                            className="new-game-swiper-slide"
                            key={index}
                          >
                            <Index.Box className="new-game-flex-li">
                              <Index.Box className="new-game-card">
                                {isVideo ? (
                                  <video className="new-game-img" controls>
                                    <source src={mediaUrl} type="video/mp4" />
                                    Your browser does not support the video tag.
                                  </video>
                                ) : (
                                  <img
                                    alt="img"
                                    src={mediaUrl}
                                    className="new-game-img"
                                    onLoad={(e) =>
                                      handleImageLoad(
                                        index,
                                        e.target.naturalWidth,
                                        e.target.naturalHeight
                                      )
                                    }
                                    style={imageStyle}
                                  />
                                )}
                              </Index.Box>
                            </Index.Box>
                          </SwiperSlide>
                        );
                      })
                    )}
                  </Swiper>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column"
              >
                <Index.Box className="view-franchises-details-box view-propert-box">
                  <Index.Typography
                    variant="h4"
                    className="proerty-details-titles"
                  >
                    {editProperty?.property_type === "Land"
                      ? "Land"
                      : editProperty?.property_name}
                  </Index.Typography>

                  <Index.Box className="property-flex">
                    <Index.Typography className="property-lables">
                      {editProperty?.property_type === "Land" ? (
                        ""
                      ) : (
                        <>
                          <img
                            src={Index.Svg.propertylocation}
                            alt="sidebar icon"
                            className="franchises-icons"
                          />
                          {address?address:"-"}
                          {/* {`${editProperty?.project_address?.city},${editProperty?.project_address?.state},${editProperty?.project_address?.pincode}`} */}
                        </>
                      )}
                    </Index.Typography>
                  </Index.Box>

                  <Index.Box className="property-detail-box view-user-property-box">
                    <Index.Box className="property-flex">
                      <Index.Typography className="property-lables">
                        <img
                          src={Index.Svg.propertytype}
                          alt="sidebar icon"
                          className="franchises-icons"
                        />
                        Property Code
                      </Index.Typography>
                      <Index.Typography className="property-details-data-text">
                        {` ${
                          editProperty?.propertyCode
                            ? editProperty?.propertyCode
                            : "-"
                        }`}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="property-flex">
                      <Index.Typography className="property-lables">
                        <img
                          src={Index.Svg.propertytype}
                          alt="sidebar icon"
                          className="franchises-icons"
                        />
                        Property Type
                      </Index.Typography>
                      <Index.Typography className="property-details-data-text">
                        {` ${
                          editProperty?.property_type
                            ? editProperty?.property_type
                            : "-"
                        }`}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="property-flex">
                      <Index.Typography className="property-lables">
                        <img
                          src={Index.Png.investAmount}
                          alt="sidebar icon"
                          className="franchises-icons"
                        />
                        Franchise Amount
                      </Index.Typography>
                      <Index.Typography className="property-details-data-text">
                        {`${
                          editProperty?.franchise_amount
                            ? `₹ ${editProperty?.franchise_amount?.toLocaleString(
                                "en-IN"
                              )}`
                            : "-"
                        }`}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="property-flex">
                      <Index.Typography className="property-lables">
                        <img
                          src={Index.Svg.propertyarea}
                          alt="sidebar icon"
                          className="franchises-icons"
                        />
                        {editProperty?.property_type === "Land"
                          ? "Size Of Land"
                          : "Total Carpet Area"}
                      </Index.Typography>
                      <Index.Typography className="property-details-data-text">
                        {editProperty?.property_type === "Land"
                          ? editProperty?.size_of_the_land
                          : `${
                              editProperty?.carpet_area
                                ? editProperty?.carpet_area
                                : "0"
                            } SqFt`}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="property-flex">
                      <Index.Typography className="property-lables">
                        <img
                          src={Index.Svg.propertyfloor}
                          alt="sidebar icon"
                          className="franchises-icons"
                        />
                        {editProperty?.property_type === "Land"
                          ? "Land Type"
                          : "Floor Height"}
                      </Index.Typography>
                      <Index.Typography className="property-details-data-text">
                        {editProperty?.property_type === "Land"
                          ? editProperty?.land_type
                          : ` ${
                              editProperty?.floor_height
                                ? editProperty?.floor_height
                                : "0"
                            } Ft`}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="property-flex">
                      <Index.Typography className="property-lables">
                        {editProperty?.property_type === "Land" ? (
                          <img
                            src={Index.Png.landConnectivity}
                            // src={Index.Svg.propertynumber}
                            alt="sidebar icon"
                            className="franchises-icons"
                          />
                        ) : (
                          <img
                            src={Index.Svg.propertynumber}
                            // src={Index.Svg.propertynumber}
                            alt="sidebar icon"
                            className="franchises-icons"
                          />
                        )}

                        {editProperty?.property_type === "Land"
                          ? "Land Connectivity"
                          : "Floor Number"}
                      </Index.Typography>
                      <Index.Typography className="property-details-data-text">
                        {editProperty?.property_type === "Land"
                          ? editProperty?.land_connectivity
                          : ` ${
                              editProperty?.floor_number
                                ? editProperty?.floor_number
                                : "-"
                            }`}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="property-flex">
                      <Index.Typography className="property-lables">
                        {editProperty?.property_type === "Land" ? (
                          ""
                        ) : (
                          <>
                            <img
                              src={Index.Png.propertyStatus}
                              alt="sidebar icon"
                              className="franchises-icons"
                            />
                            Property Status
                          </>
                        )}
                      </Index.Typography>
                      <Index.Typography className="property-details-data-text">
                        {editProperty?.property_type === "Land"
                          ? ""
                          : ` ${
                              editProperty?.project_status
                                ? editProperty?.project_status
                                : "-"
                            }`}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
} else {
  navigate("/dashboard");
}
};

export default ViewProperty;
