import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useState } from "react";
// import Index from "../../Index";
import { AdminForgotPasswordSchema } from "../../../validation/Validation";
import { AdminForgotPassword } from "../../../redux/slices/AdminService";
import Index from "../../../component/Index";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const SendEmail = (values) => {
    setLoading(true);
    dispatch(AdminForgotPassword(values)).then((val) => {
      setLoading(false);
      if (val?.payload?.status === 201) {
        const ResetId = val.payload.data._id;
        const ResetOtp = val.payload.data.otp;
        navigate("/otp", { state: { ResetId, ResetOtp } });
      }
    });
  };
  return (
    <Index.LoadingOverlay
      spinner
      text="Loading your content..."
      active={loading}
    >
      <Index.Box className="admin-login-main-flex">
        <Index.Box className="admin-login-left-main">
          <Index.Box className="login-left-bg">
            {/* <Index.Box className="auth-logo-box">
              <img
                alt="logo_img"
                src={Index.Png.headerlogo}
                className="auth-logo"
              />
            </Index.Box> */}
          </Index.Box>
        </Index.Box>
        <Index.Box className="admin-login-right-main">
          <Index.Box className="admin-login-box">
            <Index.Box className="admin-login-main">
              <Formik
                validationSchema={AdminForgotPasswordSchema}
                initialValues={{
                  email: "",
                }}
                onSubmit={SendEmail}
              >
                {({
                  values,
                  errors,
                  handleChange,
                  handleBlur,
                  touched,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Index.Box className="admin-login-inner">
                      <Index.Typography
                        component="h2"
                        variant="h2"
                        className="admin-wel-text"
                      >
                        Forgot Password
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="admin-sign-para common-para"
                      ></Index.Typography>
                      <Index.Box className="input-box">
                        <Index.FormHelperText className="form-lable">
                          Email
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            onBlur={handleBlur}
                            value={values.email}
                            onChange={handleChange}
                            // helperText={errors.email}
                            error={Boolean(errors?.email)}
                            fullWidth
                            name="email"
                            id="fullWidth"
                            className="form-control"
                            type="text"
                            autocomplete="off"
                            placeholder="Enter Your Email"
                          />
                          {errors?.email && touched?.email ? (
                            <Index.Typography className="error-msg">
                              {errors?.email}
                            </Index.Typography>
                          ) : null}
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="btn-main-primary login-btn-main">
                        <Index.Button
                          className="btn-primary login-btn"
                          type="submit"
                        >
                          Send
                        </Index.Button>
                      </Index.Box>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="admin-sign-para common-para"
                        onClick={() => {
                          navigate("/");
                        }}
                      >
                        Back to <span className="admin-sign-para">Login ?</span>
                      </Index.Typography>
                    </Index.Box>
                  </form>
                )}
              </Formik>
            </Index.Box>
          </Index.Box>
          <Index.Box className="login-footer-main-data">
            <Index.Typography
              component="p"
              variant="p"
              className=" common-para login-tagline"
            >
              India Ka Apna Smart Theatre
            </Index.Typography>
            <Index.Typography
              component="p"
              variant="p"
              className=" common-para login-tagline-footer "
            >
              Powered By VCS Industeries Ltd
            </Index.Typography>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.LoadingOverlay>
  );
};
export default ForgotPassword;
