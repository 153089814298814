import React from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { imageUrl } from "../../../../config/dataService";
import Index from "../../../Index";

function FileModal({ open, onClose, fileUrl }) {
  const docs = [
    {
      uri: `${imageUrl}/${fileUrl}`,
    },
  ];
  return (
    <Index.Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <Index.DialogTitle>File Preview</Index.DialogTitle>
      <Index.DialogContent>
        <DocViewer documents={docs} pluginRenderers={DocViewerRenderers} />
      </Index.DialogContent>
    </Index.Dialog>
  );
}

export default FileModal;
