import React, { useState } from "react";
import { Form, Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import OTPInput from "react-otp-input";
import { AdminSendOtpSchema } from "../../../validation/Validation";
import { verfiyOtp } from "../../../redux/slices/AdminService";
import Index from "../../../component/Index";

export default function Otp() {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const AdminVerfiyOtp = (val) => {
    const data = {
      id: state.ResetId,
      otp: val.otp,
    };

    
    setLoading(true);
    dispatch(verfiyOtp(data))
      .then((val) => {
        setLoading(false);
        if (val?.payload?.status === 200) {
          toast.success(val.payload.message);
          navigate("/reset-password", { state: { state } });
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };
  return (
    <Index.LoadingOverlay
      spinner
      text="Loading your content..."
      active={loading}
    >
      <div>
        <Index.Box className="admin-login-main-flex">
          <Index.Box className="admin-login-left-main">
            <Index.Box className="login-left-bg">
              {/* <Index.Box className="auth-logo-box">
                <img
                  alt="logo_img"
                  src={Index.Png.headerlogo}
                  className="auth-logo"
                />
              </Index.Box> */}
            </Index.Box>
          </Index.Box>

          <Index.Box className="admin-login-right-main">
            <Index.Box className="admin-login-box">
              <Index.Box className="admin-login-main">
                <Index.Box className="admin-login-inner">
                  <Index.Typography
                    component="h2"
                    variant="h2"
                    className="admin-wel-text"
                  >
                    Otp!
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="admin-sign-para common-para"
                  >
                    Please enter your credentials to Otp!
                  </Index.Typography>
                  <Formik
                    validationSchema={AdminSendOtpSchema}
                    initialValues={{
                      otp: "",
                    }}
                    onSubmit={AdminVerfiyOtp}
                  >
                    {({
                      values,
                      errors,
                      handleChange,
                      handleBlur,
                      touched,
                      setFieldValue,
                    }) => (
                      <Form>
                        <Index.Box className="otp-box-main">
                          <OTPInput
                            value={values.otp}
                            onChange={(e) => setFieldValue("otp", e)}
                            onBlur={handleBlur}
                            numInputs={4}
                            inputType="number"
                            shouldAutoFocus={true}
                            className="otp-box"
                            error={Boolean(errors.otp)}
                            renderInput={(props) => (
                              <input
                                {...props}
                                className="mui-otp"
                                placeholder="0"
                              />
                            )}
                          />
                          {errors?.otp && touched?.otp ? (
                            <Index.Typography className="error-msg">
                              {errors?.otp}
                            </Index.Typography>
                          ) : null}
                        </Index.Box>
                        <Index.Box className="btn-main-primary varify-btn-main">
                          <Index.Button
                            className="btn-primary verify-btn "
                            type="submit"
                          >
                            Verify
                          </Index.Button>
                          <Index.Typography
                            component="p"
                            variant="p"
                            align="center"
                            className="admin-sign-para common-para admin-verify-btn"
                            onClick={() => {
                              navigate("/");
                            }}
                          >
                            Go to Login page ?
                          </Index.Typography>
                        </Index.Box>
                      </Form>
                    )}
                  </Formik>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="login-footer-main-data">
              <Index.Typography
                component="p"
                variant="p"
                className=" common-para login-tagline"
              >
                India Ka Apna Smart Theatre
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className=" common-para login-tagline-footer "
              >
                Powered By VCS Industeries Ltd
              </Index.Typography>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </div>
    </Index.LoadingOverlay>
  );
}