// CommonModal.js
import React from 'react';
import Index from '../Index';
// import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';

function CommonModal({ open, onClose, children }) {
  return (
    <Index.Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      {children}
    </Index.Dialog>
  );
}

export default CommonModal;
