import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Index from "../../../Index";
import { useEffect } from "react";
import PageIndex from "../../../PageIndex";
import {
  getAllDashboardData,
  getAllFranchiseUserDashboardData,
  getAllTransactionDashboardData,
} from "../../../../redux/slices/AdminService";
import { editFranchise } from "../../../../redux/slices/AdminSlice";

// for progress bar design

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

// for table design

function createData(name, calories, fat, carbs) {
  return { name, calories, fat, carbs };
}

const rows = [
  createData("Valentina", "Israelchester", "33348", "12/24/2022"),
  createData("Valentina1", "Israelchester", "33348", "12/24/2022"),
  createData("Valentina2", "Israelchester", "33348", "12/24/2022"),
  createData("Valentina3", "Israelchester", "33348", "12/24/2022"),
  createData("Valentina4", "Israelchester", "33348", "12/24/2022"),
  createData("Valentina5", "Israelchester", "33348", "12/24/2022"),
  createData("Valentina6", "Israelchester", "33348", "12/24/2022"),
];

export default function Home() {
  const navigate = PageIndex.useNavigate();
  const {
    dashboardData,
    dashboardFranchiseUserData,
    dashboardTransactionData,
  } = PageIndex.useSelector((state) => state.admin);

  const dispatch = PageIndex.useDispatch();
  const dashboardCardData = () => {
    dispatch(getAllDashboardData())
      .then((val) => {
        if (val?.payload?.status === 200) {
          // dispatch(getAllDashboardData());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const dashboardTransaction = () => {
    dispatch(getAllTransactionDashboardData())
      .then((val) => {
        if (val?.payload?.status === 200) {
          // dispatch(getAllDashboardData());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const dashboardFranchiseData = () => {
    dispatch(getAllFranchiseUserDashboardData())
      .then((val) => {
        if (val?.payload?.status === 200) {
          // dispatch(getAllDashboardData());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    dashboardCardData();
    dashboardFranchiseData();
    dashboardTransaction();
  }, []);

  return (
    <>
      <Index.Box className="dashboard-content">
        <Index.Box className="admin-dashboad-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row-dashboard"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 3",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dashboard-box">
                  <Index.Box
                    className="admin-dashboard-inner-box"
                    onClick={() => {
                      navigate("franchise-user-list");
                    }}
                  >
                    <Index.Box className="admin-dash-left">
                      <Index.Typography
                        className="admin-dash-text"
                        variant="p"
                        component="p"
                      >
                        Total Franchise User
                      </Index.Typography>
                      <Index.Typography
                        className="admin-dash-price"
                        variant="h1"
                        component="h1"
                      >
                        {dashboardData?.userCount
                          ? dashboardData?.userCount?.toLocaleString("en-IN")
                          : "0"}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="admin-dash-right">
                      <img
                        src={Index.Svg.userDashIcon}
                        className="dash-icons"
                        alt="dashboard icon"
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 3",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dashboard-box">
                  <Index.Box
                    className="admin-dashboard-inner-box"
                    onClick={() => {
                      navigate("franchise-application");
                    }}
                  >
                    <Index.Box className="admin-dash-left">
                      <Index.Typography
                        className="admin-dash-text"
                        variant="p"
                        component="p"
                      >
                        Total Franchises
                      </Index.Typography>
                      <Index.Typography
                        className="admin-dash-price"
                        variant="h1"
                        component="h1"
                      >
                        {dashboardData?.franchiseCount
                          ? dashboardData?.franchiseCount?.toLocaleString(
                              "en-IN"
                            )
                          : "0"}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="admin-dash-right">
                      <img
                        src={Index.Svg.franchiseUserDashIcon}
                        className="dash-icons"
                        alt="dashboard icon"
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 3",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dashboard-box">
                  <Index.Box
                    className="admin-dashboard-inner-box"
                    onClick={() => {
                      navigate("property-list");
                    }}
                  >
                    <Index.Box className="admin-dash-left">
                      <Index.Typography
                        className="admin-dash-text"
                        variant="p"
                        component="p"
                      >
                        Total Property
                      </Index.Typography>
                      <Index.Typography
                        className="admin-dash-price"
                        variant="h1"
                        component="h1"
                      >
                        {dashboardData?.totalPropertyCount
                          ? dashboardData?.totalPropertyCount
                          : "0"}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="admin-dash-right">
                      <img
                        src={Index.Svg.totalPropertyDashIcon?.toLocaleString(
                          "en-IN"
                        )}
                        className="dash-icons"
                        alt="dashboard icon"
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 3",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dashboard-box">
                  <Index.Box
                    className="admin-dashboard-inner-box"
                    onClick={() => {
                      navigate("user-property-list");
                    }}
                  >
                    <Index.Box className="admin-dash-left">
                      <Index.Typography
                        className="admin-dash-text"
                        variant="p"
                        component="p"
                      >
                        Request Property
                      </Index.Typography>
                      <Index.Typography
                        className="admin-dash-price"
                        variant="h1"
                        component="h1"
                      >
                        {dashboardData?.requestPropertyCount
                          ? dashboardData?.requestPropertyCount?.toLocaleString(
                              "en-IN"
                            )
                          : "0"}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="admin-dash-right">
                      <img
                        src={Index.Svg.requestedPropertyDashIcon}
                        className="dash-icons"
                        alt="dashboard icon"
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row-dashboard"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 6",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dash-box dashboard-main-box">
                  <Index.Box className="flex-all admin-dashboard-table-row">
                    <Index.Box className="admin-sub-title-main">
                      <Index.Typography
                        className="admin-sub-title"
                        component="h3"
                        variant="h3"
                      >
                        Recent Registered Franchise Users
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="page-table-main dashboard-table-main">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      <Index.Table aria-label="simple table" className="table">
                        <Index.TableHead className="table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Mobile
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Name
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Email
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              City
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                          {dashboardFranchiseUserData?.length ? (
                            dashboardFranchiseUserData &&
                            dashboardFranchiseUserData?.map((row) => (
                              <Index.TableRow
                                key={row?._id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td anchor-tag"
                                  onClick={() => {
                                    dispatch(editFranchise(row));
                                    navigate("/dashboard/view-franchise-user", {
                                      state: row,
                                    });
                                  }}
                                >
                                  <span class="blue-color">
                                    {row?.mobile ? row?.mobile : "-"}
                                  </span>
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                >
                                  {!row?.name ? "---" : row?.name}
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  {!row?.email ? "---" : row?.email}
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  {!row?.city ? "---" : row?.city}
                                </Index.TableCell>
                              </Index.TableRow>
                            ))
                          ) : (
                            <Index.TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td no-record-text"
                                align="center"
                                colSpan={2}
                              >
                                No records found
                              </Index.TableCell>
                            </Index.TableRow>
                          )}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 6",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dash-box dashboard-main-box">
                  <Index.Box className="flex-all admin-dashboard-table-row">
                    <Index.Box className="admin-sub-title-main">
                      <Index.Typography
                        className="admin-sub-title"
                        component="h3"
                        variant="h3"
                      >
                        Transaction Details
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="page-table-main dashboard-table-main">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      <Index.Table aria-label="simple table" className="table">
                        <Index.TableHead className="table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Transaction Id
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              User Name
                            </Index.TableCell>
                            {/* <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Property Name
                            </Index.TableCell> */}
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Transaction Date
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Amount
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                          {dashboardTransactionData?.length ? (
                            dashboardTransactionData &&
                            dashboardTransactionData?.map((row) => (
                              <Index.TableRow
                                key={row?.name}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                >
                                  {row?.transaction_id
                                    ? row?.transaction_id
                                    : "-"}
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                >
                                  {row?.user_id === null
                                    ? "----"
                                    : row?.user_id?.name}
                                </Index.TableCell>

                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  {new Date(row.createdAt).toLocaleDateString(
                                    "en-GB"
                                  ) +
                                    " " +
                                    new Date(row.createdAt).toLocaleTimeString(
                                      "en-US",
                                      {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        hour12: true,
                                      }
                                    )}
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  {/* {row?.franchise_amount
                                    ? row?.franchise_amount
                                    : row?.pay_amount} */}
                                  {row?.pay_amount
                                    ? `₹ ${row?.pay_amount?.toLocaleString(
                                        "en-IN"
                                      )}`
                                    : "----"}
                                </Index.TableCell>
                              </Index.TableRow>
                            ))
                          ) : (
                            <Index.TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td no-record-text"
                                align="center"
                                colSpan={2}
                              >
                                No records found
                              </Index.TableCell>
                            </Index.TableRow>
                          )}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
