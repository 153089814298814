import * as React from "react";
import Index from "../../../Index";
import "./CouponStyle.css";
import PageIndex from "../../../PageIndex";
import { useState } from "react";
import { useEffect } from "react";
import { couponUserList } from "../../../../redux/slices/AdminService";

const convertTo12HourFormat = (timeString) => {
  const [hours, minutes] = timeString.split(":");
  let period = "AM";

  let hour = parseInt(hours);
  if (hour === 0) {
    hour = 12;
  } else if (hour >= 12) {
    period = "PM";
    if (hour > 12) {
      hour -= 12;
    }
  }

  return `${hour}:${minutes} ${period}`;
};

const ViewCoupon = () => {
  const dispatch = PageIndex.useDispatch();
  const navigate = PageIndex.useNavigate();
  const { editCoupon, adminData, couponByUser } = PageIndex.useSelector(
    (state) => state.admin
  );
  const [search, setSearch] = useState("");
  const [pageData, setPageData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    setLoading(true); // Set loading state to true before fetching data
    setTimeout(() => {
      // Simulating a delay of 1 seconds
      dispatch(couponUserList(editCoupon?._id))
        .then((val) => {
          if (val?.payload?.status === 200) {
            dispatch(couponUserList(editCoupon?._id));
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }, 1000);
  }, [editCoupon]);

  useEffect(() => {
    const filtered = couponByUser
      ?.filter((item) => {
        return !search
          ? item
          : item?.name?.toLowerCase()?.includes(search?.toLowerCase()) ||
              item?.email?.toLowerCase()?.includes(search?.toLowerCase()) ||
              item?.city?.toLowerCase()?.includes(search?.toLowerCase()) ||
              item?.mobile?.toString()?.includes(search?.toString());
      })
      ?.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateB - dateA;
      });
    if (filtered) {
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  }, [couponByUser, search]);
  if (
    (adminData && adminData?.role?.permission?.includes("Coupon_view")) ||
    (adminData && adminData?.isAdmin === true)
  ) {
    return (
      <div>
        <Index.Card variant="outlined" className="coupon-box">
          <Index.Box className="coupon-box-inner-box">
            <Index.Box className="discount-coupon-wrap">
              <Index.Box
                component="img"
                className="discount-coupon-img"
                src={Index.Svg.couponImg}
              />
              <Index.Box className="discount-coupon-content">
                <Index.Typography variant="h6">value</Index.Typography>
                <Index.Typography variant="h5">
                  {editCoupon?.discount_type === "flat"
                    ? `₹ ${editCoupon?.discount_amount}`
                    : `${editCoupon?.discount_amount}%`}
                </Index.Typography>
              </Index.Box>
            </Index.Box>

            <Index.Box className="coupon-box-content">
              <Index.CardContent>
                <Index.Typography className="coupon-title">
                  {editCoupon?.title}
                </Index.Typography>
                <Index.Typography className="coupon-code">
                  {editCoupon?.coupon_code}
                </Index.Typography>
                <Index.Typography className="coupon-date-start">
                  {`  Valid From
        ${
          editCoupon.start_date
            ? new Date(editCoupon.start_date).toLocaleDateString("en-GB") +
              " " +
              convertTo12HourFormat(editCoupon.start_time) // Convert to 12-hour format
            : ""
        }`}
                </Index.Typography>
                <Index.Typography className="coupon-date-end">
                  {`To ${
                    editCoupon.end_date
                      ? new Date(editCoupon.end_date).toLocaleDateString(
                          "en-GB"
                        ) +
                        " " +
                        convertTo12HourFormat(editCoupon.end_time) // Convert to 12-hour format
                      : ""
                  }`}
                </Index.Typography>
                <Index.Typography className="coupon-description">
                  {editCoupon?.description}
                </Index.Typography>
              </Index.CardContent>
            </Index.Box>
          </Index.Box>
        </Index.Card>

        <Index.Box className="dashboard-content coupon-table-dashboard">
          <Index.Box className="user-list-flex">
            <Index.Box className="admin-page-title-main">
              <Index.Typography
                className="admin-page-title"
                component="h2"
                variant="h2"
              >
                Coupon User List
              </Index.Typography>
            </Index.Box>
            <Index.Box className="userlist-btn-flex">
              <Index.Box className="user-search-main">
                <Index.Box className="user-search-box">
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      placeholder="Search coupon"
                      value={search}
                      onChange={(e) => {
                        const inputValue = e.target.value
                          .replace(/^\s+/, "")
                          .replace(/\s\s+/g, " ");
                        setSearch(inputValue);
                      }}
                    />
                    <span className="search-icon-box">
                      <img
                        alt="search_icon"
                        src={Index.Svg.search}
                        className="search-icon"
                      />
                    </span>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="admin-dashboard-list-row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row-userlist"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dash-box">
                    <Index.Box className="couponUserList-table-main page-table-main">
                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                      >
                        <Index.Table
                          sx={{ minWidth: 650 }}
                          aria-label="sticky table"
                          className="table"
                        >
                          <Index.TableHead className="table-head sticky">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                No.
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                User Name
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                User Mobile
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                User Email
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                City
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Pincode
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Date/Time
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {pageData?.length ? (
                              pageData?.map((row, ind) => (
                                <Index.TableRow
                                  key={row._id}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td"
                                    align="center"
                                  >
                                    {row.sNo ? row.sNo : "-"}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td"
                                    align="center"
                                  >
                                    {row?.name ? row?.name : "-"}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td"
                                    align="center"
                                  >
                                    {row?.mobile ? row?.mobile : "-"}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                    align="center"
                                  >
                                    {row?.email ? row?.email : "-"}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                    align="center"
                                  >
                                    {row?.city ? row?.city : "-"}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                    align="center"
                                  >
                                    {row?.pincode ? row?.pincode : "-"}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                    align="center"
                                  >
                                    {new Date(row.updatedAt).toLocaleDateString(
                                      "en-GB"
                                    ) +
                                      " " +
                                      new Date(
                                        row.updatedAt
                                      ).toLocaleTimeString("en-US", {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        hour12: true,
                                      })}
                                  </Index.TableCell>
                                </Index.TableRow>
                              ))
                            ) : (
                              <Index.TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td no-record-text"
                                  align="center"
                                  colSpan={7}
                                >
                                  No records found
                                </Index.TableCell>
                              </Index.TableRow>
                            )}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="pagination-main">
            <PageIndex.Pagination
              fetchData={filteredData}
              setPageData={setPageData}
              pageData={pageData}
              search={search}
            />
          </Index.Box>
        </Index.Box>
      </div>
    );
  } else {
    navigate("/dashboard");
  }
};

export default ViewCoupon;
