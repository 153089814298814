import { createAsyncThunk } from "@reduxjs/toolkit";
import dataService from "../../config/dataService";
import { api } from "../../config/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const loginAdmin = createAsyncThunk("admin/loginAdmin", async (data) => {
  // const response = await dataService.post(api.ADMIN_LOGIN, data);

  // return response.data;
  try {
    const response = await dataService.post(api.ADMIN_LOGIN, data);
    toast.success(response?.data?.message);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
});

export const AdminForgotPassword = createAsyncThunk(
  "admin/AdminForgotPassword",
  async (data) => {
    try {
      const response = await dataService.post(api.FORGOT_PASSWORD, data);
      toast.success(response?.data?.message);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const verfiyOtp = createAsyncThunk("admin/verfiyOtp", async (data) => {
  try {
    const response = await dataService.post(api.Verif_Otp, data);
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message);
  }
});

export const resetPassword = createAsyncThunk(
  "admin/changePassword",
  async (data) => {
    try {
      const response = await dataService.post(api.Change_Password, data);
      toast.success(response?.data?.message || "Resend OTP");
      return response?.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const editProfileUpdate = createAsyncThunk(
  "admin/editProfileUpdate",
  async (data) => {
    const response = await dataService.post(api.Edit_Profile, data);
    toast.success("Profile updated");
    return response.data;
  }
);

export const changePasswordProfile = createAsyncThunk(
  "admin/changePasswordProfile",
  async (data) => {
    try {
      const response = await dataService.post(api.Edit_Profile_Password, data);
      toast.success(response?.data?.message || "Something went wrong");
      return response?.data?.status;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const getsubadmin = createAsyncThunk(
  "admin/getsubadmin",
  async (data) => {
    const response = await dataService.get(api.Get_Sub_Admin);
    return response.data;
  }
);

export const subAdminCreate = createAsyncThunk(
  "admin/subAdminCreate",
  async (data) => {
    try {
      const response = await dataService.post(api.Sub_Admin_Create, data);
      toast.success(response?.data?.message || "Admin create");
      return response?.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const getAdminRole = createAsyncThunk(
  "admin/getAdminRole",
  async (data) => {
    const response = await dataService.get(api.Get_Subadmin_Role, data);
    return response.data;
  }
);

export const createPrivacyPolicyCms = createAsyncThunk(
  "admin/createPrivacyPolicyCms",
  async (data) => {
    try {
      const response = await dataService.post(
        api.Create_Cms_Privacy_Policy,
        data
      );
      if (response.data.status === 201) {
        toast.success(response.data.message);
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const createTermsConditionCms = createAsyncThunk(
  "admin/createTermsConditionCms",
  async (data) => {
    try {
      const response = await dataService.post(
        api.Create_Cms_Terms_Condition,
        data
      );
      if (response.data.status === 201) {
        toast.success(response.data.message);
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const getPrivacyPolicyCms = createAsyncThunk(
  "admin/getPrivacyPolicyCms",
  async (data) => {
    try {
      const response = await dataService.get(api.Get_Privay_Policy_Cms);
      if (response.data.status === 201) {
        toast.success(response.data.message);
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const getTermsConditionCms = createAsyncThunk(
  "admin/getTermsConditionCms",
  async () => {
    try {
      const response = await dataService.get(api.Get_Terms_Condition);
      if (response?.data?.status === 201) {
        toast.success(response?.data?.message);
      }
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const getGameList = createAsyncThunk(
  "admin/getGameList",
  async (data) => {
    try {
      const response = await dataService.get(api.get_Game_Data);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

//add-edit game

export const addEditGame = createAsyncThunk(
  "admin/addEditGame",
  async (data) => {
    try {
      const response = await dataService.post(api.Add_Edit_Game, data);
      toast.success(response?.data?.message || "Game create");
      return response?.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const deleteGameData = createAsyncThunk(
  "admin/deleteGameData",
  async (data) => {
    try {
      const response = await dataService.post(api.delete_Game_Data, data);
      toast.success(response?.data?.message || "delele game");
      return response?.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

//statusGenreData
export const statusGameData = createAsyncThunk(
  "admin/statusGameData",
  async (data) => {
    try {
      const response = await dataService.post(api.status_Game_Data, data);
      toast.success(response?.data?.message || "data updated.");
      return response?.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

////////////////////////////////////////////////////////////////////////////////////
//add-edit genre

export const addEditGenre = createAsyncThunk(
  "admin/addEditGenre",
  async (data) => {
    try {
      const response = await dataService.post(api.Add_Edit_Genre, data);
      toast.success(response?.data?.message || "Genre create");
      return response?.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const getAllGenreData = createAsyncThunk(
  "admin/getAllGenreData",
  async () => {
    try {
      const response = await dataService.get(api.get_Genre_Data);
      return response?.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

//delete genre data
export const deleteGenreData = createAsyncThunk(
  "admin/deleteGenreData",
  async (id) => {
    try {
      const response = await dataService.post(api.delete_Genre_Data, {
        id: id,
      });
      toast.success(response?.data?.message || "delele genre data");
      return response?.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

//Status genre data
export const statusGenreData = createAsyncThunk(
  "admin/statusGenreData",
  async (data) => {
    try {
      const response = await dataService.post(api.status_Genre_Data, data);
      toast.success(response?.data?.message || "data updated.");
      return response?.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

////////////////////////////////////////////////////////////////////////////////

// Add Edit Role
export const addEditRole = createAsyncThunk(
  "admin/createEditRole",
  async (data) => {
    try {
      const response = await dataService.post(api.Add_Role, data);
      toast.success(response?.data?.message || "Role create");
      return response?.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const getAllRoleData = createAsyncThunk(
  "admin/getAllRoleData",
  async () => {
    try {
      const response = await dataService.get(api.Get_Role);
      return response?.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const deleteRoleData = createAsyncThunk(
  "admin/deleteRoleData",
  async (id) => {
    try {
      const response = await dataService.post(api.Delete_Role, {
        id: id,
      });
      toast.success(response?.data?.message || "delele Role data");
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const statusRoleData = createAsyncThunk(
  "admin/statusRoleData",
  async (data) => {
    try {
      const response = await dataService.post(api.Active_InActive_Role, data);
      if (response?.data?.status === 200) {
        toast.success(response?.data?.message || "Role Active");
        return response?.data;
      } else {
        toast.error(response?.data?.message || "Role InActive.");
        return response?.data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);
/// General Setting
export const editGeneralSetting = createAsyncThunk(
  "admin/editGeneralSetting",
  async (data) => {
    try {
      const response = await dataService.post(api.General_Setting, data);
      toast.success(response?.data?.message || "General Setting Updated");
      return response?.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const getAllGeneralSetting = createAsyncThunk(
  "admin/getAllGeneralSetting",
  async () => {
    try {
      const response = await dataService.get(api.Get_General_Setting);
      return response?.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

// Franchise User List
export const getAllFranchiseUsers = createAsyncThunk(
  "admin/getAllFranchiseUsers",
  async () => {
    try {
      const response = await dataService.get(api.Get_AllFranchis_user);
      return response?.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);
export const getFilteredFranchiseUsers = createAsyncThunk(
  "admin/getFilteredFranchiseUsers",
  async (data) => {
    try {
      const response = await dataService.get(
        `${api.Get_AllFranchis_user}?startDate=${data?.startDate}&endDate=${data?.endDate}`
      );
      return response?.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);
export const editFranchiseUser = createAsyncThunk(
  "admin/editFranchiseUser",
  async (data) => {
    try {
      const response = await dataService.post(api.Update_FranchiseUser, data);
      toast.success(response?.data?.message || "Franchise User Updated");
      return response?.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);
export const statusFranchiseUser = createAsyncThunk(
  "admin/statusFranchiseUser",
  async (data) => {
    try {
      const response = await dataService.post(
        api.Active_InActive_FranchiseUser,
        data
      );
      // if (response?.data?.message === "User activated") {
      //   toast.success(response?.data?.message || "Franchise User Active");
      //   return response?.data;
      // } else {
      //   toast.error(response?.data?.message || "Franchise User InActive.");
      //   return response?.data;
      // }
      if (response?.data?.status === 200 || response?.data?.status === 201) {
        toast.success(response?.data?.message || "Franchise User Active");
        return response?.data;
      } else {
        toast.error(response?.data?.message);
        return response?.data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

// Add Edit PropertyData
export const addPropertyData = createAsyncThunk(
  "admin/addPropertyData",
  async (data) => {
    try {
      const response = await dataService.post(api.Add_Property, data);
      toast.success(response?.data?.message || "Property create");
      return response?.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);
export const editPropertyData = createAsyncThunk(
  "admin/editPropertyData",
  async (data) => {
    try {
      const response = await dataService.post(api.Edit_Property, data);
      toast.success(response?.data?.message || "Property create");
      return response?.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);
export const editRebrandingPropertyData = createAsyncThunk(
  "admin/editRebrandingPropertyData",
  async (data) => {
    try {
      const response = await dataService.post(
        api.Edit_Rebranding_Property,
        data
      );
      toast.success(response?.data?.message);
      return response?.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const getAllPropertyList = createAsyncThunk(
  "admin/getAllPropertyList",
  async () => {
    try {
      const response = await dataService.get(api.Get_AllProperty_list);
      return response?.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const getAdminUserAllPropertyList = createAsyncThunk(
  "admin/getAllPropertyList",
  async () => {
    try {
      const response = await dataService.get(api.Get_AdminUserAllProperty_list);
      return response?.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const getFilteredPropertyList = createAsyncThunk(
  "admin/getFilteredPropertyList",
  async (data) => {
    try {
      const response = await dataService.get(
        `${api.Get_AllProperty_list}?startDate=${data?.startDate}&endDate=${data?.endDate}`
      );
      return response?.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const deletePropertyData = createAsyncThunk(
  "admin/deletePropertyData",
  async (id) => {
    try {
      const response = await dataService.post(api.Delete_Property, {
        id: id,
      });
      toast.success(response?.data?.message || "delele Property");
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const statusPropertyData = createAsyncThunk(
  "admin/statusPropertyData",
  async (data) => {
    try {
      const response = await dataService.post(api.Active_InActive_Role, data);
      if (response?.data?.message === "Role Activated") {
        toast.success(response?.data?.message || "Role Active");
        return response?.data;
      } else {
        toast.error(response?.data?.message || "Role InActive.");
        return response?.data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

// Add Edit Coupon
export const addEditCoupon = createAsyncThunk(
  "admin/addEditCoupon",
  async (data) => {
    try {
      const response = await dataService.post(api.Add_Update_Coupon, data);
      toast.success(response?.data?.message || "Coupon create");
      return response?.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const getAllCoupon = createAsyncThunk("admin/getAllCoupon", async () => {
  try {
    const response = await dataService.get(api.Get_Coupon);
    return response?.data;
  } catch (error) {
    toast.error(error.response.data.message);
  }
});

export const getAllState = createAsyncThunk("admin/getAllState", async () => {
  try {
    const response = await dataService.get(api.Get_State);
    return response?.data;
  } catch (error) {
    toast.error(error.response.data.message);
  }
});

export const deleteCoupon = createAsyncThunk(
  "admin/deleteCoupon",
  async (id) => {
    try {
      const response = await dataService.post(api.Delete_Coupon, {
        id: id,
      });
      toast.success(response?.data?.message || "delele Coupon data");
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const statusCouponData = createAsyncThunk(
  "admin/statusCouponData",
  async (data) => {
    try {
      const response = await dataService.post(api.Active_InActive_Coupon, data);
      if (response?.data?.message === "Voucher activated.") {
        toast.success(response?.data?.message || "Coupon Active");
        return response?.data;
      } else {
        toast.success(response?.data?.message || "Coupon InActive.");
        return response?.data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

//// Franchise Application

export const getAllFranchiseApplication = createAsyncThunk(
  "admin/getAllFranchiseApplication",
  async () => {
    try {
      const response = await dataService.get(
        api.Get_AllFranchiseApplication_List
      );
      return response?.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);
export const getSingleFranchiseApplication = createAsyncThunk(
  "admin/getSingleFranchiseApplication",
  async (data) => {
    try {
      const response = await dataService.get(
        `${api.Get_Single_FranchiseApplication_List}&id=${data}`
      );
      return response?.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const statusFranchiseApplication = createAsyncThunk(
  "admin/statusFranchiseApplication",
  async (data) => {
    try {
      const response = await dataService.post(
        api.Franchise_Application_status,
        data
      );
      if (response?.data?.status === 200) {
        toast.success(response?.data?.message);
        return response?.data;
      } else {
        toast.error(response?.data?.message);
        return response?.data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const statusVerifyProertyStatus = createAsyncThunk(
  "admin/statusVerifyProertyStatus",
  async (data) => {
    try {
      const response = await dataService.post(
        api.Franchise_Application_Property_Verify_status,
        data
      );
      if (response?.data?.status === 200) {
        toast.success(response?.data?.message);
        return response?.data;
      } else {
        toast.error(response?.data?.message);
        return response?.data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

// User Property Status
export const statusUserVerifyProertyStatus = createAsyncThunk(
  "admin/statusUserVerifyProertyStatus",
  async (data) => {
    try {
      const response = await dataService.post(
        api.Franchise_Application_Property_Verify_status,
        data
      );
      if (response?.data?.status === 200) {
        toast.success(response?.data?.message);
        return response?.data;
      } else {
        toast.error(response?.data?.message);
        return response?.data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);
//// Transacion List

export const getAllTransactionData = createAsyncThunk(
  "admin/getAllTransactionData",
  async () => {
    try {
      const response = await dataService.get(api.Get_AllTransaction_List);
      return response?.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const statusTransactionData = createAsyncThunk(
  "admin/statusTransactionData",
  async (data) => {
    try {
      const response = await dataService.post(
        api.Franchise_Application_status,
        data
      );
      if (response?.data?.message === "Voucher activated.") {
        toast.success(response?.data?.message || "Coupon Active");
        return response?.data;
      } else {
        toast.error(response?.data?.message || "Coupon InActive.");
        return response?.data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

/// User Property Transaction List

export const getAllUserTransactionData = createAsyncThunk(
  "admin/getAllUserTransactionData",
  async (userAndPropertyId) => {
    try {
      const params = new URLSearchParams();
      for (const key in userAndPropertyId) {
        params.append(`${key}`, userAndPropertyId[key]);
      }
      // let data;
      // if (userAndPropertyId?.propertyId !== undefined) {
      //   data = {
      //     user_id: userAndPropertyId?.userId,
      //     property_id: userAndPropertyId?.propertyId,
      //   };
      // } else {
      //   data = {
      //     user_id: userAndPropertyId?.userId,
      //     rebranding_id: userAndPropertyId?.rebrandingId,
      //   };
      // }
      const response =
        userAndPropertyId?.property_id !== undefined
          ? await dataService.get(
              `${api.Get_All_UserTransaction_List}?user_id=${userAndPropertyId.user_id}&property_id=${userAndPropertyId.property_id}`
            )
          : await dataService.get(
              `${api.Get_All_UserTransaction_List}?user_id=${userAndPropertyId.user_id}&rebranding_id=${userAndPropertyId.rebranding_id}`
            );
      return response?.data;
    } catch (error) {
      // toast.error(error.response.data.message);
      console.log(error.response.data.message);
    }
  }
);
/// Dashboard APIS
export const getAllDashboardData = createAsyncThunk(
  "admin/getAllDashboardData",
  async () => {
    try {
      const response = await dataService.get(api.Get_Dashboard_data);
      return response?.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);
export const getAllTransactionDashboardData = createAsyncThunk(
  "admin/getAllTransactionDashboardData",
  async () => {
    try {
      const response = await dataService.get(
        api.Get_Dashboard_Transaction_data
      );
      return response?.data;
    } catch (error) {
      console.log(error.response.data.message);
    }
  }
);
export const getAllFranchiseUserDashboardData = createAsyncThunk(
  "admin/getAllFranchiseUserDashboardData",
  async () => {
    try {
      const response = await dataService.get(
        api.Get_Dashboard_Franchise_User_data
      );
      return response?.data;
    } catch (error) {
      console.log(error.response.data.message);
    }
  }
);

///// Franchise pplication Document Status APis
export const handleDocumentStatus = createAsyncThunk(
  "admin/handleDocumentStatus",
  async (data) => {
    try {
      const response = await dataService.post(api.Document_Status, data);
      if (response?.data?.status === 200) {
        toast.success(response?.data?.message);
        return response?.data;
      } else {
        toast.error(response?.data?.message);
        return response?.data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);
export const handleAddRejectDocComment = createAsyncThunk(
  "admin/handleAddRejectDocComment",
  async (data) => {
    try {
      const response = await dataService.post(api.Document_Status, data);
      if (response?.data?.status === 200) {
        toast.success(response?.data?.message);
        return response?.data;
      } else {
        toast.error(response?.data?.message);
        return response?.data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

/// Rebranding Property service
export const getAllRebrandingPropertyList = createAsyncThunk(
  "admin/getAllRebrandingPropertyList",
  async () => {
    try {
      const response = await dataService.get(
        api.Get_AllRebrandingProperty_list
      );
      return response?.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const handleRebrandingStatus = createAsyncThunk(
  "admin/handleRebrandingStatus",
  async (data) => {
    try {
      const response = await dataService.post(api.Rebranding_Status, data);
      if (response?.data?.status === 200) {
        toast.success(response?.data?.message);
        return response?.data;
      } else {
        toast.error(response?.data?.message);
        return response?.data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const handleAddRebrandingRejectComment = createAsyncThunk(
  "admin/handleAddRebrandingRejectComment",
  async (data) => {
    try {
      const response = await dataService.post(api.Rebranding_Status, data);
      if (response?.data?.status === 200) {
        toast.success(response?.data?.message);
        return response?.data;
      } else {
        toast.error(response?.data?.message);
        return response?.data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const deleteRebrandingPropertyData = createAsyncThunk(
  "admin/deleteRebrandingPropertyData",
  async (id) => {
    try {
      const response = await dataService.post(`${api.Delete_Rebranding_Property}?rebranding_id=${id}`);
      toast.success(response?.data?.message || "delele Property");
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

// export const statusRebrandingPropertyData = createAsyncThunk(
//   "admin/statusRebrandingPropertyData",
//   async (data) => {
//     try {
//       const response = await dataService.post(api.Active_InActive_Role, data);
//       if (response?.data?.message === "Role Activated") {
//         toast.success(response?.data?.message || "Role Active");
//         return response?.data;
//       } else {
//         toast.error(response?.data?.message || "Role InActive.");
//         return response?.data;
//       }
//     } catch (error) {
//       toast.error(error?.response?.data?.message);
//     }
//   }
// );

// Add Edit Employee Role data
export const addEmployeeRoleData = createAsyncThunk(
  "admin/addEmployeeRoleData",
  async (data) => {
    try {
      const response = await dataService.post(api.Add_Employee_Role, data);
      toast.success(response?.data?.message);
      return response?.data;
    } catch (error) {
      toast.error(error.response.data.message,{
        toastId: "customId"
      });
    }
  }
);
export const updateEmployeeRoleData = createAsyncThunk(
  "admin/updateEmployeeRoleData",
  async (data) => {
    try {
      const response = await dataService.post(api.Update_Employee_Role, data);
      toast.success(response?.data?.message);
      return response?.data;
    } catch (error) {
      toast.error(error.response.data.message,{
        toastId: "customId"
      });
    }
  }
);

export const getAllEmployeeRoleList = createAsyncThunk(
  "admin/getAllEmployeeRoleList",
  async () => {
    try {
      const response = await dataService.get(api.Get_All_Employee_Role_List);
      return response?.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const statusEmployeeRoleData = createAsyncThunk(
  "admin/statusEmployeeRoleData",
  async (data) => {
    try {
      const response = await dataService.post(
        api.Active_InActive_Employee_Role,
        data
      );
      if (response?.data?.status === 200) {
        toast.success(response?.data?.message);
        return response?.data;
      } else {
        toast.error(response?.data?.message);
        return response?.data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const deleteEmployeeRoleData = createAsyncThunk(
  "admin/deleteEmployeeRoleData",
  async (id) => {
    try {
      const response = await dataService.post(api.Delete_Employee_Role_Data, {
        id: id,
      });
      toast.success(response?.data?.message);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

/// Staff Hiring APIS

export const getAllStaffHiringList = createAsyncThunk(
  "admin/getAllStaffHiringList",
  async () => {
    try {
      const response = await dataService.get(api.Get_AllStaff_Hiring);
      return response?.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const statusStaffHiring = createAsyncThunk(
  "admin/statusStaffHiring",
  async (data) => {
    try {
      const response = await dataService.post(
        api.Active_InActive_FranchiseUser,
        data
      );
      if (response?.data?.message === "User activated") {
        toast.success(response?.data?.message || "Franchise User Active");
        return response?.data;
      } else {
        toast.error(response?.data?.message || "Franchise User InActive.");
        return response?.data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const loginPermission = createAsyncThunk(
  "admin/loginPermission",
  async (userData) => {
    try {
      const response = await dataService.get(api.LOGIN_PERMISSION, userData);
      return response?.data?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

//// Notification List

export const getAllNotificationData = createAsyncThunk(
  "admin/getAllNotificationData",
  async () => {
    try {
      const response = await dataService.get(api.Get_AllNotification_List);
      return response?.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

/////// Training Session APis ?///////

export const getTrainingSession = createAsyncThunk(
  "admin/getTrainingSession",
  async () => {
    try {
      const response = await dataService.get(api.Get_Training_Session);
      if (response?.data?.status === 201) {
        toast.success(response?.data?.message);
      }
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);
export const createTrainingSession = createAsyncThunk(
  "admin/createTrainingSession",
  async (data) => {
    try {
      const response = await dataService.post(
        api.Create_Cms_Training_Session,
        data
      );
      if (response.data.status === 201) {
        toast.success(response.data.message);
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);
export const statusTrainingSession = createAsyncThunk(
  "admin/statusTrainingSession",
  async (data) => {
    try {
      const response = await dataService.post(
        api.Active_InActive_FranchiseUser,
        data
      );
      if (response?.data?.message === "User activated") {
        toast.success(response?.data?.message || "Franchise User Active");
        return response?.data;
      } else {
        toast.error(response?.data?.message || "Franchise User InActive.");
        return response?.data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const deleteTrainingSession = createAsyncThunk(
  "admin/deleteTrainingSession",
  async (id) => {
    try {
      const response = await dataService.post(api.Delete_Employee_Role_Data, {
        id: id,
      });
      toast.success(response?.data?.message);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

/////// Orientation Session APis ?///////

export const getOrientationSession = createAsyncThunk(
  "admin/getOrientationSession",
  async () => {
    try {
      const response = await dataService.get(api.Get_Orientation_Session);
      if (response?.data?.status === 200) {
        toast.success(response?.data?.message);
      }
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);
export const createOrientationSession = createAsyncThunk(
  "admin/createOrientationSession",
  async (data) => {
    try {
      const response = await dataService.post(
        api.Create_Cms_Orientation_Session,
        data
      );
      if (response.data.status === 201) {
        toast.success(response.data.message);
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const deleteOrientationSessionData = createAsyncThunk(
  "admin/deleteOrientationSessionData",
  async (id) => {
    try {
      const response = await dataService.post(api.Delete_Oreintation_Data, {
        id: id,
      });
      toast.success(response?.data?.message);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);
/////// OPerational model Session APis ?///////

export const getOperationModalSession = createAsyncThunk(
  "admin/getOperationModalSession",
  async () => {
    try {
      const response = await dataService.get(api.Get_Operational_Modal_Session);
      if (response?.data?.status === 201) {
        toast.success(response?.data?.message);
      }
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);
export const createOperationModalSession = createAsyncThunk(
  "admin/createOperationModalSession",
  async (data) => {
    try {
      const response = await dataService.post(
        api.Create_Cms_Operational_Modal_Session,
        data
      );
      if (response.data.status === 201) {
        toast.success(response.data.message);
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

///// Coupon User List /////
export const couponUserList = createAsyncThunk(
  "admin/couponUserList",
  async (data) => {
    try {
      const response = await dataService.get(`${api.Coupon_User_List}/${data}`);
      if (response.data.status === 201) {
        toast.success(response.data.message);
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

//////  OPeraational MOdel Session ///////

export const createOperationalModalSession = createAsyncThunk(
  "admin/createOperationalModalSession",
  async (data) => {
    try {
      const response = await dataService.post(api.Add_OPerational_Model, data);
      toast.success(response?.data?.message);
      return response?.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const getAllOPerationalModelSession = createAsyncThunk(
  "admin/getAllOPerationalModelSession",
  async () => {
    try {
      const response = await dataService.get(api.Get_All_Operational_List);
      return response?.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const statusOperationalModelSession = createAsyncThunk(
  "admin/statusOperationalModelSession",
  async (data) => {
    try {
      const response = await dataService.post(
        api.Active_InActive_OPerational_Model_Role,
        data
      );
      if (response?.data?.status === 200) {
        toast.success(response?.data?.message);
        return response?.data;
      } else {
        toast.error(response?.data?.message);
        return response?.data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const deleteOperationalModelSession = createAsyncThunk(
  "admin/deleteOperationalModelSession",
  async (id) => {
    try {
      const response = await dataService.post(
        api.Delete_OPerational_Model_Data,
        {
          id: id,
        }
      );
      toast.success(response?.data?.message);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);
//////  Orientation MOdel Session ///////

export const createOrientationModalSession = createAsyncThunk(
  "admin/createOrientationModalSession",
  async (data) => {
    try {
      const response = await dataService.post(api.Add_Orientation_Model, data);
      toast.success(response?.data?.message);
      return response?.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const getAllOrientationModelSession = createAsyncThunk(
  "admin/getAllOrientationModelSession",
  async () => {
    try {
      const response = await dataService.get(api.Get_All_Orientation_List);
      return response?.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const statusOrientationModelSession = createAsyncThunk(
  "admin/statusOrientationModelSession",
  async (data) => {
    try {
      const response = await dataService.post(
        api.Active_InActive_Orientation_Model_Role,
        data
      );
      if (response?.data?.status === 200) {
        toast.success(response?.data?.message);
        return response?.data;
      } else {
        toast.error(response?.data?.message);
        return response?.data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const deleteOrientationSession = createAsyncThunk(
  "admin/deleteOrientationSession",
  async (id) => {
    try {
      const response = await dataService.post(
        api.Delete_Orientation_Model_Data,
        {
          id: id,
        }
      );
      toast.success(response?.data?.message);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);
//////  Training  MOdel Session ///////

export const createTrainingModalSession = createAsyncThunk(
  "admin/createTrainingModalSession",
  async (data) => {
    try {
      const response = await dataService.post(api.Add_Training_Model, data);
      toast.success(response?.data?.message);
      return response?.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const getAllTrainingModelSession = createAsyncThunk(
  "admin/getAllTrainingModelSession",
  async () => {
    try {
      const response = await dataService.get(api.Get_All_Training_List);
      return response?.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const statusTrainingModelSession = createAsyncThunk(
  "admin/statusTrainingModelSession",
  async (data) => {
    try {
      const response = await dataService.post(
        api.Active_InActive_Training_Model_Role,
        data
      );
      if (response?.data?.status === 200) {
        toast.success(response?.data?.message);
        return response?.data;
      } else {
        toast.error(response?.data?.message);
        return response?.data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const deleteTrainingModelSession = createAsyncThunk(
  "admin/deleteTrainingModelSession",
  async (id) => {
    try {
      const response = await dataService.post(api.Delete_Training_Model_Data, {
        id: id,
      });
      toast.success(response?.data?.message);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

///// For Self Logout Sub - Admin ////
export const getSelfLogoutSubAdmin = createAsyncThunk(
  "admin/getSelfLogoutSubAdmin",
  async () => {
    try {
      const response = await dataService.get(api.Get_Logout_Sub_Admin);
      return response?.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

/////////// Payment Terms Data ///////////////
export const getPaymentTermsConditionCms = createAsyncThunk(
  "admin/getPaymentTermsConditionCms",
  async () => {
    try {
      const response = await dataService.get(api.Get_Payment_Terms_Condition);
      if (response?.data?.status === 201) {
        toast.success(response?.data?.message);
      }
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);
export const createPaymentTermsConditionCms = createAsyncThunk(
  "admin/createPaymentTermsConditionCms",
  async (data) => {
    try {
      const response = await dataService.post(
        api.Create_Payment_Terms_Condition,
        data
      );
      if (response.data.status === 201) {
        toast.success(response.data.message);
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

// Add Edit State
export const addEditState = createAsyncThunk(
  "admin/addEditState",
  async (data) => {
    try {
      const response = await dataService.post(api.Add_Update_State, data);
      toast.success(response?.data?.message || "State create");
      return response?.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const downloadFranchiseInvoice = createAsyncThunk(
  "admin/downloadFranchiseInvoice",
  async (franchiseId) => {
    try {
      const response = await dataService.get(
        `user/get-invoice-PDF/${franchiseId}`,{
          responseType: 'blob', // Specify the response type as blob
        }
      );
      const pdfData = await response.blob();

      const blob = new Blob([pdfData], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);

      // Create a hidden anchor element
      const link = document.createElement('a');
      link.href = url;
      link.download = 'filename.pdf'; // You can set the desired filename
      link.style.display = 'none';

      // Append the anchor to the document body
      document.body.appendChild(link);

      // Trigger the click event
      link.click();

      // Remove the anchor from the document body
      document.body.removeChild(link);

      return response;

      // return response;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const deleteNotification = createAsyncThunk(
  "admin/deleteNotification",
  async (id) => {
    try {
      const response = await dataService.post(`${api.Delete_Notification}?id=${id}`);
      toast.success(response?.data?.message);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const readNotification = createAsyncThunk(
  "admin/readNotification",
  async (id) => {
    try {
      const response = await dataService.post(`${api.Read_Notification}?id=${id}`);
      toast.success(response?.data?.message);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const getRolePermission = createAsyncThunk(
  "admin/getRolePermission",
  async (id) => {
    try {
      const response = await dataService.get(`${api.Get_Role_permission}?id=${id}`);
      return response?.data;
    } catch (error) {
    }
  }
);