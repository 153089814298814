import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import "./Notification.css";
import {
  deleteNotification,
  getAllNotificationData,
} from "../../../../redux/slices/AdminService";
import moment from "moment";

export default function NotificationList() {
  // const { editNotification } = PageIndex.useSelector((state) => state.admin);
  const [editNotification, setEditNotification] = useState();
  let navigate = PageIndex.useNavigate();
  const dispatch = PageIndex.useDispatch();

  const [search, setSearch] = useState("");
  const [pageData, setPageData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);

  // handle delete
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [id, setId] = useState("");
  const handleDeleteOpen = (id) => {
    setId(id);
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setId("");
  };

  const handleSearch = (searchValue) => {
    const result = editNotification?.filter((item) => {
      return (
        item?.details?.toLowerCase().includes(searchValue?.toLowerCase()) ||
        item?.userId?.name?.toLowerCase().includes(searchValue?.toLowerCase())
      );
    });
    setFilteredData(result);
  };

  useEffect(() => {
    // setFilteredData(editNotification);
    // const filtered = editNotification
    //   ?.filter((item) => {
    //     return !search
    //       ? item
    //       : item?.discount_type.toLowerCase().includes(search?.toLowerCase()) ||
    //           item?.title.toLowerCase().includes(search?.toLowerCase()) ||
    //           item?.start_date.toString().includes(search?.toString()) ||
    //           item?.discount_amount.toString().includes(search?.toString());
    //   })
    //   ?.sort((a, b) => {
    //     const dateA = new Date(a.createdAt);
    //     const dateB = new Date(b.createdAt);
    //     return dateB - dateA;
    //   });
    // if (filtered) {
    //   setFilteredData(filtered);
    // } else {
    //   setFilteredData([]);
    // }
  }, []);
  // editNotification, search

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const handleDelete = () => {
    dispatch(deleteNotification(id)).then((res) => {
      getAllNotification();
      handleDeleteClose();
    });
  };
  const getAllNotification = () => {
    dispatch(getAllNotificationData()).then((res) => {
      setEditNotification(res?.payload?.data);
      setFilteredData(res?.payload?.data);
    });
  };
  useEffect(() => {
    getAllNotification();
  }, []);

  return (
    <>
      {loading ? (
        <PageIndex.LoadingOverlay
          spinner
          text="Loading your content..."
          active={loading}
        />
      ) : (
        <Index.Box className="dashboard-content">
          <Index.Box className="user-list-flex">
            <Index.Box className="admin-page-title-main">
              <Index.Typography
                className="admin-page-title"
                component="h2"
                variant="h2"
              >
                Notification List
              </Index.Typography>
            </Index.Box>
            <Index.Box className="userlist-btn-flex">
              <Index.Box className="user-search-main">
                <Index.Box className="user-search-box">
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      placeholder="Search notification"
                      value={search}
                      onChange={(e) => {
                        const inputValue = e.target.value
                          .replace(/^\s+/, "")
                          .replace(/\s\s+/g, " ");
                        setSearch(inputValue);
                        handleSearch(inputValue);
                      }}
                    />
                    <span className="search-icon-box">
                      <img
                        alt="search_icon"
                        src={Index.Svg.search}
                        className="search-icon"
                      />
                    </span>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="flex-all user-list-inner-flex"></Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="admin-dashboard-list-row">
            {pageData?.length > 0 ? (
              pageData?.map((notification, index) => (
                <Index.Stack key={index} className="notification-container">
                  <Index.Chip
                    // className="notification-container-box"
                    className={notification?.isRead==false?"notification-container-box-false":"notification-container-box"}
                    // notification-container-box
                    label={
                      <>
                        <Index.Typography className="notification-label read-notification">
                          {notification?.userId?.name
                            ? notification?.userId?.name
                            : "-"}
                        </Index.Typography>
                        <Index.Typography className="notification-label">
                          {notification?.details ? notification?.details : "-"}{" "}
                          {notification?.activityType &&
                          notification.activityType !== "Created"
                            ? notification.activityType
                            : ""}
                        </Index.Typography>
                        <Index.Typography className="notification-label">
                          {notification?.createdAt
                            ? moment(notification?.createdAt)
                                .utc()
                                .format("DD/MM/YYYY hh:mm A")
                            : "-"}
                        </Index.Typography>
                      </>
                    }
                    // avatar={
                    //   <NotificationsIcon />
                    //   // <Index.Avatar
                    //   //   src={Index.Svg.notificationAvtarImage}
                    //   //   className="notification-list-img"
                    //   //   alt="search grey img"
                    //   // />
                    // }
                    onDelete={() => {
                      handleDeleteOpen(notification?._id);
                    }}
                    deleteIcon={
                      <Index.DeleteIcon className="notification-delete-icon" />
                    }
                    variant="outlined"
                  />
                </Index.Stack>
              ))
            ) : (
              <Index.Stack className="notification-container flex-center">
                <Index.Chip
                  className="notification-container-box"
                  label={
                    <Index.Typography className="notification-label no-data">
                      No data found
                    </Index.Typography>
                  }
                ></Index.Chip>
              </Index.Stack>
            )}
          </Index.Box>
          <Index.Box className="pagination-main">
            <PageIndex.Pagination
              fetchData={filteredData}
              setPageData={setPageData}
              pageData={pageData}
              search={search}
              // filterBy={[
              //   "discount_type",
              //   "discount_amount",
              //   "title",
              //   "start_date",
              // ]}
            />
          </Index.Box>
        </Index.Box>
      )}
      {/* delete modal */}
      <PageIndex.DeleteModal
        deleteOpen={deleteOpen}
        handleDeleteClose={handleDeleteClose}
        handleDeleteRecord={handleDelete}
      />
    </>
  );
}
