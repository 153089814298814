import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import Checkbox from "@mui/material/Checkbox";
import { addEditRole } from "../../../../redux/slices/AdminService";
import { roleSchema } from "../../../../validation/Validation";
import PageIndex from "../../../PageIndex";
import "./RoleStyle.css";

const AddRole = () => {
  const dispatch = PageIndex.useDispatch();
  const params = PageIndex.useLocation();
  const navigate = PageIndex.useNavigate();
  const { adminData } = PageIndex.useSelector((store) => store.admin);
  // const { editId } = useParams();
  const editId = params?.state?._id;

  const [editRoleData, setEditRoleData] = useState({});
  const [loading, setLoading] = useState(false);

  let initialValues = {
    roleName: editRoleData?.roleName ? editRoleData?.roleName : "",
    permission: editRoleData?.permission ? editRoleData?.permission : [],
  };

  const modules = [
    {
      tag: "Role",
      title: "Roles",
    },
    {
      tag: "Admin User",
      title: "Admin Users",
    },
    {
      tag: "Franchise User",
      title: "Franchise Users",
      disable: ["add", "delete"],
    },
    {
      tag: "Property",
      title: "Properties",
    },
    {
      tag: "Requested Property",
      title: "Requested Properties",
      disable: ["add", "delete"],
    },
    {
      tag: "Rebranding",
      title: "Covert Your Existing Cinema",
      disable: ["add", "edit"],
    },
    {
      tag: "Franchise Application",
      title: "Franchise Applications",
      disable: ["add", "delete"],
    },
    {
      tag: "Transaction",
      title: "Transactions",
      disable: ["add", "edit", "delete"],
    },
    {
      tag: "Coupon",
      title: "Coupons",
    },
    {
      tag: "Staff",
      title: "Staff",
      disable: ["add", "edit", "delete"],
    },
    {
      tag: "Training Session",
      title: "Training Session",
    },
    {
      tag: "Orientation Session",
      title: "Orientation Session",
    },
    {
      tag: "Operational Session",
      title: "Operational Session",
    },
    {
      tag: "Terms & Condition",
      title: "Terms & Condition",
      disable: ["add", "delete"],
    },
  ];
  const [permissionError, setPermissionError] = useState("");
  const [permissions, setPermissions] = useState(
    params?.state?.permission || []
  );
  const [checkAll, setCheckAll] = useState({
    view: false,
    add: false,
    edit: false,
    delete: false,
  });

  const checkUncheckAllType = (action, type) => {
    let modifyType = type.split("_")[1];
    if (action === "add") {
      const updatedData = modules
        ?.filter((item) => !item?.disable?.includes(modifyType))
        ?.map((ele) => ele.tag + type);
      const viewPermissionData = modules?.map((ele) => ele.tag + "_view");
      let permissionArr = [...new Set([...updatedData, ...viewPermissionData])];
      setPermissions((prev) => [...new Set([...prev, ...permissionArr])]);
      setCheckAll((prevCheckAll) => ({
        ...prevCheckAll,
        [modifyType]: true,
      }));
    } else {
      const data = modules?.map((ele) => ele?.tag + type);
      const viewPermissionData = modules?.map((ele) => ele?.tag + "_view");
      let permissionArr = [...new Set([...data, ...viewPermissionData])];
      const removedArray = permissions?.filter((el) =>
        checkAll.add || checkAll.edit || checkAll.delete
          ? !data.includes(el)
          : !permissionArr.includes(el)
      );
      setPermissions(removedArray);
      setCheckAll((prevCheckAll) => {
        const updatedCheckAll = {
          ...prevCheckAll,
          [modifyType]: false,
        };
        if (
          !updatedCheckAll.add &&
          !updatedCheckAll.edit &&
          !updatedCheckAll.delete
        ) {
          updatedCheckAll.view = false;
        }

        return updatedCheckAll;
      });
    }
  };
  const onChangeCheckBox = (value, checked, tagName) => {
    let newData;
    const isViewSelected = (tag) => permissions?.includes(`${tag}_view`);
    let PermissionType = value?.split("_")[1];
    let checkPermission =
      permissions?.filter((data) => data?.includes(`_${PermissionType}`))
        .length === modules.length;

    if (checked && permissions?.length === modules.length) {
      setCheckAll((prevCheckAll) => ({
        ...prevCheckAll,
        [tagName]: true,
      }));
    } else {
      setCheckAll((prevCheckAll) => ({
        ...prevCheckAll,
        [tagName]: false,
      }));
    }

    const togglePermission = (permission) => {
      if (permissions?.includes(permission)) {
        newData = permissions?.filter((item) => item !== permission);
      } else {
        newData = [...permissions, permission];
      }
    };

    let lastUnderscoreIndex = value?.lastIndexOf("_");
    let tag = value?.substring(0, lastUnderscoreIndex);
    if (value?.endsWith("_view")) {
      togglePermission(value);
    } else {
      togglePermission(value);

      const viewPermission = `${tag}_view`;
      if (!isViewSelected(tag)) {
        newData?.push(viewPermission);
      }
    }

    setPermissions(newData);
  };

  const handleRoleSubmit = async (values) => {
    if (permissions == "") {
      setPermissionError("Please select at least one permission");
    } else {
      setLoading(true);
      if (editId) {
        values.id = editId;
        const permissionData = {
          permission: permissions,
          roleName: values.roleName,
          id: editId,
        };
        dispatch(addEditRole(permissionData)).then((response) => {
          if (response?.payload?.status === 200) {
            // toast.success("Role updated successfully");
            navigate("/dashboard/role-list");
            setLoading(false);
            setPermissionError("");
          } else {
            setLoading(false);
            // toast.error("Something went wrong");
          }
        });
      } else {
        const permissionData = {
          permission: permissions,
          roleName: values.roleName,
        };
        dispatch(addEditRole(permissionData)).then((response) => {
          if (response?.payload?.status === 201) {
            // toast.success("Role added successfully");
            navigate("/dashboard/role-list");
            setLoading(false);
            setPermissionError("");
          } else {
            setLoading(false);
            // toast.error("Something went wrong");
          }
        });
      }
    }
  };
  // Get record data for edit
  useEffect(() => {
    if (editId) {
      setEditRoleData(params?.state);
    }
  }, []);

  if (
    (adminData && adminData?.role?.permission?.includes("Role_add")) ||
    adminData?.role?.permission?.includes("Role_edit") ||
    (adminData && adminData?.isAdmin === true)
  ) {
    return (
      <PageIndex.LoadingOverlay
        spinner
        text="Loading your content..."
        active={loading}
      >
        <Index.Box className="dashboard-content add-user-containt">
          <Index.Typography
            className="admin-page-title page-content-title"
            component="h4"
            variant="h4"
          >
            {editId ? "Update Role" : "Add Role"}
          </Index.Typography>
          <Index.Box className="page-border">
            <Index.Box className="add-user-data-main">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <PageIndex.Formik
                  enableReinitialize
                  onSubmit={handleRoleSubmit}
                  initialValues={initialValues}
                  validationSchema={roleSchema}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    setFieldValue,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Index.Box className="admin-dashboard-list-row">
                        <Index.Box sx={{ width: 1 }} className="grid-main">
                          <Index.Box
                            display="grid"
                            className="display-row"
                            gridTemplateColumns="repeat(12, 1fr)"
                            gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                          >
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box add-user-input">
                                <Index.FormHelperText className="form-lable">
                                  Role Name*
                                </Index.FormHelperText>
                                <Index.Box className="form-group">
                                  <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    type="text"
                                    className="form-control"
                                    name="roleName"
                                    onBlur={handleBlur}
                                    value={values.roleName}
                                    // onChange={handleChange}
                                    onChange={(e) => {
                                      const inputValue = e.target.value
                                        .replace(/^\s+/, "")
                                        .replace(/\s\s+/g, " ")
                                        .replace(/[^a-zA-Z\s]/g, "");
                                      setFieldValue(
                                        "roleName",
                                        inputValue.slice(0, 30)
                                      );
                                    }}
                                    error={Boolean(
                                      errors.roleName
                                        ? touched.roleName
                                        : undefined
                                    )}
                                  />
                                  {errors?.roleName && touched?.roleName ? (
                                    <Index.Typography className="error-msg">
                                      {errors?.roleName}
                                    </Index.Typography>
                                  ) : null}
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 12",
                                md: "span 12",
                                lg: "span 12",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="admin-dash-box">
                                <Index.Box className="role-table-main page-table-main">
                                  <Index.TableContainer
                                    component={Index.Paper}
                                    className="refferal-main-paper-table role-permission-paper-table"
                                  >
                                    <Index.Table
                                      aria-label="simple table"
                                      className="table"
                                    >
                                      <Index.TableHead className="table-head sticky">
                                        <Index.TableRow className="table-row">
                                          <Index.TableCell
                                            component="th"
                                            variant="th"
                                            className="table-th"
                                            align="center"
                                          >
                                            Module
                                          </Index.TableCell>
                                          <Index.TableCell
                                            component="th"
                                            variant="th"
                                            className="table-th"
                                            align="center"
                                          >
                                            <Index.Typography>
                                              {" "}
                                              View
                                            </Index.Typography>
                                            <Checkbox
                                              checked={
                                                checkAll?.view ||
                                                permissions?.filter((data) =>
                                                  data?.match("_view")
                                                )?.length === modules?.length
                                              }
                                              onChange={(e) => {
                                                if (e?.target?.checked) {
                                                  checkUncheckAllType(
                                                    "add",
                                                    "_view"
                                                  );
                                                } else {
                                                  checkUncheckAllType(
                                                    "remove",
                                                    "_view"
                                                  );
                                                }
                                              }}
                                            />
                                          </Index.TableCell>
                                          <Index.TableCell
                                            component="th"
                                            variant="th"
                                            className="table-th"
                                            align="center"
                                          >
                                            <Index.Typography>
                                              {" "}
                                              Add
                                            </Index.Typography>
                                            <Checkbox
                                              checked={
                                                checkAll?.add ||
                                                permissions?.filter((data) =>
                                                  data?.includes("_add")
                                                ).length ===
                                                  modules.length - 7
                                              }
                                              onChange={(e) => {
                                                if (e.target.checked) {
                                                  checkUncheckAllType(
                                                    "add",
                                                    "_add"
                                                  );
                                                } else {
                                                  checkUncheckAllType(
                                                    "remove",
                                                    "_add"
                                                  );
                                                }
                                              }}
                                            />
                                          </Index.TableCell>
                                          <Index.TableCell
                                            component="th"
                                            variant="th"
                                            className="table-th"
                                            align="center"
                                          >
                                            <Index.Typography>
                                              {" "}
                                              Edit
                                            </Index.Typography>
                                            <Checkbox
                                              checked={
                                                checkAll?.edit ||
                                                permissions?.filter((data) =>
                                                  data?.includes("_edit")
                                                ).length ===
                                                  modules?.length - 3
                                              }
                                              onChange={(e) => {
                                                if (e.target.checked) {
                                                  checkUncheckAllType(
                                                    "add",
                                                    "_edit"
                                                  );
                                                } else {
                                                  checkUncheckAllType(
                                                    "remove",
                                                    "_edit"
                                                  );
                                                }
                                              }}
                                            />
                                          </Index.TableCell>
                                          <Index.TableCell
                                            component="th"
                                            variant="th"
                                            className="table-th"
                                            align="center"
                                          >
                                            <Index.Typography>
                                              {" "}
                                              Delete{" "}
                                            </Index.Typography>
                                            <Checkbox
                                              checked={
                                                checkAll?.delete ||
                                                permissions?.filter((data) =>
                                                  data.includes("_delete")
                                                ).length ===
                                                  modules?.length - 6
                                              }
                                              onChange={(e) => {
                                                if (e.target.checked) {
                                                  checkUncheckAllType(
                                                    "add",
                                                    "_delete"
                                                  );
                                                } else {
                                                  checkUncheckAllType(
                                                    "remove",
                                                    "_delete"
                                                  );
                                                }
                                              }}
                                            />
                                          </Index.TableCell>
                                        </Index.TableRow>
                                      </Index.TableHead>
                                      <Index.TableBody className="table-body">
                                        {modules?.map((row, index) => (
                                          <Index.TableRow
                                            sx={{
                                              "&:last-child td, &:last-child th":
                                                {
                                                  border: 0,
                                                },
                                            }}
                                            className="refferal-datarow-table"
                                            key={row?.tag}
                                          >
                                            <Index.TableCell
                                              component="td"
                                              variant="td"
                                              scope="row"
                                              className="table-td"
                                              align="center"
                                            >
                                              {row?.title}
                                            </Index.TableCell>
                                            <Index.TableCell
                                              component="td"
                                              variant="td"
                                              scope="row"
                                              className="table-td"
                                              align="center"
                                            >
                                              <Index.Box className="email-refferal">
                                                <Index.Checkbox
                                                  checked={
                                                    permissions?.includes(
                                                      `${row?.tag}_view`
                                                    ) ||
                                                    permissions?.includes(
                                                      `${row?.tag}_add`
                                                    ) ||
                                                    permissions?.includes(
                                                      `${row?.tag}_edit`
                                                    ) ||
                                                    permissions?.includes(
                                                      `${row?.tag}_delete`
                                                    )
                                                  }
                                                  disabled={
                                                    row?.disable?.length > 0
                                                      ? row?.disable?.includes(
                                                          "view"
                                                        )
                                                      : permissions?.includes(
                                                          `${row?.tag}_add`
                                                        ) ||
                                                        permissions?.includes(
                                                          `${row?.tag}_edit`
                                                        ) ||
                                                        permissions?.includes(
                                                          `${row?.tag}_delete`
                                                        )
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(e) => {
                                                    onChangeCheckBox(
                                                      `${row?.tag}_view`,
                                                      e.target.checked,
                                                      "view"
                                                    );
                                                    setPermissionError("");
                                                  }}
                                                />
                                              </Index.Box>
                                            </Index.TableCell>
                                            <Index.TableCell
                                              component="td"
                                              variant="td"
                                              scope="row"
                                              className="table-td"
                                              align="center"
                                            >
                                              <Index.Box className="email-refferal">
                                                <Index.Checkbox
                                                  checked={
                                                    row?.disable?.includes(
                                                      "add"
                                                    )
                                                      ? false
                                                      : permissions?.includes(
                                                          `${row?.tag}_add`
                                                        )
                                                      ? true
                                                      : false
                                                  }
                                                  disabled={
                                                    row?.disable?.length > 0
                                                      ? row?.disable?.includes(
                                                          "add"
                                                        )
                                                      : false
                                                  }
                                                  onChange={(e) => {
                                                    onChangeCheckBox(
                                                      `${row?.tag}_add`,
                                                      e.target.checked,
                                                      "add"
                                                    );
                                                    setPermissionError("");
                                                  }}
                                                />
                                              </Index.Box>
                                            </Index.TableCell>
                                            <Index.TableCell
                                              component="td"
                                              variant="td"
                                              scope="row"
                                              className="table-td"
                                              align="center"
                                            >
                                              <Index.Box className="prices-refferal">
                                                <Index.Checkbox
                                                  checked={
                                                    row?.disable?.includes(
                                                      "edit"
                                                    )
                                                      ? false
                                                      : permissions?.includes(
                                                          `${row?.tag}_edit`
                                                        )
                                                      ? true
                                                      : false
                                                  }
                                                  disabled={
                                                    row?.disable?.length > 0
                                                      ? row?.disable?.includes(
                                                          "edit"
                                                        )
                                                      : false
                                                  }
                                                  onChange={(e) => {
                                                    onChangeCheckBox(
                                                      `${row?.tag}_edit`,
                                                      e.target.checked,
                                                      "edit"
                                                    );
                                                    setPermissionError("");
                                                  }}
                                                />
                                              </Index.Box>
                                            </Index.TableCell>
                                            <Index.TableCell
                                              component="td"
                                              variant="td"
                                              scope="row"
                                              className="table-td"
                                              align="center"
                                            >
                                              <Index.Box className="prices-refferal">
                                                <Index.Checkbox
                                                  checked={
                                                    row?.disable?.includes(
                                                      "delete"
                                                    )
                                                      ? false
                                                      : permissions?.includes(
                                                          `${row?.tag}_delete`
                                                        )
                                                      ? true
                                                      : false
                                                  }
                                                  disabled={
                                                    row?.disable?.length > 0
                                                      ? row?.disable?.includes(
                                                          "delete"
                                                        )
                                                      : false
                                                  }
                                                  onChange={(e) => {
                                                    onChangeCheckBox(
                                                      `${row?.tag}_delete`,
                                                      e.target.checked,
                                                      "delete"
                                                    );
                                                    setPermissionError("");
                                                  }}
                                                />
                                              </Index.Box>
                                            </Index.TableCell>
                                          </Index.TableRow>
                                        ))}
                                      </Index.TableBody>
                                    </Index.Table>
                                  </Index.TableContainer>
                                  {permissionError && (
                                    <Index.Typography className="error-msg">
                                      {permissionError}
                                    </Index.Typography>
                                  )}
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 12",
                                md: "span 12",
                                lg: "span 12",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="user-btn-flex comman-btn-main">
                                <Index.Box className="user-btn-flex">
                                  <Index.Box className="save-btn-main border-btn-main">
                                    <Index.Button
                                      className="save-user-btn border-btn"
                                      type="submit"
                                      disabled={loading}
                                    >
                                      <img
                                        alt="save_img"
                                        src={Index.Svg.save}
                                        className="user-save-icon"
                                      ></img>
                                      {editId ? "Update" : "Add"}
                                    </Index.Button>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </form>
                  )}
                </PageIndex.Formik>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </PageIndex.LoadingOverlay>
    );
  } else {
    navigate("/dashboard");
  }
};

export default AddRole;
