import * as Yup from "yup";

const latitudeRegex = /^(\+|-)?(?:90(?:\.0{1,7})?|[1-8]?\d(?:\.\d{1,7})?)$/;
const longitudeRegex =
  /^(\+|-)?(?:180(?:\.0{1,7})?|1[0-7]?\d(?:\.\d{1,7})?|[1-9]?\d(?:\.\d{1,7})?)$/;
const phoneRegex = /^[6-9]\d{9}$/;

export const adminLoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email format")
    .matches(
      /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9.-]+$/,
      "Invalid email format"
    )
    .oneOf([Yup.ref("email"), ""], "Email must match")
    .required("Please Enter Your Email"),
  password: Yup.string()
    .required("Please Enter Your Password")
    // .min(8, "Must Contain 8 Characters")

    .matches(/^(?=.*[0-9])/, "  Must Contain One Number Character"),
});

export const AdminForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please Enter Your Valid Email")
    .matches(
      /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9.-]+$/,
      "Invalid email format"
    )

    .oneOf([Yup.ref("email"), ""], "Email must match")
    .required("Please Enter Your Email"),
});
export const AdminSendOtpSchema = Yup.object().shape({
  otp: Yup.string()
    .required("Please Enter Your Otp")
    .matches(/^\d{4}$/, "OTP must be a 4-digit number"),
});

export const ResetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required(" Please enter New Password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z0-9!@#$%^&*]{8,}$/,
      " Password must be 8 characters, one capital, one small letter, one special character and one number "
    )
    .max(32, " New Password cannot exceed 32 characters"),

  confirmPassword: Yup.string()
    .required(" Please enter Confirm Password")
    .oneOf(
      [Yup.ref("newPassword")],
      " New Password and Confirm Password should be the same"
    ),
});

export const EditProfileSchema = Yup.object().shape({
  name: Yup.string()
    .required("Please Enter Your Name")
    .min(1, " Name is Too Short.")
    .matches(
      /^(?!\s)[A-Za-z\s]+$/,
      "Name Must Have Only Capital and Small Letters"
    ),
  email: Yup.string()
    .email("Invalid email format")
    .matches(
      /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9.-]+$/,
      "Invalid email format"
    )
    .required("Please Enter Your Email"),
  mobileNumber: Yup.number()
    .min(1111111111, "Please Enter valid mobile number")
    .max(9999999999, "Please Enter valid mobile number")
    .required("Please Enter Your mobile number"),
});

export const ChangePasswordSchemaProfile = Yup.object().shape({
  oldPassword: Yup.string().required("Please Enter Your Old Password"),
  newPassword: Yup.string()
    .required(" Please enter New Password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z0-9!@#$%^&*]{8,}$/,
      " Password must be 8 characters, one capital, one small letter, one special character and one number "
    )
    .max(32, " New Password cannot exceed 32 characters"),

  confirmPassword: Yup.string()
    .required(" Please enter Confirm Password")
    .oneOf(
      [Yup.ref("newPassword")],
      " New Password and Confirm Password should be the same"
    ),
});

export const AddUserSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .matches(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/, "This is not a valid format")
    .oneOf([Yup.ref("email"), ""], "Email must match")
    .required("Please Enter Your Email"),
  password: Yup.string()
    .required("Please Enter Your Password")
    .min(8, "Must Contain 8 Characters")
    .matches(/^(?=.*[0-9])/, "  Must Contain One Number Character"),
  name: Yup.string()
    .required("Please Enter Your Name")
    .min(3, "Must Contain 3 Characters"),
  role: Yup.string().required("Please Enter Your Role"),
  mobileNumber: Yup.number()
    .min(1111111111, "Please Enter valid mobile number")
    .max(9999999999, "Please Enter valid mobile number")
    .required("Please Enter Your mobile number"),
});

//terms and condition

export const termsConditionSchema = Yup.object().shape({
  title: Yup.string().required("Please Enter Your Title"),
  description: Yup.string().required("Please Enter Your Description"),
});
export const paymentTermsConditionSchema = Yup.object().shape({
  title: Yup.string().required("Please Enter Your Title"),
  description: Yup.string().required(
    "Please Enter Your Payment Terms_Condition"
  ),
});
export const orientationSessionSchema = Yup.object().shape({
  title: Yup.string().required("Please Enter Your Orientation Title"),
  // .matches(/^[a-zA-Z0-9]*$/, "No Space And Special Character Allowed"),
  description: Yup.string().required(
    "Please Enter Your Orientation Description"
  ),
});
export const operationalSessionSchema = Yup.object().shape({
  title: Yup.string().required("Please Enter Your Operational Title"),
  // .matches(/^[a-zA-Z0-9]*$/, "No Space And Special Character Allowed"),
  description: Yup.string().required(
    "Please Enter Your Operational Description"
  ),
});
export const trainingSessionSchema = Yup.object().shape({
  title: Yup.string().required("Please Enter Your Training Title"),
  // .matches(/^[a-zA-Z0-9]*$/, "No Space And Special Character Allowed"),
  // .matches(/^(?!^\s+$).*/, "No Space And Special Character Allowed"),
  description: Yup.string().required("Please Enter Your Training Description"),
});

// Role
export const roleSchema = Yup.object().shape({
  roleName: Yup.string()
    .required("Please Enter Role")
    .matches(/^(?!\s)[\s\S]*$/, "No Spaces Allowed At The Start")
});

export const privacyPolicySchema = Yup.object().shape({
  title: Yup.string().required("Please Enter Your Title"),
  description: Yup.string().required("Please Enter Your Description"),
});
export const EditUserSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .matches(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/, "This is not a valid format")
    .oneOf([Yup.ref("email"), ""], "Email must match")
    .required("Please Enter Your Email"),
  name: Yup.string()
    .required("Please Enter Your Name")
    .min(3, "Must Contain 3 Characters"),
  role: Yup.string().required("Please Enter Your Role"),
  mobileNumber: Yup.number()
    .min(1111111111, "Please Enter valid mobile number")
    .max(9999999999, "Please Enter valid mobile number")
    .required("Please Enter Your mobile number"),
});

export const addGameSchema = Yup.object().shape({
  name: Yup.string()
    .required("Please Enter Your Name")
    .min(3, "Must Contain 3 Characters"),
});

export const editGameSchema = Yup.object().shape({
  name: Yup.string()
    .required("Please Enter Your Name")
    .min(3, "Must Contain 3 Characters"),
});

export const addGenreSchema = Yup.object().shape({
  title: Yup.string().required("Please Enter Your Title"),
  shortDescription: Yup.string().required("Please Enter Your ShortDescription"),
});
export const GeneralSettingSchema = Yup.object().shape({
  minimum_Payment: Yup.number()

    .required("Please Enter Your Minimum Amount")
    .max(100000, "Franchise Amount must be less than 100,000"),
  maximum_Payment: Yup.number()

    .required("Please Enter Your Maximum Amount")
    .max(500000, "Franchise Amount must be less than 500,000"),
  mobile_number: Yup.number()
    .min(1111111111, "Please Enter valid Mobile Number")
    .max(9999999999, "Please Enter valid Mobile Number")
    .required("Please Enter Your Mobile Number"),
});

export const updateFranchiseUserSchema = Yup.object().shape({
  name: Yup.string()
    .required("Please Enter Valid Name")
    .matches(
      /^(?!\s)[A-Za-z\s]+$/,
      "Name Must Have Only Capital and Small Letters"
    ),
  mobile: Yup.string()
    .required("Please Enter Your Mobile Number")
    .matches(phoneRegex, "Please Enter Valid Phone Number"),

  city: Yup.string()
    .required("Please Enter Your City Name")
    .matches(
      /^(?!\s)[A-Za-z\s]+$/,
      "City Name Must Have Only Capital and Small Letters"
    ),
    email: Yup.string()
    .email("Invalid Email Format")
    .matches(
      /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9.-]+$/,
      "Invalid email format"
    )
    .oneOf([Yup.ref("email"), ""], "Email must match")
    .required("Please Enter Email"),
  pincode: Yup.number().required("Please Enter Your Pin Code"),
});
export const couponSchema = Yup.object().shape({
  title: Yup.string()
    .required("Please Enter Title")
    .matches(
      /^(?!\s)[A-Za-z\s]+$/,
      "Title Must Have Only Capital and Small Letters"
    ),
  discount_amount: Yup.string()
    .required("Please Enter Discount Amount")
    .test("valid-amount", function (value) {
      if (!value) {
        return this.createError({ message: "Please Enter Discount Amount" });
      }
      if (/^0+$/.test(value)) {
        return this.createError({
          message: "Amount Start from 0 is not allowed",
        });
      }
      if (!/^(0|[1-9]\d*)(\.\d{1,2})?$/.test(value)) {
        return this.createError({ message: "Please enter a valid amount" });
      }
      return true;
    }),
  // .matches(/^(0|[1-9]\d*)(\.\d{1,2})?$/, "Please enter a valid amount. Start from 0 is not allowed."),
  // .matches(/^-?(\d+(\.\d{1,2})?|\.\d{1,2})$/, "Please Enter Valid Amount"),
  description: Yup.string().required("Please Enter Terms & Condition"),

  start_date: Yup.date().required("Start date is required"),

  end_date: Yup.date()
    .required("End date is required")
    .min(Yup.ref("start_date"), "End Date Must Be Greater Than start date"),
  start_time: Yup.string().required("Please Enter Start Time"),
  end_time: Yup.string().required("Please Enter End Time"),
});

export const propertySchema = () =>
  Yup.object().shape({
    property_type: Yup.string().required("Property Type Is Required"),

    // Other fields...

    property_name: Yup.string()
      .test(
        "property_name-validation",
        "Property Name Is Required",
        function (value) {
          const propertyType = this.resolve(Yup.ref("property_type"));
          if (propertyType !== "Land") {
            return !!value;
          }
          return true;
        }
      )
      .matches(
        /^(?!\s)[A-Za-z\s]+$/,
        "Property Name Must Have Only Capital and Small Letters"
      ),

    // carpet_area: Yup.number().test(
    //   "carpet_area-validation",
    //   "Carpet Area is required ",
    //   function (value) {
    //     const propertyType = this.resolve(Yup.ref("property_type"));
    //     if (propertyType !== "Land") {
    //       return !!value;
    //     }
    //     return true;
    //   }
    // ),

    floor_number: Yup.number().test(
      "floor_number-validation",
      "Floor Number Is Required ",
      function (value) {
        const propertyType = this.resolve(Yup.ref("property_type"));
        if (propertyType !== "Land") {
          return !!value;
        }
        return true;
      }
    ),

    floor_height: Yup.number().test(
      "floor_height-validation",
      "Floor Height Is Required ",
      function (value) {
        const propertyType = this.resolve(Yup.ref("property_type"));
        if (propertyType !== "Land") {
          return !!value;
        }
        return true;
      }
    ),

    city: Yup.string()
      .test("city-validation", "City Is Required ", function (value) {
        const propertyType = this.resolve(Yup.ref("property_type"));
        if (propertyType !== "Land") {
          return !!value;
        }
        return true;
      })
      .matches(
        /^(?!\s)[A-Za-z\s]+$/,
        "City Name Must Have Only Capital and Small Letters"
      ),

    pincode: Yup.string().test(
      "pincode-validation",
      "Pin Code Is Required ",
      function (value) {
        const propertyType = this.resolve(Yup.ref("property_type"));
        if (propertyType !== "Land") {
          return !!value;
        }
        return true;
      }
    ),

    state: Yup.string().test(
      "state-validation",
      "State Is Required ",
      function (value) {
        const propertyType = this.resolve(Yup.ref("property_type"));
        if (propertyType !== "Land") {
          return !!value;
        }
        return true;
      }
    ),

    project_status: Yup.string().test(
      "project_status-validation",
      "Project Status Is Required ",
      function (value) {
        const propertyType = this.resolve(Yup.ref("property_type"));
        if (propertyType !== "Land") {
          return !!value;
        }
        return true;
      }
    ),

    franchise_amount: Yup.number()
      .test(
        "franchise_amount-validation",
        "Franchise Amount Is Required ",
        function (value) {
          const propertyType = this.resolve(Yup.ref("property_type"));
          if (propertyType !== "Land" || propertyType === "Land") {
            return !!value;
          }
          return true;
        }
      )
      .max(499999, "Franchise Amount Must Be Less Than 500,000"),
    // admin_mobile_no: Yup.number().test(
    //   "admin_mobile_no-validation",
    //   "Mobile Number is required ",
    //   function (value) {
    //     const propertyType = this.resolve(Yup.ref("property_type"));
    //     if (propertyType !== "Land") {
    //       return !!value;
    //     }
    //     return true;
    //   }
    // ),
    // admin_mobile_no: Yup.string()
    //   .required("Mobile Number Is Required")
    //   .matches(phoneRegex, "Please Enter valid Mobile Number"),
    admin_mobile_no: Yup.string()
    .test(
      "admin_mobile_no-validation",
      "Mobile Number Is Required",
      function (value) {
        const propertyType = this.resolve(Yup.ref("property_type"));
        if (propertyType === "Land") {
          return true;  // No error for "Land" type
        }
        return !!value;
      }
    )
    .matches(phoneRegex, "Please Enter valid Mobile Number"),
    // latitude: Yup.number().test(
    //   "latitude-validation",
    //   "latitude is required ",
    //   function (value) {
    //     const propertyType = this.resolve(Yup.ref("property_type"));
    //     if (propertyType !== "Land" || propertyType === "Land") {
    //       return !!value;
    //     }
    //     return true;
    //   }
    // ),
    // longitude: Yup.number().test(
    //   "longitude-validation",
    //   "longitude is required ",
    //   function (value) {
    //     const propertyType = this.resolve(Yup.ref("property_type"));
    //     if (propertyType !== "Land" || propertyType === "Land") {
    //       return !!value;
    //     }
    //     return true;
    //   }
    // ),
    latitude: Yup.string()
      .required("Latitude Is Required")
      .matches(latitudeRegex, "Please Enter Valid Latitude"),
    longitude: Yup.string()
      .required("Longitude Is Required")
      .matches(longitudeRegex, "Please Enter Valid Longitude"),
      // carpet_area: Yup.string()
      // .required("Carpet Area Is Required")
      // .test(
      //   'is-greater-than-or-equal-3500',
      //   'Carpet Area Must Be Greater Than Or Equal To 3,500',
      //   value => parseInt(value, 10) >= 3500
      // ),
      carpet_area: Yup.string()
      .test(
        "carpet_area-validation",
        "Carpet Area Is Required",
        function (value) {
          const propertyType = this.resolve(Yup.ref("property_type"));
          if (propertyType === "Land") {
            return true;  // No error for "Land" type
          }
          return !!value;
        }
      )
      .test(
        'is-greater-than-or-equal-3500',
        'Carpet Area Must Be Greater Than Or Equal To 3,500',
        function (value) {
          const propertyType = this.resolve(Yup.ref("property_type"));
          if (propertyType === "Land") {
            return true;  // No error for "Land" type
          }
          return parseInt(value, 10) >= 3500;
        }
      ),
    // carpet_area: Yup.string().required("Carpet Area is required").min(3500,"Carpet Area must be grater than 3,500"),

    size_of_the_land: Yup.string()
      .test(
        "size_of_the_land-validation",
        "Size Of The Land Is Required ",
        function (value) {
          const propertyType = this.resolve(Yup.ref("property_type"));
          if (propertyType === "Land") {
            return !!value;
          }
          return true;
        }
      )
      // .matches(/^[a-zA-Z0-9]+$/, "Please Enter Valid Size Of The Land"),
      .matches(
        /^[A-Za-z0-9][A-Za-z0-9\s]*[A-Za-z0-9]$/,
        "Please Enter Size Of The Land IN Sq.Ft Or Acre"
      ),

    land_connectivity: Yup.string().test(
      "land_connectivity-validation",
      "Land Connectivity Is Required ",
      function (value) {
        const propertyType = this.resolve(Yup.ref("property_type"));
        if (propertyType === "Land") {
          return !!value;
        }
        return true;
      }
    ),

    land_type: Yup.string().test(
      "land_type-validation",
      "Land Type Is Required ",
      function (value) {
        const propertyType = this.resolve(Yup.ref("property_type"));
        if (propertyType === "Land") {
          return !!value;
        }
        return true;
      }
    ),
  });

export const validateAdminUser = Yup.object().shape({
  name: Yup.string()
    .required("Please Enter User Name")
    .matches(
      // /^(?!\s)[A-Za-z\s]+$/,
      // /^[a-zA-Z]+( [a-zA-Z]+){0,2}$/,
      // /^(?:[a-zA-Z]+ ?){1,2}[a-zA-Z]+$/,
      /^[a-zA-Z]+( [a-zA-Z]+){0,2}(?![^\s]*\s[a-zA-Z]+)$/,
      "Employee Name Must Have Only Capital and Small Letters"
    ),
  email: Yup.string()
    .email("Invalid email format")
    .matches(
      /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9.-]+$/,
      "Invalid email format"
    )
    .oneOf([Yup.ref("email"), ""], "Email must match")
    .required("Please Enter Email For User"),
  // mobileNumber: Yup.number()
  //   .min(1111111111, "Please Enter valid Mobile Number")
  //   .max(9999999999, "Please Enter valid Mobile Number")
  //   .required("Please Enter Your Mobile Number"),
  mobileNumber: Yup.string()
    .required("Please Enter Your Mobile Number")
    .matches(phoneRegex, "Please Enter valid Mobile Number"),

  role: Yup.string().required("Please Select Role For User"),
  password: Yup.string()
    .required(" Please Enter Password For User")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z0-9!@#$%^&*]{8,}$/,
      " Password must be 8 characters, one capital, one small letter, one special character and one number "
    )
    .max(32, " New Password cannot exceed 32 characters"),
});
export const validateUpdateAdminUser = Yup.object().shape({
  name: Yup.string()
    .required("Please Enter User Name")
    .matches(
      // /^(?!\s)[A-Za-z\s]+$/,
      // /^[a-zA-Z]+( [a-zA-Z]+){0,2}$/,
      /^(?:[a-zA-Z]+ ?){1,2}[a-zA-Z]+$/,
      "Employee Name Must Have Only Capital and Small Letters"
    ),
  email: Yup.string()
    .email("Invalid email format")
    .matches(
      /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9.-]+$/,
      "Invalid email format"
    )
    .oneOf([Yup.ref("email"), ""], "Email must match")
    .required("Please Enter Email For User"),
  mobileNumber: Yup.number()
    .min(1111111111, "Please Enter valid Mobile Number")
    .max(9999999999, "Please Enter valid Mobile Number")
    .required("Please Enter Your Mobile Number"),
  role: Yup.string().required("Please Select Role For User"),
});

export const updateRebrandingPropertySchema = Yup.object().shape({
  property_name: Yup.string()
    .required("Property Name Is Required")
    .matches(
      /^(?!\s)[A-Za-z\s]+$/,
      "Property Name Must Have Only Capital and Small Letters"
    ),
  cinema_name: Yup.string()
    .required("Cinema Name Is Required")
    .matches(
      /^(?!\s)[A-Za-z\s]+$/,
      "Property Name Must Have Only Capital and Small Letters"
    ),
    area_of_property: Yup.string()
    .required("Please Enter Area Of Property")
    .test(
      'is-greater-than-or-equal-3500',
      'Carpet Area must be greater than or equal to 3,500',
      value => parseInt(value, 10) >= 3500
    ),
  // area_of_property: Yup.string().required("Please Enter Area Of Property").min(3499,"Carpet Area must be grater than 3,500"),

  city: Yup.string()
    .required("City Is Required")
    .matches(
      /^(?!\s)[A-Za-z\s]+$/,
      "City Name Must Have Only Capital and Small Letters"
    ),

  pincode: Yup.string().required("Pin code Is Required"),
  state: Yup.string().required("State Is Required"),
  no_of_sheets: Yup.number().required("No Of Sheets Is Required"),
  no_of_screens: Yup.number().required("No Of Screens Is Required"),
  existing_business: Yup.string().required("Existing Business Is Required"),
});

export const stateSchema = Yup.object().shape({
  stateName: Yup.string()
    .required("State is required")
    .matches(/^(?!\s)[A-Za-z\s]+$/, "State Name allows only character"),
  // GSTNumber: Yup.string()
  //   .required("GST Number is required"),
});
