import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import {
  getAllStaffHiringList,
  statusStaffHiring,
} from "../../../../redux/slices/AdminService";
import PageIndex from "../../../PageIndex";
import { singleStaffHiringData } from "../../../../redux/slices/AdminSlice";

export default function StaffHiringList() {
  let navigate = PageIndex.useNavigate();
  const dispatch = PageIndex.useDispatch();
  const { staffHiringList } = PageIndex.useSelector((state) => state.admin);

  const { adminData } = PageIndex.useSelector((store) => store.admin);
  const [search, setSearch] = useState("");
  const [pageData, setPageData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    setLoading(true); // Set loading state to true before fetching data
    setTimeout(() => {
      // Simulating a delay of 1 seconds
      dispatch(getAllStaffHiringList())
        .then((val) => {
          if (val?.payload?.status === 200) {
            dispatch(getAllStaffHiringList());
          }
          setLoading(false); // Set loading state to false after data is fetched
        })
        .catch((error) => {
          setLoading(false); // Set loading state to false if there's an error
        });
    }, 1000);
  }, []);

  useEffect(() => {
    const filtered = staffHiringList
      ?.filter((item) => {
        return !search
          ? item
          : item?.designation?.toLowerCase().includes(search.toLowerCase()) ||
              item?.user_id?.name
                ?.toLowerCase()
                .includes(search.toLowerCase()) ||
              item?.totalExperience?.toString().includes(search.toString()) ||
              item?.salary?.toString().includes(search.toString()) ||
              item?.numberOfVacancy?.toString().includes(search.toString());
      })
      ?.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateB - dateA;
      });
    if (filtered) {
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  }, [staffHiringList, search]);

  const statusFranchiseData = (id, status) => {
    // setLoading(true);
    dispatch(statusStaffHiring({ isActive: status, id })).then((val) => {
      // setLoading(false);
      dispatch(getAllStaffHiringList());
    });
  };
  if (
    (adminData && adminData?.role?.permission?.includes("Staff_view")) ||
    (adminData && adminData?.isAdmin === true)
  ) {
  return (
    <>
      <PageIndex.LoadingOverlay
        spinner
        text="Loading your content..."
        active={loading}
      >
        <Index.Box className="dashboard-content">
          <Index.Box className="user-list-flex">
            <Index.Box className="admin-page-title-main">
              <Index.Typography
                className="admin-page-title"
                component="h2"
                variant="h2"
              >
                Staff Hiring List
              </Index.Typography>
            </Index.Box>
            <Index.Box className="userlist-btn-flex">
              <Index.Box className="user-search-main">
                <Index.Box className="user-search-box">
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      placeholder="Search Franchise User"
                      value={search}
                      onChange={(e) => {
                        const inputValue = e.target.value
                          .replace(/^\s+/, "")
                          .replace(/\s\s+/g, " ");
                        setSearch(inputValue);
                      }}
                    />
                    <span className="search-icon-box">
                      <img
                        alt="search_icon"
                        src={Index.Svg.search}
                        className="search-icon"
                      />
                    </span>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="flex-all user-list-inner-flex"></Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="admin-dashboard-list-row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row-userlist"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dash-box">
                    <Index.Box className="staff-hiring-table-main page-table-main">
                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                      >
                        <Index.Table
                          sx={{ minWidth: 650 }}
                          aria-label="sticky table"
                          className="table"
                        >
                          <Index.TableHead className="table-head sticky">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                No.
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Designation
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Vacancy
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Experience
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Salary
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Valid Till
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Created By
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Action
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {pageData?.length ? (
                              pageData?.map((row, ind) => (
                                <Index.TableRow
                                  key={row._id}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td"
                                    align="center"
                                  >
                                    {row.sNo ? row.sNo : "-"}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td"
                                    align="center"
                                  >
                                    {row?.designation ? row?.designation : "-"}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td"
                                    align="center"
                                  >
                                    {row?.numberOfVacancy
                                      ? row?.numberOfVacancy
                                      : "-"}
                                  </Index.TableCell>

                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td"
                                    align="center"
                                  >
                                    {row?.totalExperience
                                      ? row?.totalExperience
                                      : "-"}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td"
                                    align="center"
                                  >
                                    {row?.salary ? row?.salary : "-"}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                    align="center"
                                  >
                                    {row.endDate
                                      ? new Date(
                                          row.endDate
                                        ).toLocaleDateString("en-GB")
                                      : "-"}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                    align="center"
                                  >
                                    {row?.user_id !== null
                                      ? row?.user_id?.name
                                      : "---"}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                    align="center"
                                  >
                                    <Index.Box className="userdata-btn-flex">
                                      {(adminData &&
                                        adminData?.role?.permission?.includes(
                                          "Staff_view"
                                        )) ||
                                      (adminData &&
                                        adminData?.isAdmin === true) ? (
                                        <>
                                          <Index.IconButton
                                            color="primary"
                                            aria-label="upload picture"
                                            component="label"
                                            onClick={() => {
                                              dispatch(
                                                singleStaffHiringData(row)
                                              );
                                              navigate(
                                                "/dashboard/view-staff-hiring",
                                                {
                                                  state: row,
                                                }
                                              );
                                            }}
                                          >
                                            <Index.RemoveRedEyeIcon />
                                          </Index.IconButton>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </Index.Box>
                                  </Index.TableCell>
                                </Index.TableRow>
                              ))
                            ) : (
                              <Index.TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td no-record-text"
                                  align="center"
                                  colSpan={7}
                                >
                                  No records found
                                </Index.TableCell>
                              </Index.TableRow>
                            )}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="pagination-main">
            <PageIndex.Pagination
              fetchData={filteredData}
              setPageData={setPageData}
              pageData={pageData}
              search={search}
            />
          </Index.Box>
        </Index.Box>
      </PageIndex.LoadingOverlay>
    </>
  );
} else {
  navigate("/dashboard");
}
}
