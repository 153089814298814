import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Index from "../../../Index";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import PageIndex from "../../../PageIndex";
import { imageUrl } from "../../../../config/dataService";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./FranchiseUser.css";
import { useNavigate } from "react-router-dom";

const ViewProperty = () => {
  const navigate = useNavigate();
  const { editFranchise, adminData } = PageIndex.useSelector(
    (state) => state.admin
  );
  const [imageSizes, setImageSizes] = useState({});
  const handleImageLoad = (index, width, height) => {
    setImageSizes((prevSizes) => ({
      ...prevSizes,
      [index]: { width, height },
    }));
  };

  const mediaUrl = `${imageUrl}/${editFranchise?.image}`; // Set the correct URL based on the file type
  if (
    (adminData &&
      adminData?.role?.permission?.includes("Franchise User_view")) ||
    (adminData && adminData?.isAdmin === true)
  ) {
    return (
      <>
        <Index.Box>
          <Index.Box className="admin-page-title-main-viewcomponent">
            <Index.Typography
              className="admin-page-title-viewcomponent"
              // component="h4"
              // variant="h4"
            >
              View Franchise User Details
            </Index.Typography>
          </Index.Box>
          <Index.Box className="view-card">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 2, lg: 3 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 6",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="view-swiper-main">
                    <Swiper
                      spaceBetween={20}
                      pagination={{
                        clickable: true,
                      }}
                      breakpoints={{
                        280: {
                          slidesPerView: 1,
                        },
                        375: {
                          slidesPerView: 1,
                        },
                        640: {
                          slidesPerView: 1,
                        },
                        768: {
                          slidesPerView: 1,
                        },
                        1024: {
                          slidesPerView: 1,
                        },
                        1366: {
                          slidesPerView: 1,
                        },
                        1500: {
                          slidesPerView: 1,
                        },
                      }}
                      modules={[Pagination, Navigation]}
                      className="new-game-swiper mySwiper"
                    >
                      {!editFranchise?.image ? (
                        <SwiperSlide className="new-game-swiper-slide">
                          <Index.Box className="new-game-flex-li">
                            <Index.Box className="game-card"></Index.Box>
                            <Index.Box className="new-game-card">
                              <img
                                src={Index.Png.staticLogoImage}
                                className="static-use-image"
                                alt="logo"
                              />
                            </Index.Box>
                          </Index.Box>
                        </SwiperSlide>
                      ) : (
                        <SwiperSlide className="new-game-swiper-slide">
                          <Index.Box className="new-game-flex-li">
                            <Index.Box className="new-game-card">
                              {
                                <img
                                  alt="img"
                                  src={mediaUrl}
                                  className="new-game-img"
                                  onLoad={(e) =>
                                    handleImageLoad(
                                      // index,
                                      e.target.naturalWidth,
                                      e.target.naturalHeight
                                    )
                                  }
                                  // style={imageStyle}
                                />
                              }
                            </Index.Box>
                          </Index.Box>
                        </SwiperSlide>
                      )}
                    </Swiper>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 6",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="view-franchises-details-box view-franchise-box">
                    <Index.Typography
                      variant="h4"
                      className="proerty-details-titles view-frenchises-user-title"
                    >
                      {editFranchise?.name ? editFranchise?.name : "---"}
                    </Index.Typography>
                    <Index.Box className="property-flex">
                      <Index.Typography className="property-details-data-text">
                        <img
                          src={Index.Svg.propertylocation}
                          alt="sidebar icon"
                          className="franchises-icons"
                        />
                        {`${
                          editFranchise?.city ? editFranchise?.city : "---"
                        }, ${
                          editFranchise?.pincode
                            ? editFranchise?.pincode
                            : "---"
                        }`}
                      </Index.Typography>
                    </Index.Box>

                    <Index.Box className="property-detail-box view-user-property-box">
                      <Index.Box className="property-flex">
                        <Index.Typography className="property-lables">
                          {" "}
                          <img
                            src={Index.Svg.propertyuser}
                            alt="sidebar icon"
                            className="franchises-icons"
                          />
                          User Name
                        </Index.Typography>
                        <Index.Typography className="property-details-data-text">
                          {`  ${
                            editFranchise?.name ? editFranchise?.name : "---"
                          }`}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="property-flex">
                        <Index.Typography className="property-lables">
                          <img
                            src={Index.Svg.propertyemail}
                            alt="sidebar icon"
                            className="franchises-icons"
                          />
                          User Email
                        </Index.Typography>
                        <Index.Typography className="property-details-data-text">
                          {`  ${
                            editFranchise?.email ? editFranchise?.email : "---"
                          }`}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="property-flex">
                        <Index.Typography className="property-lables">
                          {" "}
                          <img
                            src={Index.Svg.propertycall}
                            alt="sidebar icon"
                            className="franchises-icons"
                          />
                          User mobile
                        </Index.Typography>
                        <Index.Typography className="property-details-data-text">
                          {`  ${
                            editFranchise?.mobile
                              ? editFranchise?.mobile
                              : "---"
                          }`}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="property-flex">
                        <Index.Typography className="property-lables">
                          {" "}
                          <img
                            src={Index.Png.panCard}
                            alt="sidebar icon"
                            className="franchises-icons"
                          />
                          User{" "}
                          {editFranchise?.docType === "Pan Card Number"
                            ? "Pan Card"
                            : "GST"}{" "}
                          No.
                        </Index.Typography>
                        <Index.Typography className="property-details-data-text">
                          {`  ${
                            editFranchise?.docNumber
                              ? editFranchise?.docNumber
                              : "---"
                          }`}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="property-flex">
                        <Index.Typography className="property-lables">
                          {" "}
                          <img
                            src={Index?.Png?.voucherTitle}
                            alt="sidebar icon"
                            className="franchises-icons"
                          />
                          Coupon Title
                        </Index.Typography>
                        <Index.Typography className="property-details-data-text">
                          {`  ${
                            editFranchise?.coupon_id?.title
                              ? editFranchise?.coupon_id?.title
                              : "---"
                          }`}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="property-flex">
                        <Index.Typography className="property-lables">
                          {" "}
                          <img
                            src={Index?.Png?.voucherTitle}
                            alt="sidebar icon"
                            className="franchises-icons"
                          />
                          Coupon Code
                        </Index.Typography>
                        <Index.Typography className="property-details-data-text">
                          {`  ${
                            editFranchise?.coupon_id?.coupon_code
                              ? editFranchise?.coupon_id?.coupon_code
                              : "---"
                          }`}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="property-flex">
                        <Index.Typography className="property-lables">
                          {" "}
                          <img
                            src={Index?.Png?.voucherDiscount}
                            alt="sidebar icon"
                            className="franchises-icons"
                          />
                          Coupon Discount
                        </Index.Typography>
                        <Index.Typography className="property-details-data-text">
                          {editFranchise?.coupon_id
                            ? `  ${
                                editFranchise?.coupon_id?.discount_type ===
                                "flat"
                                  ? `	₹ ${editFranchise?.coupon_id?.discount_amount?.toLocaleString(
                                      "en-IN"
                                    )}`
                                  : ` ${editFranchise?.coupon_id?.discount_amount?.toLocaleString(
                                      "en-IN"
                                    )}`
                              }`
                            : "---"}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </>
    );
  } else {
    navigate("/dashboard");
  }
};

export default ViewProperty;
