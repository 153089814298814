import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import {
  addEmployeeRoleData,
  getAllRoleData,
  updateEmployeeRoleData,
} from "../../../../redux/slices/AdminService";
import {
  validateAdminUser,
  validateUpdateAdminUser,
} from "../../../../validation/Validation";
const AddEmployeeRole = () => {
  const dispatch = PageIndex.useDispatch();
  const navigate = PageIndex.useNavigate();
  const params = PageIndex.useLocation();
  const { roleList, adminData } = PageIndex.useSelector((state) => state.admin);
  const { editEmployeeRoledata } = PageIndex.useSelector(
    (state) => state.admin
  );
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const id = params?.state;

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword = (field) => {
    switch (field) {
      case "password":
        setShowNewPassword((show) => !show);
        break;

      default:
        break;
    }
  };
  const initialValues = {
    name: id ? editEmployeeRoledata?.name : "",
    email: id ? editEmployeeRoledata?.email : "",
    mobileNumber: id ? editEmployeeRoledata?.mobileNumber : "",
    role: id ? editEmployeeRoledata?.role?._id : "",
    password: id ? editEmployeeRoledata?.password : "",
  };

  const handleSubmit = (values, { setSubmitting }) => {
    setIsLoading(true);
    if (id) {
      values.id = id;
    }
    const action = id
      ? updateEmployeeRoleData(values)
      : addEmployeeRoleData(values);
    console.log(values, "values");
    dispatch(action)
      .then((val) => {
        console.log(val, 55);
        if (val?.payload?.status === 201 || val?.payload?.status === 200) {
          navigate("/dashboard/employee-role-list");
        }
        setSubmitting(false);
        setIsLoading(false);
      })
      .catch(() => {
        setSubmitting(false);
        setIsLoading(false);
      });
  };
  // const handleSubmit = (values) => {
  //   setIsLoading(true)
  //   if (id) {
  //     values.id = id;
  //   }
  //   !id
  //     ? dispatch(addEmployeeRoleData(values)).then((val) => {
  //       if (val?.payload?.status==201) {
  //         navigate("/dashboard/employee-role-list");
  //         setIsLoading(false);
  //         }
  //         else{
  //           setIsLoading(false);
  //         }
  //       })
  //     : dispatch(updateEmployeeRoleData(values)).then((val) => {
  //       if (val?.payload?.status==200) {
  //         navigate("/dashboard/employee-role-list");
  //         setIsLoading(false);
  //         }
  //         else{
  //           setIsLoading(false);
  //         }
  //       });
  // };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    dispatch(getAllRoleData());
  }, []);
  if (
    (adminData && adminData?.role?.permission?.includes("Admin User_add")) ||
    adminData?.role?.permission?.includes("Admin User_edit") ||
    (adminData && adminData?.isAdmin === true)
  ) {
    return (
      <PageIndex.LoadingOverlay
        spinner
        text="Loading your content..."
        active={loading}
      >
        {!loading && (
          <Index.Box className="dashboard-content add-property-containt">
            <Index.Typography
              className="admin-page-title add-title"
              component="h2"
              variant="h2"
            >
              {id ? "Update" : "Add "} Admin User
            </Index.Typography>
            <Index.Box className="page-border">
              <PageIndex.Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={
                  !id ? validateAdminUser : validateUpdateAdminUser
                }
              >
                {({
                  values,
                  errors,
                  handleChange,
                  handleBlur,
                  touched,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <PageIndex.Form>
                    <Index.Box className="add-user-data-main">
                      <Index.Box sx={{ width: 1 }} className="grid-main">
                        <Index.Box
                          display="grid"
                          className="display-row-add-user"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                        >
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box add-user-input">
                              <Index.FormHelperText className="form-lable">
                                Employee Name*
                              </Index.FormHelperText>
                              <Index.Box className="form-group">
                                <PageIndex.Field
                                  name="name"
                                  as={Index.TextField}
                                  variant="outlined"
                                  fullWidth
                                  id="fullWidth"
                                  className="form-control"
                                  onChange={(event) => {
                                    const inputValue = event.target.value;
                                    if (inputValue.length <= 30) {
                                      handleChange(event);
                                    }
                                  }}
                                  maxLength={30}
                                  onBlur={handleBlur}
                                  error={Boolean(errors.name)}
                                />
                                {errors?.name && touched?.name ? (
                                  <Index.Typography className="error-msg">
                                    {errors?.name}
                                  </Index.Typography>
                                ) : null}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box add-user-input">
                              <Index.FormHelperText className="form-lable">
                                Mobile No.*
                              </Index.FormHelperText>
                              <Index.Box className="form-group">
                                <PageIndex.Field
                                  name="mobileNumber"
                                  type="tel"
                                  as={Index.TextField}
                                  variant="outlined"
                                  fullWidth
                                  id="fullWidth"
                                  className="form-control"
                                  onBlur={handleBlur}
                                  onChange={(event) => {
                                    const inputValue = event.target.value;
                                    if (inputValue.length <= 10) {
                                      setFieldValue("mobileNumber", inputValue);
                                    }
                                  }}
                                  maxLength={10}
                                  error={Boolean(errors.mobileNumber)}
                                />
                                {errors?.mobileNumber &&
                                touched?.mobileNumber ? (
                                  <Index.Typography className="error-msg">
                                    {errors?.mobileNumber}
                                  </Index.Typography>
                                ) : null}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>

                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box add-user-input">
                              <Index.FormHelperText className="form-lable">
                                Email*
                              </Index.FormHelperText>
                              <Index.Box className="form-group">
                                <PageIndex.Field
                                  name="email"
                                  as={Index.TextField}
                                  variant="outlined"
                                  fullWidth
                                  id="fullWidth"
                                  className="form-control"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={Boolean(errors.email)}
                                />
                                {errors?.email && touched?.email ? (
                                  <Index.Typography className="error-msg">
                                    {errors?.email}
                                  </Index.Typography>
                                ) : null}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          {!id && (
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box change-pass-input-box">
                                <Index.FormHelperText className="form-lable">
                                  Password*
                                </Index.FormHelperText>
                                <Index.Box className="form-group">
                                  <Index.TextField
                                    fullWidth
                                    name="password"
                                    className="form-control input-with-radius password-form-control"
                                    type={showNewPassword ? "text" : "password"}
                                    variant="outlined"
                                    autoComplete="off"
                                    inputProps={{
                                      className: "input_props",
                                    }}
                                    InputLabelProps={{
                                      className: "add-formlabel",
                                    }}
                                    FormHelperTextProps={{
                                      className: "input_label_props",
                                    }}
                                    value={values.password}
                                    onChange={handleChange}
                                    helperText={errors.password}
                                    error={Boolean(errors.password)}
                                    sx={{ mb: 3 }}
                                    InputProps={{
                                      // <-- This is where the toggle button is added.
                                      endAdornment: (
                                        <Index.InputAdornment position="end">
                                          <Index.IconButton
                                            className="passwrd-eye"
                                            aria-label="toggle password visibility"
                                            onClick={() =>
                                              handleClickShowPassword(
                                                "password"
                                              )
                                            }
                                            onMouseDown={
                                              handleMouseDownPassword
                                            }
                                            edge="end"
                                          >
                                            {showNewPassword ? (
                                              <Index.VisibilityOff />
                                            ) : (
                                              <Index.Visibility />
                                            )}
                                          </Index.IconButton>
                                        </Index.InputAdornment>
                                      ),
                                    }}
                                  />
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          )}
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box add-user-input">
                              <Index.FormHelperText className="form-lable">
                                Role*
                              </Index.FormHelperText>
                              <Index.Box className="form-group">
                                <PageIndex.Field
                                  name="role"
                                  as={Index.Select}
                                  variant="outlined"
                                  fullWidth
                                  id="fullWidth"
                                  className="dropdown-property"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={Boolean(errors.role)}
                                >
                                  <Index.MenuItem value="" disabled>
                                    Select State
                                  </Index.MenuItem>
                                  {roleList.map((role) => (
                                    <Index.MenuItem
                                      key={role?._id}
                                      value={role?._id}
                                    >
                                      {role?.roleName}
                                    </Index.MenuItem>
                                  ))}
                                </PageIndex.Field>
                                {errors?.role && touched?.role ? (
                                  <Index.Typography className="error-msg">
                                    {errors?.role}
                                  </Index.Typography>
                                ) : null}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="user-btn-flex">
                        <Index.Box className="save-btn-main border-btn-main">
                          <Index.Button
                            className="save-user-btn border-btn"
                            type="submit"
                            disabled={isLoading}
                          >
                            <img
                              alt="save_img"
                              src={Index.Svg.save}
                              className="user-save-icon"
                            ></img>
                            {id ? "Update" : " Add"} User
                          </Index.Button>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </PageIndex.Form>
                )}
              </PageIndex.Formik>
            </Index.Box>
          </Index.Box>
        )}
      </PageIndex.LoadingOverlay>
    );
  } else {
    navigate("/dashboard");
  }
};

export default AddEmployeeRole;
