import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import {
  downloadFranchiseInvoice,
  getAllFranchiseApplication,
} from "../../../../redux/slices/AdminService";
import { editfranchiseApplication } from "../../../../redux/slices/AdminSlice";
import dataService from "../../../../config/dataService";
import { toast } from "react-toastify";

export default function FranchiseApplicationList() {
  let navigate = PageIndex.useNavigate();
  const dispatch = PageIndex.useDispatch();
  const { adminData, franchiseApplicationList } = PageIndex.useSelector(
    (store) => store.admin
  );

  const [search, setSearch] = useState("");
  const [pageData, setPageData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    setLoading(true); // Set loading state to true before fetching data
    setTimeout(() => {
      // Simulating a delay of 1 seconds
      dispatch(getAllFranchiseApplication())
        .then((val) => {
          if (val?.payload?.status === 200) {
            dispatch(getAllFranchiseApplication());
          }
          setLoading(false); // Set loading state to false after data is fetched
        })
        .catch((error) => {
          setLoading(false); // Set loading state to false if there's an error
        });
    }, 1000);
  }, []);

  useEffect(() => {
    const filtered = franchiseApplicationList
      ?.filter((item) => {
        return !search
          ? item
          : item?.status_of_franchise
              .toLowerCase()
              .includes(search?.toLowerCase()) ||
              // item?.options.toLowerCase().includes(search?.toLowerCase()) ||
              item?.property_id?.property_name
                ?.toLowerCase()
                .includes(search?.toLowerCase()) ||
              item?.property_id?.property_type
                ?.toLowerCase()
                .includes(search?.toLowerCase()) ||
              item?.user_id?.name
                ?.toLowerCase()
                .includes(search?.toLowerCase()) ||
              item?.user_id?.mobile?.toString().includes(search?.toString()) ||
              item?.franchiseCode.toLowerCase().includes(search?.toLowerCase());
      })
      ?.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateB - dateA;
      });
    if (filtered) {
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  }, [franchiseApplicationList, search]);

  // const downloadInvoice = async (franchiseId) => {
  //   const franchiseCode = franchiseId.replace('#','')

  //   dispatch(downloadFranchiseInvoice(franchiseCode))
  //       .then((response) => {
  //         console.log(response);
  //       })
  //       .catch((error) => {
  //         setLoading(false); // Set loading state to false if there's an error
  //       });
  // }

  const downloadInvoice = async (franchiseId) => {
    const franchiseCode = franchiseId.replace("#", "");

    try {
      const response = await dataService.get(
        `user/get-invoice-PDF/${franchiseCode}`,
        {
          responseType: "blob", // Specify the response type as blob
        }
      );

      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = "filename.pdf";
      link.style.display = "none";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  if (
    (adminData && adminData?.role?.permission?.includes("Franchise Application_view")) ||
    (adminData && adminData?.isAdmin === true)
  ) {
  return (
    <>
      <PageIndex.LoadingOverlay
        spinner
        text="Loading your content..."
        active={loading}
      >
        <Index.Box className="dashboard-content">
          <Index.Box className="user-list-flex">
            <Index.Box className="admin-page-title-main">
              <Index.Typography
                className="admin-page-title"
                component="h2"
                variant="h2"
              >
                Franchise Application List
              </Index.Typography>
            </Index.Box>
            <Index.Box className="userlist-btn-flex">
              <Index.Box className="user-search-main">
                <Index.Box className="user-search-box">
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      placeholder="Search Franchise"
                      value={search}
                      onChange={(e) => {
                        const inputValue = e.target.value
                          .replace(/^\s+/, "")
                          .replace(/\s\s+/g, " ");
                        setSearch(inputValue);
                      }}
                    />
                    <span className="search-icon-box">
                      <img
                        alt="search_icon"
                        src={Index.Svg.search}
                        className="search-icon"
                      />
                    </span>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="admin-dashboard-list-row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row-userlist"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dash-box">
                    <Index.Box className="frenchies-application-table-main page-table-main">
                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                      >
                        <Index.Table
                          sx={{ minWidth: 650 }}
                          aria-label="sticky table"
                          className="table"
                        >
                          <Index.TableHead className="table-head sticky">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                No.
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Franchise Code
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                User Name
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Mobile No.
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Property Name
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Property Type
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Status
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Options
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Action
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {pageData?.length ? (
                              pageData?.map((row, ind) => (
                                <Index.TableRow
                                  key={row._id}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td"
                                    align="center"
                                  >
                                    {row.sNo?row.sNo:'-'}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td"
                                    align="center"
                                  >
                                    {row.franchiseCode?row.franchiseCode:'-'}
                                  </Index.TableCell>

                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td"
                                    align="center"
                                  >
                                    {row?.user_id === null
                                      ? "----"
                                      : row?.user_id?.name}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td"
                                    align="center"
                                  >
                                    {row?.user_id === null
                                      ? "----"
                                      : row?.user_id?.mobile}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td"
                                    align="center"
                                  >
                                    {!row.property_id
                                      ? row?.rebranding_id?.property_name
                                      : row.property_id?.property_type ===
                                        "Land"
                                      ? "Land"
                                      : row.property_id?.property_name}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td"
                                    align="center"
                                  >
                                    {!row.property_id
                                      ? "Rebranding"
                                      : row?.property_id?.property_type}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                    align="center"
                                  >
                                    <div
                                    // className={
                                    //   row?.isActive
                                    //     ? "table-td-active"
                                    //     : "table-td-Inactive"
                                    // }
                                    >
                                      {row?.status_of_franchise?row?.status_of_franchise:'-'}
                                    </div>
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td"
                                    align="center"
                                  >
                                    {row?.options?row?.options:'-'}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                    align="center"
                                  >
                                    <Index.Box className="userdata-btn-flex">
                                      {(adminData &&
                                        adminData?.role?.permission?.includes(
                                          "Franchise Application_view"
                                        )) ||
                                      (adminData &&
                                        adminData?.isAdmin === true) ? (
                                        <>
                                          <Index.IconButton
                                            color="primary"
                                            aria-label="upload picture"
                                            component="label"
                                            onClick={() => {
                                              dispatch(
                                                editfranchiseApplication(row)
                                              );
                                              navigate(
                                                "/dashboard/view-franchise-application",
                                                {
                                                  state: row,
                                                }
                                              );
                                            }}
                                          >
                                            <Index.RemoveRedEyeIcon />
                                          </Index.IconButton>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      <>
                                        <Index.IconButton
                                          color="primary"
                                          aria-label="upload picture"
                                          component="label"
                                          onClick={() => {
                                            downloadInvoice(
                                              row?.generateFranchiseId
                                            );
                                          }}
                                        >
                                          <Index.PictureAsPdfIcon />
                                        </Index.IconButton>
                                      </>
                                    </Index.Box>
                                  </Index.TableCell>
                                </Index.TableRow>
                              ))
                            ) : (
                              <Index.TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td no-record-text"
                                  align="center"
                                  colSpan={8}
                                >
                                  No records found
                                </Index.TableCell>
                              </Index.TableRow>
                            )}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="pagination-main">
            <PageIndex.Pagination
              fetchData={filteredData}
              setPageData={setPageData}
              pageData={pageData}
              search={search}
            />
          </Index.Box>
        </Index.Box>
      </PageIndex.LoadingOverlay>
    </>
  );
} else {
  navigate("/dashboard");
}
}
