
import loginbg from './images/png/login-bg.png';












const Jpg = {
      loginbg,
}

export default Jpg;