import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Index from "../../../Index";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import PageIndex from "../../../PageIndex";
import { imageUrl } from "../../../../config/dataService";
import FileModal from "../franchiseApplication/DocumentModal";
import "./RebrandingProperty.css";
import { Form, Field } from "formik";
import {
  handleAddRebrandingRejectComment,
  handleRebrandingStatus,
} from "../../../../redux/slices/AdminService";
import { editRebrandingProperty } from "../../../../redux/slices/AdminSlice";

const ViewRebrandingProperty = () => {
  const dispatch = PageIndex.useDispatch();
  const navigate = PageIndex.useNavigate();
  const { getRebrandingPropertyData,adminData } = PageIndex.useSelector(
    (state) => state.admin
  );
  const [fullComment, setFullComment] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [descriptionValue, setDescriptionValue] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [commentValidationMessage, setCommentValidationMessage] = useState("");
  const [imageSizes, setImageSizes] = useState({});
  const [flag, setFlag] = useState(false);
  const [value, setValue] = useState(
    getRebrandingPropertyData?.rebranding_status
  );
  const closeHandleModal = () => {
    setOpenModal(false);
    setFlag(false);
  };
  const handleOptionSelect = (option, setFieldValue) => {
    setFieldValue("rebranding_status", option);
    if (option === "Rejected") {
      setSelectedValue(option);
      setOpenModal(true); // Open the modal when "Rejected" option is selected // Close the menu regardless of the selected option
    } else {
      dispatch(
        handleRebrandingStatus({
          rebranding_id: getRebrandingPropertyData?._id,
          rebranding_status: option,
        })
      ).then((data) => {
        if (data?.payload?.status === 200) {
          dispatch(editRebrandingProperty(data?.payload?.data?.update));
        }
      });
    }
  };

  const handleRejectedComment = () => {
    const rejectCommentWords = descriptionValue.split(/\s+/);
    const rejectCommentData = {
      rebranding_status: selectedValue,
      reject_message: descriptionValue,
      rebranding_id: getRebrandingPropertyData?._id,
    };
    if (rejectCommentWords?.length >= 3) {
      dispatch(handleAddRebrandingRejectComment(rejectCommentData)).then(
        (data) => {
          if (data?.payload?.status === 200) {
            dispatch(editRebrandingProperty(data?.payload?.data?.update));
            setDescriptionValue("");
            setFlag(true);
            setValue("Rejected");
            closeHandleModal();
          }
        }
      );
      closeHandleModal();
    } else {
      setCommentValidationMessage(
        "Please Enter At Least Three Words (upload proper document)"
      );
    }
  };

  const handleImageLoad = (index, width, height) => {
    setImageSizes((prevSizes) => ({
      ...prevSizes,
      [index]: { width, height },
    }));
  };

  // Document Modal

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleOpenModal = (fileUrl) => {
    setSelectedFile(fileUrl.toString());
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedFile(null);
  };

  const initialValues = {
    rebranding_status: getRebrandingPropertyData?.rebranding_status,
  };
  const city = getRebrandingPropertyData?.property_location?.city;
  const state = getRebrandingPropertyData?.property_location?.state;
  const pincode = getRebrandingPropertyData?.property_location?.pincode;

  let address = "";

  if (city) {
    address += city;
  }

  if (state) {
    address += address ? `, ${state}` : state;
  }

  if (pincode) {
    address += address ? `, ${pincode}` : pincode;
  }
  if (
    (adminData && adminData?.role?.permission?.includes("Rebranding_view")) ||
    (adminData && adminData?.isAdmin === true)
  ) {
  return (
    <>
      <Index.Box>
        <Index.Box className="admin-page-title-main-viewcomponent">
          <Index.Typography
            className="admin-page-title-viewcomponent"
          >
            Covert Your Existing Cinema Details
          </Index.Typography>
        </Index.Box>
        <Index.Box className="view-card">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 2, lg: 3 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column"
              >
                <Index.Box className="view-swiper-main">
                  <Swiper
                    spaceBetween={20}
                    pagination={{
                      clickable: true,
                    }}
                    breakpoints={{
                      280: {
                        slidesPerView: 1,
                      },
                      375: {
                        slidesPerView: 1,
                      },
                      640: {
                        slidesPerView: 1,
                      },
                      768: {
                        slidesPerView: 1,
                      },
                      1024: {
                        slidesPerView: 1,
                      },
                      1366: {
                        slidesPerView: 1,
                      },
                      1500: {
                        slidesPerView: 1,
                      },
                    }}
                    modules={[Pagination, Navigation]}
                    className="new-game-swiper mySwiper"
                  >
                    {getRebrandingPropertyData?.images?.length === 0 ? (
                      <SwiperSlide className="new-game-swiper-slide">
                        <Index.Box className="new-game-flex-li">
                          <Index.Box className="game-card"></Index.Box>
                          <Index.Box className="new-game-card">
                            <img
                              src={Index.Png.staticLogoImage}
                              className="static-use-image"
                              alt="logo"
                            />
                          </Index.Box>
                        </Index.Box>
                      </SwiperSlide>
                    ) : (
                      getRebrandingPropertyData?.images?.map(
                        (element, index) => {
                          const isVideo =
                            element.endsWith(".mp4") ||
                            element.endsWith(".avi"); // Check if the element is a video file
                          const mediaUrl = isVideo
                            ? `${imageUrl}/${element}`
                            : `${imageUrl}/${element}`; // Set the correct URL based on the file type

                          const imageSize = imageSizes[index];
                          const imageStyle = imageSize
                            ? {
                                // maxWidth: "100%",
                                // maxHeight: "100%",
                              }
                            : {};

                          return (
                            <SwiperSlide
                              className="new-game-swiper-slide"
                              key={index}
                            >
                              <Index.Box className="new-game-flex-li">
                                <Index.Box className="new-game-card">
                                  {isVideo ? (
                                    <video className="new-game-img" controls>
                                      <source src={mediaUrl} type="video/mp4" />
                                      Your browser does not support the video
                                      tag.
                                    </video>
                                  ) : (
                                    <img
                                      alt="img"
                                      src={mediaUrl}
                                      className="new-game-img"
                                      onLoad={(e) =>
                                        handleImageLoad(
                                          index,
                                          e.target.naturalWidth,
                                          e.target.naturalHeight
                                        )
                                      }
                                      style={imageStyle}
                                    />
                                  )}
                                </Index.Box>
                              </Index.Box>
                            </SwiperSlide>
                          );
                        }
                      )
                    )}
                  </Swiper>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column"
              >
                <Index.Box className="view-franchises-details-box view-propert-box">
                  <PageIndex.Formik initialValues={initialValues}>
                    {({ values, setFieldValue }) => (
                      <Form>
                        <Index.Box className="input-box property-dropdown dropdown-select">
                          <Index.FormHelperText className="form-lable">
                            Rebranding Verification Status
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Field
                              name="rebranding_status"
                              as={Index.Select}
                              // label="Property Type"
                              variant="outlined"
                              fullWidth
                              id="fullWidth"
                              className={
                                getRebrandingPropertyData?.rebranding_status ===
                                "Approved"
                                  ? "dropdown-franchise-status form-control"
                                  : "dropdown-franchise-status form-control"
                              }
                              value={value}
                              onChange={(e) => {
                                if (
                                  e.target.value == "Rejected" &&
                                  flag == true
                                ) {
                                  setValue(e.target.value);
                                } else if (e.target.value == "Approved") {
                                  setValue(e.target.value);
                                }
                                handleOptionSelect(
                                  e.target.value,
                                  setFieldValue
                                );
                              }}
                            >
                              <Index.MenuItem disabled value="Pending">
                                Pending
                              </Index.MenuItem>
                              <Index.MenuItem value="Approved">
                                Approved
                              </Index.MenuItem>
                              <Index.MenuItem value="Rejected">
                                Rejected
                              </Index.MenuItem>
                            </Field>
                          </Index.Box>
                        </Index.Box>
                      </Form>
                    )}
                  </PageIndex.Formik>

                  {getRebrandingPropertyData?.reject_message && (
                    <Index.Typography
                      className="proerty-details-reject-message"
                    >
                      {getRebrandingPropertyData?.rebranding_status !==
                      "Approved"
                        ? getRebrandingPropertyData?.reject_message[
                            getRebrandingPropertyData.reject_message.length - 1
                          ]
                        : ""}
                    </Index.Typography>
                  )}
                  <Index.Typography
                    variant="h4"
                    className="proerty-details-titles"
                  >
                    {getRebrandingPropertyData?.cinema_name}
                  </Index.Typography>
                  {address && (
                    <Index.Box className="property-flex">
                      <Index.Typography className="property-lables">
                        <img
                          src={Index.Svg.propertylocation}
                          alt="sidebar icon"
                          className="franchises-icons"
                        />
                        {address}
                        {/* {`${getRebrandingPropertyData?.property_location?.city},${getRebrandingPropertyData?.property_location?.state},${getRebrandingPropertyData?.property_location?.pincode}`} */}
                      </Index.Typography>
                    </Index.Box>
                  )}

                  <Index.Box className="property-detail-box view-user-property-box">
                    <Index.Box className="property-flex">
                      <Index.Typography className="property-lables">
                        <img
                          src={Index.Svg.propertyuser}
                          alt="sidebar icon"
                          className="franchises-icons"
                        />
                        User Name
                      </Index.Typography>
                      <Index.Typography className="property-details-data-text">
                        {` ${
                          getRebrandingPropertyData?.created_by?.name
                            ? getRebrandingPropertyData?.created_by?.name
                            : "-"
                        }`}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="property-flex">
                      <Index.Typography className="property-lables">
                        <img
                          src={Index.Svg.propertyemail}
                          alt="sidebar icon"
                          className="franchises-icons"
                        />
                        User Email
                      </Index.Typography>
                      <Index.Typography className="property-details-data-text">
                        {` ${
                          getRebrandingPropertyData?.created_by?.email
                            ? getRebrandingPropertyData?.created_by?.email
                            : "-"
                        }`}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="property-flex">
                      <Index.Typography className="property-lables">
                        <img
                          src={Index.Svg.propertycall}
                          alt="sidebar icon"
                          className="franchises-icons"
                        />
                        User Mobile
                      </Index.Typography>
                      <Index.Typography className="property-details-data-text">
                        {` ${
                          getRebrandingPropertyData?.created_by?.mobile
                            ? getRebrandingPropertyData?.created_by?.mobile
                            : "-"
                        }`}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="property-flex">
                      <Index.Typography className="property-lables">
                        <img
                          src={Index.Png.noOfScreens}
                          alt="sidebar icon"
                          className="franchises-icons"
                        />
                        Total Number Of Screens
                      </Index.Typography>
                      <Index.Typography className="property-details-data-text">
                        {` ${
                          getRebrandingPropertyData?.no_of_screens
                            ? getRebrandingPropertyData?.no_of_screens
                            : "-"
                        }`}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="property-flex">
                      <Index.Typography className="property-lables">
                        <img
                          src={Index.Png.noOfSeats}
                          alt="sidebar icon"
                          className="franchises-icons"
                        />
                        Total Number Of Seats
                      </Index.Typography>
                      <Index.Typography className="property-details-data-text">
                        {` ${
                          getRebrandingPropertyData?.no_of_sheets
                            ? getRebrandingPropertyData?.no_of_sheets
                            : "-"
                        }`}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="property-flex">
                      <Index.Typography className="property-lables">
                        <img
                          src={Index.Svg.propertytype}
                          alt="sidebar icon"
                          className="franchises-icons"
                        />
                        Property Name
                      </Index.Typography>
                      <Index.Typography className="property-details-data-text">
                        {` ${
                          getRebrandingPropertyData?.property_name
                            ? getRebrandingPropertyData?.property_name
                            : "-"
                        }`}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="property-flex">
                      <Index.Typography className="property-lables">
                        <img
                          src={Index.Svg.propertynumber}
                          alt="sidebar icon"
                          className="franchises-icons"
                        />
                        Carpet Area of property(Sq Ft)
                      </Index.Typography>
                      <Index.Typography className="property-details-data-text">
                        {` ${
                          getRebrandingPropertyData?.area_of_property
                            ? getRebrandingPropertyData?.area_of_property
                            : "0"
                        } Sq Ft`}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="property-flex">
                      <Index.Typography className="property-lables">
                        {" "}
                        <img
                          src={Index.Svg.propertystatus}
                          alt="sidebar icon"
                          className="franchises-icons"
                        />
                        Status Of Existing Business Ongoing or Closed
                      </Index.Typography>
                      <Index.Typography className="property-details-data-text">
                        {` ${
                          getRebrandingPropertyData?.existing_business
                            ? getRebrandingPropertyData?.existing_business
                            : "-"
                        }`}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="view-card">
                    <Index.Box
                      display="grid"
                      className="display-row-add-user"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                    >
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column divider-column"
                      >
                        <Index.Box className="franchise-devider-main">
                          <Index.Divider className="franchise-divider">
                            <Index.Chip
                              label="Applicant Documents"
                              className="list-divider"
                            />
                          </Index.Divider>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="dashboard-content">
                          <Index.Box className="admin-dashboard-list-row">
                            <Index.Box sx={{ width: 1 }} className="grid-main">
                              <Index.Box
                                display="grid"
                                className="display-row-userlist"
                                gridTemplateColumns="repeat(12, 1fr)"
                                gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                              >
                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 12",
                                    md: "span 12",
                                    lg: "span 12",
                                  }}
                                  className="grid-column"
                                >
                                  <Index.Box className="rebranding-document-table-main page-table-main">
                                    <Index.TableContainer
                                      component={Index.Paper}
                                      className="table-container"
                                    >
                                      <Index.Table
                                        sx={{ minWidth: 650 }}
                                        aria-label="sticky table"
                                        className="table"
                                      >
                                        <Index.TableHead className="table-head sticky">
                                          <Index.TableRow className="table-row">
                                            <Index.TableCell
                                              component="th"
                                              variant="th"
                                              className="table-th"
                                              align="center"
                                            >
                                              No.
                                            </Index.TableCell>
                                            <Index.TableCell
                                              component="th"
                                              variant="th"
                                              className="table-th"
                                              align="center"
                                            >
                                              Documents
                                            </Index.TableCell>
                                          </Index.TableRow>
                                        </Index.TableHead>
                                        <Index.TableBody className="table-body">
                                          {getRebrandingPropertyData?.documents
                                            ?.length > 0 ? (
                                            getRebrandingPropertyData?.documents

                                              // ?.sort((a, b) => {
                                              //   return a?.property_name?.localeCompare(
                                              //     b.property_name
                                              //   );
                                              // })
                                              ?.map((row, ind) => (
                                                <Index.TableRow
                                                  key={row._id}
                                                  sx={{
                                                    "&:last-child td, &:last-child th":
                                                      {
                                                        border: 0,
                                                      },
                                                  }}
                                                >
                                                  <Index.TableCell
                                                    component="td"
                                                    variant="td"
                                                    scope="row"
                                                    className="table-td"
                                                    align="center"
                                                  >
                                                    {ind + 1}
                                                  </Index.TableCell>
                                                  <Index.TableCell
                                                    component="td"
                                                    variant="td"
                                                    className="table-td"
                                                    align="center"
                                                  >
                                                    <Index.Box className="userdata-btn-flex document-show-action">
                                                      <Index.Box
                                                        key={ind}
                                                        className="document-show-icon"
                                                        onClick={() =>
                                                          handleOpenModal(row)
                                                        }
                                                      >
                                                        <Index.Tooltip
                                                          title="View Document"
                                                          placement="top"
                                                          arrow
                                                        >
                                                          <Index.ListItemText
                                                            button
                                                            primary={`document ${
                                                              ind + 1
                                                            }`}
                                                          />
                                                        </Index.Tooltip>
                                                      </Index.Box>
                                                      <FileModal
                                                        open={modalOpen}
                                                        onClose={
                                                          handleCloseModal
                                                        }
                                                        fileUrl={selectedFile}
                                                      />
                                                    </Index.Box>
                                                  </Index.TableCell>
                                                </Index.TableRow>
                                              ))
                                          ) : (
                                            <Index.TableRow
                                              sx={{
                                                "&:last-child td, &:last-child th":
                                                  {
                                                    border: 0,
                                                  },
                                              }}
                                            >
                                              <Index.TableCell
                                                component="td"
                                                variant="td"
                                                scope="row"
                                                className="table-td no-record-text"
                                                align="center"
                                                colSpan={2}
                                              >
                                                No records found
                                              </Index.TableCell>
                                            </Index.TableRow>
                                          )}
                                        </Index.TableBody>
                                      </Index.Table>
                                    </Index.TableContainer>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        {openModal && (
          <Index.Box className="custom-dialog">
            <Index.Dialog
              open={openModal}
              onClose={closeHandleModal}
              maxWidth="sm"
              fullWidth
              className="commect-modal custom-dialog"
            >
              <Index.DialogTitle className="comment-title">
                Reason For Reject Property
              </Index.DialogTitle>
              <Index.DialogContent>
                <Index.TextField
                  id="description"
                  label="Comment"
                  className="comment-description"
                  multiline
                  minRows={3}
                  value={descriptionValue}
                  onChange={(e) => {
                    const inputValue = e.target.value
                      .replace(/^\s+/, "")
                      .replace(/\s\s+/g, " ");
                    setDescriptionValue(inputValue);
                  }}
                  fullWidth
                />
                <Index.Typography className="reject-comment-validation">
                  {commentValidationMessage}
                </Index.Typography>
              </Index.DialogContent>
              <Index.Button
                type="submit"
                variant="contained"
                className="comment-button"
                onClick={handleRejectedComment}
              >
                {/* <img
                    src={Index.Svg.plus}
                    className="plus-icon"
                    alt="plus icon"
                  /> */}
                Add Comment
              </Index.Button>
              <Index.DialogContent className="comment-time-modal">
                <Index.List>
                  {fullComment?.comment?.map((commentData) => (
                    <Index.ListItem>
                      <Index.Index.ListItemText primary={commentData} />
                    </Index.ListItem>
                  ))}
                </Index.List>
                <Index.List>
                  {fullComment?.time?.map((commentData) => (
                    <Index.ListItem>
                      <Index.Index.ListItemText
                        primary={
                          new Date(commentData).toLocaleDateString("en-GB") +
                          " " +
                          new Date(commentData).toLocaleTimeString("en-US", {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          })
                        }
                      />
                    </Index.ListItem>
                  ))}
                </Index.List>
              </Index.DialogContent>
            </Index.Dialog>
          </Index.Box>
        )}
      </Index.Box>
    </>
  );
} else {
  navigate("/dashboard");
}
};

export default ViewRebrandingProperty;
