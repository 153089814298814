import React from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes as Routess,
  useNavigate,
} from "react-router-dom";
import { createBrowserHistory } from "history";

//#region user
import { useDispatch, useSelector } from "react-redux";
//#endregion

//#region Admin

// import UserPrivateRoutes from "./AdminPrivateRoutes";

import Home from "../container/pages/admin/home/Home";
import Dashboard from "../component/admin/dashboard/Dashboard";
import EditProfile from "../container/pages/admin/editprofile/EditProfile";
import TermsCondition from "../container/pages/admin/terms&conditions/Terms&Condition";
import PrivayPolicy from "../container/pages/admin/privacypolicy/PrivacyPolicy";
import AddRole from "../container/pages/admin/addrole/AddRole";
import Login from "../container/auth/login/Login";
import Otp from "../container/auth/otp/Otp";
import SignIn from "../container/auth/signIn/SignIn";
import RoleList from "../container/pages/admin/addrole/RoleList";
import FranchiseUserList from "../container/pages/admin/franchiseUser/FranchiseUserList";
import GeneralSetting from "../container/pages/admin/generalSetting/GeneralSetting";
import UpdateFranchiseUser from "../container/pages/admin/franchiseUser/UpdateFranchiseUser";
import PrivateRoutes from "./AdminPrivateRoutes";
import PropertyList from "../container/pages/admin/property/PropertyList";

import CouponList from "../container/pages/admin/coupons/CouponList";
import AddCoupon from "../container/pages/admin/coupons/AddCoupon";
import ViewCoupon from "../container/pages/admin/coupons/ViewCoupon";
import AddProperty from "../container/pages/admin/property/AddProperty";
import ViewProperty from "../container/pages/admin/property/ViewProperty";
import FranchiseApplicationList from "../container/pages/admin/franchiseApplication/FranchiseApplicationList";
import ViewFranchiseApplication from "../container/pages/admin/franchiseApplication/ViewFranchiseApplication";
import TransactionHistoryList from "../container/pages/admin/transactionHistory/TransactionHistoryList";
import ViewFranchiseUser from "../container/pages/admin/franchiseUser/ViewFranchiseUserList";
import ViewTransaction from "../container/pages/admin/transactionHistory/ViewTransaction";
import UserPropertyList from "../container/pages/admin/userPropertyList/UserPropertyList";
import UserOwnPropertyList from "../container/pages/admin/userPropertyList/UserOwnPropertyList";

import ViewUserProperty from "../container/pages/admin/userPropertyList/ViewUserProperty";
import ConplexPageNotFound from "../component/common/ConplexPageNotFound";
import ForgotPassword from "../container/auth/forgotPassword/ForgotPassword";
import ResetPassword from "../container/auth/resetPassword/ResetPassword";
import RebrandingPropertyList from "../container/pages/admin/rebrandingProperty/RebrandingPropertyList";
import ViewRebrandingProperty from "../container/pages/admin/rebrandingProperty/ViewRebrandingProperty";
import AddEmployeeRole from "../container/pages/admin/adminEmployeeRole/AddAdminEmployeeRole";
import EmployeeRoleList from "../container/pages/admin/adminEmployeeRole/AdminEmployeeRoleList";
import NotificationList from "../container/pages/admin/notifications/NotificationList";
import TrainingSession from "../container/pages/admin/trainingSession/TrainingSessions";
import StaffHiringList from "../container/pages/admin/staffHiring/StaffHiringList";
import ViewStaffHiring from "../container/pages/admin/staffHiring/ViewStaffHiring";
import UpdateUserProperty from "../container/pages/admin/userPropertyList/UpdateUserProperty";
import ViewTransactionLog from "../container/pages/admin/franchiseApplication/ViewTransactionLog";
import OrientationSession from "../container/pages/admin/orientation/OrientationSession";
import OperationalModal from "../container/pages/admin/operationalModel/OperationalModel";
import TrainingSessionList from "../container/pages/admin/trainingSession/TrainingSessionList";
import OrientationList from "../container/pages/admin/orientation/OrientationList";
import OperationalModelList from "../container/pages/admin/operationalModel/OperationalMOdelList";
import OperationalList from "../container/pages/admin/operationalModel/OperationalMOdelList";
import PageIndex from "../container/PageIndex";
import { useEffect } from "react";
import { getSelfLogoutSubAdmin } from "../redux/slices/AdminService";
import { logout } from "../redux/slices/AdminSlice";
import PaymentTermsCondition from "../container/pages/admin/paymentTerms/AddUpdatePaymentTerms";
import UpdateRebrandingProperty from "../container/pages/admin/rebrandingProperty/UpdateRebrandingProperty";
import PaymentTermsConditions from "../container/pages/admin/paymentTerms/AddUpdatePaymentTerms";
import AddState from "../container/pages/admin/State/AddState";
import StateList from "../container/pages/admin/State/StateList";


//#endregion

const history = createBrowserHistory();
export default function Routes() {
  return (
    <BrowserRouter history={history}>
      <Routess>
        <Route path="*" element={<ConplexPageNotFound />} />
        <Route path="/" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/otp" element={<Otp />} />

        <Route element={<PrivateRoutes />}>
          <Route path="/dashboard" element={<Dashboard />}>
            <Route path="" element={<Home />} />
            <Route path="add-role" element={<AddRole />} />
            <Route path="role-list" element={<RoleList />} />
            <Route path="franchise-user-list" element={<FranchiseUserList />} />
            <Route path="view-franchise-user" element={<ViewFranchiseUser />} />
            <Route
              path="update-franchise-user"
              element={<UpdateFranchiseUser />}
            />
            <Route path="view-transaction" element={<ViewTransaction />} />
            <Route path="general-setting" element={<GeneralSetting />} />
            <Route path="property-list" element={<PropertyList />} />
            <Route path="user-property-list" element={<UserPropertyList />} />
            <Route path="user-own-property-list" element={<UserOwnPropertyList />} />

            <Route
              path="update-user-property"
              element={<UpdateUserProperty />}
            />
            <Route path="view-user-property" element={<ViewUserProperty />} />
            <Route
              path="view-transaction-log"
              element={<ViewTransactionLog />}
            />

            <Route path="view-property" element={<ViewProperty />} />
            <Route path="coupon-list" element={<CouponList />} />
            <Route path="add-coupon" element={<AddCoupon />} />
            <Route path="view-coupon" element={<ViewCoupon />} />
            <Route path="add-property" element={<AddProperty />} />

            <Route path="add-employee-role" element={<AddEmployeeRole />} />
            <Route path="employee-role-list" element={<EmployeeRoleList />} />
            <Route path="add-state" element={<AddState />} />
            <Route path="state-list" element={<StateList />} />


            <Route
              path="franchise-application"
              element={<FranchiseApplicationList />}
            />
            <Route
              path="view-franchise-application"
              element={<ViewFranchiseApplication />}
            />
            <Route
              path="transaction-list"
              element={<TransactionHistoryList />}
            />
            <Route
              path="rebranding-list"
              element={<RebrandingPropertyList />}
            />
            <Route
              path="update-rebranding-property"
              element={<UpdateRebrandingProperty />}
            />
            <Route
              path="view-rebranding"
              element={<ViewRebrandingProperty />}
            />
            {/* <Route path="view-transaction" element={<ViewTra />} /> */}

            <Route path="edit-profile" element={<EditProfile />} />
            <Route
              path="/dashboard/terms-condition"
              element={<TermsCondition />}
            />
            <Route
              path="/dashboard/payment-terms"
              element={<PaymentTermsConditions />}
            />
            <Route
              path="/dashboard/training-session"
              element={<TrainingSession />}
            />
            <Route
              path="/dashboard/training-session-list"
              element={<TrainingSessionList />}
            />
            <Route
              path="/dashboard/orientation-session"
              element={<OrientationSession />}
            />
            <Route
              path="/dashboard/orientation-session-list"
              element={<OrientationList />}
            />
            <Route
              path="/dashboard/oprational-session"
              element={<OperationalModal />}
            />
            <Route
              path="/dashboard/oprational-session-list"
              element={<OperationalList />}
            />
            <Route
              path="/dashboard/privacy-policy"
              element={<PrivayPolicy />}
            />
            <Route
              path="/dashboard/notification-list"
              element={<NotificationList />}
            />
            <Route
              path="/dashboard/staff-hiring-list"
              element={<StaffHiringList />}
            />
            <Route
              path="/dashboard/view-staff-hiring"
              element={<ViewStaffHiring />}
            />
           
          </Route>
        </Route>
      </Routess>
    </BrowserRouter>
  );
}
