import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./FranchiseApplication.css";
import PageIndex from "../../../PageIndex";
import { allUserRazorPayTransaction } from "../../../../redux/slices/AdminSlice";

const ViewTransactionLog = () => {
  const dispatch = PageIndex.useDispatch();
  let navigate = PageIndex.useNavigate();
  const { viewAllUserTransactionData } = PageIndex.useSelector(
    (state) => state.admin
  );
  const [openCommentModal, setOpenCommentModal] = useState(false);
  const [fullRazorData, setFullRazorData] = useState({});
  const [search, setSearch] = useState("");
  const [pageData, setPageData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const closeHandleRejectCommentModal = () => {
    setOpenCommentModal(false);
  };

  const handleOpenCommentModal = (razorpayData) => {
    setFullRazorData(razorpayData);
    // setOpenModal(true);
    setOpenCommentModal(true);
  };

  useEffect(() => {
    const filtered = viewAllUserTransactionData
      ?.filter((item) => {
        return !search
          ? item
          : // item?.options.toLowerCase().includes(search?.toLowerCase()) ||
            item?.property_id?.property_name
              ?.toLowerCase()
              .includes(search?.toLowerCase()) ||
              item?.amount_type
                ?.toLowerCase()
                .includes(search?.toLowerCase()) ||
              item?.user_id?.name
                .toLowerCase()
                .includes(search?.toLowerCase()) ||
              item?.transaction_id
                ?.toLowerCase()
                .includes(search?.toLowerCase()) ||
              item?.pay_amount?.toString().includes(search?.toString()) ||
              item?.pay_remaining_amount
                ?.toString()
                .includes(search?.toString());
      })
      ?.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateB - dateA;
      });
    if (filtered) {
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  }, [viewAllUserTransactionData, search]);

  return (
    <>
      <Index.Box
        display="grid"
        className="display-row-add-user"
        gridTemplateColumns="repeat(12, 1fr)"
        gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
      >
        <Index.Box
          gridColumn={{
            xs: "span 12",
            sm: "span 6",
            md: "span 12",
            lg: "span 12",
          }}
          className="grid-column"
        >
          <Index.Box className="view-franchises-details-box view-franchise-box">
            <Index.Typography
              variant="h4"
              className="proerty-details-titles view-frenchises-user-title"
            >
              Transaction Log Of{" "}
              {viewAllUserTransactionData[0]?.property_id?.property_name
                ? viewAllUserTransactionData[0]?.property_id?.property_name
                : viewAllUserTransactionData[0]?.rebranding_id?.property_name
                ? viewAllUserTransactionData[0]?.rebranding_id?.property_name
                : "Land"}
            </Index.Typography>

            {viewAllUserTransactionData[0]?.property_id?.project_address ||
            viewAllUserTransactionData[0]?.rebranding_id?.property_location ? (
              <Index.Box className="property-flex">
                <Index.Typography className="property-details-data-text">
                  <img
                    src={Index.Svg.propertylocation}
                    alt="sidebar icon"
                    className="franchises-icons"
                  />
                  {viewAllUserTransactionData[0]?.property_id?.project_address
                    ? `${viewAllUserTransactionData[0]?.property_id?.project_address?.city},${viewAllUserTransactionData[0]?.property_id?.project_address?.state} ,${viewAllUserTransactionData[0]?.property_id?.project_address?.pincode}`
                    : `${viewAllUserTransactionData[0]?.rebranding_id?.property_location?.city},${viewAllUserTransactionData[0]?.rebranding_id?.property_location?.state} ,${viewAllUserTransactionData[0]?.rebranding_id?.property_location?.pincode}`}
                </Index.Typography>
              </Index.Box>
            ) : (
              ""
            )}
            <Index.Box className="property-detail-box view-user-property-box">
              {viewAllUserTransactionData[0]?.property_id?.propertyCode && (
                <Index.Box className="property-flex">
                  <Index.Typography className="property-lables">
                    {" "}
                    <img
                      src={Index.Svg.propertytype}
                      alt="sidebar icon"
                      className="franchises-icons"
                    />
                    Property Code
                  </Index.Typography>
                  <Index.Typography className="property-details-data-text">
                    {`  ${viewAllUserTransactionData[0]?.property_id?.propertyCode}`}
                  </Index.Typography>
                </Index.Box>
              )}
              <Index.Box className="property-flex">
                <Index.Typography className="property-lables">
                  {" "}
                  <img
                    src={Index.Svg.propertytype}
                    alt="sidebar icon"
                    className="franchises-icons"
                  />
                  Property Type
                </Index.Typography>
                <Index.Typography className="property-details-data-text">
                  {viewAllUserTransactionData[0]?.property_id?.property_type
                    ? `${viewAllUserTransactionData[0]?.property_id?.property_type}`
                    : `Covert Your Existing Cinema`}
                </Index.Typography>
              </Index.Box>
              {viewAllUserTransactionData[0]?.rebranding_id?.cinema_name && (
                <Index.Box className="property-flex">
                  <Index.Typography className="property-lables">
                    {" "}
                    <img
                      src={Index.Png.theatreName}
                      alt="sidebar icon"
                      className="franchises-icons"
                    />
                    Cinema Name
                  </Index.Typography>
                  <Index.Typography className="property-details-data-text">
                    {viewAllUserTransactionData[0]?.rebranding_id?.cinema_name}
                  </Index.Typography>
                </Index.Box>
              )}
              {viewAllUserTransactionData[0]?.property_id?.project_status && (
                <Index.Box className="property-flex">
                  <Index.Typography className="property-lables">
                    {" "}
                    <img
                      src={Index.Png.propertyStatus}
                      alt="sidebar icon"
                      className="franchises-icons"
                    />
                    Property Status
                  </Index.Typography>
                  <Index.Typography className="property-details-data-text">
                    {`  ${viewAllUserTransactionData[0]?.property_id?.project_status}`}
                  </Index.Typography>
                </Index.Box>
              )}
              {viewAllUserTransactionData[0]?.property_id
                ?.land_connectivity && (
                <Index.Box className="property-flex">
                  <Index.Typography className="property-lables">
                    {" "}
                    <img
                      src={Index.Png.landConnectivity}
                      alt="sidebar icon"
                      className="franchises-icons"
                    />
                    Land Connectivity
                  </Index.Typography>
                  <Index.Typography className="property-details-data-text">
                    {`  ${viewAllUserTransactionData[0]?.property_id?.land_connectivity}`}
                  </Index.Typography>
                </Index.Box>
              )}
              {viewAllUserTransactionData[0]?.property_id?.land_type && (
                <Index.Box className="property-flex">
                  <Index.Typography className="property-lables">
                    {" "}
                    <img
                      src={Index.Png.landType}
                      alt="sidebar icon"
                      className="franchises-icons"
                    />
                    Land Type
                  </Index.Typography>
                  <Index.Typography className="property-details-data-text">
                    {`  ${viewAllUserTransactionData[0]?.property_id?.land_type}`}
                  </Index.Typography>
                </Index.Box>
              )}
              {viewAllUserTransactionData[0]?.property_id?.size_of_the_land && (
                <Index.Box className="property-flex">
                  <Index.Typography className="property-lables">
                    {" "}
                    <img
                      src={Index.Svg.propertyarea}
                      alt="sidebar icon"
                      className="franchises-icons"
                    />
                    Size Of The Land
                  </Index.Typography>
                  <Index.Typography className="property-details-data-text">
                    {`  ${viewAllUserTransactionData[0]?.property_id?.size_of_the_land}`}
                  </Index.Typography>
                </Index.Box>
              )}
              <Index.Box className="property-flex">
                <Index.Typography className="property-lables">
                  {" "}
                  <img
                    src={Index.Svg.propertyuser}
                    alt="sidebar icon"
                    className="franchises-icons"
                  />
                  User Name
                </Index.Typography>
                <Index.Typography className="property-details-data-text">
                  {`  ${viewAllUserTransactionData[0]?.user_id?.name}`}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="property-flex">
                <Index.Typography className="property-lables">
                  <img
                    src={Index.Svg.propertyemail}
                    alt="sidebar icon"
                    className="franchises-icons"
                  />
                  User Email
                </Index.Typography>
                <Index.Typography className="property-details-data-text">
                  {`  ${viewAllUserTransactionData[0]?.user_id?.email}`}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="property-flex">
                <Index.Typography className="property-lables">
                  {" "}
                  <img
                    src={Index.Svg.propertycall}
                    alt="sidebar icon"
                    className="franchises-icons"
                  />
                  User mobile
                </Index.Typography>
                <Index.Typography className="property-details-data-text">
                  {`  ${viewAllUserTransactionData[0]?.user_id?.mobile}`}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="property-flex">
                <Index.Typography className="property-lables">
                  {" "}
                  <img
                    src={Index.Png.panCard}
                    alt="sidebar icon"
                    className="franchises-icons"
                  />
                  User{" "}
                  {viewAllUserTransactionData[0]?.user_id?.docType ===
                  "Pan Card Number"
                    ? "Pan Card"
                    : "GST"}{" "}
                  No.
                </Index.Typography>
                <Index.Typography className="property-details-data-text">
                  {`  ${
                    viewAllUserTransactionData[0]?.user_id?.docNumber
                      ? viewAllUserTransactionData[0]?.user_id?.docNumber
                      : "---"
                  }`}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <PageIndex.LoadingOverlay
        spinner
        text="Loading your content..."
        active={loading}
      >
        <Index.Box className="dashboard-content">
          <Index.Box className="user-list-flex">
            <Index.Box className="admin-page-title-main">
              <Index.Typography
                className="admin-page-title"
                component="h2"
                variant="h2"
              >
                Transaction Log
              </Index.Typography>
            </Index.Box>
            <Index.Box className="userlist-btn-flex">
              <Index.Box className="user-search-main">
                <Index.Box className="user-search-box">
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      placeholder="Search Transaction"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <span className="search-icon-box">
                      <img
                        alt="search_icon"
                        src={Index.Svg.search}
                        className="search-icon"
                      />
                    </span>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="admin-dashboard-list-row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row-userlist"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dash-box">
                    <Index.Box className="transactionlist-log-table-main page-table-main">
                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                      >
                        <Index.Table
                          sx={{ minWidth: 650 }}
                          aria-label="sticky table"
                          className="table"
                        >
                          <Index.TableHead className="table-head sticky">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                No.
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Transaction Id
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                User Name
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Property Name
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Transaction Date
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Amount Type
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Pay Amount
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Remaining Amount
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Status
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Action
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {pageData?.length ? (
                              pageData?.map((row, ind) => (
                                <Index.TableRow
                                  key={row._id}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td"
                                    align="center"
                                  >
                                    {row.sNo ? row.sNo : "-"}
                                  </Index.TableCell>

                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td"
                                    align="center"
                                  >
                                    {row?.transaction_id
                                      ? row?.transaction_id
                                      : "-"}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td"
                                    align="center"
                                  >
                                    {row?.user_id === null
                                      ? "----"
                                      : row?.user_id?.name}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td"
                                    align="center"
                                  >
                                    {row?.property_id?.property_type === "Land"
                                      ? "Land"
                                      : !row.property_id
                                      ? row.rebranding_id?.property_name
                                      : row.property_id?.property_name}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td"
                                    align="center"
                                  >
                                    {new Date(row.createdAt).toLocaleDateString(
                                      "en-GB"
                                    ) +
                                      " " +
                                      new Date(
                                        row.createdAt
                                      ).toLocaleTimeString("en-US", {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        hour12: true,
                                      })}
                                  </Index.TableCell>

                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td"
                                    align="center"
                                  >
                                    {row?.amount_type ? row?.amount_type : "-"}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td"
                                    align="center"
                                  >
                                    {row?.pay_amount !== undefined
                                      ? `₹ ${row?.pay_amount?.toLocaleString(
                                          "en-IN"
                                        )}`
                                      : `₹ ${row?.property_id?.franchise_amount?.toLocaleString(
                                          "en-IN"
                                        )}`}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td"
                                    align="center"
                                  >
                                    {!row?.property_id
                                      ? "----"
                                      : row?.pay_remaining_amount === undefined
                                      ? `----`
                                      : row?.pay_remaining_amount === null ||
                                        !row?.property_id?.franchise_amount
                                      ? "----"
                                      : `₹ ${row?.pay_remaining_amount?.toLocaleString(
                                          "en-IN"
                                        )}`}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                    align="center"
                                  >
                                    <div
                                      className={
                                        row?.razorPayments?.status ===
                                        "captured"
                                          ? "table-td-active-transaction"
                                          : row?.razorPayments?.status ===
                                            "failed"
                                          ? "table-td-Inactive-transaction"
                                          : "table-td-Pending-transaction"
                                      }
                                    >
                                      {row?.razorPayments?.status === "captured"
                                        ? "Success"
                                        : row?.razorPayments?.status ===
                                          "failed"
                                        ? "Failed"
                                        : "---"}
                                    </div>
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                    align="center"
                                  >
                                    <Index.Box className="userdata-btn-flex">
                                      <Index.IconButton
                                        color="primary"
                                        aria-label="upload picture"
                                        component="label"
                                        // onClick={() => {
                                        //   dispatch(
                                        //     allUserRazorPayTransaction(row)
                                        //   );
                                        //   navigate(
                                        //     "/dashboard/view-razorpay-transaction",
                                        //     {
                                        //       state: row,
                                        //     }
                                        //   );
                                        // }}

                                        onClick={() =>
                                          handleOpenCommentModal(row)
                                        }
                                      >
                                        <Index.RemoveRedEyeIcon />
                                      </Index.IconButton>
                                    </Index.Box>
                                  </Index.TableCell>
                                </Index.TableRow>
                              ))
                            ) : (
                              <Index.TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td no-record-text"
                                  align="center"
                                  colSpan={8}
                                >
                                  No records found
                                </Index.TableCell>
                              </Index.TableRow>
                            )}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="pagination-main">
            <PageIndex.Pagination
              fetchData={filteredData}
              setPageData={setPageData}
              pageData={pageData}
              search={search}
            />
          </Index.Box>
        </Index.Box>
        {openCommentModal && (
          <Index.Box className="custom-dialog">
            <Index.Dialog
              open={openCommentModal}
              onClose={closeHandleRejectCommentModal}
              maxWidth="sm"
              fullWidth
              className="commect-modal custom-dialog "
            >
              <Index.DialogContent className="comment-time-modal-razorpay">
                <>
                  {fullRazorData?.razorPayments ? (
                    <Index.Box
                      display="grid"
                      className="display-row-add-user-razordata"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                    >
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="view-franchises-details-box view-franchise-box">
                          <Index.Typography
                            variant="h4"
                            className="proerty-details-titles view-frenchises-user-title"
                          >
                            Razorpay Transaction Of{" "}
                            {fullRazorData?.property_id?.property_name
                              ? fullRazorData?.property_id?.property_name
                              : fullRazorData?.rebranding_id?.property_name
                              ? fullRazorData?.rebranding_id?.property_name
                              : "Land"}
                          </Index.Typography>
                          <Index.Box className="property-detail-box view-user-property-box-razorpay">
                            {fullRazorData?.razorPayments?.id && (
                              <Index.Box className="property-flex">
                                <Index.Typography className="property-lables">
                                  {" "}
                                  <img
                                    src={Index.Png.razorId}
                                    alt="sidebar icon"
                                    className="franchises-icons"
                                  />
                                  Transaction Id
                                </Index.Typography>
                                <Index.Typography className="property-details-data-text">
                                  {`  ${
                                    fullRazorData?.razorPayments?.id
                                      ? fullRazorData?.razorPayments?.id
                                      : "-"
                                  }`}
                                </Index.Typography>
                              </Index.Box>
                            )}
                            {fullRazorData?.property_id?.propertyCode && (
                              <Index.Box className="property-flex">
                                <Index.Typography className="property-lables">
                                  {" "}
                                  <img
                                    src={Index.Svg.propertytype}
                                    alt="sidebar icon"
                                    className="franchises-icons"
                                  />
                                  Property Code
                                </Index.Typography>
                                <Index.Typography className="property-details-data-text">
                                  {`  ${
                                    fullRazorData?.property_id?.propertyCode
                                      ? fullRazorData?.property_id?.propertyCode
                                      : "-"
                                  }`}
                                </Index.Typography>
                              </Index.Box>
                            )}
                            <Index.Box className="property-flex">
                              <Index.Typography className="property-lables">
                                {" "}
                                <img
                                  src={Index.Svg.propertyuser}
                                  alt="sidebar icon"
                                  className="franchises-icons"
                                />
                                User Name
                              </Index.Typography>
                              <Index.Typography className="property-details-data-text">
                                {`  ${
                                  fullRazorData?.user_id?.name
                                    ? fullRazorData?.user_id?.name
                                    : "-"
                                }`}
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="property-flex">
                              <Index.Typography className="property-lables">
                                {" "}
                                <img
                                  src={Index.Svg.propertycall}
                                  alt="sidebar icon"
                                  className="franchises-icons"
                                />
                                User mobile
                              </Index.Typography>
                              <Index.Typography className="property-details-data-text">
                                {`  ${
                                  fullRazorData?.user_id?.mobile
                                    ? fullRazorData?.user_id?.mobile
                                    : "-"
                                }`}
                              </Index.Typography>
                            </Index.Box>
                            {fullRazorData?.amount_type && (
                              <Index.Box className="property-flex">
                                <Index.Typography className="property-lables">
                                  {" "}
                                  <img
                                    src={Index.Png.propertyStatus}
                                    alt="sidebar icon"
                                    className="franchises-icons"
                                  />
                                  Payment Type
                                </Index.Typography>
                                <Index.Typography className="property-details-data-text">
                                  {`  ${
                                    fullRazorData?.amount_type
                                      ? fullRazorData?.amount_type
                                      : "-"
                                  }`}
                                </Index.Typography>
                              </Index.Box>
                            )}
                            {fullRazorData?.razorPayments?.amount && (
                              <Index.Box className="property-flex">
                                <Index.Typography className="property-lables">
                                  {" "}
                                  <img
                                    src={Index.Png.investAmount}
                                    alt="sidebar icon"
                                    className="franchises-icons"
                                  />
                                  Pay Amount
                                </Index.Typography>
                                <Index.Typography className="property-details-data-text">
                                  {`  ${fullRazorData?.razorPayments?.amount?.toLocaleString(
                                    "en-IN"
                                  )}`}
                                </Index.Typography>
                              </Index.Box>
                            )}
                            {fullRazorData?.razorPayments?.method && (
                              <Index.Box className="property-flex">
                                <Index.Typography className="property-lables">
                                  {" "}
                                  <img
                                    src={Index.Png.payBy}
                                    alt="sidebar icon"
                                    className="franchises-icons"
                                  />
                                  Pay By
                                </Index.Typography>
                                <Index.Typography className="property-details-data-text">
                                  {`  ${
                                    fullRazorData?.razorPayments?.method
                                      ? fullRazorData?.razorPayments?.method
                                      : "-"
                                  }`}
                                </Index.Typography>
                              </Index.Box>
                            )}
                            {fullRazorData?.razorPayments?.card?.last4 && (
                              <Index.Box className="property-flex">
                                <Index.Typography className="property-lables">
                                  {" "}
                                  <img
                                    src={Index.Png.cradNumber}
                                    alt="sidebar icon"
                                    className="franchises-icons"
                                  />
                                  Card No.
                                </Index.Typography>
                                <Index.Typography className="property-details-data-text">
                                  {`  ${
                                    fullRazorData?.razorPayments?.card?.last4
                                      ? fullRazorData?.razorPayments?.card
                                          ?.last4
                                      : "-"
                                  }`}
                                </Index.Typography>
                              </Index.Box>
                            )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  ) : (
                    <Index.Box
                      display="grid"
                      className="display-row-add-user-razordata"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                    >
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="view-franchises-details-box view-franchise-box">
                          <Index.Typography
                            variant="h4"
                            className="proerty-details-titles view-frenchises-user-title"
                          >
                            Razorpay Transaction Not Found
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  )}
                </>
              </Index.DialogContent>
            </Index.Dialog>
          </Index.Box>
        )}
      </PageIndex.LoadingOverlay>
    </>
  );
};

export default ViewTransactionLog;
