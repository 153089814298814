import usericon from "./images/png/user.png";
import loginbg from "./images/png/login-bg.png";
import userlist1 from "./images/png/userlist1.png";
import bergurmenu from "./images/png/bergur-menu.png";
import graph1 from "./images/png/graph1.png";
import dashboadgraph from "./images/png/dashboard-graph.png";
import logo from "./images/png/logo.png";
import headerlogo from "./images/png/conplexLogo.png";
import pagenotfound from "./images/png/pagenotfound.png";
import conplexlistimage from "./images/png/conplexlistimages.png";
import staticLogoImage from "./images/png/StaticLogoimage.png";
import adminUserLogo from "./images/png/AdminUserLogo.png";
import roleListLogo from "./images/png/RoleListLogo.png";
import franchiseUserLogo from "./images/png/FranchiseUserLogo.png";
import requestedPropertyList from "./images/png/RequestedPropertyList.png";
import rebrandingPropertyList from "./images/png/RebrandingPropertyList.png";
import staffHiringLogo from "./images/png/StaffHiring.png";
// import jobVacancy from "./images/png/search.png";
import jobVacancy from "./images/png/vacancy.png";
// import salary from "./images/png/salary.png";
import salary from "./images/png/earnings.png";
// import jobTime from "./images/png/JobTime.png";
import jobTime from "./images/png/working-hours.png";
// import validTime from "./images/png/validTime.png";
import validTime from "./images/png/calendar-event.png";
import jobDescription from "./images/png/job-description.png";
import propertyStatus from "./images/png/PropertyStatus.png";
import investAmount from "./images/png/Amount.png";
import voucherTitle from "./images/png/voucherTitle.png";
import voucherDiscount from "./images/png/voucherDiscount.png";
import landConnectivity from "./images/png/landconnectivity.png";
import noOfScreens from "./images/png/noOFScreens.png";
import noOfSeats from "./images/png/cinema-seat.png";
import theatreName from "./images/png/theater-name.png";
import landType from "./images/png/landType.png";
import panCard from "./images/png/id-card.png";
import razorId from "./images/png/id.png";
import payBy from "./images/png/credit-card-payment.png";
import cradNumber from "./images/png/credit-card.png";

const Png = {
  cradNumber,
  payBy,
  razorId,
  panCard,
  landType,
  theatreName,
  noOfSeats,
  noOfScreens,
  landConnectivity,
  voucherDiscount,
  voucherTitle,
  investAmount,
  propertyStatus,
  jobDescription,
  validTime,
  jobTime,
  salary,
  jobVacancy,
  staffHiringLogo,
  rebrandingPropertyList,
  requestedPropertyList,
  franchiseUserLogo,
  roleListLogo,
  adminUserLogo,
  staticLogoImage,
  conplexlistimage,
  pagenotfound,
  usericon,
  loginbg,
  userlist1,
  bergurmenu,
  graph1,
  dashboadgraph,
  logo,
  headerlogo,
};

export default Png;
